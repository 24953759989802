import React, { useMemo } from 'react';
import { Container, Subtitle, BookingContainer, BoldText, Title } from './styles';
import { useSelector } from 'react-redux';
import { selectResource } from 'store/resource/selectors';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'shared/types/search-params';
import { useIntl } from 'react-intl';
import { Booking } from 'components/payment/quick-checkout/components/multiday-checkout-content/booking/booking';
import { useLocale } from 'hooks/use-locale.hook';
import { joinArrayItemsWithOxfordComma } from 'components/payment/quick-checkout/components/multiday-checkout-content/utils/joinItemsWithOxfordComma';
import { formatStartDatesQueryParams } from 'components/payment/quick-checkout/components/multiday-checkout-content/utils/formatStartDatesQueryParam';
import { useMultidayQuickCheckoutContent } from 'components/payment/quick-checkout/components/multiday-checkout-content/hooks/useMultidayQuickCheckoutContent.hook';

export const MultidayQuickCheckoutContent = (): JSX.Element => {
  const intl = useIntl();
  const resource = useSelector(selectResource);
  const locale = useLocale();
  useMultidayQuickCheckoutContent();

  const { startDates, startTime, endTime } = useSearchParams<searchParams>();
  const formattedDatesString = formatStartDatesQueryParams(startDates, locale);
  const andSeparator = intl.formatMessage({ id: 'and' });
  const sortedDates = useMemo<Array<string>>(() => {
    const formattedStartDates = startDates?.split(',') || [];

    return formattedStartDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  }, [startDates]);

  return (
    <Container>
      <Subtitle>
        {intl.formatMessage(
          { id: 'you_are_about_book' },
          {
            serviceName: <BoldText>{resource.name}</BoldText>,
            serviceTime: <BoldText>{joinArrayItemsWithOxfordComma(formattedDatesString, andSeparator)}</BoldText>,
          },
        )}
      </Subtitle>
      <BookingContainer data-testid="multiday-booking-container">
        <Title>{resource.name}</Title>
        {sortedDates.map(date => (
          <Booking date={date} timeRange={{ startTime, endTime }} />
        ))}
      </BookingContainer>
    </Container>
  );
};
