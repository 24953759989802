import styled from 'styled-components';
import { ResourceTile } from 'components/skeleton/styles';
import { VerticalContentTile } from '@hqo/react-components-library/dist/molecules/tiles/vertical-content-tile';
import { ContentTile } from '@hqo/react-components-library/dist/molecules/tiles/content-tile';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';

export const StyledVerticalContentTile = styled(VerticalContentTile)`
  color: ${({ theme }) => theme.colors.fontColor};
  width: 308px;

  .resource-tile-image {
    width: 308px;
    height: 205px;
  }
`;

export const StyledContentTile = styled(ContentTile)`
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const ResponsiveResourceTile = styled(ResourceTile)`
  border-radius: 6px;
  width: 200px;
  height: 128px;
  object-fit: cover;

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    width: 120px;
    height: 80px;
  }
`;
