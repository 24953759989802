import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ResourceBookingEnum } from 'store/app-instance-configs/enums';
import { selectAppInstanceConfigs, selectCurrentAppInstanceConfigUuid } from 'store/app-instance-configs/selectors';

export const useIsAppInstanceConfigNexudus = (): boolean => {
  const appInstancesConfigs = useSelector(selectAppInstanceConfigs);
  const currentConfigUuid = useSelector(selectCurrentAppInstanceConfigUuid);

  return useMemo(() => {
    return (
      appInstancesConfigs?.find(config => config?.uuid === currentConfigUuid)?.vertical_adapter?.name ===
      ResourceBookingEnum.NexudusAdapter
    );
  }, [appInstancesConfigs, currentConfigUuid]);
};
