import { useEffect } from 'react';
import { DropdownOption } from '../types';

interface UseDropDownOptions {
  value?: string;
  options: Array<DropdownOption>;
  dropRef?: React.MutableRefObject<HTMLDivElement>;
  defaultValueIndex?: number;
}

export const useDropDownOptions = ({ value, options, dropRef, defaultValueIndex }: UseDropDownOptions): void => {
  useEffect(() => {
    if (value) {
      const index = options.findIndex(({ label }) => label === value);
      dropRef.current.scrollTo({ top: index * 46 });
    } else if (defaultValueIndex) {
      dropRef.current.scrollTo({ top: defaultValueIndex * 46 });
    }
  }, [defaultValueIndex, dropRef, options, value]);
};
