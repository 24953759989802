import React from 'react';
import { useSelector } from 'react-redux';
import { resourcesState } from 'store/resources/selectors';
import { StyledCompactContentTile } from '../styles';
import { TileButton } from 'pages/resources/content/tile-button';
import { CapacityBlock } from 'components/capacity-block';
import { useResourceTile } from 'hooks/use-resource-tile.hook';

interface DefaultViewProps {
  toggleReverseAnimation: VoidFunction;
  toggleModalSlideAnimation: VoidFunction;
}

export const DefaultView = ({ toggleReverseAnimation, toggleModalSlideAnimation }: DefaultViewProps): JSX.Element => {
  const { resources } = useSelector(resourcesState);
  const { buttonType, onButtonClick } = useResourceTile({ toggleReverseAnimation, toggleModalSlideAnimation });

  return (
    <>
      {resources?.map(resource => {
        return (
          <StyledCompactContentTile
            key={resource.uuid}
            title={resource.name}
            statusLabels={[<CapacityBlock capacity={+resource.capacity} title={resource.name} key="3" />]}
            button={<TileButton buttonType={buttonType} onButtonClick={onButtonClick} resourceUuid={resource.uuid} />}
          />
        );
      })}
    </>
  );
};
