import React from 'react';
import { SwitchDefaultFilter } from '../hocs/switch-default-filter';
import { DefaultFilterEnum } from 'shared/consts';
import { Capacity } from '../capacity';
import { ResourcesTypes } from '../resources-types';

interface DefaultFilterProps {
  defaultFilter: DefaultFilterEnum;
}

export const DefaultFilter = ({ defaultFilter }: DefaultFilterProps): JSX.Element => {
  return (
    <SwitchDefaultFilter value={defaultFilter}>
      <Capacity data-value={DefaultFilterEnum.CAPACITY} />
      <ResourcesTypes data-value={DefaultFilterEnum.RESOURCE_TYPE} />
    </SwitchDefaultFilter>
  );
};
