import styled from 'styled-components';
import { Chip } from '@hqo/react-components-library/dist/atoms/chip';

export const FilterBarContainer = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  margin-bottom: 10px;
  gap: 13px;
  align-items: center;
  flex: 1;
  min-width: 50%;
`;

export const ChipContainer = styled.div`
  padding: 2px;
  overflow: scroll;
  display: flex;
  gap: 10px;
  width: 100%;
  min-height: fit-content;
  ::-webkit-scrollbar {
    width: 0px;
    display: none;
  }
`;

export const StyledChipComponent = styled(Chip)`
  min-width: fit-content;
`;

export const FilterSearchBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  .touch-search-bar-component {
    margin-bottom: 0;
  }
`;

export const FilterSearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 423px;
  margin-right: auto;
  justify-content: space-between;
  padding-right: 10px;
`;
