import { GhostCalendarView } from 'pages/date-select-page/components/ghost-calendar-view';
import { StyledCalendarView } from 'pages/date-select-page/components/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { ACTION_STATUSES } from 'shared/consts';
import {
  selectResourceInfiniteAvailableDates,
  selectResourceInfiniteAvailableDatesStatus,
} from 'store/resource-available-dates/selectors';
import { useInfiniteCalendar } from './hooks/use-infinite-calendar-view.hook';

interface InfiniteCalendarViewProps {
  indexOfMonth: number;
}

export const InfiniteCalendarView = ({ indexOfMonth }: InfiniteCalendarViewProps): JSX.Element => {
  const resourceAvailableDates = useSelector(selectResourceInfiniteAvailableDates);

  const { title, days, onDateClick } = useInfiniteCalendar(indexOfMonth);
  const isPending =
    (useSelector(selectResourceInfiniteAvailableDatesStatus) === ACTION_STATUSES.PENDING &&
      !resourceAvailableDates[indexOfMonth]) ||
    days.length === 0;

  return isPending ? (
    <GhostCalendarView />
  ) : (
    <StyledCalendarView
      showDayLabels={false}
      title={title}
      data={days}
      onDateClick={onDateClick}
      showRightArrow={false}
      isFlexStyle
    />
  );
};
