import { useCallback, useEffect, useState } from 'react';

interface ReturnValueTypes {
  ref: React.MutableRefObject<HTMLDivElement>;
  isOverflowing: boolean;
  isExpanded: boolean;
  onExpand: VoidFunction;
}

export const useAmenities = (
  isMobileDevice: boolean,
  ref: React.MutableRefObject<HTMLDivElement>,
): ReturnValueTypes => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const onExpand = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded, setIsExpanded]);

  useEffect(() => {
    if (ref.current.scrollHeight > 44 && isMobileDevice) {
      setIsOverflowing(true);
    } else {
      setIsOverflowing(false);
    }
  }, [ref, setIsOverflowing, isOverflowing, isMobileDevice]);

  return {
    ref,
    isOverflowing,
    isExpanded,
    onExpand,
  };
};
