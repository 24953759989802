import React from 'react';

import { DefaultView } from './default-view';
import { InifiniteView } from './infinite-view';
import { Route, Routes } from 'react-router-dom';
import { DateTimeSelectModal } from 'pages/resource/date-time-select-modal';
import { useDateScheduleModalNavigation } from 'hooks/use-date-schedule-modal-navigation.hook';
import { PaymentIFrame } from 'components/paymentIFrame';
import { usePaymentModal } from 'hooks/payment-hook/use-payment-modal.hook';
import { SwipePaymentIFrame } from 'components/swipePaymentIFrame';
import { useQuickCheckout } from 'hooks/use-quick-checkout.hook';
import { PaymentContent } from 'components/payment-content';
import { useReceiptScreen } from 'hooks/payment-content/use-receipt-screen.hook';
import { useCheckoutScreen } from 'hooks/payment-content/use-checkout-screen.hook';

export interface InifiniteContentProps {
  scrollContainerRef: React.MutableRefObject<HTMLDivElement>;
}

export const InifiniteContent = ({ scrollContainerRef }: InifiniteContentProps): JSX.Element => {
  const {
    reverseHorizontalAnimation,
    isModalWithoutSlideAnimation,
    onPressSave,
    toggleModal,
    onNextClick,
    onTimeLinkClick,
    onDateLinkClick,
    isDateStep,
    backToDateStep,
    onPressBack,
    toggleReverseAnimation,
    toggleModalSlideAnimation,
  } = useDateScheduleModalNavigation();
  const { closeQuickCheckoutHandler, openQuickCheckoutHandler } = useQuickCheckout();
  const { reverseAnimation } = usePaymentModal(openQuickCheckoutHandler);
  const {
    isCancelBookingStep,
    setIsCancelBookingStep,
    onCloseReceipt,
    onPressBack: onPressBackReceipt,
    openReceipt,
  } = useReceiptScreen();
  const { onCloseCheckout, openCheckout } = useCheckoutScreen();

  return (
    <>
      <DefaultView
        toggleReverseAnimation={toggleReverseAnimation}
        toggleModalSlideAnimation={toggleModalSlideAnimation}
      />
      <InifiniteView
        scrollContainerRef={scrollContainerRef}
        toggleReverseAnimation={toggleReverseAnimation}
        toggleModalSlideAnimation={toggleModalSlideAnimation}
      />
      <PaymentContent
        openReceipt={openReceipt}
        reverseAnimation={reverseAnimation}
        closeQuickCheckoutHandler={closeQuickCheckoutHandler}
        isCancelBookingStep={isCancelBookingStep}
        setIsCancelBookingStep={setIsCancelBookingStep}
        onCloseReceipt={onCloseReceipt}
        onPressBack={onPressBackReceipt}
        onCloseCheckout={onCloseCheckout}
        openCheckout={openCheckout}
      />
      <Routes>
        <Route
          path="date-schedule-select"
          element={
            <DateTimeSelectModal
              reverseHorizontalAnimation={reverseHorizontalAnimation}
              isModalWithoutSlideAnimation={isModalWithoutSlideAnimation}
              onPressSave={onPressSave}
              onClose={toggleModal}
              isDateStep={isDateStep}
              onNextClick={onNextClick}
              onDateLinkClick={onDateLinkClick}
              onTimeLinkClick={onTimeLinkClick}
              onPressBack={onPressBack}
              backToDateStep={backToDateStep}
            />
          }
        />
        <Route path="payment" element={<PaymentIFrame reverseAnimation={reverseAnimation} />} />
        <Route path="swipe-payment" element={<SwipePaymentIFrame />} />
      </Routes>
    </>
  );
};
