import * as actions from './actions';

import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { AjaxResponse } from 'rxjs/ajax';
import { Epic } from 'redux-observable';
import { ResourceAPIResponse } from 'store/resource/types';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';
import { transformErrorIntoSerializableError } from 'store/utils';

export const getResourceEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getResource.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const { ownerType, ownerUuid, resourceUuid, getResourceOptions } = payload;

      return apiClient(state)
        .getResource(ownerType, ownerUuid, resourceUuid, getResourceOptions)
        .pipe(
          map(({ response }: AjaxResponse<ResourceAPIResponse>) => {
            return actions.getResource.success(response.data.resource);
          }),
          catchError((error: Error) =>
            of(actions.getResource.failure({ error: transformErrorIntoSerializableError(error) })),
          ),
        );
    }),
  );
