import { formatCurrency } from './formatCurrency';
import { IntlShape } from 'react-intl';

export const formatItemPrice = (price: number, intl: IntlShape, currencyType: string, buildingLocale: string) => {
  if (!price) {
    return intl.formatMessage({ id: 'free' });
  }

  return formatCurrency(price, currencyType, buildingLocale, true, false);
};
