import React from 'react';
import { ArrowBackLink } from 'components/arrow-back-link';
import { StyledBackLink } from '../styles';
import { useArrowBackButton } from './hooks/use-arrow-back-button.hook';

export const ArrowBackButton = (): JSX.Element => {
  const { arrowBackButtonIsVisible, onPressBack } = useArrowBackButton();

  if (!arrowBackButtonIsVisible) return null;

  return (
    <StyledBackLink>
      <ArrowBackLink onClick={onPressBack} />
    </StyledBackLink>
  );
};
