import React from 'react';
import { QuickTermsAndConditionsProps } from 'components/termsAndConditions/types';
import { SwipeModal } from '@hqo/react-components-library/dist/molecules/modals/swipe-modal';
import { ModalContent } from '../components/modal-content';
import { useIntl } from 'react-intl';

export const MobileModal = ({ handleChangeStep }: QuickTermsAndConditionsProps) => {
  const intl = useIntl();

  return (
    <SwipeModal
      title={intl.formatMessage({ id: 'termsAndConditionsTitle' })}
      reverseAnimation={false}
      content={<ModalContent />}
      onClose={handleChangeStep}
      withLeftExitButton
    />
  );
};
