import { createGlobalStyle } from 'styled-components';

export const RootStyles = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: border-box;
  }

  html, body, #root {
    min-height: 100vh;
  }
`;
