import { StyledModalWrapper, ModalContainer } from './styles';
import React from 'react';
import { ModalContent } from './components/modal-content';

interface TransactionFailedModalProps {
  reverseAnimation: boolean;
}

export const TransactionFailedModal = ({ reverseAnimation }: TransactionFailedModalProps) => {
  const buildContent = (): JSX.Element => (
    <ModalContainer>
      <ModalContent />
    </ModalContainer>
  );

  return <StyledModalWrapper reverseAnimation={reverseAnimation} content={buildContent()} />;
};
