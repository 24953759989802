import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { ACTION_STATUSES } from 'shared/consts';
import { ResourceAvailableDatesState } from './types';

const initialState: ResourceAvailableDatesState = {
  available_dates: {},
  infiniteAvailableDates: {},
  getResourceAvailableDates: {
    status: null,
    error: null,
  },
  getResourceInfiniteAvailableDates: {
    status: null,
    error: null,
  },
};

const getResourceAvailableDatesRequestHandler = (state: ResourceAvailableDatesState): ResourceAvailableDatesState => ({
  ...state,
  getResourceAvailableDates: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getResourceAvailableDatesSuccessHandler = (
  state: ResourceAvailableDatesState,
  { payload }: ActionType<typeof actions.getResourceAvailableDates.success>,
): ResourceAvailableDatesState => ({
  ...state,
  available_dates: { ...state.available_dates, [payload.indexOfMonth]: payload.available_dates },
  getResourceAvailableDates:
    Object.keys(state?.available_dates)?.length === 1
      ? {
          error: null,
          status: ACTION_STATUSES.FULFILLED,
        }
      : { error: null, status: ACTION_STATUSES.PENDING },
});

const getResourceAvailableDatesFailureHandler = (
  state: ResourceAvailableDatesState,
  { payload: error }: ActionType<typeof actions.getResourceAvailableDates.failure>,
): ResourceAvailableDatesState => ({
  ...state,
  getResourceAvailableDates: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const getResourceInfiniteAvailableDatesRequestHandler = (
  state: ResourceAvailableDatesState,
  { payload }: ActionType<typeof actions.getResourceInfiniteAvailableDates.request>,
): ResourceAvailableDatesState => ({
  ...state,
  infiniteAvailableDates: { ...state.infiniteAvailableDates, [payload.indexOfMonth]: null },
  getResourceInfiniteAvailableDates: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getResourceInfiniteAvailableDatesSuccessHandler = (
  state: ResourceAvailableDatesState,
  { payload }: ActionType<typeof actions.getResourceInfiniteAvailableDates.success>,
): ResourceAvailableDatesState => ({
  ...state,
  infiniteAvailableDates: { ...state.infiniteAvailableDates, [payload.indexOfMonth]: payload.available_dates },
  getResourceInfiniteAvailableDates: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getResourceInfiniteAvailableDatesFailureHandler = (
  state: ResourceAvailableDatesState,
  { payload: error }: ActionType<typeof actions.getResourceInfiniteAvailableDates.failure>,
): ResourceAvailableDatesState => ({
  ...state,
  getResourceInfiniteAvailableDates: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const resetResourceAvailableDatesHandler = (state: ResourceAvailableDatesState): ResourceAvailableDatesState => ({
  ...state,
  available_dates: {},
  infiniteAvailableDates: {},
  getResourceAvailableDates: {
    status: null,
    error: null,
  },
  getResourceInfiniteAvailableDates: {
    status: null,
    error: null,
  },
});

const resourceAvailableDatesReducer = createReducer(initialState)
  .handleAction(actions.getResourceAvailableDates.request, getResourceAvailableDatesRequestHandler)
  .handleAction(actions.getResourceAvailableDates.success, getResourceAvailableDatesSuccessHandler)
  .handleAction(actions.getResourceAvailableDates.failure, getResourceAvailableDatesFailureHandler)
  .handleAction(actions.getResourceInfiniteAvailableDates.request, getResourceInfiniteAvailableDatesRequestHandler)
  .handleAction(actions.getResourceInfiniteAvailableDates.success, getResourceInfiniteAvailableDatesSuccessHandler)
  .handleAction(actions.getResourceInfiniteAvailableDates.failure, getResourceInfiniteAvailableDatesFailureHandler)
  .handleAction(actions.resetResourceAvailableDates, resetResourceAvailableDatesHandler);

export default resourceAvailableDatesReducer;
