export const removeQueryParams = (search: string, paramsToRemove: Array<string>): string | null => {
  if (!search) {
    return null;
  }

  const updatedParams = search
    .substr(1)
    .split('&')
    .filter(param => !paramsToRemove.includes(param.split('=')[0]))
    .join('&');

  return updatedParams ? `?${updatedParams}` : '';
};
