import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { BuildingGroupItem, FeatureValue, Floor, FloorItem } from 'store/spaceManager/types';
import qs from 'qs';
import { useFloorSelect } from 'hooks/use-floor-select.hook';
import { replace } from 'store/router/actions';

interface UseFloorFilterReturnValues {
  floors: FloorItem[];
  pickedFloor?: Floor;
  onFloorChange: (value: string) => void;
  isFloorPickerWheelShown: boolean;
  toggleFloorPickerWheel: VoidFunction;
  buildingGroups: BuildingGroupItem[];
  pickedBuildingGroup: FeatureValue | null;
  onWheelChangeBuildingGroup: (value: string) => void;
  isBuildingGroupPickerWheelShown: boolean;
  toggleBuildingGroupPickerWheel: VoidFunction;
  setPickedBuildingGroup: React.Dispatch<any>;
  setPickedFloor: React.Dispatch<any>;
}

export const useFloorFilter = (): UseFloorFilterReturnValues => {
  const queryParams = useSearchParams();
  const dispatch = useDispatch();

  const {
    floors,
    onWheelChange,
    pickedFloor,
    isFloorPickerWheelShown,
    toggleFloorPickerWheel,
    buildingGroups,
    pickedBuildingGroup,
    onWheelChangeBuildingGroup,
    isBuildingGroupPickerWheelShown,
    toggleBuildingGroupPickerWheel,
    setPickedBuildingGroup,
    setPickedFloor,
  } = useFloorSelect();

  useEffect(() => {
    if (pickedBuildingGroup || pickedFloor) {
      onFloorChange(pickedFloor?.uuid);
    } else {
      onFloorChange(null);
    }
  }, [pickedFloor, onWheelChange, pickedBuildingGroup]);
  const onFloorChange = useCallback(
    (floorUuid: string) => {
      onWheelChange(floorUuid);
      if (floorUuid) {
        const queryString = qs.stringify({ ...queryParams, filterByFloor: floorUuid });
        dispatch(replace(`${location.pathname}?${queryString}`));
      }
    },
    [dispatch, onWheelChange, qs.stringify(queryParams)],
  );

  return {
    floors,
    onFloorChange,
    pickedFloor,
    isFloorPickerWheelShown,
    toggleFloorPickerWheel,
    buildingGroups,
    pickedBuildingGroup,
    onWheelChangeBuildingGroup,
    isBuildingGroupPickerWheelShown,
    toggleBuildingGroupPickerWheel,
    setPickedBuildingGroup,
    setPickedFloor,
  };
};
