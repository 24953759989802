import { ModalContainer, ContentContainer, Text, Title, TextContainer } from './styles';
import React from 'react';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';

interface ModalContentProps {
  title?: string;
}

export const ModalContent = ({ title }: ModalContentProps) => {
  const { termsAndConditionsValues } = useAppInstanceConfigFeature();
  const { termsAndConditionsText } = termsAndConditionsValues || {};

  return (
    <ModalContainer>
      <ContentContainer>
        {title && <Title>{title}</Title>}
        <TextContainer>
          <Text>{termsAndConditionsText}</Text>
        </TextContainer>
      </ContentContainer>
    </ModalContainer>
  );
};
