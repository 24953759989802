import { createReducer } from 'typesafe-actions';
import * as headerActions from './actions';

export interface HeaderState {
  isHeaderSet: boolean;
}

export const initialState: HeaderState = {
  isHeaderSet: false,
};

const setHeaderState = (): HeaderState => ({
  isHeaderSet: true,
});

const resetHeaderState = (): HeaderState => ({
  isHeaderSet: false,
});

const headerStateReducer = createReducer(initialState)
  .handleAction(headerActions.setHeaderAction, setHeaderState)
  .handleAction(headerActions.resetHeaderState, resetHeaderState);

export default headerStateReducer;
