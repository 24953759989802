import { CompactContentTile } from '@hqo/react-components-library/dist/molecules/tiles/content-tile/compact';
import styled from 'styled-components';

export const StyledCompactContentTile = styled(CompactContentTile)`
  @media screen and (max-width: 769px) {
    .text-container {
      width: calc(100% - 138px);
    }
  }
`;
