import { Container } from './styles';
import { DesktopTimeSelector } from 'components/time-selector/desktop';
import { MobileTimeSelector } from './mobile';
import React from 'react';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport/';

export interface TimeSelectorProps {
  isDisabled?: boolean;
  toggleOverlay: VoidFunction;
}

export const TimeSelector = ({ isDisabled = true, toggleOverlay }: TimeSelectorProps): JSX.Element => {
  const isMobileDevice = useIsSmallViewportWidth();

  return (
    <Container isDisabled={isDisabled}>
      {isMobileDevice ? <MobileTimeSelector toggleOverlay={toggleOverlay} /> : <DesktopTimeSelector />}
    </Container>
  );
};
