import { createElement } from 'react';
import useFormatHtmlText from 'shared/hooks/useFormatHtmlText/useFormatHtmlText.hook';

export interface MarkdownTextProps {
  children: string;
  element?: string;
}

const HtmlText = ({ children, element = 'div' }: MarkdownTextProps) => {
  const html = useFormatHtmlText(children);
  return createElement(element, {
    dangerouslySetInnerHTML: {
      __html: html,
    },
  });
};

export default HtmlText;
