import styled from 'styled-components';

interface ItemWrapperProps {
  itemHeight: number;
}

export const ItemWrapper = styled.li<ItemWrapperProps>`
  width: 100%;
  height: ${({ itemHeight }) => `${itemHeight}px`};
  line-height: ${({ itemHeight }) => `${itemHeight}px`};
  text-align: center;
  list-style-type: none;
  scroll-snap-align: center;
`;

export const ItemLabel = styled.div`
  height: 100%;
  font: 400 20px/24px ${({ theme }) => theme.fonts.join()};
  line-height: inherit;
`;
