import qs from 'qs';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useDisableTimePicker = (): boolean => {
  const { search } = useLocation();

  const { presetWindows } = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]);

  return !!presetWindows;
};
