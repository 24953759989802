import React, { forwardRef } from 'react';

import { AddingVisitorsBlock } from 'components/adding-visitors-block';
import { Alert } from 'components/alert';
import { Button } from '../button/button';
import { CheckoutTermsAndConditions } from 'components/payment/quick-checkout/components/terms-and-conditions';
import { CloseButton } from 'components/close-button';
import { ItemQuantityBlock } from '@hqo/react-components-library/dist/molecules/itemQuantityBlock';
import { PaymentMethodsSection } from 'components/payment/quick-checkout/components/payment-methods-section';

import { getPrice } from 'utils/getPrice';
import { formatDate } from 'utils/formatDate';
import { getTaxSummary } from 'utils/getTaxSummary';

import { VisitorsError } from 'components/adding-visitors-block/visitors-error.enum';

import {
  CloseButtonContainer,
  ContentContainer,
  EditButton,
  QuickCheckoutContent,
  StyledPriceBlock,
  StyledTitleBlock,
} from './styles';

import { useIntl } from 'react-intl';
import { SavedPaymentMethod } from 'store/payment/types';
import { BookingConfirmationModalBaseProps } from 'components/payment/quick-checkout/components/types';

export interface BookingConfirmationModalProps extends BookingConfirmationModalBaseProps {
  isProcessPaymentFailed: boolean;
  isVisiblePaymentMethodBlock: boolean;
  isAllowedVisitorsExist: boolean;
  hasInvoiceWithoutCreditCard: boolean;
  isNativePaymentError: boolean;
  pendingNativePayment: boolean;
  currentPaymentMethodId: string;
  visitorError?: VisitorsError;
  paymentMethod?: SavedPaymentMethod;
  isFreeCart: boolean;
  isPaymentMethodsAvailable: boolean;
  onEditClick: VoidFunction;
}

const BookingConfirmationModal = forwardRef<HTMLDivElement, BookingConfirmationModalProps>(
  function BookingConfirmationModalComponent(
    {
      checkoutDisabled,
      closeQuickCheckoutHandler,
      isProcessPaymentFailed,
      onCTAClick,
      onEditClick,
      cart,
      resourceTimezone,
      isVisiblePaymentMethodBlock,
      locale,
      isAllowedVisitorsExist,
      isNativePaymentError,
      pendingNativePayment,
      isTermsVisible,
      isTermsChecked,
      cartError,
      currentPaymentMethodId,
      visitorError,
      hasInvoiceWithoutCreditCard,
      paymentMethod,
      isFreeCart,
      isPaymentMethodsAvailable,
      onIsTermsChecked,
      submitCartStatus,
    },
    ref,
  ) {
    const intl = useIntl();

    const { eventWeekDay, eventDateMonthLong, formatEventStartTime, eventDateDay } = formatDate(
      cart?.items[0]?.resource_booking?.start_at,
      locale as string,
      resourceTimezone,
    );

    const resolveAddOnsText = (): string => {
      const addOns = cart.items[0].resource_booking.add_ons;
      let addOnsText = '';
      addOns?.forEach(addOn => {
        addOnsText += `+${addOn.quantity} ${addOn.name}${addOn.id !== addOns[addOns.length - 1].id ? ', ' : ''}`;
      });

      return addOnsText;
    };

    const resolveAdditionalPriceText = (): string => {
      if (!cart?.total_summary?.tax_summary?.taxes_total || isFreeCart) {
        return null;
      }

      return `${getTaxSummary(cart, locale)} ${intl.formatMessage({ id: 'tax_text' })}`;
    };

    return (
      <ContentContainer data-testid="booking-confirmation-modal">
        <CloseButtonContainer>
          <CloseButton onClose={closeQuickCheckoutHandler} />
        </CloseButtonContainer>
        <QuickCheckoutContent className="quick-checkout-content" ref={ref}>
          <StyledTitleBlock
            spacing={20}
            title={intl.formatMessage({ id: 'book' })}
            subtitle={intl.formatMessage(
              { id: 'you_are_about_book' },
              {
                serviceName: cart.items?.[0]?.display_info.title,
                serviceTime: `${eventWeekDay}, ${eventDateMonthLong} ${eventDateDay}, ${intl.formatMessage({
                  id: 'at',
                })} ${formatEventStartTime}`,
              },
            )}
          />
          <StyledPriceBlock>
            {cart.items?.map(item => (
              <ItemQuantityBlock
                key={item.id}
                title={item.display_info.title}
                subtitle={resolveAddOnsText() || item.display_info.subtitle}
                price={getPrice(isFreeCart, cart, intl.formatMessage({ id: 'free' }), locale)}
                additionalPrice={resolveAdditionalPriceText()}
              />
            ))}
          </StyledPriceBlock>
          {isAllowedVisitorsExist && <AddingVisitorsBlock />}
          <PaymentMethodsSection
            isAllowedVisitorsExist={isAllowedVisitorsExist}
            isVisiblePaymentMethodBlock={isVisiblePaymentMethodBlock}
            onEditClick={onEditClick}
            cart={cart}
            isProcessPaymentFailed={isProcessPaymentFailed}
            currentPaymentMethodId={currentPaymentMethodId}
            paymentMethod={paymentMethod}
            cartError={cartError}
            isNativePaymentError={isNativePaymentError}
          />
          {hasInvoiceWithoutCreditCard && (
            <EditButton data-testid="add-cards-option" onClick={onEditClick}>
              {intl.formatMessage({ id: 'add_cards_option' })}
            </EditButton>
          )}
        </QuickCheckoutContent>
        {isTermsVisible && (
          <CheckoutTermsAndConditions termsChecked={isTermsChecked} setIsTermsChecked={onIsTermsChecked} />
        )}
        {Boolean(visitorError) && visitorError !== VisitorsError.INVALID_EMAIL && (
          <Alert label={visitorError} testId="visitor-error-alert" />
        )}
        <Button
          isPaymentMethodsAvailable={isPaymentMethodsAvailable}
          isFreeCart={isFreeCart}
          submitCartStatus={submitCartStatus}
          onCTAClick={onCTAClick}
          pendingNativeMethod={pendingNativePayment}
          checkoutDisabled={checkoutDisabled}
        />
      </ContentContainer>
    );
  },
);

export default BookingConfirmationModal;
