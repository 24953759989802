export const formatDate = (value: string, locale: string, timezone?: string) => {
  const timeZone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  const eventDateMonth = new Date(value).toLocaleString(locale, { month: 'short', timeZone });
  const eventDateMonthNum = new Date(value).toLocaleString(locale, { month: 'numeric', timeZone });
  const eventDateDay = new Date(value).toLocaleString(locale, { day: 'numeric', timeZone });
  const eventDateMonthLong = new Date(value).toLocaleString(locale, { month: 'long', timeZone });
  const eventDateYear = new Date(value).toLocaleString(locale, { year: 'numeric', timeZone });
  const eventWeekDay = new Date(value).toLocaleString(locale, { weekday: 'long', timeZone });
  const formatEventStartTime = new Date(value).toLocaleString(locale, {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZone,
  });

  return {
    eventDateMonth,
    eventDateMonthNum,
    eventDateDay,
    eventDateMonthLong,
    eventDateYear,
    formatEventStartTime,
    eventWeekDay,
  };
};

export const bookingDate = (startDate: string, locale: string, timezone?: string): string => {
  const { eventWeekDay, eventDateMonth, eventDateDay, eventDateYear } = formatDate(startDate, locale, timezone);
  return `${eventWeekDay}, ${eventDateMonth} ${eventDateDay}, ${eventDateYear}`;
};

export const getFormattedEventDuration = (
  startDate: string,
  endDate: string,
  locale: string,
  timezone?: string,
): string => {
  const timeZone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const eventStartTime = new Date(startDate).toLocaleString(locale, {
    hour: 'numeric',
    minute: '2-digit',
    timeZone,
  });
  const eventEndTime = new Date(endDate).toLocaleString(locale, {
    hour: 'numeric',
    minute: '2-digit',
    timeZone,
  });

  return `${eventStartTime} - ${eventEndTime}`;
};

export const getFormattedDate = (date: Date, format: string, locale: string): string => {
  const mm = date.toLocaleDateString(locale, { month: '2-digit' });
  const dd = date.toLocaleDateString(locale, { day: '2-digit' });
  const yy = date.getFullYear();
  if (format === 'YYYY-MM-DD') {
    return `${yy}-${mm}-${dd}`;
  }
  if (format === 'MMMM YYYY') {
    return date.toLocaleString(locale, { month: 'long', year: 'numeric' });
  }

  return '';
};

export const transformToSortedDatesList = (datesString: string): Array<string> => {
  const formattedStartDates = datesString?.length > 0 ? datesString?.split(',') : [];

  return formattedStartDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
};
