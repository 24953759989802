import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetHeaderState } from 'store/header-state/actions';
import { resetResourceTimeRanges } from 'store/resource-time-ranges/actions';
import { resetResource } from 'store/resource/actions';
import { resetResourceAvailableDates } from 'store/resource-available-dates/actions';
import { selectResourceAvailableDatesStatus } from 'store/resource-available-dates/selectors';
import { ACTION_STATUSES } from 'shared/consts';

export const useResetResourceState = () => {
  const dispatch = useDispatch();
  const resourceAvailableDatesStatus = useSelector(selectResourceAvailableDatesStatus);

  useEffect(() => {
    if (resourceAvailableDatesStatus === ACTION_STATUSES.FULFILLED) {
      dispatch(resetResourceAvailableDates());
    }

    dispatch(resetResource());
    dispatch(resetHeaderState());
    dispatch(resetResourceTimeRanges());
  }, []);
};
