import { FooterContainer, StyledButton } from './styles';

import React from 'react';
import { useIntl } from 'react-intl';

interface SelectLocationModalFooterProps {
  handleClick: VoidFunction;
  disabled?: boolean;
}

export const SelectLocationModalFooter = ({
  handleClick,
  disabled = false,
}: SelectLocationModalFooterProps): JSX.Element => {
  const intl = useIntl();
  const extraParams = disabled ? { disabled } : {};

  return (
    <FooterContainer>
      <StyledButton
        variant="primary"
        data-testid="location-picker-button"
        onClick={handleClick}
        text={intl.formatMessage({ id: 'done' })}
        multiline={false}
        {...extraParams}
      />
    </FooterContainer>
  );
};
