import { useIntl } from 'react-intl';
import React from 'react';
import { useCheckoutModalNavigation } from 'components/payment/quick-checkout/components/terms-and-conditions/use-checkout-modal-navigation.hook';
import {
  TermsContainer,
  TermsLinkTitle,
} from 'components/payment/quick-checkout/components/terms-and-conditions/styles';
import { CheckboxHqo } from '@hqo/react-components-library/dist/molecules/checkbox-hqo';

interface CheckoutTermsAndConditionsProps {
  setIsTermsChecked?: (terms: boolean) => void;
  termsChecked: boolean;
}

export const CheckoutTermsAndConditions = ({ setIsTermsChecked, termsChecked }: CheckoutTermsAndConditionsProps) => {
  const intl = useIntl();
  const { handleOpenModal } = useCheckoutModalNavigation();
  return (
    <TermsContainer data-testid="terms-container">
      <CheckboxHqo
        data-testid="terms-checkbox"
        checked={termsChecked}
        onChange={event => setIsTermsChecked(event.target.checked)}
        id="terms-checkbox"
      />
      {intl.formatMessage({ id: 'i_agree_to_the' })}
      <TermsLinkTitle data-testid="terms-link" onClick={handleOpenModal}>
        {intl.formatMessage({ id: 'terms_and_conditions' })}
      </TermsLinkTitle>
    </TermsContainer>
  );
};
