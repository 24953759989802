import { CloseSmall } from '@hqo/react-components-library/dist/icons';
import { DIMENSIONS, ZINDEX } from '@hqo/react-components-library/dist/utils';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  backgroundColor?: string;
}

export const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => `${theme.colorsRgba.black_5}`};
  z-index: ${ZINDEX.MODAL_BACKGROUND};
`;

export const Container = styled.div<Props>`
  background: ${({ theme, backgroundColor }) => backgroundColor || theme.colors.universal.white};
  margin: auto;
  box-shadow: ${({ theme }) => `0px 0px 4px ${theme.colorsRgba.black_25}`};
  display: flex;
  justify-content: flex-start;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: inherit;
  z-index: ${ZINDEX.MODAL_CONTAINER};

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    transform: translate(0%, 0%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export const Content = styled.div<Props>`
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
`;

export const CloseIcon = styled(CloseSmall)`
  margin: 17px 17px 0 0;

  cursor: pointer;
  color: ${({ theme }) => theme.colors.fontColor};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.greys.sharedMediumGrey};
  }
`;
export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const BackButtonContainer = styled.span`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  left: 29px;
  top: 32px;
  cursor: pointer;
`;

export const BackIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 3px;
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const TextContainer = styled.div`
  font: 400 14px/16px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;
