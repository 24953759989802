import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';
import { IFrameContainerProps } from './paymentIFrame.interface';
import IframeModalWrapper from 'components/modals/iframeWrapper';
import styled from 'styled-components';
import { reverseSlide, slide, fade } from 'hocs/shared-styles';

export const ModalContainer = styled.div<IFrameContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 734px;
  width: 873px;
  transition: height 1s;
  ${({ isCheckoutDesktop }) => !isCheckoutDesktop && 'height: 528px; width: auto'};
  ${({ isSwipeModalContent }) => isSwipeModalContent && 'height: 100%; width: auto'};
  ${({ isQuickCheckout }) => isQuickCheckout && 'height: 329px; width: auto'};

  @media only screen and (max-width: ${DIMENSIONS.LARGE}) {
    transition: none;
    width: 100%;
    align-items: flex-start;
  }
`;

export const StyledModalWrapper = styled(IframeModalWrapper)<IFrameContainerProps>`
  width: 873px;
  transition: width 1s;
  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    .iframe-background {
      animation: ${props => fade(props.reverseAnimation)} 0.5s;
    }
    .iframe-wrapper {
      animation: ${props => (props.reverseAnimation ? reverseSlide : slide)} 0.5s;
    }
  }
  ${({ isCheckoutDesktop }) =>
    !isCheckoutDesktop &&
    `width: 984px;
    @media only screen and (max-width: ${DIMENSIONS.LARGE}) {
    width: 100%;
  }`};
  @media only screen and (max-width: ${DIMENSIONS.LARGE}) {
    width: 100%;
  }

  ${({ quickCheckout }) =>
    quickCheckout &&
    `
  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    .iframe-wrapper {
      top: auto;
    }

    .iframe-modal-content > div {
      height: 310px;
    }
  }
  `}
  ${({ isQuickCheckout }) =>
    isQuickCheckout &&
    ` width: 543px;

    @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    height: 310px;

    .iframe-wrapper {
      height: 310px;
      border-radius: 8px 8px 0 0;
      top: auto;
    }

    .iframe-modal-content {
      border-radius: 8px 8px 0 0;
      height: auto;
    }
  }`};
  ${({ isReceipt }) =>
    isReceipt &&
    `
  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    .iframe-wrapper {
      top: 0;
    }

    .iframe-modal-content > div {
      height: 100%;
    }
  }
  `}
`;
