import { ScheduleSelect } from 'components/schedule-select/schedule-select';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TimeSelectPageContent } from 'pages/time-select-page/components/time-select-page-content';
import React from 'react';
import { useLocation } from 'react-router-dom';

interface TimeSelectContentProps {
  scrollContainerRef: React.MutableRefObject<HTMLDivElement>;
  contentBodyRef: React.MutableRefObject<HTMLDivElement>;
}

export const TimeSelectContent = ({ scrollContainerRef, contentBodyRef }: TimeSelectContentProps) => {
  const { showResourceDetailsDateTimeInputs } = useFlags();
  const { pathname } = useLocation();
  const isResourcesPage = pathname.includes('/resources');
  const isFloorPlanPage = pathname.includes('/floorplan');

  return showResourceDetailsDateTimeInputs && !isResourcesPage && !isFloorPlanPage ? (
    <TimeSelectPageContent />
  ) : (
    <ScheduleSelect scrollContainerRef={scrollContainerRef} contentBodyRef={contentBodyRef} />
  );
};
