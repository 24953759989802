import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 -12px;
  padding: 21px 12px;
`;

export const Title = styled.div`
  font: 700 20px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  margin-bottom: 10px;
`;

export const ListContainer = styled.div`
  margin-top: 11px;
  display: flex;
  gap: 14px;
  flex-direction: column;
`;

export const AmenitiesDescription = styled.div<{ isOverflowing: boolean; isExpanded: boolean }>`
  position: relative;
  line-height: 22px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ isOverflowing }) => isOverflowing && '-webkit-line-clamp: 2'}
  ${({ isExpanded }) => isExpanded && '-webkit-line-clamp: 4'}
`;

export const ReadMoreContainer = styled.span`
  font: 500 16px/22px ${({ theme }) => theme.fonts.join()};
  border: none;
`;

export const AmenitiesDescriptionContainer = styled.div`
  position: relative;
`;

export const ReadMoreButton = styled.span`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.secondary};
`;
