import * as Sentry from '@sentry/react';
import sentryBeforeSendFilter from 'init/sentryBeforeSendFilter';
import React from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

if (!process.env.REACT_APP_SENTRY_DSN || process.env.REACT_APP_SENTRY_DSN === '') {
  // eslint-disable-next-line no-console
  console.info('Sentry DSN not set, Sentry will not be initialized');
}

const sentryConfig: Sentry.BrowserOptions = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_VERSION,
  autoSessionTracking: true,
  environment: process.env.NODE_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.httpClientIntegration(),
    Sentry.captureConsoleIntegration({
      levels: ['error'],
    }),
    Sentry.extraErrorDataIntegration(),
  ],
  tracesSampleRate: 1.0,
  normalizeDepth: 5,
  profilesSampleRate: 1.0,
  beforeSend: sentryBeforeSendFilter,
};

Sentry.init(sentryConfig);
