import React from 'react';
import { useFilteringEnabled } from 'hooks/use-filtering-enabled.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FilterBar } from '../filter-bar';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { useResourceLoadingHandler } from 'pages/resources/hooks';

export const FilterPicker = ({ className }: { className?: string }): JSX.Element => {
  const isFilteringEnabled = useFilteringEnabled();
  const isMobileDevice = useIsSmallViewportWidth();
  const isLoadingState = useResourceLoadingHandler(isFilteringEnabled);

  const { showResourceBookingMiniappFiltering: isResourceFilteringVisible } = useFlags();

  if (!(isResourceFilteringVisible && isFilteringEnabled) || (!isMobileDevice && isLoadingState)) return null;

  return <FilterBar className={className} isVisible={isMobileDevice || (!isMobileDevice && !isLoadingState)} />;
};
