import { useBooleanState } from '@hqo/react-components-library/dist/hooks';
import { useSpaceManager } from 'hooks/use-space-manager.hook';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ACTION_STATUSES } from 'shared/consts';
import {
  selectFloor,
  selectFloorBuildingGroupFeatures,
  selectFloors,
  selectGetFloorsStatus,
} from 'store/spaceManager/selectors';
import { BuildingGroupItem, FeatureValue, Floor } from 'store/spaceManager/types';

interface UseFloorSelectValues {
  floors: Floor[];
  pickedFloor: Floor;
  onWheelChange: (value: string) => void;
  isFloorPickerWheelShown: boolean;
  toggleFloorPickerWheel: VoidFunction;
  buildingGroups: BuildingGroupItem[];
  pickedBuildingGroup: FeatureValue | null;
  onWheelChangeBuildingGroup: (value: string) => void;
  isBuildingGroupPickerWheelShown: boolean;
  toggleBuildingGroupPickerWheel: VoidFunction;
  setPickedBuildingGroup: React.Dispatch<any>;
  setPickedFloor: React.Dispatch<any>;
}

export const useFloorSelect = (): UseFloorSelectValues => {
  useSpaceManager();
  const floors: Floor[] = useSelector(selectFloors);
  const floorsStatus = useSelector(selectGetFloorsStatus);
  const currentFloor: Floor = useSelector(selectFloor);
  const [pickedFloor, setPickedFloor] = useState(currentFloor);
  const buildingGroups: FeatureValue[] = useSelector(selectFloorBuildingGroupFeatures);
  const [pickedBuildingGroup, setPickedBuildingGroup] = useState(null);
  const [filteredFloors, setFilteredFloors] = useState(undefined);
  const { value: isBuildingGroupPickerWheelShown, toggle: toggleBuildingGroupPickerWheel } = useBooleanState(false);
  const { value: isFloorPickerWheelShown, toggle: toggleFloorPickerWheel } = useBooleanState(false);
  const intl = useIntl();
  const allBuildingGroups = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: 'allBuildingGroups' }),
        value: intl.formatMessage({ id: 'allBuildingGroups' }),
      },
    ],
    [intl],
  );
  const onWheelChangeBuildingGroup = useCallback(
    (buildingGroupValue: string) => {
      if (Array.isArray(buildingGroups)) {
        const selectedBuildingGroup = buildingGroups.find(buildingGroup => buildingGroup.value === buildingGroupValue);
        setPickedBuildingGroup(selectedBuildingGroup || allBuildingGroups[0]);
        const filteredBuildingGroupFloors = selectedBuildingGroup
          ? floors.filter(floor => floor.features?.some(feature => feature.value === selectedBuildingGroup.value))
          : floors;
        setFilteredFloors(filteredBuildingGroupFloors);
        setPickedFloor(filteredBuildingGroupFloors[0]);
      }
    },
    [setPickedFloor, buildingGroups, setPickedBuildingGroup, floors, allBuildingGroups],
  );
  const buildingGroupValues = useMemo(() => {
    if (Array.isArray(buildingGroups)) {
      buildingGroups.forEach((buildingGroupItem: FeatureValue) => {
        allBuildingGroups.push({
          label: buildingGroupItem.value,
          value: buildingGroupItem.value,
        });
      });
    }
    return allBuildingGroups;
  }, [buildingGroups, allBuildingGroups]);
  const onWheelChange = useCallback(
    (floorUuid: string) => {
      if (Array.isArray(floors)) {
        setPickedFloor(floors.find(floor => floor.uuid === floorUuid));
      }
    },
    [floors, setPickedFloor],
  );
  useEffect(() => {
    if (filteredFloors === undefined && floorsStatus === ACTION_STATUSES.FULFILLED) {
      setFilteredFloors(floors);
    }
  }, [filteredFloors, floors, floorsStatus]);
  return {
    floors: filteredFloors || [],
    pickedFloor,
    onWheelChange,
    isFloorPickerWheelShown,
    toggleFloorPickerWheel,
    buildingGroups: buildingGroupValues,
    pickedBuildingGroup,
    onWheelChangeBuildingGroup,
    isBuildingGroupPickerWheelShown,
    toggleBuildingGroupPickerWheel,
    setPickedBuildingGroup,
    setPickedFloor,
  };
};
