import styled from 'styled-components';
import { closeSlideAnimation, openSlideAnimation } from 'hocs/shared-styles';

export const MultipleBookingsContainer = styled.div<{ containerHeight: number; reverseAnimation: boolean }>`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  max-height: 500px;
  overflow: auto;
  height: ${({ containerHeight }) => `${containerHeight}px`};
  animation: ${({ containerHeight, reverseAnimation }) =>
      reverseAnimation ? closeSlideAnimation(containerHeight) : openSlideAnimation(containerHeight)}
    0.2s ease;
`;
