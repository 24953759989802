import { useEffect, useMemo, useCallback, useRef } from 'react';
import {
  useRerenderElementsType,
  useHandleScrollType,
  useScrollIntoViewType,
  useWheelPickerScrollType,
  useWheelPickerType,
} from './component.interface';

const SCALE_DIVIDER = 3;

const getScrollOffsetValues = (containerHeight: number, itemHeight: number) => {
  const visibleItemsCount = Math.floor(containerHeight / itemHeight);

  const offset = Math.round((visibleItemsCount + 1) / 2) + 1;
  const maxScrollOffset = (containerHeight - itemHeight) / 2;

  return { offset, maxScrollOffset };
};

export const useRerenderElements: useRerenderElementsType = (
  itemRefs,
  itemsLength,
  offset,
  itemHeight,
  maxScrollOffset,
) =>
  useCallback(
    (
      selectedElement: number,
      scrollTop: number,
      firstItemIndex = Math.max(selectedElement - offset, 0),
      lastItemIndex = Math.min(selectedElement + offset, itemsLength),
    ): void => {
      if (itemRefs.current) {
        itemRefs.current.slice(firstItemIndex, lastItemIndex).forEach((item, index) => {
          const realIndex = index + firstItemIndex;
          const scrollOffset = Math.min(Math.abs(scrollTop - realIndex * itemHeight - itemHeight / 2), maxScrollOffset);
          const normalizedOffset = scrollOffset / maxScrollOffset;

          const div = item.querySelector('div');

          if (div) {
            div.style.transform = `scale(${1 - normalizedOffset / SCALE_DIVIDER})`;
          }
        });
      }
    },
    [itemHeight, itemRefs, itemsLength, maxScrollOffset, offset],
  );

export const useHandleScroll: useHandleScrollType = (
  items,
  itemHeight,
  rerenderElements,
  handleChange,
  setCurrentValue,
) => {
  const isAnimating = useRef<boolean>(false);
  const isScrolling = useRef<NodeJS.Timeout>();

  return useCallback(
    (event: Event) => {
      if (!isAnimating.current) {
        isAnimating.current = true;
        requestAnimationFrame(() => {
          const scrollTop = Math.max((event.target as HTMLUListElement)?.scrollTop, 0);
          const selectedElement = Math.min(Math.max(Math.floor(scrollTop / itemHeight), 0), items.length - 1);

          window.clearTimeout(isScrolling.current);
          rerenderElements(selectedElement, scrollTop);
          setCurrentValue(selectedElement);

          isScrolling.current = setTimeout(() => {
            handleChange(items[selectedElement]?.value);
          }, 20);

          isAnimating.current = false;
        });
      }
    },
    [setCurrentValue, handleChange, itemHeight, items, rerenderElements],
  );
};

export const useScrollIntoView: useScrollIntoViewType = (itemRefs, currentValue, itemsLength, rerenderElements) =>
  useCallback(
    (scrollTop, scrollParams = {}) => {
      if (itemRefs.current[currentValue.current]) {
        itemRefs.current[currentValue.current].scrollIntoView?.({ ...scrollParams, block: 'center' });
        rerenderElements(currentValue.current, scrollTop, 0, itemsLength);
      }
    },
    [currentValue, itemRefs, itemsLength, rerenderElements],
  );

export const useWheelPickerScroll: useWheelPickerScrollType = (
  value,
  itemsMap,
  currentValue,
  itemsContainerRef,
  handleScroll,
  scrollIntoView,
  setCurrentValue,
) => {
  useEffect(() => {
    const itemsContainer = itemsContainerRef.current;
    if (itemsContainer) {
      itemsContainer.addEventListener('scroll', handleScroll);
      scrollIntoView(itemsContainer.scrollTop);
    }

    return () => {
      if (itemsContainer) {
        itemsContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll, itemsContainerRef, scrollIntoView]);

  useEffect(() => {
    const itemsContainer = itemsContainerRef.current;

    if (itemsContainer) {
      scrollIntoView(itemsContainer.scrollTop);
    }
  }, [currentValue, itemsContainerRef, itemsMap, scrollIntoView, setCurrentValue, value]);
};

export const useWheelPicker: useWheelPickerType = (items, value, containerHeight, itemHeight, handleChange) => {
  const itemsContainerRef = useRef<HTMLUListElement>(null);
  const itemRefs = useRef<Array<HTMLLIElement>>([]);
  const itemsMap: Map<Date, number> = useMemo(
    () => items.reduce((map, item, index) => map.set(item.value, index), new Map()),
    [items],
  );
  const currentValue = useRef<number>(0);
  useEffect(() => {
    const itemsArray = [...itemsMap];
    currentValue.current = itemsArray.findIndex(([date]) => date.toString() === value?.toString());
    if (itemsArray?.[currentValue.current]?.[0]) {
      handleChange(itemsArray?.[currentValue.current]?.[0]);
    }
  }, [value]);

  const { offset, maxScrollOffset } = getScrollOffsetValues(containerHeight, itemHeight);

  const setCurrentValue = useCallback((newValue: number) => {
    currentValue.current = newValue;
  }, []);

  const rerenderElements = useRerenderElements(itemRefs, items.length, offset, itemHeight, maxScrollOffset);
  const handleScroll = useHandleScroll(items, itemHeight, rerenderElements, handleChange, setCurrentValue);
  const scrollIntoView = useScrollIntoView(itemRefs, currentValue, items.length, rerenderElements);

  useWheelPickerScroll(
    value,
    itemsMap,
    currentValue.current,
    itemsContainerRef,
    handleScroll,
    scrollIntoView,
    setCurrentValue,
  );

  return { itemsContainerRef, itemRefs };
};
