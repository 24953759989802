import React from 'react';
import { StyledPill } from './styles';

interface LabelPillProps {
  text: string;
}

export const LabelPill = ({ text }: LabelPillProps): JSX.Element => {
  return <StyledPill status={text} />;
};
