import { createSelector } from 'reselect';
import { NotificationsState } from 'store/notifications/types';
import { RootState } from 'store/reducer';

export const notificationsSelector = (state: RootState): NotificationsState => state.notifications;

export const currentNotification = createSelector(
  [notificationsSelector],
  notifications => notifications.currentNotification,
);
