import React, { useRef, useState } from 'react';
import { CalendarView } from './calendar-view';
import { CalendarViewContainer } from './styles';
import { LoadingState } from '../interface';
import { InfiniteView } from './infinite-view';
import { useInfiniteLoading } from 'hooks/use-infinite-loading.hook';
import { usePreselectedDayLoading } from 'hooks/use-preselected-day-loading/use-preselected-day-loading.hook';

export const DateSelectPageContent = (): JSX.Element => {
  const initialLoadingState = {
    0: true,
    1: true,
  };
  const [loadingState, setLoadingState] = useState<LoadingState>(initialLoadingState);
  const isFirstMonthLoading = loadingState[0];
  const isSecondMonthLoading = loadingState[0] ? true : loadingState[1];
  const calendarContainerRef = useRef<HTMLDivElement>(null);

  useInfiniteLoading();
  usePreselectedDayLoading(calendarContainerRef);

  return (
    <CalendarViewContainer data-testid="calendar-view-container" ref={calendarContainerRef}>
      <CalendarView indexOfMonth={0} isLoading={isFirstMonthLoading} setLoadingState={setLoadingState} />
      <CalendarView indexOfMonth={1} isLoading={isSecondMonthLoading} setLoadingState={setLoadingState} />
      <InfiniteView />
    </CalendarViewContainer>
  );
};
