import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';

import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 0 0px 0;
  height: 805px;
  width: 606px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding: 10px 0 0 0;
    width: 100%;
    height: 100%;
  }
`;

export const ClearFilterWrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 19px;
`;

export const ModalTitle = styled.div`
  font: 700 26px/30px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const ModalContentContainer = styled.div`
  height: 100%;
  overflow-y: auto;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    overflow-y: unset;
  }
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    bottom: 0;
    width: calc(100%);
    background-color: #fff;
  }
`;

export const ShowResultsButton = styled(Button)`
  padding: 6px;
  width: 140px;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding: 12px;
    width: 100%;
  }
`;

export const Background = styled.div<{ height?: number }>`
  position: fixed;
  top: -50px;
  left: 0;
  width: 100%;
  height: calc(100% + 50px);
  background-color: ${({ theme }) => `${theme.colorsRgba.black_5}`};
  backdrop-filter: blur(0);
  position: absolute;
  border-radius: 10px;
`;

export const ModalTitleWrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;

  button {
    align-self: center;
  }
`;
