import { SMALL_DEVICE_MAX_WIDTH } from 'shared/consts';
import styled from 'styled-components';

export const ResourcesTypesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 15px;

  @media (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 24px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Title = styled.div`
  font: 700 16px/19px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;
