import { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import { DEFAULT_LATITUDE, DEFAULT_LONGITUDE, DEFAULT_MAPBOX_STYLES, DEFAULT_ZOOM } from '../constants';
import { useSelector } from 'react-redux';
import { selectCurrentLocationCoordinates } from 'store/app-instance-configs/selectors';

interface UseMapViewReturnValues {
  mapContainerRef: React.MutableRefObject<HTMLDivElement>;
  mapRef: React.MutableRefObject<mapboxgl.Map | null>;
}

export const useInitializeMapView = (): UseMapViewReturnValues => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const { latitude: lat, longitude: lng } = useSelector(selectCurrentLocationCoordinates) || {};

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    const centerCoordinates =
      lat != null && lng != null ? { lat, lng } : { lat: DEFAULT_LATITUDE, lng: DEFAULT_LONGITUDE };

    mapRef.current = new mapboxgl.Map({
      style: DEFAULT_MAPBOX_STYLES,
      container: mapContainerRef.current,
      zoom: DEFAULT_ZOOM,
      center: centerCoordinates,
    });
  }, []);

  return { mapContainerRef, mapRef };
};
