import React, { useCallback, useEffect } from 'react';
import { ACTION_STATUSES } from 'shared/consts';
import { useDispatch, useSelector } from 'react-redux';
import { selectComplete3DSCartError, selectSubmitCartStatus } from 'store/cart/selectors';
import { setCurrentPaymentMethodId, updatePaymentMethod } from 'store/payment/actions';
import {
  selectDeletePaymentMethodsStatus,
  selectGetPaymentMethodsStatus,
  selectSavePaymentMethodStatus,
  selectUpdatePaymentMethodStatus,
} from 'store/payment/selectors';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface UsePaymentMethodsManagerValues {
  onPressHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  updatePaymentMethodStatus: ACTION_STATUSES;
  getPaymentMethodStatus: ACTION_STATUSES;
  deletePaymentMethodsStatus: ACTION_STATUSES;
  savePaymentMethodStatus: ACTION_STATUSES;
}

interface UsePaymentMethodsManagerProps {
  setErrorPaymentId: React.Dispatch<React.SetStateAction<number>>;
  submittedPaymentMethodId: number;
  currentPaymentError: boolean;
  cartId: string;
}

export const usePaymentMethodsManager = ({
  setErrorPaymentId,
  submittedPaymentMethodId,
  currentPaymentError,
  cartId,
}: UsePaymentMethodsManagerProps): UsePaymentMethodsManagerValues => {
  const dispatch = useDispatch();
  const submitCartStatus = useSelector(selectSubmitCartStatus);
  const complete3DSCartError = useSelector(selectComplete3DSCartError);
  const updatePaymentMethodStatus = useSelector(selectUpdatePaymentMethodStatus);
  const getPaymentMethodStatus = useSelector(selectGetPaymentMethodsStatus);
  const deletePaymentMethodsStatus = useSelector(selectDeletePaymentMethodsStatus);
  const savePaymentMethodStatus = useSelector(selectSavePaymentMethodStatus);
  const { enableHqoMiniappApplePay } = useFlags();

  useEffect(() => {
    if (submitCartStatus === ACTION_STATUSES.REJECTED || complete3DSCartError || currentPaymentError) {
      setErrorPaymentId(submittedPaymentMethodId);
    } else {
      setErrorPaymentId(undefined);
    }
  }, [submitCartStatus, submittedPaymentMethodId, complete3DSCartError, currentPaymentError]);

  const onPressHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (enableHqoMiniappApplePay && navigator.userAgent.includes('iPhone')) {
        dispatch(setCurrentPaymentMethodId(event.target.value));
        return;
      }
      dispatch(
        updatePaymentMethod.request({
          default: true,
          paymentMethodId: parseInt(event.target.value),
        }),
      );
    },
    [dispatch, cartId, enableHqoMiniappApplePay, setCurrentPaymentMethodId],
  );

  return {
    onPressHandler,
    updatePaymentMethodStatus,
    getPaymentMethodStatus,
    deletePaymentMethodsStatus,
    savePaymentMethodStatus,
  };
};
