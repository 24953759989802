import { OrderTotal } from 'store/cart/types';

export const formatSummaryValues = (summary: OrderTotal) => {
  const currencyType = summary?.currency_type;
  const subtotal = summary?.subtotal;
  const discountApplied = summary?.discount_applied;
  const total = summary?.total;
  const taxSummary = summary?.tax_summary.taxes_total;

  return { currencyType, subtotal, discountApplied, total, taxSummary };
};
