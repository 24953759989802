import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';
import styled from 'styled-components';

export const FilterSection = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    gap: 8px;
  }
`;
