import React from 'react';
import { DateTimeSelectModalContentProps } from '../interface';
import { DateSelectPageContent } from 'pages/resource/date-select-modal/components/date-select-content';

import { TimeSelectContent } from 'pages/resource/date-time-select-modal/components/time-select-content';

export const DateTimeSelectModalContent = ({
  isDateStep,
  scrollContainerRef,
  contentBodyRef,
}: DateTimeSelectModalContentProps): JSX.Element => {
  return isDateStep ? (
    <DateSelectPageContent />
  ) : (
    <TimeSelectContent scrollContainerRef={scrollContainerRef} contentBodyRef={contentBodyRef} />
  );
};
