import { UiMetadataState } from 'store/ui-metadata/types';
import { ActionType, createReducer } from 'typesafe-actions';
import { getUiMetadata } from 'store/ui-metadata/actions';
import { ACTION_STATUSES } from 'shared/consts';

export const initialState: UiMetadataState = {
  data: null,
  getUiMetaData: { status: null, error: null },
};

const getUiMetadataRequestHandler = (state: UiMetadataState): UiMetadataState => ({
  ...state,
  getUiMetaData: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getUiMetadataSuccessHandler = (
  state: UiMetadataState,
  response: ActionType<typeof getUiMetadata.success>,
): UiMetadataState => ({
  ...state,
  data: response.payload,
  getUiMetaData: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getUiMetadataFailureHandler = (
  state: UiMetadataState,
  { payload: error }: ActionType<typeof getUiMetadata.failure>,
): UiMetadataState => ({
  ...state,
  getUiMetaData: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const uiMetadataReducer = createReducer(initialState)
  .handleAction(getUiMetadata.request, getUiMetadataRequestHandler)
  .handleAction(getUiMetadata.success, getUiMetadataSuccessHandler)
  .handleAction(getUiMetadata.failure, getUiMetadataFailureHandler);

export default uiMetadataReducer;
