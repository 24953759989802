import React from 'react';
import { IntlConfig, IntlProvider as ReactIntlProvider } from 'react-intl';

import { useLocale } from 'hooks/use-locale.hook';
import { DEFAULT_LOCALE, useLocaleData } from 'components/intl-provider/intl-provider.hooks';

type IntlProviderProps = Omit<React.PropsWithChildren<IntlConfig>, 'locale' | 'defaultLocale' | 'messages'>;

export const IntlProvider: React.FC<IntlProviderProps> = ({ children, ...props }: IntlProviderProps): JSX.Element => {
  const locale = useLocale();
  const { messages } = useLocaleData(locale);

  return (
    <ReactIntlProvider messages={messages} locale={locale} defaultLocale={DEFAULT_LOCALE} {...props}>
      {children}
    </ReactIntlProvider>
  );
};
