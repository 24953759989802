import moment from 'moment';
import { Moment } from 'moment-timezone';
import { CalendarDateStatuses, DATE_FORMAT } from 'shared/consts';

export const getCalendarViewDateStatus = (
  day: Moment,
  availableDates: Array<string>,
  startDatesList: Array<string>,
  startDateParam?: string,
  isCartOverloaded?: boolean,
): CalendarDateStatuses => {
  let status: CalendarDateStatuses = CalendarDateStatuses.UNAVAILABLE;
  const formattedDay = day.format(DATE_FORMAT);
  if (isCartOverloaded) {
    status = CalendarDateStatuses.UNAVAILABLE;
  } else if (availableDates?.some(availableDate => availableDate === day.format(DATE_FORMAT))) {
    status = CalendarDateStatuses.AVAILABLE;
    if (moment(day).isSame(moment(), 'day')) {
      status = CalendarDateStatuses.CURRENT;
    }
  }

  if (startDatesList.length) {
    return startDatesList.some(date => date === formattedDay) ? CalendarDateStatuses.ACTIVE : status;
  }

  return startDateParam === formattedDay ? CalendarDateStatuses.ACTIVE : status;
};
