import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  selectAppInstanceConfigs,
  selectCurrentAppInstanceConfigUuid,
  selectOnboardUserStatus,
} from 'store/app-instance-configs/selectors';
import { ACTION_STATUSES, LANDING_PATH, QUERY_PARAMS } from 'shared/consts';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'pages/date-select-page/interface';
import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hook';
import qs from 'qs';
import { pick } from 'utils/pickObjectProperty';
import { replace } from 'store/router/actions';
import {
  getAppInstanceConfigs,
  isCurrentLocationIdUpdating,
  onboardUser,
  resetOnboardUserStatus,
  updateCurrentAppInstanceConfigUuid,
  updateCurrentLocationId,
} from 'store/app-instance-configs/actions';
import { useLocation } from 'react-router';
import { UseModalContentReturnValues } from 'components/termsAndConditions/types';
import { LocaleEnum } from 'store/cart/types';
import { OwnerTypesEnum } from 'store/app-instance-configs/enums';
import { useOwnerParams } from 'hooks/use-owner-params.hook';
import { useLocale } from 'hooks/use-locale.hook';

export const useModalContent = (): UseModalContentReturnValues => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const client = useMiniappSdk();
  const { ...restQueryParams } = useSearchParams<searchParams>();
  const { ownerType, ownerUuid } = useOwnerParams();
  const locale = useLocale();
  const currentAppInstanceConfigUuid = useSelector(selectCurrentAppInstanceConfigUuid);
  const onboardUserStatus = useSelector(selectOnboardUserStatus);
  const appInstanceConfigs = useSelector(selectAppInstanceConfigs);
  const isAgreeButtonLoading: boolean = onboardUserStatus === ACTION_STATUSES.PENDING;
  const [declineButtonProps, setDeclineButtonProps] = useState<Record<string, boolean>>(null);
  const { isLoading: isDeclineButtonLoading, isDisabled: isDeclineButtonDisabled } = declineButtonProps || {};

  const configWithoutTermsAndConditions = useMemo(
    () => appInstanceConfigs?.find(config => !config.config.terms_and_conditions_enabled),
    [appInstanceConfigs],
  );
  const { uuid, location_id } = configWithoutTermsAndConditions || {};

  const redirectPathname = pathname.includes('resources')
    ? pathname.replace('/terms-and-conditions', '')
    : LANDING_PATH;

  const queryParams = useMemo(
    () =>
      qs.stringify(
        pick(
          restQueryParams,
          QUERY_PARAMS.filter(
            param =>
              param !== 'startTime' &&
              param !== 'startDate' &&
              param !== 'endTime' &&
              param !== 'isExact' &&
              param !== 'duration' &&
              param !== 'presetWindows',
          ),
        ),
      ),
    [restQueryParams],
  );

  useEffect(() => {
    if (onboardUserStatus === ACTION_STATUSES.FULFILLED) {
      dispatch(
        getAppInstanceConfigs.request({
          lang: locale as LocaleEnum,
          owners: [
            {
              uuid: ownerUuid,
              type: ownerType.toUpperCase() as OwnerTypesEnum,
            },
          ],
        }),
      );
      dispatch(resetOnboardUserStatus());
      dispatch(replace(`${redirectPathname}?${queryParams}`));
    }
  }, [dispatch, locale, onboardUserStatus, ownerType, ownerUuid, queryParams, redirectPathname, restQueryParams]);

  const onAgreeClick = useCallback(() => {
    dispatch(onboardUser.request({ appInstanceConfigUuid: currentAppInstanceConfigUuid }));
  }, [currentAppInstanceConfigUuid, dispatch]);

  const onDeclineClick = useCallback(() => {
    setTimeout(() => {
      setDeclineButtonProps({ ...declineButtonProps, isLoading: false, isDisabled: true });
    }, 2000);
    setDeclineButtonProps({ ...declineButtonProps, isLoading: true });
    if (configWithoutTermsAndConditions) {
      dispatch(updateCurrentLocationId({ currentLocationId: location_id }));
      dispatch(isCurrentLocationIdUpdating({ isCurrentLocationIdUpdating: true }));
      dispatch(updateCurrentAppInstanceConfigUuid({ currentAppInstanceConfigUuid: uuid }));
      dispatch(replace(`${redirectPathname}?${queryParams}`));
    } else if ('close' in client) {
      client.close();
    }
  }, [
    client,
    configWithoutTermsAndConditions,
    declineButtonProps,
    dispatch,
    location_id,
    queryParams,
    redirectPathname,
    uuid,
  ]);

  return { onAgreeClick, onDeclineClick, isAgreeButtonLoading, isDeclineButtonLoading, isDeclineButtonDisabled };
};
