import { MiniappSdkContext } from 'components/miniapp-sdk-provider/miniapp-sdk.context';
import React from 'react';
import { getMiniappSdkClient } from './miniapp-sdk.hook';

interface MiniappSdkProviderProps {
  children: React.ReactNode;
}

export const MiniappSdkProvider = ({ children }: MiniappSdkProviderProps): JSX.Element => {
  const miniappSdkClient = getMiniappSdkClient(process.env.REACT_APP_UUID);

  return <MiniappSdkContext.Provider value={miniappSdkClient}>{children}</MiniappSdkContext.Provider>;
};
