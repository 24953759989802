import { IntlShape } from 'react-intl';

export const formatResourceType = (type: string): string => {
  if (!type) {
    return '';
  }
  return type.toUpperCase().replace(/ /g, '_');
};

export const stringToCamelCase = (type: string): string => {
  if (!type) {
    return '';
  }
  const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
  const string = type
    .toLowerCase()
    .replace(/[^A-Za-z0-9]/g, ' ')
    .split(' ')
    .reduce((result, word) => result + capitalize(word.toLowerCase()));
  return string.charAt(0).toLowerCase() + string.slice(1);
};

export const getResourceTypeString = (isCurrentConfigNexudus: boolean, type: string, intl?: IntlShape): string => {
  if (isCurrentConfigNexudus) {
    return type.substring(0, 45);
  }

  return intl.formatMessage({ id: `${stringToCamelCase(type)}`, defaultMessage: type });
};
