import React, { useMemo } from 'react';
import { useCurrencyType } from 'hooks/use-currency-type.hook';
import { useLocale } from 'hooks/use-locale.hook';
import { getCurrencySymbol } from 'utils/getCurrencySymbol';
import { LabelPill } from 'components/label-pill';

const PaidLabelPill = () => {
  const languageCode = useLocale();
  const currencyCode = useCurrencyType();
  const currencySymbol = useMemo(() => getCurrencySymbol(languageCode, currencyCode), [languageCode, currencyCode]);

  return <LabelPill text={currencySymbol} />;
};

export default PaidLabelPill;
