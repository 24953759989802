import * as userActions from '../user/actions';

import {
  API_URL_LS_KEY,
  APP_BRAND_LS_KEY,
  BUILDING_UUID_LS_KEY,
  LOCALE_LS_KEY,
  AUTH_TOKEN_LS_KEY,
  SINGLE_APP_INSTANCE_CONFIG_LS_KEY,
  SINGLE_LOCATION_CONFIG_LS_KEY,
} from 'shared/consts';

import { ConfigState } from './types';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { getDefaultApiUrl } from 'store/config/get-default-url.util';
import { saveExternalConfig } from 'store/config/actions';
import qs from 'qs';
import { ViewParams } from 'shared/types';

export const getInitialState = () => {
  const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true }) as unknown as ViewParams;
  const buildingUuid = queryParams.buildingUuid ?? localStorage.getItem(BUILDING_UUID_LS_KEY) ?? null;
  const singleAppInstanceConfig: boolean = queryParams.singleAppInstanceConfig
    ? queryParams.singleAppInstanceConfig === 'true'
    : localStorage.getItem(`${SINGLE_APP_INSTANCE_CONFIG_LS_KEY}:${buildingUuid}`) === 'true';

  const singleLocationConfig: boolean = queryParams.singleLocationConfig
    ? queryParams.singleLocationConfig === 'true'
    : localStorage.getItem(`${SINGLE_LOCATION_CONFIG_LS_KEY}:${buildingUuid}`) === 'true';

  return {
    apiUrl: queryParams.apiUrl ?? localStorage.getItem(API_URL_LS_KEY) ?? getDefaultApiUrl(),
    appBrand: queryParams.appBrand ?? localStorage.getItem(APP_BRAND_LS_KEY) ?? null,
    buildingUuid,
    locale: queryParams.locale?.replace('_', '-') ?? localStorage.getItem(LOCALE_LS_KEY) ?? null,
    authToken: queryParams.authToken ?? localStorage.getItem(AUTH_TOKEN_LS_KEY) ?? null,
    singleAppInstanceConfig,
    singleLocationConfig,
  };
};

export const initialState: ConfigState = getInitialState();

const getExternalConfigSaveReducer = <K extends keyof ConfigState>(key: K, lsKey: string) => {
  return (state: ConfigState[K], action: ReturnType<typeof saveExternalConfig>): ConfigState[K] => {
    let value = action.payload[key];

    if (key === 'singleAppInstanceConfig' && value) {
      localStorage.setItem(lsKey, JSON.stringify(value === 'true'));
      return (value === 'true') as ConfigState[K];
    }
    if (key === 'singleLocationConfig' && value) {
      localStorage.setItem(lsKey, JSON.stringify(value === 'true'));
      return (value === 'true') as ConfigState[K];
    }
    if (value) {
      if (key === 'locale') {
        value = value.toLowerCase()?.replace('_', '-');
      }
      localStorage.setItem(lsKey, value);
    }

    return (value ?? state) as ConfigState[K];
  };
};

const apiUrlReducer = createReducer(initialState.apiUrl)
  .handleAction(saveExternalConfig, getExternalConfigSaveReducer('apiUrl', API_URL_LS_KEY))
  .handleAction(userActions.getCurrentUser.failure, () => {
    localStorage.removeItem(API_URL_LS_KEY);

    return getDefaultApiUrl();
  });

const appBrandReducer = createReducer(initialState.appBrand).handleAction(
  saveExternalConfig,
  getExternalConfigSaveReducer('appBrand', APP_BRAND_LS_KEY),
);

const buildingUuidReducer = createReducer(initialState.buildingUuid).handleAction(
  saveExternalConfig,
  getExternalConfigSaveReducer('buildingUuid', BUILDING_UUID_LS_KEY),
);

const localeReducer = createReducer(initialState.locale).handleAction(
  saveExternalConfig,
  getExternalConfigSaveReducer('locale', LOCALE_LS_KEY),
);

const authTokenReducer = createReducer(initialState.authToken)
  .handleAction(saveExternalConfig, getExternalConfigSaveReducer('authToken', AUTH_TOKEN_LS_KEY))
  .handleAction(userActions.getCurrentUser.failure, () => {
    localStorage.removeItem(AUTH_TOKEN_LS_KEY);

    return null;
  });

const singleAppInstanceConfigReducer = createReducer(initialState.singleAppInstanceConfig).handleAction(
  saveExternalConfig,
  getExternalConfigSaveReducer(
    'singleAppInstanceConfig',
    `${SINGLE_APP_INSTANCE_CONFIG_LS_KEY}:${initialState.buildingUuid}`,
  ),
);

const singleLocationConfigReducer = createReducer(initialState.singleLocationConfig).handleAction(
  saveExternalConfig,
  getExternalConfigSaveReducer('singleLocationConfig', `${SINGLE_LOCATION_CONFIG_LS_KEY}:${initialState.buildingUuid}`),
);

export default combineReducers({
  apiUrl: apiUrlReducer,
  appBrand: appBrandReducer,
  buildingUuid: buildingUuidReducer,
  locale: localeReducer,
  authToken: authTokenReducer,
  singleAppInstanceConfig: singleAppInstanceConfigReducer,
  singleLocationConfig: singleLocationConfigReducer,
});
