import React from 'react';
import { IconContainer, ActiveDot } from './styles';
import { SlidersH } from '@hqo/react-components-library/dist/icons';

interface FilterIconProps {
  isActive: boolean;
  onClick: () => void;
}

export const FilterIcon = ({ isActive, onClick }: FilterIconProps): JSX.Element => {
  return (
    <IconContainer className="filter-icon" onClick={onClick} data-testid="icon-container">
      <SlidersH variant="adminBlack" />
      {isActive && <ActiveDot data-testid="icon-container-dot" />}
    </IconContainer>
  );
};
