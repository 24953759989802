import styled, { keyframes } from 'styled-components';
import { Notification } from 'store/notifications/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { NOTIFICATION_ANIMATION_DURATION_MS } from 'components/notifications/components/notification/consts';

const typeColorMap: Record<NonNullable<Notification['type']>, string> = {
  success: 'systemGreenAlt',
};

const typeColorLightMap: Record<NonNullable<Notification['type']>, string> = {
  success: 'systemGreenLight',
};

const bounceInRight = keyframes`
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
`;

const bounceOutRight = keyframes`
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
`;

export const Root = styled.div<{ visible: boolean; duration: number; type: Notification['type'] }>`
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 10px 8px 8px 8px;
  border-radius: 4px;
  box-shadow: 0px 4px 70px 0px rgba(105, 110, 114, 0.15), 0px 8px 10px 0px rgba(105, 110, 114, 0.06),
    0px 4px 8px 0px rgba(105, 110, 114, 0.12), 0px 0px 0px 0px rgba(105, 110, 114, 0.1);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  overflow: hidden;
  font-family: ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.fontColor};
  cursor: pointer;
  animation-name: ${({ visible }) => (visible ? bounceInRight : bounceOutRight)};
  animation-fill-mode: forwards;
  animation-duration: ${NOTIFICATION_ANIMATION_DURATION_MS}ms;

  @media only screen and (max-width: 480px) {
    margin-bottom: 0;
  }

  background-color: ${({ theme }) => theme.colors.palette.sharedWhite};
  z-index: 10;
`;

export const Content = styled.span`
  font-size: 12px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
  margin: 0;
  font-weight: 400;
`;

export const Title = styled.h3`
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  font-weight: 500;
`;

const progressBarAnimation = keyframes`
  0%{
    transform: scaleX(1);
  }
  100%{
    transform: scaleX(0);
  }
`;

export const ProgressBar = styled.div<{ duration: number; type: Notification['type'] }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  transform-origin: left;
  animation: linear 1 forwards;
  animation-duration: ${({ duration }) => `${duration}ms`};
  animation-name: ${progressBarAnimation};
  transition: transform 0.2s;
  background: ${({ theme, type }) => theme.colors.palette[typeColorMap[type]]};
`;

export const ProgressBarContainer = styled.div<{ type: Notification['type'] }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: ${({ theme, type }) => theme.colors.palette[typeColorLightMap[type]]};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
`;

export const Icon = styled(FontAwesomeIcon)<{ type: Notification['type'] }>`
  color: ${({ theme, type }) => theme.colors.palette[typeColorMap[type]]};
  margin-top: 4px;
`;

export const CloseButton = styled(Button)`
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: 16px;
`;
