import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { selectInitialRoute } from 'store/routes/selectors';
import { updateInitialRoute } from 'store/routes/actions';

export const useInitialRoute = (): string => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const initialRoute = useSelector(selectInitialRoute);

  useEffect(() => {
    if (!initialRoute) {
      dispatch(updateInitialRoute(pathname));
    }
  }, [dispatch, initialRoute, pathname]);

  return pathname;
};
