import { useSearchParams } from 'hooks/use-search-params.hook';
import { useMemo } from 'react';
import { TRACK_EVENT_SOURCES } from 'shared/consts';
import useKioskEnabled from 'store/kiosk/hooks/use-kiosk-enabled';

interface useTrackingInfoReturnValues {
  source?: TRACK_EVENT_SOURCES;
}

export const useTrackingInfo = (): useTrackingInfoReturnValues => {
  const isKioskEnabled = useKioskEnabled();
  const { source } = useSearchParams();

  const trackingSource = useMemo(() => {
    if (isKioskEnabled) {
      return TRACK_EVENT_SOURCES.KIOSK;
    }
    if (source === 'floor_plan') {
      return TRACK_EVENT_SOURCES.FLOOR_PLAN;
    }
  }, [isKioskEnabled, source]);

  return {
    source: trackingSource,
  };
};
