import styled from 'styled-components';
import { StyledMobileModal } from 'components/modals/modal-portal/modal.styles';

export const StyledModalWrapper = styled(StyledMobileModal)`
  top: unset;
  z-index: 14;
  padding: 16px 8px;
  box-shadow: 0 0 10px black;
  border-radius: 8px 8px 0 0;
  box-shadow: ${({ theme }) => `0px 0px 4px ${theme.colors.greys.adminBlack}33`};
`;
