import { TIMEKIT_BOOKING_ERROR_MESSAGE } from 'shared/consts/errors';
import { PaymentErrorsEnum } from 'shared/consts/payment-error-types';
import { BookingCreationError } from 'store/booking-flow/types';

export const getCartErrorMessage = (cartError: SerializableError): BookingCreationError => {
  if (cartError?.message.includes(TIMEKIT_BOOKING_ERROR_MESSAGE)) {
    return BookingCreationError.BOOKING_PROBLEM_APOINTMENT;
  }
  switch (cartError?.key) {
    case PaymentErrorsEnum.BOOKING_UNAVAILABLE_ERROR:
      return BookingCreationError.BOOKING_UNAVAILABLE;
    case PaymentErrorsEnum.CARD_DECLINED_ERROR:
      return BookingCreationError.CARD_DECLINED;
    case PaymentErrorsEnum.CART_SUBMISSION_ERROR:
      return BookingCreationError.CART_SUBMISSION;
    case PaymentErrorsEnum.PAYMENT_PROCESSING_ERROR:
      return BookingCreationError.PAYMENT_PROCESSING;
    default:
      return BookingCreationError.CART_SUBMISSION;
  }
};
