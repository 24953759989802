import React from 'react';
import { QuickTermsAndConditionsProps } from 'components/termsAndConditions/types';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { MobileModal } from './mobile';
import { DesktopModal } from './desktop';

export const TermsAndConditionsTextModal = ({ handleChangeStep }: QuickTermsAndConditionsProps) => {
  const isMobileDevice = useIsSmallViewportWidth();

  return isMobileDevice ? (
    <MobileModal handleChangeStep={handleChangeStep} />
  ) : (
    <DesktopModal handleChangeStep={handleChangeStep} />
  );
};
