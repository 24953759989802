import { ACTION_STATUSES, PATCH_STATUSES } from 'shared/consts';
import { ButtonContainer, CancelButton } from './styles';

import { PatchTransaction } from 'store/transactions/types';
import React from 'react';
import { useIntl } from 'react-intl';
import { BookingNotCancelableReasons } from 'store/bookings/types';

interface CancelButtonBlockProps {
  onCancel: VoidFunction;
  patchTransactionData: Pick<PatchTransaction, 'status' | 'type'>;
  cancelable: boolean;
  notCancelableReason?: BookingNotCancelableReasons;
  isSwipeModalContent: boolean;
}

export const CancelButtonBlock = ({
  onCancel,
  patchTransactionData,
  cancelable,
  notCancelableReason,
  isSwipeModalContent,
}: CancelButtonBlockProps) => {
  const intl = useIntl();

  const resolveButtonText = () => {
    switch (notCancelableReason) {
      case BookingNotCancelableReasons.BOOKING_IN_THE_PAST: {
        return 'cancellation_denied';
      }
      case BookingNotCancelableReasons.CANCELATION_PERIOD_CLOSED: {
        return 'cancellation_period_closed';
      }
      default:
    }

    if (patchTransactionData.type !== PATCH_STATUSES.CANCELLED) return 'cancel_booking';

    switch (patchTransactionData.status) {
      case ACTION_STATUSES.FULFILLED:
        return 'canceled';
      case ACTION_STATUSES.PENDING:
        return 'cancel_booking';
      case ACTION_STATUSES.REJECTED:
        return 'cancellation_denied';
      default:
        return 'yes_cancel';
    }
  };

  return (
    <ButtonContainer>
      <CancelButton
        isSwipeModalContent={isSwipeModalContent}
        data-testid="cancel-button"
        text={intl.formatMessage({ id: resolveButtonText() })}
        variant="Primary"
        onClick={onCancel}
        loading={
          patchTransactionData.status === ACTION_STATUSES.PENDING &&
          patchTransactionData.type === PATCH_STATUSES.CANCELLED
        }
        disabled={!cancelable || !!patchTransactionData.status}
      />
    </ButtonContainer>
  );
};
