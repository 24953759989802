import { Container, ScheduleTitle, ScheduleHeader } from './styles';
import React from 'react';
import { useScheduleSelectHeader } from './hooks/use-schedule-select-header.hook';
import { DurationBlock } from './components/duration-block';
import { SwitchBlock } from './components/switch-block';
import { RefObject, TimeRangeByDuration } from 'components/schedule-select/interface';

interface ScheduleSelectHeaderProps {
  refs: Array<RefObject>;
  availableTimeRangeByMinDuration: TimeRangeByDuration;
}

export const ScheduleSelectHeader = ({
  refs,
  availableTimeRangeByMinDuration,
}: ScheduleSelectHeaderProps): JSX.Element => {
  const { scheduleTitle, checked, onChangeHandler, isSwitchBlockVisible } = useScheduleSelectHeader({
    refs,
    availableTimeRangeByMinDuration,
  });

  return (
    <Container data-testid="schedule-select-header">
      <ScheduleTitle className="schedule-select-title" data-testid="schedule-select-title">
        {scheduleTitle}
      </ScheduleTitle>
      <ScheduleHeader>
        <DurationBlock isSwitchBlockVisible={isSwitchBlockVisible} />
        {isSwitchBlockVisible && <SwitchBlock checked={checked} onChangeHandler={onChangeHandler} />}
      </ScheduleHeader>
    </Container>
  );
};
