import { ACTION_STATUSES } from 'shared/consts';
import { Container, StyledPaymentMethodsManager, ErrorWrapper, ErrorLabel } from './styles';
import { Order, UIEnablements } from 'store/cart/types';
import React, { useMemo, useState } from 'react';
import { SavedPaymentMethod } from 'store/payment/types';
import { useIntl } from 'react-intl';
import { AddCard } from '../add-card';
import { usePaymentLabels } from './hooks/use-payment-labels.hook';
import { usePaymentMethodsManager } from './hooks/use-payment-methods-manager.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface PaymentMethodsProps {
  cart: Order;
  onCancelAddCardClick: VoidFunction;
  formTitle?: string;
  enabledPaymentMethods: UIEnablements;
  paymentMethods: Array<SavedPaymentMethod>;
  selectedPaymentMethodId?: string;
  onRemovePaymentMethod: VoidFunction;
  onAddNewCard: VoidFunction;
  submittedPaymentMethodId?: number;
  currentPaymentError?: boolean;
  cartId: string;
  total?: number;
  isSwipeModalContent?: boolean;
  showAddCardForm: boolean;
  isAddCardButtonDisabled: boolean;
  isNativePaymentError: boolean;
  setIsAddCardButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PaymentMethods = ({
  formTitle,
  paymentMethods,
  selectedPaymentMethodId,
  onRemovePaymentMethod,
  onAddNewCard,
  submittedPaymentMethodId,
  currentPaymentError,
  cartId,
  total,
  isSwipeModalContent = false,
  showAddCardForm,
  cart,
  onCancelAddCardClick,
  isAddCardButtonDisabled,
  setIsAddCardButtonDisabled,
  isNativePaymentError,
}: PaymentMethodsProps) => {
  const intl = useIntl();
  const [errorPaymentId, setErrorPaymentId] = useState<number>(undefined);
  const { enableHqoMiniappApplePay } = useFlags();
  const isApplePaySupported: boolean = enableHqoMiniappApplePay && navigator.userAgent.includes('iPhone');
  const { buildSavedPaymentLabels } = usePaymentLabels({
    paymentMethods,
    errorPaymentId,
    total,
    isApplePaySupported,
  });
  const {
    onPressHandler,
    updatePaymentMethodStatus,
    savePaymentMethodStatus,
    getPaymentMethodStatus,
    deletePaymentMethodsStatus,
  } = usePaymentMethodsManager({
    setErrorPaymentId,
    submittedPaymentMethodId,
    currentPaymentError,
    cartId,
  });
  const isRadioFormDisabled = useMemo<boolean>(
    () =>
      (updatePaymentMethodStatus && updatePaymentMethodStatus !== ACTION_STATUSES.FULFILLED) ||
      getPaymentMethodStatus === ACTION_STATUSES.PENDING ||
      deletePaymentMethodsStatus === ACTION_STATUSES.PENDING ||
      savePaymentMethodStatus === ACTION_STATUSES.PENDING ||
      showAddCardForm,
    [
      updatePaymentMethodStatus,
      getPaymentMethodStatus,
      deletePaymentMethodsStatus,
      savePaymentMethodStatus,
      showAddCardForm,
    ],
  );
  const labels = useMemo(
    () => ({
      title: formTitle,
      invoiceText: intl.formatMessage({ id: 'invoice_text' }),
      addCardButtonText: intl.formatMessage({
        id: 'add_new_card',
      }),
    }),
    [intl, formTitle],
  );
  const isPaymentErrorVisible = useMemo<boolean>(
    () => (!!errorPaymentId || isNativePaymentError || currentPaymentError) && isApplePaySupported,
    [errorPaymentId, isNativePaymentError, currentPaymentError, isApplePaySupported],
  );

  return (
    <Container data-testid="edit-payment" isSwipeModalContent={isSwipeModalContent}>
      <StyledPaymentMethodsManager
        paymentMethods={paymentMethods}
        onOptionClick={onPressHandler}
        onRemovePaymentMethod={onRemovePaymentMethod}
        selectedPaymentMethodId={selectedPaymentMethodId}
        onAddNewCard={onAddNewCard}
        options={buildSavedPaymentLabels()}
        isRadioFormDisabled={isRadioFormDisabled}
        labels={labels}
        isAddCardButtonDisabled={isAddCardButtonDisabled}
      />
      {isPaymentErrorVisible && (
        <ErrorWrapper>
          <ErrorLabel>{intl.formatMessage({ id: 'payment_error' })}</ErrorLabel>
        </ErrorWrapper>
      )}
      {showAddCardForm && (
        <AddCard
          onCancel={onCancelAddCardClick}
          spreedlyEnvKey={cart.ui_enablements.spreedly_env_token}
          cartId={cartId}
          setIsAddCardButtonDisabled={setIsAddCardButtonDisabled}
        />
      )}
    </Container>
  );
};
