import {
  BaseQueryApi,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  createApi,
  fetchBaseQuery,
  retry,
} from '@reduxjs/toolkit/query/react';
import prepareHeaders from 'api/helix/utils/prepareHeaders';
import { extractDataFromHelixResponse } from 'api/helix/utils/transform';
import { HelixApiResponse } from 'api/helix/utils/types';

import { RootState } from 'store';

export type QueryApi = Omit<BaseQueryApi, 'getState'> & {
  getState(): RootState;
};

export type HelixModuleApiPath = string | ((api: QueryApi) => string);
export type DefaultParamsBuilder = object | ((api: QueryApi) => object)[];

export default function createHelixModuleApi(
  moduleApiPath: HelixModuleApiPath = '',
  defaultParams: DefaultParamsBuilder = {},
  options: Omit<Parameters<typeof fetchBaseQuery>[0], 'url'> = {},
) {
  const baseQuery: BaseQueryFn<
    string | FetchArgs,
    HelixApiResponse<unknown>,
    FetchBaseQueryError,
    object,
    FetchBaseQueryMeta
  > = fetchBaseQuery({
    prepareHeaders,
    responseHandler: async response => {
      const content: HelixApiResponse<unknown> = await response.json();
      return extractDataFromHelixResponse(content);
    },
    ...options,
  });

  const baseQueryWithRetry = retry(baseQuery, { maxRetries: 3 });

  // See https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#constructing-a-dynamic-base-url-using-redux-state
  const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions,
  ) => {
    const apiUrl = (api as unknown as QueryApi).getState().config.apiUrl || '';
    const sanitizedApiUrl = apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl;

    const moduleUrl = typeof moduleApiPath === 'string' ? moduleApiPath : moduleApiPath(api as unknown as QueryApi);
    const adjustedDefaultParams = Array.isArray(defaultParams)
      ? defaultParams
          .map(func => func(api as unknown as QueryApi))
          .reduce((newParams, currentParams) => ({ ...currentParams, ...newParams }), {})
      : defaultParams;
    const params =
      typeof args === 'string'
        ? adjustedDefaultParams
        : {
            ...adjustedDefaultParams,
            ...args.params,
          };
    const urlEnd = typeof args === 'string' ? args : args.url;
    const fullUrl = `${sanitizedApiUrl}/api/${moduleUrl}/${urlEnd}`;
    const adjustedArgs = typeof args === 'string' ? fullUrl : { ...args, params, url: fullUrl };
    return baseQueryWithRetry(adjustedArgs, api, extraOptions);
  };

  const api = createApi({
    baseQuery: dynamicBaseQuery,
    endpoints: () => ({}),
  });

  return {
    dynamicBaseQuery,
    baseQuery,
    baseQueryWithRetry,
    api,
  };
}
