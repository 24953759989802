import { Chip } from '@hqo/react-components-library/dist/atoms/chip';
import styled from 'styled-components';

export const CapacityFilterContainer = styled.div`
  margin: 35px 0;
`;

export const CapacityFilterTitle = styled.div`
  margin-bottom: 16px;
  font: normal 16px/19px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};
`;

export const StyledChip = styled(Chip)<{ isDisabled: boolean }>``;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px 16px;

  .capacity-chip {
    justify-content: center;
  }
`;
