import { Paper } from '@hqo/react-components-library/dist/atoms/paper';
import styled from 'styled-components';

export const StyledPaper = styled(Paper)`
  margin-top: 8px;
  padding: 8px 0;
  overflow: auto;
  position: absolute;
  max-height: 272px;
  height: auto;
  box-shadow: none;
  border: 1px solid ${({ theme }) => theme.colors.greys.adminGreyLight};
  z-index: 1;
`;
