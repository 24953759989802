import { RootState } from 'store/reducer';
import { ResourceState } from './types';
import { createSelector } from 'reselect';

export const resourceState = (state: RootState): ResourceState => state.resource;

export const selectResource = createSelector([resourceState], state => state.resource);

export const selectGetResourceStatus = createSelector([resourceState], state => state.getResource.status);

export const selectGetResourceError = createSelector([resourceState], state => state.getResource.error);

export const selectAddOns = createSelector([resourceState], state =>
  state.resource.add_ons?.sort((firstAddon, secondAddon) => Number(secondAddon.required) - Number(firstAddon.required)),
);

export const selectRoomLayouts = createSelector([resourceState], state => state.resource.room_layouts);

export const selectResourceClosestAvailability = createSelector(
  [selectResource],
  resource => resource.closest_availability,
);

export const selectResourceClosestAvailableDayTimeRanges = createSelector(
  [selectResource],
  resource => resource.closest_availability?.closest_day_time_ranges,
);

export const imageIsLoadedSelector = createSelector([resourceState], resource => resource.imageIsLoaded);
