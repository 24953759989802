import { EMPTY_STRING } from 'components/schedule-select/const';

const formatDate = (dateString: string, locale: string) => {
  const dateObj = new Date(dateString);
  const month = dateObj.toLocaleString(locale, { month: 'short', day: 'numeric', timeZone: 'UTC' });

  return `${month}`;
};

export const formatStartDatesQueryParams = (startDates = EMPTY_STRING, locale: string): Array<string> => {
  const formattedStartDates = startDates.split(',') || [];
  const sortedStartDates = formattedStartDates.sort(
    (firstDate, secondDate) => new Date(firstDate).getTime() - new Date(secondDate).getTime(),
  );

  return sortedStartDates.map(dateString => formatDate(dateString, locale));
};
