import { useLocale } from 'hooks/use-locale.hook';
import { useOwnerParams } from 'hooks/use-owner-params.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_STATUSES, BOOKING_DAYS_LIMIT, INFINITE_SCROLL_SENSITIVITY } from 'shared/consts';
import { searchParams } from 'shared/types/search-params';
import { OwnerTypesEnum } from 'store/app-instance-configs/enums';
import { getResourceInfiniteAvailableDates } from 'store/resource-available-dates/actions';
import {
  selectResourceAvailableDatesStatus,
  selectResourceInfiniteAvailableDates,
  selectResourceInfiniteAvailableDatesStatus,
} from 'store/resource-available-dates/selectors';
import { resourceState } from 'store/resource/selectors';
import { getFormattedDates } from 'utils/getFormattedDates';

const SCROLL_ACCURACY = 3;

export const useInfiniteLoading = (): VoidFunction => {
  const dispatch = useDispatch();
  const { ownerUuid } = useOwnerParams();
  const resourceAvailableDates = useSelector(selectResourceInfiniteAvailableDates);
  const resourceId = useSelector(resourceState).resource.id;
  const isInfiniteDatesLoading = useSelector(selectResourceInfiniteAvailableDatesStatus) === ACTION_STATUSES.PENDING;
  const isDatesLoading = useSelector(selectResourceAvailableDatesStatus) === ACTION_STATUSES.PENDING;
  const locale = useLocale();
  const { startDates } = useSearchParams<searchParams>();
  const formattedStartDates = startDates ? startDates.split(',') : [];
  const scrollableContainer = document.getElementById('scroll-container');
  const isOneDayBeforeBookingLimit = formattedStartDates.length === BOOKING_DAYS_LIMIT - 1;
  const isContainerNearBottom =
    scrollableContainer?.scrollTop + scrollableContainer?.clientHeight + SCROLL_ACCURACY >
    scrollableContainer?.scrollHeight;

  const indexOfMonth = useMemo<{
    numberOfMonths: number;
    formattedFirstDay: string;
    formattedLastDay: string;
  }>(() => {
    const numberOfMonths = Object.keys(resourceAvailableDates).length;
    const { formattedFirstDay, formattedLastDay } = getFormattedDates(numberOfMonths, locale);

    return {
      numberOfMonths,
      formattedFirstDay,
      formattedLastDay,
    };
  }, [locale, resourceAvailableDates]);

  useEffect(() => {
    const isUserUnselectingLimitDayAtScrollBottom = isOneDayBeforeBookingLimit && isContainerNearBottom;
    if (isUserUnselectingLimitDayAtScrollBottom) {
      dispatch(
        getResourceInfiniteAvailableDates.request({
          ownerType: OwnerTypesEnum.BUILDING,
          ownerUuid,
          resourceId,
          from: indexOfMonth.formattedFirstDay,
          to: indexOfMonth.formattedLastDay,
          indexOfMonth: indexOfMonth.numberOfMonths,
        }),
      );
    }
  }, [formattedStartDates]);

  const onScroll = useCallback((): void => {
    const { scrollTop, offsetHeight, scrollHeight } = scrollableContainer || {};
    const isAtBottomOfPage = scrollHeight < Math.round(scrollTop) + offsetHeight + INFINITE_SCROLL_SENSITIVITY;

    if (isAtBottomOfPage && !isInfiniteDatesLoading && !isDatesLoading) {
      dispatch(
        getResourceInfiniteAvailableDates.request({
          ownerType: OwnerTypesEnum.BUILDING,
          ownerUuid,
          resourceId,
          from: indexOfMonth.formattedFirstDay,
          to: indexOfMonth.formattedLastDay,
          indexOfMonth: indexOfMonth.numberOfMonths,
        }),
      );
    }
  }, [
    dispatch,
    indexOfMonth.formattedFirstDay,
    indexOfMonth.formattedLastDay,
    indexOfMonth.numberOfMonths,
    isDatesLoading,
    isInfiniteDatesLoading,
    ownerUuid,
    resourceId,
    scrollableContainer,
  ]);

  useEffect(() => {
    if (formattedStartDates.length < BOOKING_DAYS_LIMIT) {
      scrollableContainer?.addEventListener('scroll', onScroll);
    }

    return () => scrollableContainer?.removeEventListener('scroll', onScroll);
  }, [indexOfMonth, isDatesLoading, isInfiniteDatesLoading, onScroll, scrollableContainer, formattedStartDates]);

  return onScroll;
};
