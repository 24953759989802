import { ActiveBlockContainer, Container, ScheduleContainer } from './styles';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts/';

import { ActiveBlock } from './components/active-block';
import { GhostScheduleSelect } from './components/ghost-schedule-select';
import { RefObject } from './interface';
import { ScheduleSelectBlock } from './components/schedule-select-block';
import { resourceState } from 'store/bookings/selectors';
import { track } from '@hqo/web-tracking';
import { useScheduleSelect } from './use-schedule-select.hook';
import { useSelector } from 'react-redux';
import { getResourceTrackInfo } from 'utils/getResourceTrackInfo';
import { ScheduleSelectHeader } from './components/schedule-select-header';

interface ScheduleSelectProps {
  scrollContainerRef?: React.MutableRefObject<HTMLDivElement>;
  contentBodyRef?: React.MutableRefObject<HTMLDivElement>;
}

export const ScheduleSelect = ({ scrollContainerRef, contentBodyRef }: ScheduleSelectProps): JSX.Element => {
  const [isScrollToSelectedValue, setIsScrollToSelectedValue] = useState<boolean>(true);
  const { resource } = useSelector(resourceState);
  const ref = useRef<HTMLDivElement>(null);
  const {
    filteredTimeRanges,
    isLoadingState,
    formatTime,
    top,
    height,
    timeRangeByDuration,
    availableTimeRangeByMinDuration,
    availableTimeRangeByStartTime,
    durations,
    timeInterval,
    isTimeIntervalFromTimeRanges,
  } = useScheduleSelect();
  const listRefs: RefObject[] = [];
  const [refs, setRefs] = useState<RefObject[]>(null);
  const [defaultOffsetTop, setDefaultOffsetTop] = useState<number>(null);
  const scrollContainer = scrollContainerRef?.current;
  const contentBody = contentBodyRef?.current;

  useEffect(() => {
    if (scrollContainer && contentBody) {
      scrollContainer.style.overflow = 'hidden';
      contentBody.style.height = '100%';
    }
    return () => {
      if (scrollContainer && contentBody) {
        scrollContainer.style.overflow = 'auto';
        contentBody.style.height = 'initial';
      }
    };
  }, [contentBody, scrollContainer]);

  const handleAddRef = useCallback((currentRef: RefObject) => {
    listRefs.push(currentRef);
  }, []);

  useEffect(() => {
    setRefs(listRefs);
  }, []);

  useEffect(() => {
    if (!isLoadingState && !!resource) {
      track(
        TRACK_EVENT_NAMES.RESOURCE_SCHEDULE_IMPRESSION,
        {
          type: TRACK_EVENT_TYPES.IMPRESSION,
          resource: getResourceTrackInfo(resource),
        },
        { sendToHqoTracking: true },
      );
    }
  }, [isLoadingState, resource]);

  useEffect(() => {
    if (ref?.current?.offsetTop) {
      setDefaultOffsetTop(ref?.current?.offsetTop);
    }
  }, [ref?.current?.offsetTop, isLoadingState]);

  return isLoadingState ? (
    <GhostScheduleSelect />
  ) : (
    <Container className="schedule-select-container" data-testid="schedule-select-container">
      <ScheduleSelectHeader refs={refs} availableTimeRangeByMinDuration={availableTimeRangeByMinDuration} />
      <ScheduleContainer id="schedule-container" ref={ref}>
        <ActiveBlockContainer>
          <ActiveBlock
            isScrollToSelectedValue={isScrollToSelectedValue}
            top={top}
            height={height}
            listRefs={refs}
            currentRef={ref}
            setIsScrollToSelectedValue={setIsScrollToSelectedValue}
            timeRangeByDuration={timeRangeByDuration}
            availableTimeRangeByMinDuration={availableTimeRangeByMinDuration}
            availableTimeRangeByStartTime={availableTimeRangeByStartTime}
            durations={durations}
            timeInterval={timeInterval}
            isTimeIntervalFromTimeRanges={isTimeIntervalFromTimeRanges}
          />
        </ActiveBlockContainer>
        {filteredTimeRanges?.map(resourceTimeRange => (
          <ScheduleSelectBlock
            defaultOffsetTop={defaultOffsetTop}
            durations={durations}
            top={top}
            addRef={handleAddRef}
            key={resourceTimeRange.time}
            time={resourceTimeRange.time}
            available={resourceTimeRange.available}
            formatTime={formatTime}
            listRefs={refs}
            setIsScrollToSelectedValue={setIsScrollToSelectedValue}
          />
        ))}
      </ScheduleContainer>
    </Container>
  );
};
