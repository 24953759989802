import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  selectAppInstanceConfigs,
  selectCurrentAppInstanceConfigUuid,
  selectCurrentLocationId,
} from 'store/app-instance-configs/selectors';
import { AppInstanceConfig } from 'store/app-instance-configs/types';

export const useCurrentConfig = (): AppInstanceConfig => {
  const appInstanceConfigs = useSelector(selectAppInstanceConfigs);
  const currentAppInstanceConfigUuid = useSelector(selectCurrentAppInstanceConfigUuid);
  const locationId = useSelector(selectCurrentLocationId);

  return useMemo<AppInstanceConfig>(() => {
    if (locationId) {
      return appInstanceConfigs?.find(config => config.location_id === locationId);
    }

    return appInstanceConfigs?.find(config => config.uuid === currentAppInstanceConfigUuid && config.location_default);
  }, [appInstanceConfigs, currentAppInstanceConfigUuid, locationId]);
};
