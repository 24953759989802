import React from 'react';
import { Chip } from '@hqo/react-components-library/dist/atoms/chip';
import { getResourceTypeString } from 'utils/formatResourceType';
import { ResourceType } from 'store/resources/types';
import { useIntl } from 'react-intl';

interface TypesComponentProps {
  resourceTypes: ResourceType[];
  selectedTypes: string[];
  onTypeChipClickHandler: (value: string) => void;
  isCurrentConfigNexudus?: boolean;
}

export const TypesComponent = ({
  resourceTypes,
  selectedTypes,
  onTypeChipClickHandler,
  isCurrentConfigNexudus,
}: TypesComponentProps): JSX.Element => {
  const intl = useIntl();

  return (
    <>
      {resourceTypes?.map(type => (
        <Chip
          key={type}
          label={getResourceTypeString(isCurrentConfigNexudus, type, intl)}
          value={type}
          isActive={!!selectedTypes.find(filterType => filterType === type)}
          onChipClick={onTypeChipClickHandler}
          className="type-chip"
          data-test={`type-filter-chip-${type}`}
        />
      ))}
    </>
  );
};
