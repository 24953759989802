import { ACTION_STATUSES, IMG_URL } from 'shared/consts';
import {
  DesktopButtonContainer,
  CheckoutWrapper,
  DesktopContentContainer,
  DesktopModalContainer,
  LeftPaneContainer,
  RightPaneContainer,
  StyledImage,
  DesktopTitleContainer,
  TextContainer,
} from './styles';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { TitleBlock } from '@hqo/react-components-library/dist/molecules/titleBlock';
import { TwoColumnContent } from '@hqo/react-components-library/dist/molecules/twoColumnContent';

import { CancellationPolicy } from './cancellation-policy';
import { CheckoutButton } from 'components/checkout-button';
import { Container } from 'hocs/shared-styles';
import { PaymentMethods } from 'components/payment-methods';
import { Order } from 'store/cart/types';
import React from 'react';
import { SavedPaymentMethod } from 'store/payment/types';
import { formatSummaryValues } from 'utils/formatSummaryValues';
import { useIntl } from 'react-intl';
import { CheckoutPaymentDetails } from './checkout-payment-details';

interface CheckoutDesktopProps {
  cart: Order;
  paymentMethods: Array<SavedPaymentMethod>;
  currentPaymentMethodId: string;
  submittedPaymentMethodId?: number;
  currentPaymentError?: boolean;
  onRemovePaymentMethod: VoidFunction;
  onAddPaymentMethodClick: VoidFunction;
  onPayClick: VoidFunction;
  submitCartStatus: ACTION_STATUSES;
  showAddCardForm: boolean;
  cartId: string;
  onCancelAddCardClick: VoidFunction;
  isAddCardButtonDisabled: boolean;
  isNativePaymentError: boolean;
  setIsAddCardButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CheckoutDesktop = ({
  cart,
  onAddPaymentMethodClick,
  paymentMethods,
  currentPaymentMethodId,
  submittedPaymentMethodId,
  currentPaymentError,
  onRemovePaymentMethod,
  onPayClick,
  submitCartStatus,
  showAddCardForm,
  cartId,
  onCancelAddCardClick,
  isAddCardButtonDisabled,
  setIsAddCardButtonDisabled,
  isNativePaymentError,
}: CheckoutDesktopProps) => {
  const intl = useIntl();
  const { total } = formatSummaryValues(cart?.total_summary);

  return (
    <DesktopModalContainer>
      {!cart && !paymentMethods ? (
        <Container isLoading>
          <Spinner color={defaultTheme.colors.$greyLight} size="10em" />
        </Container>
      ) : (
        <CheckoutWrapper>
          <TwoColumnContent
            isCheckoutDesktop
            isButtonVisible={false}
            LeftPaneContent={
              <LeftPaneContainer>
                <DesktopTitleContainer>
                  <TitleBlock title={intl.formatMessage({ id: 'checkout' })} />
                </DesktopTitleContainer>
                <DesktopContentContainer>
                  <PaymentMethods
                    enabledPaymentMethods={cart.ui_enablements}
                    paymentMethods={paymentMethods}
                    submittedPaymentMethodId={submittedPaymentMethodId}
                    currentPaymentError={currentPaymentError}
                    selectedPaymentMethodId={currentPaymentMethodId}
                    onRemovePaymentMethod={onRemovePaymentMethod}
                    onAddNewCard={onAddPaymentMethodClick}
                    cartId={cartId}
                    total={total}
                    showAddCardForm={showAddCardForm}
                    onCancelAddCardClick={onCancelAddCardClick}
                    cart={cart}
                    formTitle={intl.formatMessage({ id: 'payment' })}
                    isAddCardButtonDisabled={isAddCardButtonDisabled}
                    setIsAddCardButtonDisabled={setIsAddCardButtonDisabled}
                    isNativePaymentError={isNativePaymentError}
                  />
                  {cart.items[0].display_info?.cancellation_description && (
                    <TextContainer showAddCardForm={showAddCardForm}>
                      <CancellationPolicy cart={cart} />
                    </TextContainer>
                  )}
                </DesktopContentContainer>
                <DesktopButtonContainer>
                  <CheckoutButton
                    text={
                      cart.total_summary?.total
                        ? intl.formatMessage({ id: 'confirm_and_pay' })
                        : intl.formatMessage({ id: 'confirm_and_book' })
                    }
                    isDisabled={
                      !currentPaymentMethodId || submitCartStatus === ACTION_STATUSES.PENDING || showAddCardForm
                    }
                    isLoading={submitCartStatus === ACTION_STATUSES.PENDING}
                    onClick={onPayClick}
                  />
                </DesktopButtonContainer>
              </LeftPaneContainer>
            }
            RightPaneContent={
              <RightPaneContainer>
                <StyledImage src={IMG_URL + cart.vendor?.logo_url} alt="service_logo" />
                <CheckoutPaymentDetails cart={cart} />
              </RightPaneContainer>
            }
          />
        </CheckoutWrapper>
      )}
    </DesktopModalContainer>
  );
};
