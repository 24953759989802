import { CreateBookingDto } from '../../shared/types';

export interface GetBookingsAPIResponse {
  data: GetBookingsResponse;
}

export interface GetBookingsResponse {
  bookings: Booking[];
  startDate?: string;
  endDate?: string;
}

export interface CreateBookingAPIResponse {
  data: CreateBookingResponse;
}

interface CreateBookingResponse {
  booking: Booking;
}

export enum BookingStatus {
  PENDING = 'Pending',
  ACCEPTED = 'Accepted',
  DENIED_UNAVAILABLE = 'Denied_Unavailable',
  DENIED_AVAILABLE = 'Denied_Available',
  CANCELED = 'Canceled',
}

export enum MAP_STATUSES {
  'Pending' = 'pending',
  'Accepted' = 'accepted',
  'Denied_Unavailable' = 'denied_unavailable',
  'Denied_Available' = 'denied_available',
  'Canceled' = 'canceled',
  'Pending_Payment' = 'pending_payment',
}

export interface Booking {
  name: string;
  id: number | string;
  uuid: string;
  user: {
    first_name: string;
    last_name: string;
    email: string;
    company: {
      name: string;
    };
    resource: string;
  };
  resource: {
    name: string;
  };
  location_name: string;
  email: string;
  userName: string;
  userEmail: string;
  userCompanyName: string;
  resourceName: string;
  start_at: string;
  end_at: string;
  created_at: string;
  reservedDate: string;
  reservedTimeRange: string;
  status: BookingStatus;
  contacted: boolean;
  canceled_at?: string;
  buffer_before?: number;
  buffer_after?: number;
  form_responses?: Array<FormResponse>;
}

export interface BookingsState {
  bookings: Booking[];
  startDate: string;
  endDate: string;
  createdBooking?: Booking;
  loading: boolean;
  processed: boolean;
  cancelProcessed: boolean;
  refreshing: boolean;
  error?: SerializableError;
  createBookingRequest: CreateBookingDto;
}

export enum BookingNotCancelableReasons {
  CANCELATION_PERIOD_CLOSED = 'cancellation_period_closed',
  BOOKING_IN_THE_PAST = 'booking_in_the_past',
}
