import * as paymentActions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { ACTION_STATUSES } from 'shared/consts';
import { PaymentState } from './types';

export const initialState: PaymentState = {
  paymentMethods: null,
  currentPaymentMethodId: null,
  getPaymentsMethods: {
    status: null,
    error: null,
  },
  deletePaymentMethod: {
    status: null,
    error: null,
  },
  savePaymentMethod: {
    status: null,
    error: null,
  },
  updatePaymentMethod: {
    status: null,
    error: null,
  },
};

const getPaymentMethodsRequestHandler = (state: PaymentState): PaymentState => ({
  ...state,
  getPaymentsMethods: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getPaymentMethodsSuccessHandler = (
  state: PaymentState,
  { payload }: ActionType<typeof paymentActions.getPaymentMethods.success>,
): PaymentState => ({
  ...state,
  paymentMethods: payload,
  getPaymentsMethods: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getPaymentMethodsFailureHandler = (
  state: PaymentState,
  { payload: error }: ActionType<typeof paymentActions.getPaymentMethods.failure>,
): PaymentState => ({
  ...state,
  getPaymentsMethods: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const deletePaymentMethodRequestHandler = (state: PaymentState): PaymentState => ({
  ...state,
  deletePaymentMethod: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const deletePaymentMethodSuccessHandler = (state: PaymentState): PaymentState => ({
  ...state,
  deletePaymentMethod: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const deletePaymentMethodFailureHandler = (
  state: PaymentState,
  { payload: error }: ActionType<typeof paymentActions.deletePaymentMethod.failure>,
): PaymentState => ({
  ...state,
  deletePaymentMethod: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const savePaymentMethodRequestHandler = (state: PaymentState): PaymentState => ({
  ...state,
  savePaymentMethod: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const savePaymentMethodSuccessHandler = (
  state: PaymentState,
  { payload }: ActionType<typeof paymentActions.savePaymentMethod.success>,
): PaymentState => ({
  ...state,
  currentPaymentMethodId: payload?.id.toString(),
  savePaymentMethod: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const savePaymentMethodFailureHandler = (
  state: PaymentState,
  { payload: error }: ActionType<typeof paymentActions.savePaymentMethod.failure>,
): PaymentState => ({
  ...state,
  savePaymentMethod: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const selectPaymentMethodHandler = (
  state: PaymentState,
  { payload }: ActionType<typeof paymentActions.setCurrentPaymentMethodId>,
): PaymentState => ({
  ...state,
  currentPaymentMethodId: payload,
});

const updatePaymentMethodRequestHandler = (state: PaymentState): PaymentState => ({
  ...state,
  updatePaymentMethod: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const updatePaymentMethodSuccessHandler = (state: PaymentState): PaymentState => ({
  ...state,
  updatePaymentMethod: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const updatePaymentMethodFailureHandler = (
  state: PaymentState,
  { payload: error }: ActionType<typeof paymentActions.updatePaymentMethod.failure>,
): PaymentState => ({
  ...state,
  updatePaymentMethod: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const localUpdatePaymentMethodHandler = (
  state: PaymentState,
  { payload: id }: ActionType<typeof paymentActions.localUpdatePaymentMethod>,
): PaymentState => ({
  ...state,
  paymentMethods: state.paymentMethods.map(method =>
    method.id === id ? { ...method, default: true } : { ...method, default: false },
  ),
});

const resetSavePaymentMethodStatusHandler = (state: PaymentState): PaymentState => ({
  ...state,
  savePaymentMethod: {
    ...initialState.savePaymentMethod,
  },
});

const paymentReducer = createReducer(initialState)
  .handleAction(paymentActions.getPaymentMethods.request, getPaymentMethodsRequestHandler)
  .handleAction(paymentActions.getPaymentMethods.success, getPaymentMethodsSuccessHandler)
  .handleAction(paymentActions.getPaymentMethods.failure, getPaymentMethodsFailureHandler)
  .handleAction(paymentActions.deletePaymentMethod.request, deletePaymentMethodRequestHandler)
  .handleAction(paymentActions.deletePaymentMethod.success, deletePaymentMethodSuccessHandler)
  .handleAction(paymentActions.deletePaymentMethod.failure, deletePaymentMethodFailureHandler)
  .handleAction(paymentActions.savePaymentMethod.request, savePaymentMethodRequestHandler)
  .handleAction(paymentActions.savePaymentMethod.success, savePaymentMethodSuccessHandler)
  .handleAction(paymentActions.savePaymentMethod.failure, savePaymentMethodFailureHandler)
  .handleAction(paymentActions.setCurrentPaymentMethodId, selectPaymentMethodHandler)
  .handleAction(paymentActions.updatePaymentMethod.request, updatePaymentMethodRequestHandler)
  .handleAction(paymentActions.updatePaymentMethod.success, updatePaymentMethodSuccessHandler)
  .handleAction(paymentActions.updatePaymentMethod.failure, updatePaymentMethodFailureHandler)
  .handleAction(paymentActions.localUpdatePaymentMethod, localUpdatePaymentMethodHandler)
  .handleAction(paymentActions.resetSavePaymentMethodStatus, resetSavePaymentMethodStatusHandler);

export default paymentReducer;
