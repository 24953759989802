import useCurrentAppInstanceConfigs from 'store/app-instance-configs/hooks/use-current-app-instance-configs.hook';
import { NativeResourceBookingConfig } from 'store/app-instance-configs/types';

export default function useCrossBuildingBookingEnabled() {
  const currentAppInstanceConfigs = useCurrentAppInstanceConfigs();

  return currentAppInstanceConfigs.some(
    config => (config.config as NativeResourceBookingConfig)?.portfolio_resources_enabled,
  );
}
