import { initializeWebTracking } from '@hqo/web-tracking';
import { setUser as setSentryUser } from '@sentry/react';
import { useEffect } from 'react';
import { useWebTrackingAnalytics } from './use-web-tracking.hook';
import { useSentryUserAnalytics } from 'hooks/use-sentry-user-analytics.hook';

export const useAnalytics = (): void => {
  const sentryUser = useSentryUserAnalytics();
  const webTrackingData = useWebTrackingAnalytics();

  useEffect(() => {
    if (sentryUser) {
      setSentryUser(sentryUser);
    }
  }, [sentryUser]);

  useEffect(() => {
    if (
      webTrackingData &&
      webTrackingData?.currentUser &&
      webTrackingData?.buildingUuid &&
      process.env.REACT_APP_DISABLE_TRACKING !== 'true'
    ) {
      initializeWebTracking(
        webTrackingData.authToken,
        webTrackingData.buildingUuid,
        webTrackingData.appUuid,
        navigator?.userAgent,
        webTrackingData.clientApiUrl,
      );
    }
  }, [webTrackingData]);
};
