import {
  NothingAvailableNowContainer,
  Placeholder,
  Title,
  Wrapper,
  CalendarIcon,
} from 'components/available-now-block/styles';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons/faCalendar';

import { push } from 'store/router/actions';
import { useLocale } from 'hooks/use-locale.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import qs from 'qs';
import React, { useCallback } from 'react';

import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { getFormattedDate } from 'utils/formatDate';
import { resetResourcesState } from 'store/resources/actions';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

type searchParams = { [key: string]: string };

export const EmptyAvailableBlock = (): JSX.Element => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const locale = useLocale();
  const { ...queryParams } = useSearchParams<searchParams>();

  const date = getFormattedDate(new Date(), 'YYYY-MM-DD', locale);

  const onSearchClick = useCallback(() => {
    const queryString = qs.stringify({ ...queryParams, startDate: date });
    dispatch(resetResourcesState());
    dispatch(push(`resource-booking/resources?${queryString}`));
  }, [date, dispatch, queryParams]);

  return (
    <Wrapper data-testid="nothing-available-now-wrapper">
      <Title data-testid="nothing-available-now-title">{intl.formatMessage({ id: 'available_now' })}</Title>
      <NothingAvailableNowContainer>
        <CalendarIcon icon={faCalendar} data-testid="nothing-available-now-icon" size="2x" />
        <Placeholder data-testid="nothing-available-now-placeholder">
          {intl.formatMessage({ id: 'nothing_available_now' })}
        </Placeholder>
        <Button
          size="xxs"
          data-testid="nothing-available-now-button"
          onClick={onSearchClick}
          text={intl.formatMessage({ id: 'search_todays_availability' })}
          variant="secondary"
          multiline={false}
        />
      </NothingAvailableNowContainer>
    </Wrapper>
  );
};
