import styled from 'styled-components';

interface ContainerProps {
  active: boolean;
  lastItem: boolean;
}

export const Container = styled.div<ContainerProps>`
  padding: 8px 16px;
  margin-bottom: ${({ lastItem }) => (lastItem ? '0' : '8px')};
  cursor: pointer;
  background: ${({ active, theme }) => (active ? theme.colors.greys.adminGreyMedium : theme.colors.universal.white)};
  color: ${({ theme }) => theme.colors.fontColor};
  font-family: ${({ theme }) => theme.fonts.join()};
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  &:hover {
    background: ${({ active, theme }) => {
      const { adminGreyMedium, adminGreyLight } = theme.colors.greys;
      return active ? adminGreyMedium : adminGreyLight;
    }};
  }
`;

export const Text = styled.span`
  margin: 0;
`;
