import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { goBack, push } from 'store/router/actions';

interface UseModalNavigationReturnValues {
  handleCloseModal: VoidFunction;
  handleOpenModal: VoidFunction;
}

export const useModalNavigation = (): UseModalNavigationReturnValues => {
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();

  const handleCloseModal = useCallback(() => {
    dispatch(goBack());
  }, [dispatch]);

  const handleOpenModal = useCallback(() => {
    dispatch(push(`${pathname}/terms-and-conditions${search}`));
  }, [dispatch, pathname, search]);

  return { handleCloseModal, handleOpenModal };
};
