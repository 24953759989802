import { toggleStringElement } from 'utils/toggleStringElement';

export const getCalendarViewDateParams = (
  selectedDate: string,
  startDatesList: Array<string>,
  startDateParam?: string,
) => {
  if (startDatesList.length) {
    const selectedDates = toggleStringElement(startDatesList, selectedDate);

    return selectedDates.length === 1 ? { startDate: selectedDates[0] } : { startDates: selectedDates.join(',') };
  }
  if (startDateParam && selectedDate !== startDateParam) {
    return { startDates: `${startDateParam},${selectedDate}` };
  }

  return { startDate: selectedDate };
};
