import styled from 'styled-components';

export const StyledDot = styled.div`
  width: 3px;
  height: 3px;
  align-self: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};
`;

export const Text = styled.div`
  font: 400 14px/16px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const DurationContainer = styled.div<{ isSwitchBlockVisible: boolean }>`
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
  width: ${({ isSwitchBlockVisible }) => (isSwitchBlockVisible ? '60%' : '100%')};
`;

export const SwitchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
`;

export const SwitchLabel = styled(Text)`
  margin-right: 10px;
`;
