import addOnsReducer from 'store/add-ons/reducer';
import appInstanceConfigsReducer from './app-instance-configs/reducer';
import bookingsReducer from 'store/bookings/reducer';
import buildingReducer from 'store/building/reducer';
import cartReducer from 'store/cart/reducer';
import configReducer from 'store/config/reducer';
import errorsReducer from 'store/errors/reducer';
import featureFlagsReducer from 'store/featureFlags/reducer';
import floorReducer from 'store/spaceManager/reducer';
import floorplanAppInstanceConfigReducer from './floorplan-app-instance-config/reducer';
import floorplanResourcesReducer from './floorplan-resources/reducer';
import headerReducer from 'store/header-state/reducer';
import paymentReducer from 'store/payment/reducer';
import priceReducer from './price/reducer';
import resourceAvailableDatesReducer from './resource-available-dates/reducer';
import resourceReducer from 'store/resource/reducer';
import resourceTimeRangesReducer from './resource-time-ranges/reducer';
import resourcesReducer from 'store/resources/reducer';
import roomLayoutReducer from 'store/room-layout/reducer';
import spreedlyReducer from 'store/add-card/reducer';
import themeReducer from 'store/theme/reducer';
import transactionsReducer from 'store/transactions/reducer';
import uiMetadataReducer from 'store/ui-metadata/reducer';
import unitCodesReducer from 'store/unit-codes/reducers';
import userReducer from 'store/user/reducer';
import visitorsEmailsReducer from 'store/visitors-emails/reducer';
import { combineReducers } from '@reduxjs/toolkit';
import helixApi from 'api/helix/resourceBooking';
import kioskReducer from 'store/kiosk/reducer';
import bookingFlowReducer from 'store/booking-flow/reducer';
import notificationsReducer from 'store/notifications/reducer';
import routesReducer from 'store/routes/reducer';

const rootReducer = combineReducers({
  [helixApi.reducerPath]: helixApi.reducer,

  config: configReducer,
  user: userReducer,
  resource: resourceReducer,
  resources: resourcesReducer,
  transactions: transactionsReducer,
  bookings: bookingsReducer,
  theme: themeReducer,
  cart: cartReducer,
  errors: errorsReducer,
  building: buildingReducer,
  price: priceReducer,
  featureFlags: featureFlagsReducer,
  appInstanceConfigs: appInstanceConfigsReducer,
  floorplanAppInstanceConfig: floorplanAppInstanceConfigReducer,
  floorplanResources: floorplanResourcesReducer,
  resourceAvailableDates: resourceAvailableDatesReducer,
  resourceTimeRanges: resourceTimeRangesReducer,
  floor: floorReducer,
  uiMetaData: uiMetadataReducer,
  spreedly: spreedlyReducer,
  payment: paymentReducer,
  roomLayout: roomLayoutReducer,
  addOns: addOnsReducer,
  headerState: headerReducer,
  unitCodes: unitCodesReducer,
  visitorsEmails: visitorsEmailsReducer,
  kiosk: kioskReducer,
  bookingFlow: bookingFlowReducer,
  notifications: notificationsReducer,
  routes: routesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
