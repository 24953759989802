const ONE_DATE_LENGTH = 10;

type ResolvedDatesParam = { startDates: string } | { startDate: string };

export const resolveStartDatesParam = (
  selectedDate: string,
  startDate: string | undefined,
  startDates: string | undefined,
): ResolvedDatesParam => {
  let result: ResolvedDatesParam;

  if (startDate) {
    if (startDate === selectedDate) {
      result = null;
    } else {
      result = { startDates: `${startDate},${selectedDate}` };
    }
  } else if (startDates) {
    const dateRegex = new RegExp(`(^|,)${selectedDate}(,|$)`, 'g');
    const updatedDatesselectedDate = startDates.includes(selectedDate)
      ? startDates.replace(dateRegex, (_match, firstCapturedContent, secondCapturedContent) =>
          firstCapturedContent && secondCapturedContent ? ',' : '',
        )
      : `${startDates},${selectedDate}`;

    result =
      updatedDatesselectedDate.length === ONE_DATE_LENGTH
        ? { startDate: updatedDatesselectedDate }
        : { startDates: updatedDatesselectedDate };
  } else {
    result = { startDate: selectedDate };
  }

  return result;
};
