import React, { useCallback } from 'react';
import { SelectValue } from 'components/floor-select/desktop/styles';
import { TitleComponent } from 'components/floor-select/mobile/styles';
import { BuildingGroupItem, FeatureValue } from 'store/spaceManager/types';
import { Select } from '@hqo/react-components-library/dist/select';
import { SelectItem } from '@hqo/react-components-library/dist/select-item';
import { useIntl } from 'react-intl';

interface FloorSelectModalContentProps {
  pickedBuildingGroup: FeatureValue | null;
  buildingGroupOptions: BuildingGroupItem[];
  onWheelChangeBuildingGroup: React.Dispatch<React.SetStateAction<string>>;
}

export const BuildingGroupSelectModalDesktopContent = ({
  pickedBuildingGroup,
  buildingGroupOptions,
  onWheelChangeBuildingGroup,
}: FloorSelectModalContentProps): JSX.Element => {
  const intl = useIntl();
  const handleRenderingValue = useCallback(() => {
    const selectedValue = pickedBuildingGroup
      ? pickedBuildingGroup.value
      : intl.formatMessage({ id: 'noBuildingGroupSelected' });
    return <SelectValue>{selectedValue}</SelectValue>;
  }, [pickedBuildingGroup]);
  const handleBuildingGroupSelect = useCallback(
    (buildingGroupValue: any) => {
      onWheelChangeBuildingGroup(buildingGroupValue.target.value);
    },
    [onWheelChangeBuildingGroup],
  );

  return (
    <Select
      data-testid="building-group"
      onChange={handleBuildingGroupSelect}
      value={pickedBuildingGroup ? pickedBuildingGroup.value : ''}
      renderValue={handleRenderingValue}
      titleComponent={<TitleComponent>{intl.formatMessage({ id: 'building' })}</TitleComponent>}
    >
      {buildingGroupOptions.map(option => {
        return <SelectItem key={option.value} value={option.label} renderedValue={option.label} />;
      })}
    </Select>
  );
};
