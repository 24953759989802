import { CHECKOUT_BREAK_POINT } from 'shared/consts';
import styled, { css } from 'styled-components';

const skeletonAnimation = () => css`
  background: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground};
  background: ${({ theme }) =>
    `linear-gradient(110deg, ${theme?.colors?.greys?.adminGreyMedium} 8%, ${theme?.colors?.greys?.adminGreyLightAlt} 18%, ${theme?.colors?.greys?.adminGreyMedium} 33%)`};
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
`;

interface AvailableNowContainerProps {
  isColumnDirection?: boolean;
  gap: number;
  isWidth?: boolean;
  isWrap?: boolean;
  flex?: number;
}

export const AvailableNowContainer = styled.div<AvailableNowContainerProps>`
  display: flex;
  ${({ isWidth }) => isWidth && 'width: 100%'};
  flex-direction: ${({ isColumnDirection }) => (isColumnDirection ? 'column' : 'row')};
  gap: ${({ gap }) => gap}px;
  ${({ isWrap }) => isWrap && 'flex-wrap: wrap'};
  ${({ flex }) => flex && `flex: ${flex}`};
`;

export const AvailableNowSectionTitle = styled.div`
  font: 700 16px/19px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  padding-top: 2px;
  margin-bottom: 24px;
`;

export const AvailableNowItemWrapper = styled.div<{
  isCompactUiEnabled?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 132px;
  margin-bottom: 56px;
  gap: 10px;

  @media (min-width: ${CHECKOUT_BREAK_POINT}px) {
    width: 240px;
    ${({ isCompactUiEnabled }) => isCompactUiEnabled && 'height: 127px;'}
  }
`;

export const AvailableNowTile = styled.div`
  width: 152;
  height: 97px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground ?? '#EDEDED'};
  ${skeletonAnimation};

  @media (min-width: ${CHECKOUT_BREAK_POINT}px) {
    width: 240px;
    height: 97px;
  }
`;

export const AvailableNowTitle = styled.div`
  width: 80%;
  height: 15px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground ?? '#EDEDED'};
  ${skeletonAnimation};
`;

export const AvailableNowSubtitle = styled.div`
  width: 60%;
  height: 15px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground ?? '#EDEDED'};
  ${skeletonAnimation};
`;
