import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 370px;
  display: flex;
  gap: 20px;
`;

export const StyledLabel = styled.div`
  font-family: ${({ theme }) => theme.fonts.join()};
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
`;

export const DropdownContainer = styled.div``;
