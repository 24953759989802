import React from 'react';
import { GhostContainer, GhostTimesBlock, GhostScheduleTime, GhostScheduleBlock } from './styles';

export const GhostScheduleSelect = (): JSX.Element => (
  <GhostContainer className="ghost-container" data-testid="ghost-container">
    <GhostTimesBlock className="ghost-times-block" data-testid="ghost-times-block">
      <GhostScheduleTime />
      <GhostScheduleTime />
      <GhostScheduleTime />
      <GhostScheduleTime />
      <GhostScheduleTime />
      <GhostScheduleTime />
      <GhostScheduleTime />
      <GhostScheduleTime />
    </GhostTimesBlock>
    <GhostScheduleBlock className="ghost-schedule-block" data-testid="ghost-schedule-block" />
  </GhostContainer>
);
