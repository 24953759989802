import React, { useCallback } from 'react';
import { VisitorBlockContainer, Title, StyledFormField } from './styles';
import { useIntl } from 'react-intl';
import { FormFieldType } from '@hqo/react-components-library/dist/atoms/form-field';
import { isEmailValid } from 'utils/isEmailValid';

interface VisitorBlockProps {
  value: string;
  onChangeVisitors: (key: number, value: string) => void;
  visitorNumber: number;
}

export const VisitorBlock: React.FC<VisitorBlockProps> = ({
  value,
  onChangeVisitors,
  visitorNumber,
}: VisitorBlockProps): JSX.Element => {
  const intl = useIntl();
  const isErrorTextVisible = Boolean(value) && !isEmailValid(value);

  const handleChangeVisitors = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeVisitors(visitorNumber, event.target.value);
    },
    [visitorNumber, onChangeVisitors],
  );

  return (
    <VisitorBlockContainer data-testid={`visitor-block-${visitorNumber}`}>
      <Title>{intl.formatMessage({ id: 'guest' }, { guestNumber: visitorNumber })}</Title>
      <StyledFormField
        data-testid="guest-email-field"
        errorText={isErrorTextVisible && intl.formatMessage({ id: 'errorEmail' })}
        fieldType={FormFieldType.INPUT}
        label={intl.formatMessage({ id: 'email' })}
        onChange={handleChangeVisitors}
        placeholder={intl.formatMessage({ id: 'enterGuestEmail' })}
        value={value}
        variant="master"
      />
    </VisitorBlockContainer>
  );
};
