import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SMALL_DEVICE_MAX_WIDTH } from 'shared/consts';

export const LocationLabel = styled('span')`
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  font-family: ${({ theme }) => theme.fonts.join()};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
`;

export const Container = styled('div')<{ compactUi?: boolean }>`
  max-height: 28px;
  display: flex;
  gap: 8px;
  max-width: 152px;
  width: 152px;
  min-width: 152px;

  @media (min-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    max-width: 240px;
    width: 240px;
    min-width: 240px;
  }

  padding-right: ${({ compactUi }) => (compactUi ? '24px' : '0')};
`;

export const LocationIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.fontColor};
  margin-right: 8px;
  font-size: 18px;
  line-height: 28px;
  vertical-align: sub;
`;
