import React from 'react';
import { DateTimeSelectModalFooterProps } from '../interface';
import { DateSelectPageFooter } from 'pages/resource/date-select-modal/components/date-select-footer';
import { ScheduleSelectPageFooter } from 'pages/schedule-select-page/components/schedule-select-footer';

export const DateTimeSelectModalFooter = ({
  isDateStep,
  onNextClick,
  onDateLinkClick,
  onTimeLinkClick,
  minDurationText,
  onPressSave,
  onDateTimeSaveClick,
}: DateTimeSelectModalFooterProps): JSX.Element => {
  return isDateStep ? (
    <DateSelectPageFooter
      onNextClick={onNextClick}
      onDateLinkClick={onDateLinkClick}
      onTimeLinkClick={onTimeLinkClick}
      onSaveClick={onDateTimeSaveClick}
    />
  ) : (
    <ScheduleSelectPageFooter
      onDateLinkClick={onDateLinkClick}
      onTimeLinkClick={onTimeLinkClick}
      minDurationText={minDurationText}
      onPressSave={onPressSave}
    />
  );
};
