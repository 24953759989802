import { LANDING_PATH, TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts/';
import { goBack, push, replace } from 'store/router/actions';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { track } from '@hqo/web-tracking';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';

interface UseFilterBarReturnValues {
  reverseAnimation: boolean;
  onOpenModal: VoidFunction;
  onCloseModal: VoidFunction;
  onCTAClick: VoidFunction;
  pathname: string;
}

export const useFilterModalNavigation = (): UseFilterBarReturnValues => {
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const { value: reverseAnimation, toggle: toggleReverseAnimation } = useBooleanState(true);
  const { ...queryParams } = useSearchParams();
  const { filterByType, startDate, startDates, startTime, endTime, duration, filterByFloor } = queryParams;
  const { isMapViewEnabled } = useAppInstanceConfigFeature();
  const multiDay = !!startDates;

  const isResourcesContentWithMapView = useMemo(
    () => isMapViewEnabled && pathname === LANDING_PATH,
    [isMapViewEnabled, pathname],
  );

  const pathnameForReplaceAction = useMemo(
    () => (isMapViewEnabled && pathname === '/filter-by' ? 'filter-by' : '/filter-by'),
    [isMapViewEnabled, pathname],
  );

  const filterEntries = Object.entries({
    filterByType,
    startDate,
    multiDay,
    startTime,
    endTime,
    duration,
    filterByFloor,
  });
  const filtersForTracking = filterEntries.map(entry => (entry[1] ? entry[0] : null)).filter(value => value !== null);

  const onOpenModal = useCallback(() => {
    toggleReverseAnimation();

    if (isResourcesContentWithMapView) {
      dispatch(push(`filter-by${search}`));
    } else {
      dispatch(push(`${pathname}/filter-by${search}`));
    }
  }, [toggleReverseAnimation, isResourcesContentWithMapView, dispatch, search, pathname]);

  const onCTAClick = useCallback(() => {
    track(
      TRACK_EVENT_NAMES.FILTER_SHOW_RESULTS_CLICK,
      {
        type: TRACK_EVENT_TYPES.ACTION,
        filters_applied: filtersForTracking,
      },
      { sendToHqoTracking: true },
    );
    toggleReverseAnimation();
    dispatch(replace(`${pathname.replace(pathnameForReplaceAction, '')}${search}`));
  }, [filtersForTracking, toggleReverseAnimation, dispatch, pathname, pathnameForReplaceAction, search]);

  const onCloseModal = useCallback(() => {
    toggleReverseAnimation();
    dispatch(goBack());
  }, [dispatch, toggleReverseAnimation]);

  return {
    reverseAnimation,
    onOpenModal,
    onCloseModal,
    onCTAClick,
    pathname,
  };
};
