import { CalendarView } from '@hqo/react-components-library/dist/molecules/calendar-view';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';

import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { ButtonProps } from '../interface';
import styled from 'styled-components';
import { skeletonAnimation } from 'components/skeleton/styles';

export const CalendarViewContainer = styled.div``;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    display: flex;
    justify-content: flex-end;
  }
`;

export const StyledButton = styled(Button)<ButtonProps>`
  height: 36px;
  width: 110px;
  border-radius: 4px;
  font-size: 16px;
  padding: 16px;
  color: ${({ isSkipButton, theme }) => isSkipButton && theme.colors.admin.greenAlt2};
  text-decoration: ${({ isSkipButton }) => isSkipButton && 'underline'};
  align-self: flex-end;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    height: 48px;
    width: 100%;
  }
`;

export const StyledCalendarView = styled(CalendarView)`
  margin-bottom: 30px;
  width: 100%;
  .calendar-header {
    padding-left: 5px;
  }

  .title {
    color: ${({ theme }) => theme.colors.greys.darkGrey7};
    font: 500 24px/22px ${({ theme }) => theme.fonts.join()};
    align-self: flex-start;
  }

  .day-cell-unavailable {
    color: ${({ theme }) => theme.colors.greys.sharedMediumGrey};
    text-decoration-line: none;
    cursor: not-allowed;
  }

  .day-cell,
  .days-view {
    border: 0 none;
  }

  .day-cell {
    border-radius: 50%;
    font: 400 20px/22px ${({ theme }) => theme.fonts.join()};
  }

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    .title {
      color: ${({ theme }) => theme.colors.greys.darkGrey7};
      font: 500 16px/22px ${({ theme }) => theme.fonts.join()};
      align-self: flex-start;
    }

    .day-cell {
      font: 400 14px/22px ${({ theme }) => theme.fonts.join()};
    }
  }
`;

export const DayLabel = styled.label`
  width: 48px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
  font: 500 20px/22px ${({ theme }) => theme.fonts.join()};

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    font: 500 14px/22px ${({ theme }) => theme.fonts.join()};
  }
`;

export const DaysLayout = styled.section`
  display: inline-grid;
  grid-template-columns: repeat(${({ theme }) => theme.components.calendar.daysInRow}, 1fr);
  width: 100%;
`;

export const GhostCalendarViewContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const GhostCalendarViewTitle = styled.div`
  width: 120px;
  height: 25px;
  border-radius: 5px;
  margin-bottom: 10px;
  align-self: flex-start;
  background-color: ${({ theme }) => theme.colors.greys.adminGreyLight};
  ${skeletonAnimation};
`;

export const GhostDatesContainer = styled(DaysLayout)``;

export const GhostDate = styled.div`
  width: 35px;
  height: 35px;
  display: inline-flex;
  border-radius: 3px;
  margin: 6px;
  background-color: ${({ theme }) => theme.colors.greys.adminGreyLight};
  ${skeletonAnimation};
`;
