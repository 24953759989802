import React from 'react';
import { LabelPill } from 'components/label-pill';
import { useIntl } from 'react-intl';

const FreeLabelPill = () => {
  const intl = useIntl();
  return <LabelPill text={intl.formatMessage({ id: 'free' })} />;
};

export default FreeLabelPill;
