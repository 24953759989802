import { Button } from '@hqo/react-components-library/dist/atoms/button';
import styled from 'styled-components';

interface ButtonProps {
  isSelectedDateTimeFilterTextOnly?: boolean;
}

export const StyledButton = styled(Button)<ButtonProps>`
  color: ${({ theme }) => theme.colors.admin.greenAlt2};
  text-decoration: underline;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  min-width: 0;

  ${({ isSelectedDateTimeFilterTextOnly }) =>
    isSelectedDateTimeFilterTextOnly &&
    `
    text-decoration: none;
    :hover {
      cursor: default;
    }
    pointer-events: none;
  `};
`;
