import { BookingWindow } from 'store/resource/types';
import moment from 'moment-timezone';
import { daysOfWeek, MappedWeekDays, OpenCloseTimes } from 'utils/getOpeningHours/getOpeningHours.enum';
import { getOpeningIntervalsForDay } from 'utils/getOpeningHours/getOpeningIntervalsForDay';
import { getFilteredOpeningHours } from './getFilteredOpeningHours';

const mappedWeekDays: MappedWeekDays = {};

export const getHour = (time: string, locale: string, timezone: string, isCloseTime = false): number => {
  if (moment(time).locale(locale).tz(timezone).utc().minute() > 0 && isCloseTime) {
    return +moment(time).locale(locale).tz(timezone).utc().format('H') + 1;
  }

  return +moment(time).locale(locale).tz(timezone).utc().format('H');
};

const getFormattedTime = (time: string, locale: string, timezone: string, withLocale = true): string => {
  return time
    ? moment(time)
        .locale(locale)
        .tz(timezone)
        .utc()
        .format(locale.includes('us') && withLocale ? 'hh:mm A' : 'HH:mm')
    : 'closed';
};

export const getOpeningHours = (
  bookingWindows: Array<BookingWindow>,
  timezone: string,
  locale: string,
  withLocale = true,
): MappedWeekDays => {
  const filteredBookingWindows = bookingWindows?.filter(window => window.type !== 'Unavailable');
  daysOfWeek.forEach(day => {
    mappedWeekDays[day] = {};
    mappedWeekDays[day].rrules = [];
    filteredBookingWindows.forEach(bookingWindow => {
      const byDayOption = bookingWindow.rrule.split(';').find(option => option.includes('BYDAY'));

      if (byDayOption.includes(day)) {
        mappedWeekDays[day]?.rrules.push(bookingWindow.rrule.replace(byDayOption, `BYDAY=${day}`));
        mappedWeekDays[day].duration = bookingWindow.minimum_duration;
      }
    });
  });

  Object.keys(mappedWeekDays).forEach(weekday => {
    mappedWeekDays[weekday].calculatedSetOpeningHours = [];
    let openingHours: OpenCloseTimes[] = [];

    mappedWeekDays[weekday].rrules.forEach(rrule => {
      const openingArray = getOpeningIntervalsForDay(daysOfWeek.indexOf(weekday), rrule);

      openingHours = [
        ...openingHours,
        {
          open: openingArray[0],
          close: openingArray[1],
        },
      ];
      mappedWeekDays[weekday].calculatedSetOpeningHours = [
        ...openingArray,
        ...mappedWeekDays[weekday].calculatedSetOpeningHours,
      ];
      mappedWeekDays[weekday].calculatedSetOpeningHours.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
      openingHours.sort((a, b) => new Date(a.open).getTime() - new Date(b.open).getTime());
    });

    const openingTime = mappedWeekDays[weekday].calculatedSetOpeningHours[0];
    const closingTime =
      mappedWeekDays[weekday].calculatedSetOpeningHours[mappedWeekDays[weekday].calculatedSetOpeningHours.length - 1];
    mappedWeekDays[weekday].openingHour = openingTime && getHour(openingTime, locale, timezone);
    mappedWeekDays[weekday].closingHour = closingTime && getHour(closingTime, locale, timezone, true);
    const filteredOpeningHours = getFilteredOpeningHours(openingHours);

    mappedWeekDays[weekday].openingTime = getFormattedTime(openingTime, locale, timezone, withLocale);
    mappedWeekDays[weekday].closingTime = getFormattedTime(closingTime, locale, timezone, withLocale);
    mappedWeekDays[weekday].openingHours =
      filteredOpeningHours &&
      Object.entries(filteredOpeningHours).map(([key, value]) => ({
        open: getFormattedTime(value, locale, timezone, withLocale),
        close: getFormattedTime(key, locale, timezone, withLocale),
      }));
  });

  return mappedWeekDays;
};
