import { Background, Content, CloseButtonContainer, DesktopContainer } from './styles';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CloseButton } from 'components/modals/checkoutModalWrapper/components/close-button';

export interface DesktopModalWrapperProps {
  content: JSX.Element;
  onClose: VoidFunction;
}

export const DesktopModalWrapper = ({ content, onClose }: DesktopModalWrapperProps) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'initial';
    };
  }, []);

  const modal = (
    <>
      <Background />
      <DesktopContainer data-testid="modal-wrapper">
        <CloseButtonContainer>
          <CloseButton onClose={onClose} />
        </CloseButtonContainer>
        <Content>{content}</Content>
      </DesktopContainer>
    </>
  );

  return ReactDOM.createPortal(modal, document.body);
};
