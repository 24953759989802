export interface Duration {
  label: string;
  value: number;
}

export const durations: Array<Duration> = [
  { label: '15min', value: 15 },
  { label: '30min', value: 30 },
  { label: '1hour', value: 60 },
  { label: '2hours', value: 120 },
  { label: '3hours', value: 180 },
  { label: '4hours', value: 240 },
  { label: '5hours', value: 300 },
  { label: '8hours', value: 480 },
  { label: '12hours', value: 720 },
];
