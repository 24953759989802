import React, { useEffect, useRef } from 'react';
import { SwipeModal } from '@hqo/react-components-library/dist/molecules/modals/swipe-modal';
import { useIntl } from 'react-intl';
import { DEFAULT_MODAL_BOTTOM_MARGIN } from 'shared/consts';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { DesktopModalWrapper } from './desktop-modal-wrapper';
import { MobileModalContent } from './mobile-modal-content';
import { DesktopModalContent } from './desktop-modal-content';
import { useModalNavigation } from '../use-modal-navigation.hook';

export const ModalContainer = () => {
  const intl = useIntl();
  const isMobileDevice = useIsSmallViewportWidth();
  const { handleCloseModal } = useModalNavigation();
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && !ref.current.style.marginBottom) {
      ref.current.style.marginBottom = DEFAULT_MODAL_BOTTOM_MARGIN;
    }
  }, []);

  return isMobileDevice ? (
    <SwipeModal
      title={intl.formatMessage({ id: 'terms_and_conditions' })}
      reverseAnimation={false}
      content={<MobileModalContent />}
      onClose={handleCloseModal}
      withLeftExitButton
      scrollContainerRef={ref}
    />
  ) : (
    <DesktopModalWrapper onClose={handleCloseModal} content={<DesktopModalContent />} />
  );
};
