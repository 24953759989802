import styled, { keyframes } from 'styled-components';

interface LoadingContainerProps {
  isLoading?: boolean;
  isIframeLoading?: boolean;
  isModal?: boolean;
}

export const slide = () => keyframes`
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
`;

export const reverseSlide = () => keyframes`
  50% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(100%);
  }
`;

export const fade = (reverseAnimation: boolean) => keyframes`
  from {
    opacity: ${reverseAnimation ? 1 : 0};
  }

  to {
    opacity: ${reverseAnimation ? 0 : 1};
  }
`;

export const openSlideAnimation = (targetHeight: number) => keyframes`
  from {
    height: 0;
  }

  to {
    height: ${targetHeight}px;
  }
`;

export const closeSlideAnimation = (targetHeight: number) => keyframes`
  from {
    height: ${targetHeight}px;
  }
  to {
    height: 0;
  }
`;

export const Container = styled.div<LoadingContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ isLoading }) => isLoading && 'center'};
  height: ${({ isIframeLoading, isModal }) => (isIframeLoading || isModal ? '100%' : '100vh')};
`;
