import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPaymentMethods } from 'store/payment/selectors';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'shared/types/search-params';
import {
  DEFAULT_ADDING_VISITORS_BLOCK_HEIGHT,
  DEFAULT_DATE_TIME_BLOCK_HEIGHT,
  DEFAULT_PAYMENT_METHOD_BLOCK_HEIGHT,
  DEFAULT_QUICK_CHECKOUT_HEIGHT,
  DEFAULT_TERMS_AND_CONDITIONS_BLOCK,
  VISITOR_BLOCK_HEIGHT,
  DEFAULT_TOP,
} from './constants';
import { selectResource } from 'store/resource/selectors';

export const useQuickCheckoutHeight = (): number => {
  const { showResourceBookingResourceTermsAndConditions } = useFlags();
  const { startDates } = useSearchParams<searchParams>();
  const paymentMethods = useSelector(selectPaymentMethods);
  const { enable_capacity_booking: isCapacityBookingEnabled, allowed_visitors: allowedVisitors } =
    useSelector(selectResource) || {};

  const datesCount = useMemo<number>(() => {
    const formattedStartDates = startDates?.split(',') || [];

    return formattedStartDates.length;
  }, [startDates]);

  const heightStartDates = useMemo<number>(() => {
    return datesCount ? datesCount * DEFAULT_DATE_TIME_BLOCK_HEIGHT : 0;
  }, [datesCount]);

  const heightPaymentMethods = useMemo<number>(() => {
    return paymentMethods?.length > 0 ? paymentMethods.length * DEFAULT_PAYMENT_METHOD_BLOCK_HEIGHT : 0;
  }, [paymentMethods]);

  const additionalTermsAndConditionsHeight = useMemo<number>(() => {
    return showResourceBookingResourceTermsAndConditions ? DEFAULT_TERMS_AND_CONDITIONS_BLOCK : 0;
  }, [showResourceBookingResourceTermsAndConditions]);

  const addingVisitorsBlockHeight = useMemo<number>(() => {
    return isCapacityBookingEnabled && allowedVisitors
      ? VISITOR_BLOCK_HEIGHT * allowedVisitors + DEFAULT_ADDING_VISITORS_BLOCK_HEIGHT
      : 0;
  }, [isCapacityBookingEnabled, allowedVisitors]);

  return useMemo<number>(() => {
    const height =
      DEFAULT_QUICK_CHECKOUT_HEIGHT +
      heightStartDates +
      heightPaymentMethods +
      additionalTermsAndConditionsHeight +
      addingVisitorsBlockHeight;

    return document.documentElement.clientHeight < height
      ? document.documentElement.clientHeight - DEFAULT_TOP
      : height;
  }, [addingVisitorsBlockHeight, additionalTermsAndConditionsHeight, heightPaymentMethods, heightStartDates]);
};
