import styled from 'styled-components';

export const ItemsContainer = styled.ul`
  width: 100%;
  height: 100%;
  padding: 50% 0;
  margin: 0;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
