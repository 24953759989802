import React from 'react';
import { StyledCheckoutButton } from './styles';

interface Props {
  text: string;
  onClick: VoidFunction;
  isDisabled: boolean;
  isLoading: boolean;
  icon?: JSX.Element;
  isNativePayment?: boolean;
}

export const CheckoutButton = ({ isDisabled, onClick, text, isLoading, icon, isNativePayment }: Props): JSX.Element => {
  return (
    <StyledCheckoutButton
      data-testid="checkout-button"
      disabled={isDisabled}
      onClick={onClick}
      text={text}
      loading={isLoading}
      Icon={icon}
      isNativePayment={isNativePayment}
    />
  );
};
