import { DEFAULT_CONTAINER_PADDING, DEFAULT_HEIGHT } from '../const';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { ScheduleSelectBlockProps } from '../interface';
import qs from 'qs';
import { replace } from 'store/router/actions';
import { searchParams } from 'pages/date-select-page/interface';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'hooks/use-search-params.hook';

export const useSelectBlock = ({
  addRef,
  listRefs,
  available,
  formatTime,
  time,
  setIsScrollToSelectedValue,
  durations,
  defaultOffsetTop,
}: ScheduleSelectBlockProps) => {
  const ref = useRef(null);
  const shiftTop = useMemo<number>(() => defaultOffsetTop + DEFAULT_CONTAINER_PADDING, [defaultOffsetTop]);
  const { presetWindows, ...restQueryParams } = useSearchParams<searchParams>();
  const dispatch = useDispatch();
  const { min: minDuration } = durations || {};
  const minHeight = useMemo<number>(() => (minDuration / 15) * DEFAULT_HEIGHT, [minDuration]);

  const isNextTimeSlotUnavailable = useCallback(
    (height: number) => {
      return listRefs?.find(currentRef => currentRef.offsetTop === height && !currentRef.available);
    },
    [listRefs],
  );

  const handleBlockClick = useCallback(() => {
    const lastTimeSlot = listRefs?.[listRefs.length - 1];
    setIsScrollToSelectedValue(false);
    const resizableTop = document.getElementById('resizable-top');
    const resizableBottom = document.getElementById('resizable-bottom');
    if (!resizableTop || !available) {
      return;
    }
    resizableTop.style.top = `${ref.current.offsetTop - shiftTop}px`;
    let currentHeight = +resizableBottom.style.height.replace('px', '') || minHeight;
    let currentTop = +resizableTop.style.top.replace('px', '');
    if (
      !isNextTimeSlotUnavailable(currentTop + currentHeight) &&
      (isNextTimeSlotUnavailable(currentTop + minHeight) ||
        isNextTimeSlotUnavailable(currentTop + minHeight - DEFAULT_HEIGHT))
    ) {
      resizableBottom.style.height = `${minHeight}px`;
      resizableTop.style.top = `${currentTop}px`;
      currentHeight = minHeight;
    }
    if (
      isNextTimeSlotUnavailable(currentTop + currentHeight) &&
      !isNextTimeSlotUnavailable(currentTop + minHeight - DEFAULT_HEIGHT)
    ) {
      resizableBottom.style.height = `${minHeight}px`;
      resizableTop.style.top = `${currentTop}px`;
      currentHeight = minHeight;
    }
    if (
      isNextTimeSlotUnavailable(currentTop - minHeight + DEFAULT_HEIGHT) &&
      !isNextTimeSlotUnavailable(currentTop + minHeight - DEFAULT_HEIGHT)
    ) {
      resizableBottom.style.height = `${minHeight}px`;
      resizableTop.style.top = `${
        isNextTimeSlotUnavailable(currentTop - minHeight + DEFAULT_HEIGHT)?.offsetTop + DEFAULT_HEIGHT
      }px`;
      currentTop = isNextTimeSlotUnavailable(currentTop - minHeight + DEFAULT_HEIGHT)?.offsetTop + DEFAULT_HEIGHT;
      currentHeight = minHeight;
    }
    if (
      !isNextTimeSlotUnavailable(currentTop - minHeight + DEFAULT_HEIGHT) &&
      isNextTimeSlotUnavailable(currentTop + minHeight - DEFAULT_HEIGHT)
    ) {
      resizableBottom.style.height = `${minHeight}px`;
      resizableTop.style.top = `${
        isNextTimeSlotUnavailable(currentTop + minHeight - DEFAULT_HEIGHT)?.offsetTop - DEFAULT_HEIGHT
      }px`;
      currentTop = isNextTimeSlotUnavailable(currentTop + minHeight - DEFAULT_HEIGHT)?.offsetTop - DEFAULT_HEIGHT;
      currentHeight = minHeight;
    }
    if (
      isNextTimeSlotUnavailable(currentTop + DEFAULT_HEIGHT) ||
      listRefs[listRefs.length - 1]?.offsetTop === currentTop
    ) {
      resizableBottom.style.height = `${currentHeight}px`;
      resizableTop.style.top = `${currentTop - (currentHeight - DEFAULT_HEIGHT)}px`;
      currentTop -= currentHeight - DEFAULT_HEIGHT;
    }
    if (lastTimeSlot?.offsetTop < currentTop + currentHeight) {
      resizableBottom.style.height = `${currentHeight}px`;
      resizableTop.style.top = `${lastTimeSlot?.offsetTop - currentHeight}px`;
      currentTop = lastTimeSlot?.offsetTop - currentHeight;
    }
    const shiftHeight = currentHeight;
    const queryParams = {
      ...restQueryParams,
      startTime: listRefs?.find(currentRef => currentRef.offsetTop === currentTop)?.queryStringTime,
      endTime: listRefs?.find(currentRef => currentRef.offsetTop === currentTop + shiftHeight)?.queryStringTime,
      isExact: true,
    };
    const queryString = qs.stringify(queryParams);
    dispatch(replace(`${location.pathname}?${queryString}`));
  }, [shiftTop, ref, listRefs, presetWindows]);

  useEffect(() => {
    if (defaultOffsetTop) {
      addRef({
        available,
        time: formatTime(time),
        queryStringTime: time,
        offsetTop: ref?.current?.offsetTop - shiftTop,
      });
    }
  }, [addRef, available, formatTime, shiftTop, time, ref?.current?.offsetTop, ref, defaultOffsetTop]);

  return { handleBlockClick, ref };
};
