import React, { useCallback } from 'react';

import { StyledButton } from './styles';
import { TILE_BUTTON_TYPE } from 'pages/resources/content/tile-button/tile-button.enum';
import { useIntl } from 'react-intl';

export interface TileButtonProps {
  buttonType: TILE_BUTTON_TYPE;
  onButtonClick: (resourceUuid: string) => void;
  resourceUuid: string;
}

export const TileButton = ({ buttonType, onButtonClick, resourceUuid }: TileButtonProps): JSX.Element => {
  const intl = useIntl();

  const handleButtonClick = useCallback(() => {
    onButtonClick(resourceUuid);
  }, [onButtonClick, resourceUuid]);

  switch (buttonType) {
    case TILE_BUTTON_TYPE.VIEW_AVAILABILITY:
      return (
        <StyledButton
          type="button"
          variant="secondary"
          text={intl.formatMessage({ id: 'view_availability' })}
          size="xxs"
          onClick={handleButtonClick}
          multiline={false}
        />
      );
    case TILE_BUTTON_TYPE.BOOK:
      return (
        <StyledButton
          isBookButton
          type="button"
          variant="primary"
          text={intl.formatMessage({ id: 'book' })}
          size="xxs"
          onClick={handleButtonClick}
          multiline={false}
        />
      );
    default:
      return null;
  }
};
