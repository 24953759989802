import styled from 'styled-components';
import { Popover } from '@hqo/react-components-library/dist/molecules/popover';
import { Paper } from '@hqo/react-components-library/dist/atoms/paper';

export const StyledPaper = styled(Paper)`
  padding: 24px 12px;
`;

export const StyledPopover = styled(Popover)`
  z-index: 1;
`;
