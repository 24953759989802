import { ResourceTimeRangesState } from './types';
import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';

export const resourceTimeRangesSelector = (state: RootState): ResourceTimeRangesState => state.resourceTimeRanges;

export const selectResourceTimeRanges = createSelector([resourceTimeRangesSelector], resource => resource.time_ranges);

export const selectResourceTimeRangesStatus = createSelector(
  [resourceTimeRangesSelector],
  resource => resource.getResourceTimeRanges.status,
);

export const selectResourceTimeRangesError = createSelector(
  [resourceTimeRangesSelector],
  resource => resource.getResourceTimeRanges.error,
);

export const selectPresetBookableWindows = createSelector(
  [resourceTimeRangesSelector],
  timeRanges => timeRanges.preset_bookable_windows,
);
