import { useState } from 'react';

export interface UseAddCardButtonInterface {
  isAddCardButtonDisabled: boolean;
  setIsAddCardButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useAddCardButton = (): UseAddCardButtonInterface => {
  const [isAddCardButtonDisabled, setIsAddCardButtonDisabled] = useState(false);

  return {
    isAddCardButtonDisabled,
    setIsAddCardButtonDisabled,
  };
};
