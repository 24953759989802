import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hook';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsReAuthenticateRequired } from 'store/errors/selectors';
import { push } from 'store/router/actions';
import { ERROR_PATH } from 'shared/consts';

export interface ErrorHandlerProps {
  children: JSX.Element;
}

export const ErrorHandler = ({ children }: ErrorHandlerProps): JSX.Element => {
  const isReAuthenticateRequired = useSelector(selectIsReAuthenticateRequired);
  const client = useMiniappSdk();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isReAuthenticateRequired) {
      setTimeout(() => {
        dispatch(push(ERROR_PATH));
      }, 2000);

      client.logout();
    }
  }, [isReAuthenticateRequired, client, dispatch]);

  return <>{children}</>;
};
