import React, { useEffect } from 'react';
import { StyledModal, Container, Content, Footer, Title, FooterContainer } from './styles';
import { ModalPortal } from 'components/modals/modal-portal/modal-portal';
import { ScheduleSelectPageProps } from '../interface';
import { ScheduleSelect } from 'components/schedule-select';
import { ScheduleSelectPageFooter } from '../components/schedule-select-footer';
import { useScheduleSelectPage } from '../use-schedule-select-page.hook';
import { BackLinkWrapper } from 'pages/time-select-page/desktop/styles';
import { ArrowBackLink } from 'components/arrow-back-link';

export const ScheduleSelectDesktopPage = ({
  onClose,
  onTimeLinkClick,
  onDateLinkClick,
  onPressBack,
  backToDateStep,
  onPressSave,
}: ScheduleSelectPageProps): JSX.Element => {
  const { resourceName, minDurationText } = useScheduleSelectPage();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, []);

  return (
    <ModalPortal>
      <StyledModal opened onClose={onClose} dataTestId="modal-window" data-cy="modal-window">
        <Container className="schedule-select-page-desktop" data-testid="schedule-select-page-desktop">
          {backToDateStep && (
            <BackLinkWrapper>
              <ArrowBackLink onClick={onPressBack} />
            </BackLinkWrapper>
          )}
          <Title backToDateStep={backToDateStep}>{resourceName}</Title>
          <Content backToDateStep={backToDateStep}>
            <ScheduleSelect />
          </Content>
          <FooterContainer>
            <Footer>
              <ScheduleSelectPageFooter
                onPressSave={onPressSave}
                onTimeLinkClick={onTimeLinkClick}
                onDateLinkClick={onDateLinkClick}
                minDurationText={minDurationText}
              />
            </Footer>
          </FooterContainer>
        </Container>
      </StyledModal>
    </ModalPortal>
  );
};
