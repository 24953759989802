import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'pages/date-select-page/interface';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';
import qs from 'qs';
import { pick } from 'utils/pickObjectProperty';
import { QUERY_PARAMS } from 'shared/consts';
import { replace } from 'store/router/actions';

interface UseClearButtonReturnValues {
  isActive: boolean;
  isClearAllButtonVisible: boolean;
  onClearAllClick: VoidFunction;
}

interface UseClearButtonProps {
  isDateStep: boolean;
}

export const useClearButton = ({ isDateStep }: UseClearButtonProps): UseClearButtonReturnValues => {
  const { startTime, endTime, ...restQueryParams } = useSearchParams<searchParams>();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isActive = useMemo<boolean>(() => !!(startTime || endTime), [startTime, endTime]);
  const isClearAllButtonVisible = useMemo<boolean>(
    () => pathname.includes('/resource-booking/resources') && !isDateStep,
    [pathname, isDateStep],
  );

  const onClearAllClick = useCallback(() => {
    const queryString = qs.stringify(
      pick(
        restQueryParams,
        QUERY_PARAMS.filter(param => param !== 'startTime' && param !== 'endTime'),
      ),
    );
    dispatch(replace(`${pathname}?${queryString}`));
  }, [dispatch, pathname, restQueryParams]);

  return {
    isActive,
    isClearAllButtonVisible,
    onClearAllClick,
  };
};
