import React from 'react';
import { Container, Subtitle } from './styles';
import { useLocale } from 'hooks/use-locale.hook';
import { BookingTimeHandler } from 'utils/bookingTimeHandler/bookingTimeHandler';

interface BookingProps {
  date: string;
  timeRange: TimeRange;
}

interface TimeRange {
  startTime: string;
  endTime: string;
}

export const Booking = ({ date, timeRange }: BookingProps): JSX.Element => {
  const locale = useLocale();
  const formattedDate = new Date(date).toLocaleString(locale, { month: 'short', timeZone: 'UTC', day: 'numeric' });
  const formattedTimeRange = new BookingTimeHandler({ ...timeRange, locale }).getTimeRangeString();

  return (
    <Container data-testid="booking-container">
      <Subtitle>
        {formattedDate} • {formattedTimeRange}
      </Subtitle>
    </Container>
  );
};
