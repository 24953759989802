export const daysOfWeek = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

export interface OpenCloseTimes {
  open: string;
  close: string;
}

export interface MappedWeekDays {
  [x: string]: {
    rrules?: Array<string>;
    duration?: number;
    calculatedSetOpeningHours?: Array<string>;
    closingTime?: string;
    openingTime?: string;
    openingHours?: OpenCloseTimes[];
    openingHour?: number;
    closingHour?: number;
  };
}
