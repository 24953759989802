import { VisitorsError } from 'components/adding-visitors-block/visitors-error.enum';
import hasDuplicates from 'utils/hasDuplicates';
import { isEmailValid } from 'utils/isEmailValid';

export default function visitorEmailsValidator(visitorEmails: string[], hostEmail: string): VisitorsError | undefined {
  const isInvalidEmailExist = visitorEmails.some(email => !isEmailValid(email));

  if (isInvalidEmailExist) {
    return VisitorsError.INVALID_EMAIL;
  }

  if (hasDuplicates(visitorEmails)) {
    return VisitorsError.DUPLICATE_EMAIL;
  }

  if (visitorEmails.includes(hostEmail)) {
    return VisitorsError.DUPLICATE_ORGANIZER_EMAIL;
  }

  return undefined;
}
