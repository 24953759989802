import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PaymentIFrame } from 'components/paymentIFrame';
import { SwipePaymentIFrame } from 'components/swipePaymentIFrame';
import { TransactionFailedModal } from 'components/modals/transaction-failed-modal/transaction-failed-modal';
import { TermsAndConditions } from 'components/termsAndConditions';
import { ModalContainer } from 'pages/resource/components/terms-and-conditions/components/modal-container';

interface LandingPageRoutesProps {
  reverseAnimation: boolean;
  bookingId: number | string;
  setBookingId: React.Dispatch<React.SetStateAction<string | number>>;
}

export const LandingPageRoutes = ({
  reverseAnimation,
  bookingId,
  setBookingId,
}: LandingPageRoutesProps): JSX.Element => {
  return (
    <Routes>
      <Route
        path="payment"
        element={
          <PaymentIFrame reverseAnimation={reverseAnimation} bookingId={bookingId} setBookingId={setBookingId} />
        }
      />
      <Route path="swipe-payment" element={<SwipePaymentIFrame bookingId={bookingId} setBookingId={setBookingId} />} />
      <Route path="transaction-failed" element={<TransactionFailedModal reverseAnimation={reverseAnimation} />} />
      <Route path="terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="quick-checkout/terms-and-conditions" element={<ModalContainer />} />
    </Routes>
  );
};
