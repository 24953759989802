import {
  NOTIFICATION_ANIMATION_DURATION_MS,
  NOTIFICATION_DEFAULT_HIDE_MS,
  NOTIFICATION_PROGRESS_UPDATE_INTERVAL_MS,
} from 'components/notifications/components/notification/consts';
import { Notification as NotificationData } from 'store/notifications/types';
import React, { useCallback, useEffect, useState } from 'react';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faClose } from '@fortawesome/pro-solid-svg-icons/faClose';

import { Root, Content, Title, ProgressBar, TextContainer, Icon, CloseButton, ProgressBarContainer } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';

export interface NotificationProps extends NotificationData {
  onClose?: () => void;
  hideTimerInMs?: number;
}

export default function Notification({
  title,
  type,
  message,
  onClose,
  hideTimerInMs = NOTIFICATION_DEFAULT_HIDE_MS,
}: NotificationProps) {
  const [visible, setVisible] = useState(true);
  const [progressInPercent, setProgressInPercent] = useState<number>(100);
  const intl = useIntl();

  const hide = useCallback(
    event => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      setVisible(false);

      if (onClose) {
        setTimeout(onClose, NOTIFICATION_ANIMATION_DURATION_MS);
      }
    },
    [setVisible, onClose],
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (hideTimerInMs > 0 && Number.isFinite(hideTimerInMs)) {
      timeout = setTimeout(hide, hideTimerInMs);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [hide, hideTimerInMs]);

  useEffect(() => {
    let interval: NodeJS.Timer | undefined;

    if (hideTimerInMs >= 0) {
      const step = (NOTIFICATION_PROGRESS_UPDATE_INTERVAL_MS * 100) / hideTimerInMs;
      interval = setInterval(() => {
        setProgressInPercent(prev => {
          const newValue = prev - step;
          return newValue < 0 ? 0 : newValue;
        });
      }, NOTIFICATION_PROGRESS_UPDATE_INTERVAL_MS);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [visible, hideTimerInMs]);

  return (
    <Root
      role="alert"
      aria-labelledby={title ? 'notification-title' : undefined}
      aria-describedby="notification-description"
      duration={hideTimerInMs}
      type={type}
      visible={visible}
      data-testid="notification"
      onClick={hide}
    >
      <Icon icon={faCheckCircle} size="2x" type={type} />
      <TextContainer>
        {title && <Title id="notification-title">{title}</Title>}
        <Content id="notification-description">{message}</Content>
      </TextContainer>
      <CloseButton
        aria-label={intl.formatMessage({ id: 'close' })}
        variant="Plain"
        size="m"
        Icon={<FontAwesomeIcon icon={faClose} />}
        onClick={hide}
      />
      {hideTimerInMs > 0 && (
        <ProgressBarContainer type={type}>
          <ProgressBar
            role="progressbar"
            aria-valuemin={0}
            aria-valuemax={100}
            aria-valuenow={progressInPercent}
            duration={hideTimerInMs}
            type={type}
          />
        </ProgressBarContainer>
      )}
    </Root>
  );
}
