import { legacy_createStore as createStore } from 'redux';

import { ACTION_STATUSES } from 'shared/consts';
import { BrowserRouter } from 'react-router-dom';
import { BuildingState } from 'store/building/types';
import { HqoThemeProvider } from '@hqo/react-components-library/dist/hqo-theme-provider';
import { IntlProvider } from 'components/intl-provider';
import { Provider } from 'react-redux';
import React from 'react';
import { RootState } from 'store/reducer';
import { mock } from 'test-utils/mock';
import { ConfigState } from 'store/config/types';
import { KioskState } from 'store/kiosk/types';

interface TestWrapperProps {
  children?: React.ReactNode;
  state: Partial<RootState>;
}

const MOCKED_CONFIG: ConfigState = {
  apiUrl: 'url',
  appBrand: null,
  buildingUuid: null,
  locale: null,
  authToken: null,
  singleAppInstanceConfig: false,
  singleLocationConfig: false,
};

const MOCKED_BUILDING = {
  building: {
    uuid: '1',
    locale: 'en-US',
    currency_type: 'USD',
  },
  getBuilding: {
    status: ACTION_STATUSES.FULFILLED,
    error: null,
  },
} as BuildingState;

const MOCKED_KIOSK = {
  enabled: false,
} as KioskState;

export const TestWrapper: React.FC<TestWrapperProps> = ({ children, state }: TestWrapperProps): JSX.Element => {
  const MOCKED_STATE = mock<RootState>({
    ...state,
  });

  MOCKED_STATE.config = state?.config || MOCKED_CONFIG;
  MOCKED_STATE.building = state?.building || MOCKED_BUILDING;
  MOCKED_STATE.kiosk = state?.kiosk || MOCKED_KIOSK;
  const store = createStore(() => MOCKED_STATE);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <IntlProvider
          onError={err => {
            if (err.code === 'MISSING_TRANSLATION' || err.code === 'MISSING_DATA') {
              // Ignore missing locales in unit tests
              return;
            }
            throw err;
          }}
        >
          <HqoThemeProvider>{children}</HqoThemeProvider>
        </IntlProvider>
      </BrowserRouter>
    </Provider>
  );
};
