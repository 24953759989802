import moment from 'moment';
import RRule from 'rrule';

const getUpcomingDateForDay = (dayIndex: number): Date => {
  const today = new Date();
  if (today.getDay() === dayIndex) {
    return today;
  }
  const daysToAdd = today.getDay() < dayIndex ? dayIndex - today.getDay() : 7 - today.getDay() + dayIndex;
  today.setDate(today.getDate() + daysToAdd);
  return today;
};

export const getOpeningIntervalsForDay = (dayIndex: number, rruleString: string): Array<string> => {
  const upcomingDay = getUpcomingDateForDay(dayIndex);

  const firstStartDateOfIndexInMonth = moment(upcomingDay)
    .utcOffset(0, true)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .toDate();

  const firstEndDateOfIndexInMonth = moment(upcomingDay)
    .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
    .utc(true)
    .toDate();

  const parsedString = RRule.parseString(rruleString);
  const newRRule = new RRule({
    ...parsedString,
    dtstart: firstStartDateOfIndexInMonth,
    until: firstEndDateOfIndexInMonth,
  });
  const intervalWithinDay = newRRule.all();
  const firstInterval = intervalWithinDay[0];

  let timeOffset = 15;
  if (typeof parsedString.byminute === 'number' && parsedString.byminute === 0) {
    timeOffset = 60;
  }

  const filteredIntervalWithinDay = intervalWithinDay.filter(date => date >= firstInterval);
  const sortedIntervalWithinDay = filteredIntervalWithinDay.sort(
    (a, b) => new Date(a)?.getTime() - new Date(b)?.getTime(),
  );
  const newDateObj = new Date(
    sortedIntervalWithinDay[sortedIntervalWithinDay.length - 1]?.getTime() + timeOffset * 60_000,
  );
  sortedIntervalWithinDay.push(newDateObj);

  const slicedIntervalsWithinDay = [
    sortedIntervalWithinDay[0],
    sortedIntervalWithinDay[sortedIntervalWithinDay.length - 1],
  ];
  return slicedIntervalsWithinDay.map(date => date.toUTCString());
};
