import React, { useEffect } from 'react';
import { DateSelectPageProps } from '../interface';
import { DateSelectPageContent } from '../components/date-select-content';
import { DateSelectPageFooter } from '../components/date-select-footer';
import { DayLabels } from 'pages/date-select-page/components/day-labels';
import { useIntl } from 'react-intl';
import { Content } from 'components/date-desktop-modal/styles';

import { DateDesktopModal } from 'components/date-desktop-modal';

export const DateSelectDesktopModal = ({
  onClose,
  onNextClick,
  onDateLinkClick,
  onTimeLinkClick,
  onSaveClick,
}: DateSelectPageProps): JSX.Element => {
  const intl = useIntl();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, []);

  return (
    <DateDesktopModal
      onClose={onClose}
      className="date-select-modal-desktop"
      title={intl.formatMessage({ id: 'whenBooking' })}
      headerContent={<DayLabels />}
      footerContent={
        <DateSelectPageFooter
          onNextClick={onNextClick}
          onDateLinkClick={onDateLinkClick}
          onTimeLinkClick={onTimeLinkClick}
          onSaveClick={onSaveClick}
        />
      }
    >
      <Content data-testid="scroll-container" id="scroll-container">
        <DateSelectPageContent />
      </Content>
    </DateDesktopModal>
  );
};
