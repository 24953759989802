import {
  CustomIconCancel,
  CustomIconConfirmed,
  IconWrapper,
  OverviewContainer,
  StatusTitle,
  StatusTitleWrapper,
  StyledStatusIcon,
  Subtitle,
  TitleContainer,
} from './styles';
import { formatDate } from 'utils/formatDate';
import { BoldText } from 'hocs/bold-text';
import React, { useEffect, useMemo, useState } from 'react';
import { TransactionDetails } from 'store/transactions/types';
import { selectBuildingTimeZone } from 'store/building/selectors';
import { selectPatchTransactionsStatus, selectTypeOfPatchTransaction } from 'store/transactions/selectors';
import { useIntl } from 'react-intl';
import { useLocale } from 'hooks/use-locale.hook';
import { useSelector } from 'react-redux';
import { ACTION_STATUSES, BOOKING_STATUSES, PATCH_STATUSES } from 'shared/consts';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'shared/types/search-params';
import { selectCart } from 'store/cart/selectors';
import { formatStartDatesQueryParams } from 'components/payment/quick-checkout/components/multiday-checkout-content/utils/formatStartDatesQueryParam';
import { joinArrayItemsWithOxfordComma } from 'components/payment/quick-checkout/components/multiday-checkout-content/utils/joinItemsWithOxfordComma';
import { isBookingsForOneResource } from 'components/payment/receipt/components/utils/isBookingsForOneResource';
import { BookingStatus } from 'store/bookings/types';

interface ReceiptOverviewProps {
  transaction: TransactionDetails;
  isSwipeModalContent?: boolean;
}

export const ReceiptOverview = ({ transaction, isSwipeModalContent = false }: ReceiptOverviewProps): JSX.Element => {
  const intl = useIntl();
  const locale = useLocale();
  const patchTransactionStatus = useSelector(selectPatchTransactionsStatus);
  const typeOfPatchTransaction = useSelector(selectTypeOfPatchTransaction);
  const patchTransactionData = useMemo(
    () => ({
      status: patchTransactionStatus,
      type: typeOfPatchTransaction,
    }),
    [patchTransactionStatus, typeOfPatchTransaction],
  );
  const buildingTimeZone = useSelector(selectBuildingTimeZone);
  const cart = useSelector(selectCart);
  const [isCancelledIcon, setIsCancelledIcon] = useState(false);
  const { startDates } = useSearchParams<searchParams>();

  const multidayBookingInfo = useMemo<{ resourceName: string; status: string } | null>(() => {
    const isMultidayBooking = isBookingsForOneResource(cart?.items);

    return !!startDates?.length && isMultidayBooking
      ? { resourceName: cart?.items[0].resource_booking.name, status: cart.items[0].status }
      : null;
  }, [cart?.items, startDates?.length]);

  const { eventWeekDay, eventDateMonthLong, eventDateDay } = formatDate(
    transaction?.details.start_at,
    locale as string,
    buildingTimeZone,
  );

  const hideIcon = useMemo<boolean>(
    () =>
      multidayBookingInfo
        ? multidayBookingInfo.status === BookingStatus.PENDING
        : transaction?.details.status === BookingStatus.PENDING && patchTransactionStatus !== ACTION_STATUSES.FULFILLED,
    [multidayBookingInfo, transaction?.details.status, patchTransactionStatus],
  );
  useEffect(() => {
    if (
      patchTransactionStatus === ACTION_STATUSES.FULFILLED &&
      patchTransactionData.type === PATCH_STATUSES.CANCELLED
    ) {
      setIsCancelledIcon(true);
    } else {
      setIsCancelledIcon(false);
    }
  }, [patchTransactionData.type, patchTransactionStatus]);

  const formattedDatesString = formatStartDatesQueryParams(startDates, locale);
  const andSeparator = intl.formatMessage({ id: 'and' });

  const formattedDatesStringWithSeparator = joinArrayItemsWithOxfordComma(formattedDatesString, andSeparator);

  const getReceiptTitle = (bookingStatus: string, patchTransaction: { status: string; type: string }) => {
    if (patchTransaction.status === ACTION_STATUSES.FULFILLED && patchTransaction.type === PATCH_STATUSES.CANCELLED) {
      return 'resource_cancelled';
    }
    if (bookingStatus === BOOKING_STATUSES.ACCEPTED) {
      return 'booking_confirmed';
    }
    if (bookingStatus === BOOKING_STATUSES.PENDING) {
      return 'resource_requested';
    }
    return 'default_receipt';
  };

  const getReceiptSubtitle = (bookingStatus: string, patchTransaction: { status: string; type: string }) => {
    if (patchTransaction.status === ACTION_STATUSES.FULFILLED && patchTransaction.type === PATCH_STATUSES.CANCELLED) {
      return 'cancelled_text';
    }
    if (bookingStatus === BOOKING_STATUSES.ACCEPTED) {
      return 'confirmation_text';
    }
    if (bookingStatus === BOOKING_STATUSES.PENDING) {
      return 'requested_subtitle';
    }
    return 'default_receipt';
  };

  const getOverviewTitle = () => {
    const booking_status = multidayBookingInfo ? multidayBookingInfo.status : transaction?.details.status;
    return {
      title: getReceiptTitle(booking_status, patchTransactionData),
      subtitle: multidayBookingInfo
        ? intl.formatMessage(
            {
              id: getReceiptSubtitle(booking_status, patchTransactionData),
            },
            {
              resourceName: <BoldText>{multidayBookingInfo.resourceName}</BoldText>,
              bookingTime: <BoldText>{formattedDatesStringWithSeparator}</BoldText>,
            },
          )
        : intl.formatMessage(
            {
              id: getReceiptSubtitle(booking_status, patchTransactionData),
            },
            {
              resourceName: <BoldText>{transaction?.details.name}</BoldText>,
              bookingTime: <BoldText>{`${eventWeekDay}, ${eventDateMonthLong} ${eventDateDay}`}</BoldText>,
            },
          ),
    };
  };
  return (
    <OverviewContainer isSwipeModalContent={isSwipeModalContent} data-testid="receipt-overview">
      <TitleContainer>
        <StatusTitleWrapper isCancelledIcon={isCancelledIcon} data-testid="status-title-wrapper">
          <StatusTitle data-testid="booking-status-text">
            {intl.formatMessage({ id: getOverviewTitle().title })}
          </StatusTitle>
          {!hideIcon && (
            <IconWrapper>
              <StyledStatusIcon isCancelledIcon={isCancelledIcon} />
              {patchTransactionData.type === PATCH_STATUSES.CANCELLED && (
                <CustomIconCancel isCancelledIcon={isCancelledIcon} data-testid="custom-cancel-icon" />
              )}
              {multidayBookingInfo === null && (
                <CustomIconConfirmed isCancelledIcon={isCancelledIcon} data-testid="custom-confirmed-icon" />
              )}
              {multidayBookingInfo && cart.items.every(booking => booking.status === BookingStatus.ACCEPTED) && (
                <CustomIconConfirmed isCancelledIcon={isCancelledIcon} data-testid="custom-confirmed-icon" />
              )}
            </IconWrapper>
          )}
        </StatusTitleWrapper>
      </TitleContainer>
      {/* @ts-ignore */}
      <Subtitle>{getOverviewTitle().subtitle}</Subtitle>
    </OverviewContainer>
  );
};
