import React, { memo } from 'react';
import { MapViewContainer } from './styles';
import { useInitializeMapView } from './hooks/use-initialize-map-view.hook';
import { useMapView } from './hooks/use-map-view.hook';

// eslint-disable-next-line react/display-name
export const MapView = memo((): JSX.Element => {
  const { mapContainerRef, mapRef } = useInitializeMapView();
  useMapView({ mapRef });

  return <MapViewContainer data-testid="map-container" ref={mapContainerRef} className="map-container" />;
});
