import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@hqo/react-components-library/dist/atoms/button';

import { ButtonsWrapper, Content, Root, Title } from './styles';

type BookOrCancelModalProps = {
  onCancel: VoidFunction;
  onBook: VoidFunction;
  resourceName: string;
  disabled?: boolean;
} & React.ButtonHTMLAttributes<HTMLDivElement>;

const BookOrCancelModal = forwardRef<HTMLDivElement, BookOrCancelModalProps>(function BookOrCancelModalComponent(
  { disabled = false, onCancel, onBook, children, resourceName, ...props },
  ref,
) {
  const intl = useIntl();

  return (
    <Root data-testid="book-or-cancel-modal">
      <Title>{intl.formatMessage({ id: 'book_or_cancel_modal_title' }, { resourceName })}</Title>
      <Content ref={ref} {...props}>
        {children}
      </Content>
      <ButtonsWrapper>
        <Button text={intl.formatMessage({ id: 'cancel' })} variant="Plain" size="m" onClick={onCancel} />
        <Button
          text={intl.formatMessage({ id: 'book' })}
          onClick={onBook}
          disabled={disabled}
          size="m"
          variant="Primary"
        />
      </ButtonsWrapper>
    </Root>
  );
});

export default BookOrCancelModal;
