import transformExternalUrl from 'shared/utils/transformExternalUrl';
import { CTATypeEnum } from 'store/resource/types';

export const formatLink = (cta_type: CTATypeEnum, cta_value: string): string | null => {
  if (cta_type === CTATypeEnum.EMAIL && cta_value) {
    return `mailto:${cta_value}`;
  }
  if (cta_type === CTATypeEnum.PHONE && cta_value) {
    return `tel:${cta_value}`;
  }
  if (cta_type === CTATypeEnum.URL && cta_value) {
    return transformExternalUrl(cta_value);
  }
  return null;
};
