import React from 'react';
import { useIntl } from 'react-intl';
import { CollapsableBlock } from 'components/collapsable-block';
import { ReceiptUserResponse } from '../receipt-user-response';

interface ReceiptAdditionalInformationProps {
  formResponses: Array<FormResponse>;
}

export const ReceiptAdditionalInformation = ({ formResponses }: ReceiptAdditionalInformationProps): JSX.Element => {
  const intl = useIntl();

  return (
    <CollapsableBlock title={intl.formatMessage({ id: 'additional_information' })}>
      {formResponses?.map(response => (
        <ReceiptUserResponse key={response.question} response={response} />
      ))}
    </CollapsableBlock>
  );
};
