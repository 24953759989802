import { Container, HeaderTitle, StyledCalendarIcon, RightPane, Text, LeftPane, Content } from './styles';
import React from 'react';
import { ArrowBackLink } from '../arrow-back-link';
import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar';
import { useIntl } from 'react-intl';

interface ResourceBookingHeaderProps {
  withBackButton: boolean;
  title: string;
  onBackClick?: VoidFunction;
  showMyBookingsSection?: boolean;
  onMyBookingsClick?: VoidFunction;
}

export const ResourceBookingHeader = ({
  withBackButton,
  title,
  onBackClick,
  showMyBookingsSection,
  onMyBookingsClick,
}: ResourceBookingHeaderProps): JSX.Element => {
  const intl = useIntl();

  return (
    <Container>
      {withBackButton && <ArrowBackLink onClick={onBackClick} />}
      <Content>
        <LeftPane>
          <HeaderTitle>{title}</HeaderTitle>
        </LeftPane>
        {showMyBookingsSection && (
          <RightPane onClick={onMyBookingsClick}>
            <StyledCalendarIcon icon={faCalendar} />
            <Text>{intl.formatMessage({ id: 'my_bookings' })}</Text>
          </RightPane>
        )}
      </Content>
    </Container>
  );
};
