import moment from 'moment-timezone';

type TimeInDayInMinutes = number;

export function parseTime(timeString: string): TimeInDayInMinutes {
  const [hours, minutes] = timeString.split(':').map(Number);

  if (hours === undefined || minutes === undefined || Number.isNaN(hours) || Number.isNaN(minutes)) {
    throw new Error(`Invalid time string "${timeString}" should match HH:mm format`);
  }

  return hours * 60 + minutes;
}

export enum TimeStringFormats {
  HOURS_ONLY_UP = 'HOURS_ONLY_UP',
  HH_MM_UP = 'HH_MM_UP',
}

export function formatTime(
  time: TimeInDayInMinutes,
  locale: string,
  format: TimeStringFormats = TimeStringFormats.HH_MM_UP,
): string {
  const date = moment().startOf('day').set('minute', time).toDate();
  switch (format) {
    case TimeStringFormats.HH_MM_UP:
      return date.toLocaleTimeString(locale, { timeStyle: 'short' }).toUpperCase();
    default:
    case TimeStringFormats.HOURS_ONLY_UP:
      return date.toLocaleTimeString(locale, { hour: 'numeric' }).toLowerCase().replace(' h', '').toUpperCase();
  }
}

export function getTimeInDayInMinutesFromUTCISO8601(datetime: string, timezone: string): TimeInDayInMinutes {
  const m = moment(datetime).tz(timezone);
  return m.hours() * 60 + m.minutes();
}
