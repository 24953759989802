import transformExternalUrl from 'shared/utils/transformExternalUrl';

export const HQO_PARAMETER_NAME_TO_OPEN_OS_BROWSER_ON_EXTERNAL_LINK = 'hqoExternalLink';

export interface TransformAllHtmlAnchorsLinkOptions {
  attributesToAdd?: Record<string, string>;
  openInOsBrowserExternalLink?: boolean;
}

const transformAllHtmlAnchorsLink = (
  htmlString: string,
  { attributesToAdd = {}, openInOsBrowserExternalLink = true }: TransformAllHtmlAnchorsLinkOptions = {},
): string => {
  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const linkElements = doc.querySelectorAll('a');

    linkElements.forEach(linkElement => {
      Object.entries(attributesToAdd).forEach(([name, value]) => linkElement.setAttribute(name, value));

      const newUrlValue = transformExternalUrl(linkElement.href, openInOsBrowserExternalLink);
      if (newUrlValue) {
        linkElement.setAttribute('href', newUrlValue);
      }
    });

    return doc.documentElement.outerHTML;
  } catch (error) {
    return '';
  }
};

export default transformAllHtmlAnchorsLink;
