import { DIMENSIONS, ZINDEX } from '@hqo/react-components-library/dist/utils';
import styled from 'styled-components';

export const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => `${theme.colorsRgba.black_5}`};
  z-index: ${ZINDEX.MODAL_BACKGROUND};
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.universal.white};
  margin: auto;
  box-shadow: ${({ theme }) => `0px 0px 4px ${theme.colorsRgba.black_25}`};
  display: flex;
  justify-content: flex-start;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: inherit;
  z-index: ${ZINDEX.MODAL_CONTAINER};

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    transform: translate(0%, 0%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export const Content = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const ModalWrapper = styled.div``;
