import styled from 'styled-components';
import { Modal } from '@hqo/react-components-library/dist/molecules/modals/modal';
import { Button } from '@hqo/react-components-library/dist/atoms/button';

export const StyledModal = styled(Modal)`
  overflow: auto;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 348px;
    max-height: 683px;
    max-width: 963px;
    padding: 24px 32px;
    margin: auto;
    overflow: initial;
  }
`;

export const BackLinkWrapper = styled.div`
  width: 100%;
  justify-content: flex-start;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;

export const StyledClearButton = styled(Button)<{ isActive?: boolean }>`
  font: 500 16px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme, isActive }) =>
    isActive ? `${theme.colors.greys.adminBlack}` : `${theme.colors.greys.adminGreyMediumAlt}`};
  padding: 20px;
  opacity: 1;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const Content = styled.div`
  overflow-y: auto;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 10px;
  flex-grow: 1;
`;

export const Title = styled.div`
  padding: 20px 0;
  width: 100%;
  font: 700 28px/33px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  box-sizing: border-box;
  justify-content: flex-start;
`;

export const FooterContainer = styled.div`
  width: 100%;
  justify-content: flex-end;
  display: flex;
`;
