import { UnitCodesState } from 'store/unit-codes/types';
import { addUnitCodes } from 'store/unit-codes/actions';

import { ActionType, createReducer } from 'typesafe-actions';

const initialState: UnitCodesState = {
  unitCodes: [],
};

const addUnitCodesHandler = (state: UnitCodesState, { payload }: ActionType<typeof addUnitCodes>): UnitCodesState => {
  return { ...state, unitCodes: payload };
};

const unitCodesReducer = createReducer(initialState).handleAction(addUnitCodes, addUnitCodesHandler);

export default unitCodesReducer;
