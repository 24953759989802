import { ResourceAvailableDatesState } from './types';
import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';

export const resourceAvailableDatesSelector = (state: RootState): ResourceAvailableDatesState =>
  state.resourceAvailableDates;

export const selectResourceAvailableDates = createSelector(
  [resourceAvailableDatesSelector],
  resource => resource.available_dates,
);

export const selectResourceAvailableDatesStatus = createSelector(
  [resourceAvailableDatesSelector],
  resource => resource.getResourceAvailableDates.status,
);

export const selectResourceAvailableDatesError = createSelector(
  [resourceAvailableDatesSelector],
  resource => resource.getResourceAvailableDates.error,
);

export const selectResourceInfiniteAvailableDates = createSelector(
  [resourceAvailableDatesSelector],
  resource => resource.infiniteAvailableDates,
);

export const selectResourceInfiniteAvailableDatesStatus = createSelector(
  [resourceAvailableDatesSelector],
  resource => resource.getResourceInfiniteAvailableDates.status,
);
