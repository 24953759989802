import React from 'react';
import { ResourceTile, ResourceTitle, ResourceSubtitle, FlexContainer } from '../styles';

interface ResourceProps {
  isDesktop?: boolean;
}

export const Resource = ({ isDesktop }: ResourceProps): JSX.Element => {
  return (
    <FlexContainer gap={19} isWidth flex={1} data-testid="compact-ghost-tile">
      <ResourceTile isDesktop={isDesktop} />
      <FlexContainer gap={12} isColumnDirection>
        <ResourceTitle isDesktop={isDesktop} />
        <ResourceSubtitle isDesktop={isDesktop} />
      </FlexContainer>
    </FlexContainer>
  );
};
