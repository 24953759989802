import { DropdownItem } from '../dropdown-item/dropdown-item';
import { DropdownOption } from '../types';
import React from 'react';
import { StyledPaper } from './styles';
import { useDropDownOptions } from './use-dropdown-options.hook';

interface Props {
  options: Array<DropdownOption>;
  value?: string;
  onSelect: (item: DropdownOption) => void;
  dropRef?: React.MutableRefObject<HTMLDivElement>;
  defaultValueIndex: number;
}

export const DropdownOptions: React.FC<Props> = ({ options, onSelect, value, dropRef, defaultValueIndex }: Props) => {
  const dropProps = {
    depth: 1,
    borderRadius: 'sm',
    'data-testid': 'resource-booking-dropdown-options',
    'data-cy': 'resource-booking-dropdown-options',
    ref: dropRef,
  } as const;

  useDropDownOptions({ value, options, dropRef, defaultValueIndex });

  return (
    <StyledPaper {...dropProps}>
      {options.map((option: DropdownOption, optionPosition: number) => {
        const isActiveOption = value ? option.label === value : option.label === options[defaultValueIndex]?.label;
        const isLastItem = options.length - 1 === optionPosition;
        return (
          <DropdownItem
            active={isActiveOption}
            item={option}
            key={option.value.toTimeString() || option.label}
            lastItem={isLastItem}
            onSelect={onSelect}
          />
        );
      })}
    </StyledPaper>
  );
};
