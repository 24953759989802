import { DurationFilterContainer, DurationFilterTitle } from './styles';

import { DurationComponent } from 'components/duration-component';
import React from 'react';
import { durations } from 'shared/consts/duration';
import { useDurationFilter } from './use-duration-filter.hook';
import { useIntl } from 'react-intl';

export const DurationFilter = () => {
  const { formatMessage } = useIntl();
  const { selectedDuration, onDurationChipClickHandler, disabledDurations } = useDurationFilter();

  return (
    <DurationFilterContainer>
      <DurationFilterTitle isDisabled={disabledDurations.length === durations.length}>
        {formatMessage({ id: 'durationWithinTimeRange' })}
      </DurationFilterTitle>
      <DurationComponent
        disabledDurations={disabledDurations}
        durations={durations}
        selectedDuration={selectedDuration}
        onClickHandler={onDurationChipClickHandler}
      />
    </DurationFilterContainer>
  );
};
