import styled from 'styled-components';
import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';

interface ButtonProps {
  isSkipButton?: boolean;
}

export const ButtonsWrapper = styled.div<{ setWrapperWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ setWrapperWidth }) => setWrapperWidth && 'width: 100%;'}
}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    display: flex;
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledButton = styled(Button)<ButtonProps>`
  height: 36px;
  border-radius: 4px;
  font-size: 16px;
  width: 110px;
  padding: 16px;
  color: ${({ isSkipButton, theme }) => isSkipButton && theme.colors.admin.greenAlt2};
  text-decoration: ${({ isSkipButton }) => isSkipButton && 'underline'};
  align-self: flex-end;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    height: 48px;
    width: 100%;
  }
`;
