import { PriceState } from './types';
import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';

export const priceSelector = (state: RootState): PriceState => state.price;

export const selectPrice = createSelector([priceSelector], cart => cart.price);

export const selectPriceStatus = createSelector([priceSelector], cart => cart.getPrice.status);

export const selectPriceError = createSelector([priceSelector], cart => cart.getPrice.error);
