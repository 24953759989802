import {
  Background,
  Container,
  ModalButtonContainer,
  ModalContentContainer,
  ModalTitle,
  ModalTitleWrapper,
  ShowResultsButton,
} from './styles';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { CapacityFilter } from '../capacity-filter';
import { DateFilter } from '../date-filter';
import { DurationFilter } from '../duration-filter';
import { TimeFilter } from '../time-filter';
import { TypesFilter } from '../types-filter';
import { useClearFilters } from './useClearFilter.hook';
import { useFilterModal } from 'hooks/use-filter-modal.hook';
import { useIntl } from 'react-intl';
import { StyledClearButton } from 'pages/date-time-select-page/styles';
import { PresetWindowToggle } from 'components/presetWindowToggle';
import { FloorFilter } from 'pages/filter-modal/floor-filter';
import useHasFloorPlan from 'floorplan/pages/floorplan/hooks/use-has-floor-plan';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';

interface FilterModalContentProps {
  toggleScrollableContent?: VoidFunction;
  onCTAClick?: VoidFunction;
}

export const FilterModalContent = forwardRef(function FilterModalContentComponent(
  { toggleScrollableContent, onCTAClick }: FilterModalContentProps,
  ref,
): JSX.Element {
  const {
    isOverlayVisible,
    toggleOverlay,
    setContentHeight,
    contentHeight,
    isLoadingResources,
    isMobileDevice,
    isActive,
    isPresetWindowFilterVisible,
  } = useFilterModal();
  const hasFloorPlan = useHasFloorPlan();

  const clearAllFilters = useClearFilters();

  const buildingGroupAndFloorRef = useRef(null);
  const contentRef = useRef(null);
  const dateRef = useRef(null);
  const intl = useIntl();
  const { isMapViewEnabled } = useAppInstanceConfigFeature();

  const onClearAll = useCallback(() => {
    clearAllFilters();
    buildingGroupAndFloorRef?.current?.onResetAll();
  }, [clearAllFilters]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = isMapViewEnabled && isMobileDevice ? 'hidden' : 'initial';
    };
  }, [isMapViewEnabled, isMobileDevice]);

  useImperativeHandle(ref, () => ({
    onClearAll,
  }));

  useEffect(() => {
    if (contentRef) {
      const childHeight = contentRef.current && contentRef.current.scrollHeight;
      setContentHeight(childHeight);
    }
  }, [contentRef, setContentHeight]);

  const toggleOverlayAndScrollable = useCallback(() => {
    toggleScrollableContent();
    toggleOverlay();
  }, [toggleOverlay, toggleScrollableContent]);

  return (
    <Container ref={contentRef}>
      {isOverlayVisible && isMobileDevice && <Background data-testid="overlay-filter-content" height={contentHeight} />}
      {!isMobileDevice && (
        <ModalTitleWrapper>
          <ModalTitle>{intl.formatMessage({ id: 'filterBy' })}</ModalTitle>
          <StyledClearButton
            isActive={isActive}
            size="xxs"
            text={intl.formatMessage({ id: 'clearAll' })}
            variant="closeButton"
            onClick={onClearAll}
            multiline={false}
            disabled={!isActive}
          />
        </ModalTitleWrapper>
      )}
      <ModalContentContainer>
        <TypesFilter />
        <DateFilter toggleOverlay={toggleOverlayAndScrollable} dateRef={dateRef} />
        <TimeFilter toggleOverlay={toggleOverlay} />
        {isPresetWindowFilterVisible && <PresetWindowToggle />}
        {hasFloorPlan && <FloorFilter ref={buildingGroupAndFloorRef} isMobileDevice={isMobileDevice} />}
        <DurationFilter />
        <CapacityFilter />
      </ModalContentContainer>
      {!isMobileDevice && (
        <ModalButtonContainer>
          <ShowResultsButton
            data-testid="show-results-button"
            text={intl.formatMessage({ id: 'showResults' })}
            loading={isLoadingResources}
            disabled={isLoadingResources}
            onClick={onCTAClick}
            multiline={false}
          />
        </ModalButtonContainer>
      )}
    </Container>
  );
});
