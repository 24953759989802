import { AppInstanceConfigsState, AppInstanceConfigWithLocations } from 'store/app-instance-configs/types';
import { ResourceBookingEnum } from 'store/app-instance-configs/enums';

export const getSelectedAppInstanceConfigs = (
  appInstanceConfigsState: AppInstanceConfigsState,
): Array<AppInstanceConfigWithLocations> => {
  const {
    selectedAppInstanceConfigsLocations,
    app_instance_configs: appInstanceConfigs,
    currentAppInstanceConfigUuid,
  } = appInstanceConfigsState;
  const currentAppInstanceConfig = appInstanceConfigs?.find(config => config.uuid === currentAppInstanceConfigUuid);
  const verticalAdapterName = currentAppInstanceConfig?.vertical_adapter?.name;
  const isNativeAdapter = verticalAdapterName === ResourceBookingEnum.NativeAdapter;
  const isEssensysAdapter = verticalAdapterName === ResourceBookingEnum.EssensysAdapter;

  return isEssensysAdapter || isNativeAdapter ? selectedAppInstanceConfigsLocations : [];
};
