import { FailureActionPayload } from 'store/errors/types';
import { OwnerParams } from 'shared/types';
import { UiMetadata } from './types';
import { createAsyncAction } from 'typesafe-actions';

export const getUiMetadata = createAsyncAction(
  'ui-metadata/FETCH_REQUEST',
  'ui-metadata/FETCH_SUCCESS',
  'ui-metadata/FETCH_FAILURE',
)<OwnerParams, UiMetadata, FailureActionPayload>();
