import { StyledMobileModal } from './modal.styles';

import React from 'react';

interface MobileModalProps {
  children: React.ReactNode;
}

export const MobileModal = ({ children }: MobileModalProps): JSX.Element => {
  return (
    <StyledMobileModal data-testid="mobile-modal-window" data-cy="modal-window">
      {children}
    </StyledMobileModal>
  );
};
