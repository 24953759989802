import { useBooleanState } from '@hqo/react-components-library/dist/hooks';
import { push } from 'store/router/actions';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ACTION_STATUSES, PAYMENT_MINIAPP_POST_MESSAGES } from 'shared/consts';
import { resetCreateCartStatus } from 'store/cart/actions';
import { selectCreateCartStatus } from 'store/cart/selectors';
import { resourceState } from 'store/resource/selectors';
import { selectGetPaymentMethodsStatus } from 'store/payment/selectors';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';

interface UsePaymentModalReturnValues {
  reverseAnimation: boolean;
  showPaymentModal: boolean;
}

export const usePaymentModal = (openQuickCheckoutHandler?: VoidFunction): UsePaymentModalReturnValues => {
  const { value: reverseAnimation, toggle: toggleReverseAnimation } = useBooleanState(false);
  const { value: showPaymentModal, toggle: togglePaymentModal } = useBooleanState(false);
  const dispatch = useDispatch();
  const { isCompactUiEnabled } = useAppInstanceConfigFeature();
  const createCartStatus = useSelector(selectCreateCartStatus);
  const getPaymentMethodsStatus = useSelector(selectGetPaymentMethodsStatus);
  const { resource } = useSelector(resourceState);
  const { search, pathname } = useLocation();

  const hasAdditionalInfo = !!resource?.user_prompts?.length;

  const onMessage = useCallback(
    (event: MessageEvent): void => {
      if (event.data === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_CLOSE) {
        setTimeout(() => {
          togglePaymentModal();
        }, 400);
      }
    },
    [togglePaymentModal, toggleReverseAnimation],
  );

  useEffect(() => {
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  useEffect(() => {
    if (createCartStatus === ACTION_STATUSES.FULFILLED && getPaymentMethodsStatus === ACTION_STATUSES.FULFILLED) {
      if (isCompactUiEnabled) {
        if (openQuickCheckoutHandler) {
          openQuickCheckoutHandler();
        } else if (pathname === '/') {
          dispatch(push(`payment${search}`));
        } else {
          dispatch(push(`${pathname}/payment${search}`));
        }
      }
      if (
        !pathname.includes('additional-information') &&
        !pathname.includes('add-ons') &&
        !hasAdditionalInfo &&
        !isCompactUiEnabled
      ) {
        if (openQuickCheckoutHandler) {
          openQuickCheckoutHandler();
        } else if (pathname === '/') {
          dispatch(push(`payment${search}`));
        } else {
          dispatch(push(`${pathname}/payment${search}`));
        }
      }
      if (pathname.includes('additional-information') && !isCompactUiEnabled) {
        if (openQuickCheckoutHandler) {
          openQuickCheckoutHandler();
        } else {
          dispatch(push(`${pathname}/payment${search}`));
        }
      } else if (pathname.includes('add-ons') && openQuickCheckoutHandler) {
        openQuickCheckoutHandler();
      } else if (!pathname.includes('additional-information') && hasAdditionalInfo) {
        return;
      }

      dispatch(resetCreateCartStatus());
    } else if (createCartStatus === ACTION_STATUSES.REJECTED) {
      dispatch(resetCreateCartStatus());
    }
  }, [
    createCartStatus,
    dispatch,
    togglePaymentModal,
    hasAdditionalInfo,
    pathname,
    isCompactUiEnabled,
    search,
    openQuickCheckoutHandler,
    getPaymentMethodsStatus,
  ]);

  return { reverseAnimation, showPaymentModal };
};
