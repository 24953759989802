import React, { useState, useEffect, useCallback, useRef } from 'react';
import { DivVisibilityContainer } from 'components/image/styles';
import ImagePlaceholder from 'assets/image-placeholder.png';
import { useImageLoading } from 'components/image/hooks/useImageLoading.hook';

interface Props {
  src?: string;
  alt?: string;
  skeleton?: React.ReactNode;
  isRoomLayoutImage?: boolean;
  setImageHeight?: React.Dispatch<React.SetStateAction<number>>;
}

export const Image = ({ src, alt, skeleton, isRoomLayoutImage, setImageHeight, ...props }: Props) => {
  const [imagePlaceholder, setImagePlaceholder] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onLoadComplete = useCallback(() => setIsLoading(false), [setIsLoading]);
  const ref = useRef<HTMLImageElement>(null);

  useImageLoading({ isLoading, setImageHeight, ref });

  const onError = useCallback(() => {
    setImagePlaceholder(ImagePlaceholder);
    setIsLoading(false);
  }, [setIsLoading]);

  useEffect(() => {
    setImagePlaceholder(!src ? ImagePlaceholder : null);
    if (skeleton) setIsLoading(true);
  }, [setIsLoading, skeleton]);

  if (skeleton) {
    return (
      <>
        {isLoading && skeleton}
        <DivVisibilityContainer isRoomLayoutImage={isRoomLayoutImage} hide={isLoading}>
          <img
            ref={ref}
            src={imagePlaceholder || src}
            alt={alt}
            onError={onError}
            onLoad={onLoadComplete}
            {...props}
            id="resource-image"
          />
        </DivVisibilityContainer>
      </>
    );
  }

  return (
    <img
      ref={ref}
      src={imagePlaceholder || src}
      alt={alt}
      onError={onError}
      onLoad={onLoadComplete}
      {...props}
      id="resource-image"
    />
  );
};
