import { formatCurrency } from './formatCurrency';
import { formatItemPrice } from './formatItemPrice';
import { IntlShape } from 'react-intl';

export const getPaymentDetailsValues = (
  price: number,
  total: number,
  subtotal: number,
  taxSummary: number,
  discountApplied: number,
  currencyType: string,
  buildingLocale: string,
  intl: IntlShape,
) => ({
  itemPrice: formatItemPrice(price, intl, currencyType, buildingLocale),
  subtotalValue: formatCurrency(subtotal, currencyType, buildingLocale),
  taxValue: formatCurrency(taxSummary, currencyType, buildingLocale),
  totalValue: formatCurrency(total, currencyType, buildingLocale),
  ...(discountApplied && { creditsValue: formatCurrency(discountApplied, currencyType, buildingLocale, true, true) }),
});
