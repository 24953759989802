import { ApiClient } from './api-client';
import { Building } from 'store/building/types';
import { ConfigState } from 'store/config/types';
import { AppInstanceConfigsState } from '../app-instance-configs/types';
import { handleNonISOLocales } from 'utils/handleNonISOLocales';

interface ApiClientState {
  config: ConfigState;
  building?: {
    building?: Building;
  };
  appInstanceConfigs: AppInstanceConfigsState;
}

export const apiClient = (
  state: ApiClientState,
  baseUrlOverride?: string | null,
  authTokenOverride?: string | null,
  buildingUuidOverride?: string | null,
  appBrandOverride?: string | null,
  appInstanceConfigUuidOverride?: string | null,
  locationIdOverride?: string | null,
  localeOverride?: string | null,
): ApiClient => {
  const lang = handleNonISOLocales(state.config?.locale, state.building?.building?.locale);

  const baseUrl = baseUrlOverride ?? state.config.apiUrl;
  const authToken = authTokenOverride ?? state.config.authToken;
  const buildingUuid = buildingUuidOverride ?? state.config.buildingUuid;
  const appBrand = appBrandOverride ?? state.config.appBrand;
  const appInstanceConfigUuid = appInstanceConfigUuidOverride ?? state.appInstanceConfigs.currentAppInstanceConfigUuid;
  const locationId = locationIdOverride ?? state.appInstanceConfigs.currentLocationId;
  const locale =
    localeOverride ??
    (lang || state.config?.locale || state.building?.building?.locale || navigator?.language || 'en-US');
  return new ApiClient(baseUrl, authToken, buildingUuid, appBrand, appInstanceConfigUuid, locationId, locale);
};
