/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';

export default function sentryBeforeSendFilter(event: Sentry.ErrorEvent, hint: Sentry.EventHint) {
  const exception = event.exception?.values?.[0];
  const originalException = hint.originalException as Error;

  const isAxiosError =
    originalException && originalException instanceof Error && (originalException as AxiosError).isAxiosError;
  const isRequestError =
    (exception && exception.type === 'Error' && /HTTP Client Error/gm.test(exception.value) && event.request) ||
    isAxiosError;
  let requestUrl;

  if (isRequestError) {
    if (isAxiosError) {
      requestUrl = (originalException as AxiosError)?.config?.url;
    } else {
      requestUrl = event.request?.url;
    }
  }

  // Ignore 401 errors
  if (isAxiosError && (originalException as AxiosError)?.response?.status === 401) {
    console.log('Sentry ignore error because its a 401 request error', event, hint);
    return null;
  }

  // Ignore errors that are warnings
  if (event.message && /^warning/gim.test(event.message)) {
    console.log('Sentry ignore error because its a warning', event, hint);
    return null;
  }

  // For request error if possible we rewrite the error message
  if (isRequestError) {
    try {
      const urlObject = new URL(requestUrl);
      const requestPath = urlObject?.pathname || requestUrl;
      const requestMethod = event.request.method || '?';
      const requestStatus = event.message.substring(event.message.length - 3) || '?';
      const newMessage = `API Error (${requestStatus}): ${requestMethod} ${requestPath}`;

      if (!event.extra) {
        event.extra = {};
      }

      event.extra.requestUrl = requestUrl;
      event.extra.originalMessage = event.message;
      event.extra.arguments = urlObject?.searchParams;
      event.message = newMessage;
    } catch (e) {
      // ignore
    }
  }

  // Remove email from user data
  if (event.user) {
    // eslint-disable-next-line no-param-reassign
    delete event.user.email;
  }

  // On development environment we log the error to the console for easier debugging
  if (process.env.NODE_ENV === 'development') {
    console.info('Sending Error to Sentry', event, hint);
  }

  return event;
}
