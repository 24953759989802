import { DEFAULT_OWNER_TYPE } from 'shared/consts';
import { OwnerParams } from 'shared/types';
import { parseQueryValue } from 'shared/utils/parse-query-value';
import { useSearchParams } from './use-search-params.hook';
import { OwnerTypesEnum } from 'store/app-instance-configs/enums';

export const useOwnerParams = (): OwnerParams => {
  const { ownerType, ownerUuid, buildingUuid, locationId } = useSearchParams();

  if (ownerType && ownerUuid) {
    return {
      ownerType: parseQueryValue(ownerType).toLocaleLowerCase() as OwnerTypesEnum,
      ownerUuid: parseQueryValue(ownerUuid),
    };
  }

  return {
    ownerType: DEFAULT_OWNER_TYPE,
    ownerUuid: parseQueryValue(ownerUuid ?? buildingUuid ?? locationId),
  };
};
