import { useCallback, useState } from 'react';
import { TermsAndConditionsSteps, UseStepsNavigationReturnValues } from '../types';

export const useStepsNavigation = (): UseStepsNavigationReturnValues => {
  const [step, setStep] = useState<string>(TermsAndConditionsSteps.QUICK_APPLY_STEP);

  const handleChangeStep = useCallback(() => {
    setStep(
      step === TermsAndConditionsSteps.QUICK_APPLY_STEP
        ? TermsAndConditionsSteps.TERMS_AND_CONDITIONS_TEXT_STEP
        : TermsAndConditionsSteps.QUICK_APPLY_STEP,
    );
  }, [step]);

  return { step, handleChangeStep };
};
