import styled from 'styled-components';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';

export const DateTimeFilterWrapper = styled.div<{
  isKioskMode: boolean;
}>`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  ${({ isKioskMode }) =>
    !isKioskMode
      ? `
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 5px;
      `
      : `
  justify-content: center;
      `};

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    margin-bottom: 13px;
  }
`;

export const DateTimeFilterContainer = styled.div<{
  areDateTimeLinksVisible: boolean;
}>`
  display: flex;
  width: 100%;
  justify-content: ${({ areDateTimeLinksVisible }) => (areDateTimeLinksVisible ? 'flex-start' : 'center')};
  align-items: flex-start;
  flex-direction: column-reverse;
  align-self: flex-start;
  user-select: none;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    justify-content: ${({ areDateTimeLinksVisible }) => (areDateTimeLinksVisible ? 'space-between' : 'flex-end')};
    align-items: center;
    flex-direction: row;
  }
`;
