import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@hqo/react-components-library/dist/atoms/button';

import { ButtonsWrapper, Content, Root, Title } from './styles';
import { BookingCreationError } from 'store/booking-flow/types';

type ErrorModalProps = {
  onClose: VoidFunction;
  bookingCreationError: BookingCreationError;
} & React.ButtonHTMLAttributes<HTMLDivElement>;

const ErrorModal = forwardRef<HTMLDivElement, ErrorModalProps>(function ErrorModalComponent(
  { onClose, bookingCreationError, ...props },
  ref,
) {
  const intl = useIntl();

  return (
    <Root data-testid="booking-error-modal">
      <Title>{intl.formatMessage({ id: 'booking_error_modal_title' })}</Title>
      <Content ref={ref} {...props}>
        {intl.formatMessage({ id: bookingCreationError })}
      </Content>
      <ButtonsWrapper>
        <Button text={intl.formatMessage({ id: 'close' })} onClick={onClose} size="m" variant="Primary" />
      </ButtonsWrapper>
    </Root>
  );
});

export default ErrorModal;
