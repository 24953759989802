import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const AvailableNowContainer = styled.div<{ isEmpty: boolean }>`
  margin: 0 -16px;
  ${({ isEmpty }) =>
    isEmpty &&
    `
  `}
  .carousel-list {
    gap: 26px;
    padding: 13px 0 13px 13px;
    cursor: default;

    &::after {
      content: '';
      flex: 0 0 1px;
    }

    & > div:last-child {
      margin-right: -13px;
    }
  }

  .carousel-title {
    margin-left: 16px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const Title = styled.div`
  margin-left: 16px;
  font: 700 16px/19px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const Placeholder = styled.div`
  font: 500 16px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};
`;

export const NothingAvailableNowContainer = styled.div`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

export const CalendarIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};
`;
