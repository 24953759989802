import { QUERY_PARAMS, TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';
import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router';

import { bookingTimeHandler } from 'utils/bookingTimeHandler';
import { getFormattedDate } from 'utils/formatDate';
import { pick } from 'utils/pickObjectProperty';
import { goBack, push, replace } from 'store/router/actions';
import qs from 'qs';
import { resetResourcesState } from 'store/resources/actions';
import { searchParams } from 'pages/date-select-page/interface';
import { track } from '@hqo/web-tracking';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks';
import { useDispatch } from 'react-redux';
import { useSearchParams } from './use-search-params.hook';
import { useAppInstanceConfigFeature } from './use-app-instance-config-feature.hook';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport/';

interface UseTouchSearchBarReturnValues {
  reverseAnimation: boolean;
  isDateStep: boolean;
  onPressBack: VoidFunction;
  onDateClick: VoidFunction;
  onNextClick: VoidFunction;
  onTimeClick: VoidFunction;
  onSearchClick: VoidFunction;
  onSkipTimeClick: VoidFunction;
  onClose: VoidFunction;
  backToDateStep: boolean;
  reverseHorizontalAnimation: boolean;
  isModalWithoutSlideAnimation: boolean;
}

export enum DateTimeSteps {
  DateSelectPage,
  TimeSelectPage,
}

export const useTouchSearchBar = (): UseTouchSearchBarReturnValues => {
  const { search, pathname } = useLocation();
  const queryParams = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]);
  const dispatch = useDispatch();
  const isMobileDevice = useIsSmallViewportWidth();
  const { startDate, startTime, endTime, startDates, ...restQueryParams } = useSearchParams<searchParams>();
  const { value: reverseAnimation, toggle: toggleReverseAnimation } = useBooleanState(true);
  const { value: reverseHorizontalAnimation, toggle: toggleReverseHorizontalAnimation } = useBooleanState(true);
  const { value: isModalWithoutSlideAnimation, toggle: toggleModalSlideAnimation } = useBooleanState(false);
  const { value: backToDateStep, toggle: toggleBackToDateStep } = useBooleanState(false);
  const [step, setStep] = useState<DateTimeSteps>(DateTimeSteps.DateSelectPage);
  const isDateStep = step === DateTimeSteps.DateSelectPage;
  const defaultStartDate = startDate || getFormattedDate(new Date(), 'YYYY-MM-DD', 'en-US');
  const { isMapViewEnabled } = useAppInstanceConfigFeature();
  const isResourcesContentWithMapView = !isMobileDevice && isMapViewEnabled && pathname === '/date-time-select';

  const shouldClearPathname = useMemo(
    () =>
      isResourcesContentWithMapView ||
      pathname.includes('/resource-booking/resources') ||
      pathname.includes('/floorplan'),
    [isResourcesContentWithMapView, pathname],
  );

  const pathnameForReplaceAction = useMemo(
    () => (isResourcesContentWithMapView ? 'date-time-select' : '/date-time-select'),
    [isResourcesContentWithMapView],
  );

  const toggleTimeModal = useCallback(() => {
    if (backToDateStep) {
      toggleBackToDateStep();
    }
    if (isModalWithoutSlideAnimation) {
      toggleModalSlideAnimation();
    }
    toggleReverseAnimation();
    dispatch(goBack());
  }, [
    backToDateStep,
    isModalWithoutSlideAnimation,
    toggleReverseAnimation,
    dispatch,
    toggleBackToDateStep,
    toggleModalSlideAnimation,
  ]);

  const onSkipTimeClick = useCallback(() => {
    toggleReverseAnimation();
    if (!reverseHorizontalAnimation) {
      toggleReverseHorizontalAnimation();
    }
    if (backToDateStep) {
      toggleBackToDateStep();
    }
    if (isModalWithoutSlideAnimation) {
      toggleModalSlideAnimation();
    }
    const startDatesParams = startDate ? { startDate } : { startDates };
    const params: { [x: string]: string } =
      startDate || startDates ? { ...restQueryParams, ...startDatesParams } : { ...restQueryParams };
    const queryString = qs.stringify(
      pick(
        params,
        QUERY_PARAMS.filter(param => param !== 'duration' && param !== 'isExact'),
      ),
    );
    if (shouldClearPathname) {
      dispatch(replace(`${pathname.replace(pathnameForReplaceAction, '')}?${queryString}`));
    } else {
      dispatch(replace(`resource-booking/resources?${queryString}`));
      dispatch(resetResourcesState());
    }
  }, [
    pathnameForReplaceAction,
    toggleReverseAnimation,
    reverseHorizontalAnimation,
    backToDateStep,
    isModalWithoutSlideAnimation,
    startDate,
    startDates,
    restQueryParams,
    shouldClearPathname,
    toggleReverseHorizontalAnimation,
    toggleBackToDateStep,
    toggleModalSlideAnimation,
    dispatch,
    pathname,
  ]);

  const toggleDateModal = useCallback(() => {
    if (isModalWithoutSlideAnimation) {
      toggleModalSlideAnimation();
    }
    toggleReverseAnimation();
    dispatch(goBack());
  }, [isModalWithoutSlideAnimation, toggleModalSlideAnimation, toggleReverseAnimation, dispatch]);

  const onClose = isDateStep ? toggleDateModal : toggleTimeModal;

  const onPressBack = useCallback(() => {
    toggleBackToDateStep();
    toggleReverseHorizontalAnimation();
    if (isDateStep) {
      toggleReverseAnimation();
      dispatch(goBack());
    }
    setStep(DateTimeSteps.DateSelectPage);
  }, [dispatch, isDateStep, toggleBackToDateStep, toggleReverseAnimation, toggleReverseHorizontalAnimation]);

  const onDateClick = useCallback(() => {
    track(TRACK_EVENT_NAMES.DATE_TIME_LANDING_CLICK, { type: TRACK_EVENT_TYPES.ACTION }, { sendToHqoTracking: true });
    toggleReverseAnimation();
    toggleModalSlideAnimation();
    setStep(DateTimeSteps.DateSelectPage);
    if (pathname === '/') {
      dispatch(push(`date-time-select${search}`));
    } else if (!pathname.includes('date-time-select')) {
      dispatch(push(`${pathname}/date-time-select${search}`));
    }
  }, [toggleReverseAnimation, toggleModalSlideAnimation, dispatch, pathname, search]);
  const [isDefaultStartDateBeingUsed, setIsDefaultStartDateBeingUsed] = useState(false);

  const onTimeClick = useCallback(() => {
    toggleReverseAnimation();
    toggleModalSlideAnimation();
    setIsDefaultStartDateBeingUsed(!startDate);
    const params = {
      ...restQueryParams,
      ...(startDates ? { startDates } : { startDate: defaultStartDate }),
      startTime,
      endTime,
    };
    const queryString = qs.stringify(params);
    setStep(DateTimeSteps.TimeSelectPage);
    if (pathname === '/') {
      dispatch(push(`date-time-select?${queryString}`));
    } else if (!pathname.includes('date-time-select')) {
      dispatch(push(`${pathname}/date-time-select?${queryString}`));
    }
  }, [
    toggleReverseAnimation,
    toggleModalSlideAnimation,
    restQueryParams,
    defaultStartDate,
    startTime,
    startDate,
    startDates,
    endTime,
    dispatch,
    pathname,
  ]);

  const onNextClick = useCallback(() => {
    if (isModalWithoutSlideAnimation) {
      toggleModalSlideAnimation();
    }
    toggleReverseHorizontalAnimation();
    const params = { ...restQueryParams, startDate, startDates, startTime, endTime };
    setIsDefaultStartDateBeingUsed(!startDate);
    const queryString = qs.stringify(params);
    setStep(DateTimeSteps.TimeSelectPage);
    toggleBackToDateStep();
    dispatch(replace(`${pathname}?${queryString}`));
  }, [
    startDates,
    isModalWithoutSlideAnimation,
    toggleReverseHorizontalAnimation,
    restQueryParams,
    startDate,
    startTime,
    endTime,
    toggleBackToDateStep,
    dispatch,
    pathname,
    toggleModalSlideAnimation,
  ]);

  const onSearchClick = useCallback(() => {
    toggleReverseAnimation();
    if (!reverseHorizontalAnimation) {
      toggleReverseHorizontalAnimation();
    }
    if (backToDateStep) {
      toggleBackToDateStep();
    }
    if (isModalWithoutSlideAnimation) {
      toggleModalSlideAnimation();
    }
    let queryString = search;
    if (endTime) {
      const diff = bookingTimeHandler({
        startTime: startTime.toString(),
        endTime: endTime.toString(),
      }).getTimeDiff();
      queryString = `?${qs.stringify({ ...queryParams, duration: diff, isExact: true })}`;
    }
    if (pathname.includes('/resource-booking/resources') || isResourcesContentWithMapView) {
      if (isDefaultStartDateBeingUsed && !startTime) {
        queryString = `?${qs.stringify(
          pick(
            queryParams,
            QUERY_PARAMS.filter(param => param !== 'startDate'),
          ),
        )}`;
      }
      dispatch(replace(`${pathname.replace(pathnameForReplaceAction, '')}${queryString}`));
    } else if (pathname.includes('floorplan')) {
      dispatch(replace(`${pathname.replace('/date-time-select', '')}${queryString}`));
    } else {
      dispatch(replace(`resource-booking/resources${queryString}`));
      dispatch(resetResourcesState());
    }
  }, [
    isResourcesContentWithMapView,
    pathnameForReplaceAction,
    backToDateStep,
    dispatch,
    endTime,
    isModalWithoutSlideAnimation,
    queryParams,
    reverseHorizontalAnimation,
    search,
    startTime,
    toggleBackToDateStep,
    toggleModalSlideAnimation,
    toggleReverseAnimation,
    toggleReverseHorizontalAnimation,
    pathname,
    isDefaultStartDateBeingUsed,
  ]);

  return {
    isModalWithoutSlideAnimation,
    reverseHorizontalAnimation,
    reverseAnimation,
    onDateClick,
    onNextClick,
    onTimeClick,
    onSearchClick,
    onSkipTimeClick,
    isDateStep,
    onClose,
    onPressBack,
    backToDateStep,
  };
};
