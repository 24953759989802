import styled from 'styled-components';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';
import MarkdownText from 'shared/components/MarkdownText/MarkdownText';

export const ModalContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 1344px;
  height: 657px;
  transition: none;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    align-items: center;
    transition: height 1s;
    height: 100%;
    width: auto;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 32px;
  height: 100%;
  width: 100%;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding: 0;
  }
`;

export const Text = styled(MarkdownText)`
  font: 400 16px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.adminBlack};
`;

export const TextContainer = styled.div`
  overflow: auto;
  width: 100%;
`;

export const Title = styled.div`
  margin-bottom: 24px;
  font: 600 28px/33px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.adminBlack};
`;
