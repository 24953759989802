import { RootState } from 'store/reducer';
import { FeatureValue, Floor, FloorItem, FloorState } from './types';
import { createSelector } from 'reselect';

export const floorState = (state: RootState): FloorState => state.floor;

export const selectFloor = createSelector([floorState], state => state.selectedFloor);

export const selectFloors = createSelector([floorState], state => state.floors);

export const selectFloorItems = createSelector([floorState], (state): FloorItem[] =>
  state.floors.map((floor: Floor) => {
    return { name: floor.name, uuid: floor.uuid };
  }),
);

export const selectFloorBuildingGroupFeatures = createSelector([floorState], (state): FeatureValue[] => {
  const allBuildingGroups = state.floors
    .filter(floor => floor.features && floor.features.length > 0)
    .flatMap((floor: Floor) => {
      return floor.features.filter(feature => feature.code === 'BUILDING_GROUP');
    });
  return allBuildingGroups.reduce((uniqueFeatures, currentFeature) => {
    if (!uniqueFeatures.find(feature => feature.value === currentFeature.value)) {
      uniqueFeatures.push(currentFeature);
    }
    return uniqueFeatures;
  }, []);
});

export const selectGetFloorsStatus = createSelector([floorState], state => state.getFloors.status);

export const selectGetFloorsError = createSelector([floorState], state => state.getFloors.error);

export const selectFloorBuilding = createSelector([floorState], state => state.buildingUuid);

export const selectSpaces = createSelector([floorState], state => state.selectedFloor?.spaces);

export const selectGetSpacesStatus = createSelector([floorState], state => state.getSpaces.status);

export const selectGetSpacesError = createSelector([floorState], state => state.getSpaces.error);
