import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Container = styled.div`
  ${({ theme }) => `border-top: 1px solid ${theme.colors.greys.adminGreyLight};`}
`;

export const Header = styled.div`
  margin-top: 28px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.$primary};
`;

export const Title = styled.div`
  font: 700 16px/19px ${({ theme }) => theme.fonts.join()};
`;

export const Content = styled.div`
  height: auto;
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: ${({ theme }) => theme.colors.fontColor};
`;
