import styled from 'styled-components';

export const AddingVisitorsBlockContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  margin-bottom: 12px;
`;

export const Title = styled.div`
  font: 500 16px / normal ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.systemBlack};
`;

export const Subtitle = styled.div`
  font: 400 14px / normal ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.systemBlack};
  margin-bottom: 12px;
`;
