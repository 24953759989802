import { push } from 'store/router/actions';
import { useSearchParams } from 'hooks/use-search-params.hook';
import qs from 'qs';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { QUERY_PARAMS } from 'shared/consts';
import { pick } from 'utils/pickObjectProperty';
import { selectFloorItems } from 'store/spaceManager/selectors';
import { FloorItem } from 'store/spaceManager/types';

interface UseFloorPillProps {
  selectedFloor: FloorItem;
  onCancelClick: VoidFunction;
}

export const useFloorPill = (): UseFloorPillProps => {
  const floors: FloorItem[] = useSelector(selectFloorItems);
  const { filterByFloor } = useSearchParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const selectedFloor = useMemo<FloorItem>(
    () => floors.find(floor => floor.uuid === filterByFloor),
    [floors, filterByFloor],
  );

  const onCancelClick = useCallback(() => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    const queryString = qs.stringify(
      pick(
        queryParams,
        QUERY_PARAMS.filter(param => param !== 'filterByFloor'),
      ),
    );

    dispatch(push(`${location.pathname}?${queryString}`));
  }, [location]);

  return { selectedFloor, onCancelClick };
};
