import { Cancel, Confirmed } from '@hqo/react-components-library/dist/icons';
import { mediaMaxWidth } from '@hqo/react-components-library/dist/device';
import { PaymentDetails } from '@hqo/react-components-library/dist/payment/payment-details';
import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';
import styled, { css } from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PAYMENT_CONTENT_WIDTH } from 'shared/consts';

export const OverviewContainer = styled.div<{ isSwipeModalContent: boolean }>`
  display: grid;
  grid-gap: 18px;
  padding: ${({ isSwipeModalContent }) => (isSwipeModalContent ? 0 : '0 16px')};
`;

export const Subtitle = styled.div`
  font: 400 16px/19.2px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const TitleContainer = styled.div`
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    margin-top: 25px;
  }
`;

export const PaymentMethodContainer = styled.div`
  @media only screen and (min-width: ${DIMENSIONS.LARGE}) {
    padding: 0 12px;
  }
`;

export const SummaryContainer = styled.div`
  display: grid;
  grid-gap: 18px;
  box-sizing: border-box;
  padding-bottom: 130px;
  overflow: hidden;
`;

export const PaymentMethodTitle = styled.div`
  font: 500 16px/24px ${({ theme }) => theme.fonts.join()};
  margin-bottom: 23px;
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const CustomIconConfirmed = styled(Confirmed)<{ isCancelledIcon: boolean }>`
  display: ${({ isCancelledIcon }) => (isCancelledIcon ? 'none' : 'flex')};
  color: #04623e;
`;

export const CustomIconCancel = styled(Cancel)<{ isCancelledIcon: boolean }>`
  display: ${({ isCancelledIcon }) => (isCancelledIcon ? 'flex' : 'none')};
  color: ${({ theme }) => theme.colors.admin.pink};
`;

export const ServiceDetails = styled.div<{ isSwipeModalContent: boolean }>`
  margin: ${({ isSwipeModalContent }) => (isSwipeModalContent ? 0 : '0 16px')};

  button[data-testid='icon-block-action'] {
    color: ${({ theme }) => theme.colors.$primary};
  }

  .icon-block-link {
    color: ${({ theme }) => theme.colors.$primary};
  }

  @media only screen and (max-width: ${PAYMENT_CONTENT_WIDTH}) {
    ${({ theme }) => `border-top: 1px solid ${theme.colors.greys.sharedMediumGrey};`}
    padding-top: 16px;
  }
`;

export const IconBlockContainer = styled.div`
  margin: 16px 0 32px 0;
  display: grid;
  grid-gap: 23px;

  .icon-block-title {
    font-weight: bold;
  }

  & > div:last-child {
    margin-top: 12px;
  }
`;

export const StyledSummarySubtitle = styled.div`
  font: 700 20px/23px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const StatusTitleWrapper = styled.div<{ isCancelledIcon: boolean }>`
  flex-direction: row-reverse;
  gap: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  align-items: center;

  ${mediaMaxWidth(css`
    margin-bottom: 16px;
  `)}
`;

export const StyledStatusIcon = styled.div<{ isCancelledIcon: boolean }>`
  background-color: ${({ theme, isCancelledIcon }): string =>
    isCancelledIcon ? 'transparent' : theme.colors.admin.green};
  opacity: 0.2;
  border-radius: 50%;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const StatusTitle = styled.div`
  font: 700 24px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }): string => theme.colors.fontColor};
  margin-right: 8px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 6px;
`;

export const StyledPaymentDetails = styled(PaymentDetails)`
  margin-top: 0;

  @media only screen and (min-width: ${DIMENSIONS.LARGE}) {
    padding: 0 12px;
  }
`;

export const PaymentDetailsItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  margin: 2px 0 0 7px;
`;

export const CheckInContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  padding: 16px;
  margin: 32px 16px 16px 16px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.greys.adminGreyMedium};
  border-radius: 8px;
`;

export const CheckInTitle = styled.div`
  font: 500 20px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }): string => theme.colors.fontColor};
  text-transform: capitalize;
`;

export const CheckInDescription = styled.div`
  font: 400 14px/21px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
`;

export const CheckInButtonContainer = styled.div`
  display: flex;
  justify-self: end;
  width: 35%;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    justify-self: center;
    width: 100%;
  }
`;

export const CheckInButton = styled(Button)`
  width: 100%;
  height: 48px;
  padding: 0;
`;

export const CheckedInContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  font: 400 16px/22px ${({ theme }) => theme.fonts.join()};
  font-style: italic;
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};

  svg path {
    fill: ${({ theme }) => theme.colors.greys.adminGreyDark};
  }
`;

export const CheckedInIcon = styled(Confirmed)`
  margin-right: 4px;
`;

export const VisitorsCountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 8px;
`;

export const Title = styled.div`
  font: 500 14px / normal ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.darkGrey7};
`;

export const StyledVisitorsIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.palette.systemBlackAlt};
`;
