/* eslint-disable react/display-name */
import React, { memo } from 'react';
import { Wrapper } from './styles';
import { MapView } from './map-view';
import { useSelector } from 'react-redux';
import { selectResources } from 'store/resources/selectors';

export const MapViewContainer = memo((): JSX.Element => {
  const resources = useSelector(selectResources);
  if (!resources) {
    return null;
  }

  return (
    <Wrapper>
      <MapView />
    </Wrapper>
  );
});
