import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAppInstanceConfigs } from 'store/app-instance-configs/selectors';
import { useSearchParams } from './use-search-params.hook';
import { searchParams } from 'pages/date-select-page/interface';
import { updateCurrentLocationId } from 'store/app-instance-configs/actions';
import { selectBuilding } from 'store/building/selectors';

export const useDefaultLocationId = (): void => {
  const dispatch = useDispatch();
  const { locationID } = useSearchParams<searchParams>();
  const appInstancesConfigs = useSelector(selectAppInstanceConfigs);
  const building = useSelector(selectBuilding);

  useEffect(() => {
    if (locationID && !appInstancesConfigs.length && !building) {
      dispatch(updateCurrentLocationId({ currentLocationId: locationID }));
    }
  }, [locationID, dispatch, appInstancesConfigs, building]);
};
