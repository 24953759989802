import { useSelector } from 'react-redux';

import { userLoading } from 'store/user/selectors';

interface AppLoadingData {
  loading: boolean;
}

export const useAppLoading = (): AppLoadingData => {
  const loading = useSelector(userLoading);

  return { loading };
};
