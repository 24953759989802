import { Text, Title, DesktopContentContainer, ScrollTextContainer } from './styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectResource } from 'store/resource/selectors';
import { useIntl } from 'react-intl';

export const DesktopModalContent = (): JSX.Element => {
  const intl = useIntl();
  const resource = useSelector(selectResource);

  return (
    <DesktopContentContainer>
      <Title>{intl.formatMessage({ id: 'terms_and_conditions' })}</Title>
      <ScrollTextContainer>
        <Text>{resource.terms_and_conditions || ''}</Text>
      </ScrollTextContainer>
    </DesktopContentContainer>
  );
};
