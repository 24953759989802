import { StyledSwipeModal } from './styles';
import React from 'react';
import { ResourcesSwipeModalContent } from './components/resources-swipe-modal-content';

interface ResourcesSwipeModalProps {
  isLoadingState: boolean;
}

const MIN_POSITION_SWIPE_MODAL_FOR_MAP_VIEW = 130;
export const INITIAL_POSITION_SWIPE_MODAL_FOR_MAP_VIEW = 300;

export const ResourcesSwipeModal = ({ isLoadingState }: ResourcesSwipeModalProps): JSX.Element => {
  const positions = {
    initialPosition: INITIAL_POSITION_SWIPE_MODAL_FOR_MAP_VIEW,
    minPosition: MIN_POSITION_SWIPE_MODAL_FOR_MAP_VIEW,
  };

  return (
    <StyledSwipeModal
      data-testid="modal"
      reverseAnimation={false}
      className="modal"
      noContentWrapperPadding
      content={<ResourcesSwipeModalContent isLoadingState={isLoadingState} />}
      positions={positions}
      hasBackground={false}
      withoutLeftIcon
      withLeftExitButton={false}
    />
  );
};
