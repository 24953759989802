import React from 'react';
import { QuickTermsAndConditions } from './components/quick-terms-and-conditions';
import { useStepsNavigation } from './hooks/use-steps-navigation.hook';
import { TermsAndConditionsSteps } from './types';
import { TermsAndConditionsTextModal } from './components/terms-and-conditions-text-modal';

export const TermsAndConditions = (): JSX.Element => {
  const { step, handleChangeStep } = useStepsNavigation();

  return step === TermsAndConditionsSteps.QUICK_APPLY_STEP ? (
    <QuickTermsAndConditions handleChangeStep={handleChangeStep} />
  ) : (
    <TermsAndConditionsTextModal handleChangeStep={handleChangeStep} />
  );
};
