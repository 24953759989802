import { ACTION_STATUSES, CHECKOUT_PATH, PAYMENT_MINIAPP_POST_MESSAGES, TRANSACTION_STATES } from 'shared/consts';
import { Order, TransactionObject } from 'store/cart/types';
import {
  select3DSTransactionState,
  select3DSTransactionToken,
  selectCart,
  selectComplete3DSCartStatus,
  selectSubmitCartStatus,
  selectTriggerTransaction,
} from 'store/cart/selectors';
import { selectTransaction, selectTransactionStatus } from 'store/transactions/selectors';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dispatch } from 'redux';
import { complete3DSCart } from 'store/cart/actions';
import { replace } from 'store/router/actions';
import { statusUpdates3dSecureSpreedly } from 'store/add-card/actions';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/device';
import { useLocation } from 'react-router-dom';

export interface Use3DSModalProps {
  setPaymentError: (value: boolean) => void;
  transactionToken: string;
  spreedlyToken: string;
  isQuickCheckout?: boolean;
  currentOffsetHeight?: number;
}
export interface Use3DSModalInterface {
  isFingerprintPending: boolean;
  show3DSModal: boolean;
}

// eslint-disable-next-line max-lines-per-function
export const statusUpdates = (
  event: {
    action: string;
    token: string;
    finalize: (arg0: unknown) => void;
  },
  location: { search: string },
  dispatch: Dispatch,
  setShow3DSModal: React.Dispatch<React.SetStateAction<boolean>>,
  setPaymentError: (value: boolean) => void,
  setIsFingerprintPending: React.Dispatch<React.SetStateAction<boolean>>,
  cart: Order,
  pendingToken: string,
  pendingTransaction?: TransactionObject,
  isQuickCheckout?: boolean,
) => {
  if (cart.transaction.token === event.token) {
    if (event.action === 'succeeded') {
      dispatch(replace(`/receipt${location.search}`));
    } else if (event.action === 'error') {
      setShow3DSModal(false);
      setPaymentError(true);
    } else if (event.action === 'challenge') {
      if (isQuickCheckout) {
        document.getElementById('challenge-modal').classList.remove('hidden');
      }
    } else if (event.action === 'device-fingerprint') {
      document.getElementById('device-fingerprint').classList.remove('hidden');
      setIsFingerprintPending(true);
    } else if (event.action === 'trigger-completion') {
      setIsFingerprintPending(false);
      dispatch(complete3DSCart.request({ transaction_token: pendingToken }));

      if (pendingTransaction?.state === TRANSACTION_STATES.SUCCESS) {
        dispatch(replace(`/receipt${location.search}`));
      }
      if (pendingTransaction?.state === TRANSACTION_STATES.PENDING) {
        event.finalize(pendingTransaction);
      }
    }
  }
};

// eslint-disable-next-line max-lines-per-function
export const use3DSModal = ({
  setPaymentError,
  transactionToken,
  spreedlyToken,
  isQuickCheckout,
}: Use3DSModalProps): Use3DSModalInterface => {
  const dispatch = useDispatch();
  const location = useLocation();

  const pendingToken = useSelector(select3DSTransactionToken);
  const submitCartStatus = useSelector(selectSubmitCartStatus);
  const transactionState = useSelector(select3DSTransactionState);
  const complete3DSCartStatus = useSelector(selectComplete3DSCartStatus);
  const cart = useSelector(selectCart);
  const [isFingerprintPending, setIsFingerprintPending] = useState<boolean>(false);
  const [show3DSModal, setShow3DSModal] = useState<boolean>(false);
  const { showMiniappPaymentsNavigation } = useFlags();
  const transaction = useSelector(selectTransaction);
  const transactionStatus = useSelector(selectTransactionStatus);
  const isMobileDevice = useIsSmallViewportWidth();

  const pendingTransaction = useSelector(selectTriggerTransaction);

  const statusUpdatesCallback = useCallback(
    // eslint-disable-next-line max-lines-per-function
    (event: { action: string; token: string; finalize: (arg0: unknown) => void }) => {
      statusUpdates(
        event,
        location,
        dispatch,
        setShow3DSModal,
        setPaymentError,
        setIsFingerprintPending,
        cart,
        pendingToken,
        pendingTransaction,
        isQuickCheckout,
      );
    },
    [cart, dispatch, isQuickCheckout, location, pendingToken, pendingTransaction, setPaymentError],
  );

  const createLifecycle = useCallback(() => {
    const lifecycle = new window.Spreedly.ThreeDS.Lifecycle({
      environmentKey: spreedlyToken,
      hiddenIframeLocation: 'device-fingerprint',
      challengeIframeLocation: 'challenge',
      transactionToken,
      challengeIframeClasses: 'SpreedlyIFrameStyle',
    });
    lifecycle.start();
  }, [transactionToken, spreedlyToken]);

  useEffect(() => {
    if (submitCartStatus === ACTION_STATUSES.FULFILLED && transactionState === TRANSACTION_STATES.PENDING) {
      setShow3DSModal(true);
    }
    if (complete3DSCartStatus === ACTION_STATUSES.REJECTED) {
      setShow3DSModal(false);
    }
  }, [submitCartStatus, complete3DSCartStatus, transactionState, cart]);

  // eslint-disable-next-line max-lines-per-function
  useEffect(() => {
    if (submitCartStatus === ACTION_STATUSES.FULFILLED && transactionState === TRANSACTION_STATES.SUCCESS) {
      if (showMiniappPaymentsNavigation && transactionStatus === ACTION_STATUSES.FULFILLED && isQuickCheckout) {
        window.parent.postMessage(
          {
            message: `${PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_DONE_BUTTON_CLICK}`,
            transactionId: transaction?.details.id,
            transactionUuid: cart.transaction_uuid,
          },
          '*',
        );
      } else if (
        showMiniappPaymentsNavigation &&
        transactionStatus === ACTION_STATUSES.FULFILLED &&
        location.pathname === CHECKOUT_PATH
      ) {
        dispatch(replace(`/receipt${location.search}`));
      }
      if (!isMobileDevice || !showMiniappPaymentsNavigation) {
        dispatch(replace(`/receipt${location.search}`));
      }
    }
  }, [
    submitCartStatus,
    transactionState,
    dispatch,
    location.search,
    location.pathname,
    transactionToken,
    transactionStatus,
    cart,
  ]);

  useEffect(() => {
    if (submitCartStatus === ACTION_STATUSES.FULFILLED && transactionState === TRANSACTION_STATES.PENDING) {
      createLifecycle();
      dispatch(statusUpdates3dSecureSpreedly.request(statusUpdatesCallback));
    }
  }, [submitCartStatus, transactionState, createLifecycle, statusUpdatesCallback]);

  return { isFingerprintPending, show3DSModal };
};
