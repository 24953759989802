import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectCurrentLocationName } from 'store/app-instance-configs/selectors';
import useHasFloorPlan from 'floorplan/pages/floorplan/hooks/use-has-floor-plan';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { push } from 'store/router/actions';

interface UseBuildingInfoReturnValues {
  locationName: string;
  showFloorPlanLink: boolean;
  handleLinkClick: VoidFunction;
}

export const useBuildingInfo = (): UseBuildingInfoReturnValues => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const locationName = useSelector(selectCurrentLocationName);
  const hasFloorPlan = useHasFloorPlan();
  const { showFloorPlan } = useFlags();
  const showFloorPlanLink = hasFloorPlan && showFloorPlan;

  const handleLinkClick = useCallback(() => {
    dispatch(push(`/floorplan${search}`));
  }, [dispatch, search]);

  return { showFloorPlanLink, locationName, handleLinkClick };
};
