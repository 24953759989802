import { ACTION_STATUSES, BOOKING_DAYS_LIMIT, QUERY_PARAMS, TIME_SELECT_MODAL_STEP } from 'shared/consts';
import { CalendarDateInfo, LoadingState, searchParams, UseCalendarReturnValues } from '../interface';
import { replace } from 'store/router/actions';

import {
  selectResourceAvailableDates,
  selectResourceAvailableDatesStatus,
} from 'store/resource-available-dates/selectors';
/* eslint-disable no-nested-ternary */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OwnerTypesEnum } from 'store/app-instance-configs/enums';
import { bookingTimeHandler } from 'utils/bookingTimeHandler';
import { getResourceAvailableDates } from 'store/resource-available-dates/actions';
import moment from 'moment-timezone';
import { pick } from 'utils/pickObjectProperty';
import qs from 'qs';
import { selectGetResourceStatus, selectResource } from 'store/resource/selectors';
import { useLocale } from 'hooks/use-locale.hook';
import { useOwnerParams } from 'hooks/use-owner-params.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';
import { getCalendarViewDateParams } from 'utils/getCalendarViewDateParams';
import { getCalendarViewDateStatus } from 'utils/getCalendarViewDateStatus';
import { selectResourceTimeRanges, selectResourceTimeRangesStatus } from 'store/resource-time-ranges/selectors';
import { isSelectedTimeRangeAvailable } from './utils/isSelectedTimeRangeAvailable';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface DateTimeRange {
  firstDay: Date;
  lastDay: Date;
  timeRange: {
    startTime: string;
    endTime: string;
    locale: string;
  };
}

export const useCalendar = (
  indexOfMonth: number,
  setLoadingState: React.Dispatch<React.SetStateAction<LoadingState>>,
): UseCalendarReturnValues => {
  const { startDate, startDates, ...restQueryParams } = useSearchParams<searchParams>();
  const { ownerUuid } = useOwnerParams();
  const { id: resourceId, supports_multi_day_bookings: supportsMultiDayBookings } = useSelector(selectResource);
  const getResourceActionStatus = useSelector(selectGetResourceStatus);
  const resourceAvailableDates = useSelector(selectResourceAvailableDates);
  const resourceAvailableDatesStatus = useSelector(selectResourceAvailableDatesStatus);
  const resourceTimeRanges = useSelector(selectResourceTimeRanges);
  const resourceTimeRangesStatus = useSelector(selectResourceTimeRangesStatus);
  const locale = useLocale();
  const { isMultiDayBookingEnabled } = useAppInstanceConfigFeature();
  const { startTime, endTime, step } = restQueryParams || {};
  const formattedStartDates = startDates?.split(',') || [];
  const isCartOverloaded = formattedStartDates.length >= BOOKING_DAYS_LIMIT;
  const { showResourceDetailsDateTimeInputs } = useFlags();

  const timezone = moment.tz.guess();

  const month = moment().tz(timezone).clone().add(indexOfMonth, 'M');
  const { firstDay, lastDay, timeRange } = useMemo<DateTimeRange>(() => {
    const firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + indexOfMonth, 1);
    const lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1 + indexOfMonth, 0);
    const firstDayTime = firstDayOfMonth.toLocaleTimeString(locale, { hour: 'numeric', minute: 'numeric' });
    const lastDayTime = lastDayOfMonth.toLocaleTimeString(locale, { hour: 'numeric', minute: 'numeric' });
    return {
      firstDay: firstDayOfMonth,
      lastDay: lastDayOfMonth,
      timeRange: { startTime: firstDayTime, endTime: lastDayTime, locale },
    };
  }, [indexOfMonth, locale]);

  const formattedFirstDay = bookingTimeHandler(timeRange).getFormatDate(firstDay, 'YYYY-MM-DD').replace(/\./g, '');
  const formattedLastDay = bookingTimeHandler(timeRange).getFormatDate(lastDay, 'YYYY-MM-DD').replace(/\./g, '');

  const title = bookingTimeHandler(timeRange).getFormatDate(firstDay, 'MMMM YYYY');
  const dispatch = useDispatch();
  const availableDates = useSelector(selectResourceAvailableDates)[indexOfMonth.toString()];
  const [days, setDays] = useState<CalendarDateInfo[]>([]);
  const isTimeRangesAvailable = useMemo<boolean>(
    () => resourceTimeRanges?.length && resourceTimeRanges.some(resourceTimeRange => resourceTimeRange.available),
    [resourceTimeRanges],
  );

  const startDatesList = useMemo(() => {
    return startDates?.split(',') || [];
  }, [startDates]);

  useEffect(() => {
    if (
      !showResourceDetailsDateTimeInputs &&
      resourceTimeRangesStatus === ACTION_STATUSES.FULFILLED &&
      startDates &&
      startTime &&
      endTime &&
      !isSelectedTimeRangeAvailable(startTime, endTime, resourceTimeRanges) &&
      Number(step) !== TIME_SELECT_MODAL_STEP &&
      isTimeRangesAvailable
    ) {
      const queryString = qs.stringify({ ...restQueryParams, startDates, startTime: undefined, endTime: undefined });
      dispatch(replace(`${location.pathname}?${queryString}`));
    }
  }, [
    resourceTimeRangesStatus,
    resourceTimeRanges,
    startTime,
    endTime,
    startDates,
    restQueryParams,
    dispatch,
    step,
    isTimeRangesAvailable,
    showResourceDetailsDateTimeInputs,
  ]);

  useEffect(() => {
    const isReturnedFromSecondStep =
      Object.keys(resourceAvailableDates)?.length === 2 && resourceAvailableDatesStatus === ACTION_STATUSES.FULFILLED;
    if (
      !isReturnedFromSecondStep &&
      getResourceActionStatus !== ACTION_STATUSES.PENDING &&
      resourceId &&
      resourceAvailableDatesStatus !== ACTION_STATUSES.PENDING
    ) {
      dispatch(
        getResourceAvailableDates.request({
          ownerType: OwnerTypesEnum.BUILDING,
          ownerUuid,
          resourceId,
          from: indexOfMonth
            ? formattedFirstDay
            : bookingTimeHandler(timeRange).getFormatDate(new Date(), 'YYYY-MM-DD'),
          to: formattedLastDay,
          indexOfMonth,
        }),
      );
    }
  }, [dispatch, formattedFirstDay, formattedLastDay, indexOfMonth, resourceId, getResourceActionStatus]);

  useEffect(() => {
    if (availableDates) {
      const firstDayOfMonth = month.clone().startOf('month').startOf('week');
      const day = firstDayOfMonth.clone();
      const lastDayOfMonth = month.clone().endOf('month').endOf('week');
      const data: CalendarDateInfo[] = [];

      while (day.isSameOrBefore(lastDayOfMonth)) {
        data.push({
          date: +day.format('DD'),
          month: +day.format('MM'),
          year: +day.format('YYYY'),
          status: getCalendarViewDateStatus(day, availableDates, startDatesList, startDate, isCartOverloaded),
        });
        day.add(1, 'day');
      }
      const filterData = data.map(date => (date.month !== firstDay.getMonth() + 1 ? ({} as CalendarDateInfo) : date));
      setDays(filterData);
      setLoadingState(prevState => ({ ...prevState, [indexOfMonth]: false }));
    }
  }, [availableDates, startDate, startDates, isCartOverloaded]);

  const onDateClick = useCallback(
    (_: any, data: any) => {
      const date = `${data.year}/${data.month}/${data.date}`;
      const formattedDateValue = bookingTimeHandler(timeRange)
        .getFormatDate(new Date(date), 'YYYY-MM-DD')
        .replace(/\./g, '');
      const dateParams =
        isMultiDayBookingEnabled && supportsMultiDayBookings
          ? getCalendarViewDateParams(formattedDateValue, startDatesList, startDate)
          : { startDate: formattedDateValue };
      const queryParams = date ? { ...restQueryParams, ...dateParams } : restQueryParams;
      const queryString = qs.stringify(
        pick(
          queryParams,
          QUERY_PARAMS.filter(param => param !== 'duration' && param !== 'presetWindows'),
        ),
      );
      dispatch(replace(`${location.pathname}?${queryString}`));
    },
    [
      timeRange,
      isMultiDayBookingEnabled,
      supportsMultiDayBookings,
      startDate,
      startDatesList,
      restQueryParams,
      dispatch,
    ],
  );

  return {
    onDateClick,
    title,
    days,
  };
};
