export const INVOICE = 'INVOICE';
export const APPLE_PAY = 'APPLE_PAY';
export const CREDIT_CARD = 'credit_card';
export const CREDIT_POINTS = 'CREDIT_POINTS';
export const AFTER_PAYMENT = 'AFTER_PAYMENT';

export const AFTER_PAYMENT_PAYMENT_TYPE_ID = '-4';
export const CREDIT_PAYMENT_TYPE_ID = '-3';
export const INVOICE_PAYMENT_TYPE_ID = '-2';
export const APPLE_PAY_PAYMENT_TYPE_ID = '-1';
