import qs from 'qs';
import {
  searchParams,
  timeFormatOptions,
  Item,
  UseTimeSelectorOptions,
  useTimeSelectorDefaultOptions,
} from '../time-selector.interface';
import { replace } from 'store/router/actions';
import { useLocale } from 'hooks/use-locale.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { COMMA, QUERY_PARAMS, TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';
import { getClosestTimeSlot, getStartTimeArray } from 'utils';
import { pick } from 'utils/pickObjectProperty';
import { bookingTimeHandler } from 'utils/bookingTimeHandler';
import { MIDNIGHT_HOURS_24H } from 'components/schedule-select/const';
import { selectResourceTimeRanges } from 'store/resource-time-ranges/selectors';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocation } from 'react-router-dom';
import {
  getAvailableEndTimesFromStartTime,
  getAvailableStartTimes,
} from 'pages/resource/utils/getAvailableStartEndTimes';
import { formatStartEndTimeItemsArray } from 'pages/resource/utils/formatStartEndTimeItemsArray';
import { DEFAULT_DURATION, MILLISECONDS_IN_MIN } from 'shared/consts/time';
import { getDateObjectFromTimeString } from './utils/getDateObjectFromTimeString';
import { getFormattedTime } from 'components/time-selector/desktop/utils/getFormattedTime';
import { track } from '@hqo/web-tracking';
import { resolveSafariMidnight } from 'components/time-selector/hooks/utils/resolveSafariMidnight';
import { DropdownOption } from 'components/dropdown/types';
import { useTimeZone } from 'hooks/use-timezone.hook';
import { formatDate } from 'utils/formatDate';

interface TimeSelectorActionsProps {
  locale: string;
  startTime: string;
  endTime: string;
  restQueryParams: searchParams;
  setSelectedStartTime: React.Dispatch<React.SetStateAction<string>>;
  setSelectedEndTime: React.Dispatch<React.SetStateAction<string>>;
  isResourceDetailsDateTimeInputs: boolean;
  endTimeValues: Array<Item>;
}

const useDesktopTimeSelectorActions = ({
  locale,
  startTime,
  endTime,
  restQueryParams,
  setSelectedStartTime,
  setSelectedEndTime,
  isResourceDetailsDateTimeInputs,
  endTimeValues,
}: TimeSelectorActionsProps) => {
  const dispatch = useDispatch();
  const resourceTimeRanges = useSelector(selectResourceTimeRanges);
  const minDuration = resourceTimeRanges?.[0].minimum_duration;
  const availableTimeRanges = resourceTimeRanges?.filter(timeRange => timeRange.available);

  const onSelectStartTime = useCallback(
    (option: DropdownOption) => {
      setSelectedStartTime(option.label);
      const formattedTimeValue = option.value.toLocaleString(locale, timeFormatOptions) as string;
      const encodedStartTime = getDateObjectFromTimeString(formattedTimeValue);
      const encodedEndTime = getDateObjectFromTimeString(endTime);

      let selectedEndTime = endTime;
      let newSelectedEndTimeLabel = null;
      let selectedDuration = DEFAULT_DURATION;

      if (encodedEndTime && encodedStartTime) {
        selectedDuration = (encodedEndTime.getTime() - encodedStartTime.getTime()) / MILLISECONDS_IN_MIN;
      }

      if (encodedStartTime >= encodedEndTime || (minDuration && selectedDuration < minDuration)) {
        if (isResourceDetailsDateTimeInputs && availableTimeRanges?.length) {
          selectedEndTime = getAvailableEndTimesFromStartTime(availableTimeRanges, formattedTimeValue)?.[0];
        } else {
          const nextEndTime = endTimeValues.find(({ value }) => {
            const endTimeDate = new Date(value);

            return (
              endTimeDate >= encodedStartTime &&
              (endTimeDate.getTime() - encodedStartTime.getTime()) / MILLISECONDS_IN_MIN >=
                (minDuration || DEFAULT_DURATION)
            );
          });
          selectedEndTime = nextEndTime
            ? (nextEndTime.value.toLocaleString(locale, timeFormatOptions) as string)
            : null;
        }

        if (selectedEndTime) {
          const hourFormat = selectedEndTime.includes(MIDNIGHT_HOURS_24H) && { hour12: false };
          newSelectedEndTimeLabel = endTimeValues.find(
            ({ value }) =>
              (value.toLocaleString(locale, {
                ...timeFormatOptions,
                ...hourFormat,
              }) as string) === selectedEndTime,
          )?.label;
          setSelectedEndTime(newSelectedEndTimeLabel);
        } else {
          setSelectedEndTime(null);
        }
      }

      const queryParams = option.label
        ? { ...restQueryParams, startTime: formattedTimeValue, endTime: selectedEndTime }
        : restQueryParams;
      const queryString = qs.stringify(queryParams);

      dispatch(replace(`${location.pathname}?${queryString}`));
    },
    [
      setSelectedStartTime,
      locale,
      endTime,
      isResourceDetailsDateTimeInputs,
      minDuration,
      availableTimeRanges,
      restQueryParams,
      dispatch,
      setSelectedEndTime,
      endTimeValues,
    ],
  );

  const onSelectEndTime = useCallback(
    (option: DropdownOption) => {
      setSelectedEndTime(option.label);
      const hourFormat = option.value.getMinutes() === 0 && {
        hour12: false,
      };

      const resolvedEndTime = resolveSafariMidnight(option?.value, {
        locale,
        timeFormatOptions,
        hourFormat,
      });

      const diff = bookingTimeHandler({ startTime: startTime.toString(), endTime: resolvedEndTime }).getTimeDiff();

      const queryParams = option.label
        ? { ...restQueryParams, startTime, endTime: resolvedEndTime, isExact: true, duration: diff }
        : restQueryParams;
      const queryString = qs.stringify(queryParams);

      dispatch(replace(`${location.pathname}?${queryString}`));
    },
    [dispatch, locale, restQueryParams, setSelectedEndTime, startTime],
  );

  return { onSelectStartTime, onSelectEndTime };
};

/**
 * Hook for managing desktop time selector state and behavior.
 * @param options Configuration options for the time selector
 * @returns Object containing time selector state and handlers
 */
export const useDesktopTimeSelector = (options: UseTimeSelectorOptions = useTimeSelectorDefaultOptions) => {
  const locale = useLocale();
  const timezone = useTimeZone();
  const dispatch = useDispatch();

  const { showResourceDetailsDateTimeInputs } = useFlags();
  const { pathname } = useLocation();
  const isResourceDetailsPage = pathname.includes('/date-schedule-select') && !pathname.includes('/resources');
  const isResourceDetailsDateTimeInputs = showResourceDetailsDateTimeInputs && isResourceDetailsPage;

  const { startTime, endTime, ...restQueryParams } = useSearchParams<searchParams>();
  const { startDate, startDates } = restQueryParams || {};
  const timeRanges = useSelector(selectResourceTimeRanges);
  const selectedDate = startDate || startDates?.split(COMMA)?.[0];

  const startTimeValues = useMemo(() => {
    let times = [];
    if (isResourceDetailsDateTimeInputs && timeRanges?.length) {
      const startTimeLabels = getAvailableStartTimes(timeRanges);
      times = formatStartEndTimeItemsArray(startTimeLabels, selectedDate, locale);
    } else {
      times = getStartTimeArray(locale);
    }

    if (options.filterPastTimes) {
      const { eventDateYear, eventDateMonthNum, eventDateDay, formatEventStartTime } = formatDate(
        new Date().toISOString(),
        locale,
        timezone,
      );
      const now = new Date(`${eventDateYear}-${eventDateMonthNum}-${eventDateDay} ${formatEventStartTime}`);
      const [year, month, day] = selectedDate?.split('-') ?? [];
      const selectedDateObj = new Date(+year, +month - 1, +day);

      const isToday = selectedDateObj.toDateString() === now.toDateString();

      if (isToday) {
        return times.filter(({ value }) => value > now);
      }
    }

    return times;
  }, [isResourceDetailsDateTimeInputs, timeRanges, options.filterPastTimes, selectedDate, locale, timezone]);

  const [selectedStartTime, setSelectedStartTime] = useState<string>(null);
  const [selectedEndTime, setSelectedEndTime] = useState<string>(null);
  const [endTimeValues, setEndTimeValues] = useState<Array<Item>>(startTimeValues);

  useEffect(() => {
    if (!startTime && !endTime) {
      setSelectedStartTime(null);
      setSelectedEndTime(null);
    }
  }, [endTime, startTime]);

  useEffect(() => {
    if (endTime) {
      const hourFormat = endTime.includes(MIDNIGHT_HOURS_24H) && {
        hour12: false,
      };

      setSelectedEndTime(
        endTimeValues.find(
          ({ value }) =>
            (value.toLocaleString(locale, {
              ...timeFormatOptions,
              ...hourFormat,
            }) as string) === endTime,
        )?.label ?? getFormattedTime(endTime, locale),
      );
    }
  }, [locale, endTime, endTimeValues]);

  useEffect(() => {
    if (startTime) {
      setSelectedStartTime(
        startTimeValues.find(({ value }) => (value.toLocaleString(locale, timeFormatOptions) as string) === startTime)
          ?.label,
      );
    }
  }, [locale, startTime, startTimeValues]);

  useEffect(() => {
    if (!endTime) {
      return;
    }
    const encodedStartTime = new Date(startTimeValues.find(({ label }) => label === selectedStartTime)?.value) ?? null;
    const encodedEndTime = new Date(endTimeValues.find(({ label }) => label === selectedEndTime)?.value) ?? null;

    if (encodedStartTime >= encodedEndTime && endTime !== '24:00') {
      setSelectedEndTime(null);
      const queryParams = startTime ? { ...restQueryParams, startTime } : restQueryParams;
      const queryString = qs.stringify(
        pick(
          queryParams,
          QUERY_PARAMS.filter(param => param !== 'duration'),
        ),
      );

      dispatch(replace(`${location.pathname}?${queryString}`));
    }
  }, [
    dispatch,
    endTime,
    endTimeValues,
    restQueryParams,
    selectedEndTime,
    selectedStartTime,
    startTime,
    startTimeValues,
  ]);

  const getEndTimeValues = useCallback(() => {
    if (isResourceDetailsDateTimeInputs && timeRanges?.length) {
      const endTimeLabels = getAvailableEndTimesFromStartTime(timeRanges, startTime);

      return formatStartEndTimeItemsArray(endTimeLabels, selectedDate, locale);
    }

    const selectedStartDate = startTimeValues.find(({ label }) => label === selectedStartTime)?.value;
    const indexOfSlicedElement =
      selectedStartDate &&
      startTimeValues.findIndex(item => new Date(item.value).toISOString() > new Date(selectedStartDate).toISOString());

    return startTimeValues.slice(indexOfSlicedElement);
  }, [
    isResourceDetailsDateTimeInputs,
    timeRanges,
    startTimeValues,
    startTime,
    selectedDate,
    locale,
    selectedStartTime,
  ]);

  useEffect(() => {
    if (selectedStartTime) {
      setEndTimeValues(getEndTimeValues());
    }
  }, [getEndTimeValues, selectedStartTime, startTimeValues]);

  const closestTimeSlot = getClosestTimeSlot();
  const defaultValueIndex = startTimeValues.findIndex(({ value }) => value.toString() === closestTimeSlot.toString());

  const { onSelectStartTime, onSelectEndTime } = useDesktopTimeSelectorActions({
    locale,
    startTime,
    endTime,
    restQueryParams,
    setSelectedStartTime,
    setSelectedEndTime,
    isResourceDetailsDateTimeInputs,
    endTimeValues,
  });

  useEffect(() => {
    if (startTime && endTime) {
      const formattedStartTime = getFormattedTime(startTime, locale);
      const formattedEndTime = getFormattedTime(endTime, locale);

      setSelectedStartTime(formattedStartTime);
      setSelectedEndTime(formattedEndTime);
    }
  }, [endTime, locale, startTime]);

  const trackEvent = (trackName: string) => {
    track(
      trackName,
      {
        type: TRACK_EVENT_TYPES.ACTION,
      },
      { sendToHqoTracking: true },
    );
  };

  const startTimeClickTrackEvent = useCallback(() => {
    if (isResourceDetailsPage) {
      trackEvent(TRACK_EVENT_NAMES.RESOURCE_DETAIL_PAGE_START_TIME_CLICK);
    }
  }, [isResourceDetailsPage]);

  const endTimeClickTrackEvent = useCallback(() => {
    if (isResourceDetailsPage) {
      trackEvent(TRACK_EVENT_NAMES.RESOURCE_DETAIL_PAGE_END_TIME_CLICK);
    }
  }, [isResourceDetailsPage]);

  return {
    startTimeClickTrackEvent,
    endTimeClickTrackEvent,
    onSelectStartTime,
    onSelectEndTime,
    selectedStartTime,
    defaultValueIndex,
    endTimeValues,
    selectedEndTime,
    startTimeValues,
  };
};
