import { LabelBlock } from '@hqo/react-components-library/dist/molecules/label-block';
import { SMALL_DEVICE_MAX_WIDTH } from 'shared/consts';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledLabelBlock = styled(LabelBlock)`
  width: 100%;
  @media (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    width: 100%;
  }
  .capacity {
    overflow-y: hidden;
  }

  .capacity-text {
    -webkit-line-clamp: 3;
    display: -webkit-inline-box;
    -webkit-box-orient: vertical;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.greys.adminBlack};
  }
`;

export const CapacityContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 15px;
  margin-bottom: 24px;

  @media (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const Container = styled.div`
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  font: 500 14px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.adminBlack};
  padding: 0 0 8px 4px;
`;

export const PeopleIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.greys.adminBlack};
`;

export const CapacityNumber = styled.div<{ isAnyCapacity?: boolean }>`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: ${({ isAnyCapacity }) => (isAnyCapacity ? 0 : '11px')};
  font: 500 16px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.adminBlack};
`;
