export interface PaymentAsyncActionTypes {
  status: import('shared/consts').ACTION_STATUSES;
  error?: SerializableError;
}

export enum PaymentErrorsEnum {
  CART_SUBMISSION_ERROR = 'CART_SUBMISSION_ERROR',
  PAYMENT_PROCESSING_ERROR = 'PAYMENT_PROCESSING_ERROR',
  CARD_DECLINED_ERROR = 'CARD_DECLINED_ERROR',
  BOOKING_UNAVAILABLE_ERROR = 'BOOKING_UNAVAILABLE_ERROR',
}
