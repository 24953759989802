import {
  CheckInContainer,
  CheckInTitle,
  CheckInDescription,
  CheckInButton,
  CheckInButtonContainer,
  CheckedInContainer,
  CheckedInIcon,
} from './styles';
import React from 'react';
import { useIntl } from 'react-intl';
import { BoldText } from 'hocs/bold-text';
import { useSelector } from 'react-redux';
import { selectCurrentTransaction } from '../../../../store/transactions/selectors';
import { CHECK_IN_CLOSED_STATUSES, CHECK_IN_STATUSES } from '../../../../shared/consts';

interface ReceiptCheckInProps {
  onCheckIn: VoidFunction;
}

export const ReceiptCheckIn = ({ onCheckIn }: ReceiptCheckInProps): JSX.Element => {
  const intl = useIntl();
  const transaction = useSelector(selectCurrentTransaction);
  const transactionCheckInStatus = transaction?.details?.check_in?.status;

  return CHECK_IN_CLOSED_STATUSES.includes(transactionCheckInStatus as CHECK_IN_STATUSES) ? (
    <CheckedInContainer data-testid="receipt-checked-in">
      <CheckedInIcon size="sm" />
      {intl.formatMessage({ id: 'is_checked_in' })}
    </CheckedInContainer>
  ) : (
    <CheckInContainer data-testid="receipt-check-in">
      <CheckInTitle data-testid="check-in-title">
        {intl.formatMessage(
          { id: 'check_in_title' },
          {
            resourceName: transaction?.details?.name,
          },
        )}
      </CheckInTitle>
      <CheckInDescription data-testid="check-in-description">
        {/* @ts-ignore */}
        {intl.formatMessage(
          { id: 'check_in_description' },
          {
            resourceName: <BoldText>{transaction?.details?.name}</BoldText>,
          },
        )}
      </CheckInDescription>
      <CheckInButtonContainer>
        <CheckInButton
          data-testid="check-in-check-in-button"
          text={intl.formatMessage({ id: 'check_in_status' })}
          variant="primary"
          onClick={onCheckIn}
        />
      </CheckInButtonContainer>
    </CheckInContainer>
  );
};
