import React from 'react';
import { StyledVerticalContentTile } from './styles';
import { AvailableNowTile } from '../available-now-skeleton/styles';
import AvailableNowLabels from 'components/available-now-block/components/available-now-labels/available-now-labels';
import { VerticalContentTileProps } from '@hqo/react-components-library/dist/molecules/tiles/vertical-content-tile';

export interface AvailableNowItemProps
  extends Omit<
    VerticalContentTileProps,
    'title' | 'subtitle' | 'statusLabels' | 'imageClassName' | 'variant' | 'className' | 'skeleton'
  > {
  name: string;
  nowAvailabilitySummary: string;
  capacity: number;
  compactUi?: boolean;
  isFree?: boolean;
  isPaid?: boolean;
  locationLabel?: string;
}

const AvailableNowItem: React.FC<AvailableNowItemProps> = ({
  name,
  nowAvailabilitySummary,
  capacity,
  compactUi = false,
  isFree = false,
  isPaid = false,
  locationLabel,
  ...props
}: AvailableNowItemProps): JSX.Element => {
  return (
    <StyledVerticalContentTile
      className="available-now-tile"
      title={name}
      subtitle={nowAvailabilitySummary}
      isCompactUiEnabled={compactUi}
      variant={compactUi ? 'compact' : 'standard'}
      statusLabels={[
        <AvailableNowLabels
          key="labels"
          isFree={isFree}
          isPaid={isPaid}
          capacity={capacity}
          title={name}
          locationLabel={locationLabel}
          compactUi={compactUi}
        />,
      ]}
      imageClassName="responsive-image"
      skeleton={<AvailableNowTile />}
      {...props}
    />
  );
};

export default AvailableNowItem;
