import { IntlShape } from 'react-intl';
import { PaymentMethodTypes, SavedPaymentMethod } from 'store/payment/types';
import { formatSavedPaymentMethodLabel } from 'utils/formatSavedPaymentMethodLabel';

export const resolvePaymentMethodText = (paymentMethod: SavedPaymentMethod, intl: IntlShape) => {
  const { capitalizedBrand, formattedExpMonth, formattedExpYear } = formatSavedPaymentMethodLabel(paymentMethod) || {};

  switch (paymentMethod?.payment_method_type) {
    case PaymentMethodTypes.CREDIT_CARD:
      return intl.formatMessage(
        {
          id: 'saved_payment_method_option',
        },
        {
          cardBrand: capitalizedBrand,
          cardLastFour: paymentMethod?.card_last_four,
          cardExpMonth: formattedExpMonth,
          cardExpYear: formattedExpYear,
        },
      );
    case PaymentMethodTypes.INVOICE:
      return intl.formatMessage({ id: 'invoice_text' });

    default:
      return null;
  }
};
