import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import * as actions from './actions';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';
import { transformErrorIntoSerializableError } from 'store/utils';

export const getUiMetadataEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getUiMetadata.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      return apiClient(state)
        .fetchUiMetadata(payload)
        .pipe(
          map(({ response }) => {
            return actions.getUiMetadata.success(response);
          }),
          catchError((error: Error) =>
            of(actions.getUiMetadata.failure({ error: transformErrorIntoSerializableError(error) })),
          ),
        );
    }),
  );
