import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PAYMENT_MINIAPP_POST_MESSAGES } from 'shared/consts';
import { currentUser } from 'store/user/selectors';

export const useIframeInitData = (): void => {
  const user = useSelector(currentUser);
  const iframeRef = document.getElementById('payment-iframe') as unknown as HTMLIFrameElement;
  const [userDataSent, setUserDataSent] = useState<boolean>(false);

  const onMessage = (event: MessageEvent): void => {
    if (event.data.message === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_INIT && !userDataSent) {
      setUserDataSent(true);
      iframeRef?.contentWindow?.postMessage(
        {
          message: { message: PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_INIT_RESPONSE, user },
        },
        '*',
      );
    }
  };

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage]);
};
