import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown';
import { faAngleUp } from '@fortawesome/pro-light-svg-icons/faAngleUp';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons/faCalendarAlt';
import { faLocationDot } from '@fortawesome/pro-light-svg-icons/faLocationDot';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons/faCircleCheck';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle';
import { faPhone } from '@fortawesome/pro-regular-svg-icons/faPhone';
import { faTv } from '@fortawesome/pro-regular-svg-icons/faTv';
import { faWifi } from '@fortawesome/pro-regular-svg-icons/faWifi';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt';

library.add(
  faTrash,
  faMapMarkerAlt,
  faExclamationTriangle,
  faAngleUp,
  faAngleDown,
  faCalendarAlt,
  faLocationDot,
  faWifi,
  faPhone,
  faTv,
  faCircleCheck,
);
