import { AddingVisitorsBlockContainer, Subtitle, Title } from './styles';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { VisitorBlock } from './components/visitor-block';
import { generateArrayOfNumbersByLength } from 'utils/generateArrayOfNumbersByLength';
import { selectAllVisitorsEmails } from 'store/visitors-emails/selectors';
import { selectResource } from 'store/resource/selectors';
import { setVisitors } from 'store/visitors-emails/actions';
import { useIntl } from 'react-intl';

export interface Visitors {
  [key: number]: string;
}

export const AddingVisitorsBlock: React.FC = (): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { allowed_visitors: allowedVisitors } = useSelector(selectResource) || {};
  const visitors: Visitors = useSelector(selectAllVisitorsEmails);
  const visitorsArray = useMemo(
    () => generateArrayOfNumbersByLength(allowedVisitors),
    [allowedVisitors, generateArrayOfNumbersByLength],
  );

  const onChangeVisitors = useCallback(
    (key: number, value: string) => {
      dispatch(setVisitors({ ...visitors, [key]: value }));
    },
    [dispatch, visitors],
  );

  return (
    <AddingVisitorsBlockContainer data-testid="adding-visitors-block">
      <Title>{intl.formatMessage({ id: 'add_guests' })}</Title>
      <Subtitle>{intl.formatMessage({ id: 'can_invite_guests' }, { guestsNumber: allowedVisitors })}</Subtitle>
      {visitorsArray.map(visitorNumber => (
        <VisitorBlock
          key={visitorNumber}
          value={visitors?.[visitorNumber]}
          onChangeVisitors={onChangeVisitors}
          visitorNumber={visitorNumber}
        />
      ))}
    </AddingVisitorsBlockContainer>
  );
};
