import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { ACTION_STATUSES } from 'shared/consts';
import { PriceState } from './types';

const initialState: PriceState = {
  price: null,
  getPrice: {
    status: null,
    error: null,
  },
};

const getPriceRequestHandler = (state: PriceState): PriceState => ({
  ...state,
  getPrice: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getPriceSuccessHandler = (
  state: PriceState,
  { payload }: ActionType<typeof actions.getPrice.success>,
): PriceState => ({
  ...state,
  price: payload.price,
  getPrice: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getPriceFailureHandler = (
  state: PriceState,
  { payload: error }: ActionType<typeof actions.getPrice.failure>,
): PriceState => ({
  ...state,
  getPrice: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const resetPriceHandler = (state: PriceState): PriceState => ({
  ...state,
  price: null,
  getPrice: {
    ...initialState.getPrice,
  },
});

const changePriceHandler = (
  state: PriceState,
  { payload: changePriceAmount }: ActionType<typeof actions.changePrice>,
): PriceState => ({
  ...state,
  price: {
    ...state.price,
    price: state.price.price + changePriceAmount,
  },
});

const priceReducer = createReducer(initialState)
  .handleAction(actions.getPrice.request, getPriceRequestHandler)
  .handleAction(actions.getPrice.success, getPriceSuccessHandler)
  .handleAction(actions.getPrice.failure, getPriceFailureHandler)
  .handleAction(actions.resetPrice, resetPriceHandler)
  .handleAction(actions.changePrice, changePriceHandler);

export default priceReducer;
