import React from 'react';
import { ScheduleBlockContainer, ScheduleTime, ScheduleBlock } from './styles';
import { useSelectBlock } from './use-select-block.hook';
import { ScheduleSelectBlockProps } from '../interface';

export const ScheduleSelectBlock = ({
  time,
  available,
  formatTime,
  addRef,
  listRefs,
  top,
  setIsScrollToSelectedValue,
  durations,
  defaultOffsetTop,
}: ScheduleSelectBlockProps): JSX.Element => {
  const { handleBlockClick, ref } = useSelectBlock({
    addRef,
    listRefs,
    available,
    formatTime,
    time,
    top,
    setIsScrollToSelectedValue,
    durations,
    defaultOffsetTop,
  });

  return (
    <ScheduleBlockContainer ref={ref}>
      <ScheduleTime>{formatTime(time)}</ScheduleTime>
      <ScheduleBlock
        onClick={handleBlockClick}
        className="schedule-block"
        data-testid="schedule-block"
        isAvailable={available}
      />
    </ScheduleBlockContainer>
  );
};
