import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'hooks/use-search-params.hook';
import qs from 'qs';
import { replace } from 'store/router/actions';

export const useClearFilters = () => {
  const {
    filterByType,
    startDate,
    startTime,
    endTime,
    duration,
    isExact,
    capacity,
    presetWindows,
    filterByFloor,
    startDates,
    ...restQueryParams
  } = useSearchParams();
  const dispatch = useDispatch();

  return useCallback(() => {
    if (
      !filterByType &&
      !startDate &&
      !startTime &&
      !endTime &&
      !duration &&
      !isExact &&
      !capacity &&
      !presetWindows &&
      !filterByFloor &&
      !startDates
    ) {
      return;
    }
    const queryString = qs.stringify(restQueryParams);

    dispatch(replace(`${location.pathname}?${queryString}`));
  }, [
    filterByType,
    startDate,
    startDates,
    startTime,
    endTime,
    duration,
    isExact,
    capacity,
    presetWindows,
    filterByFloor,
    restQueryParams,
    dispatch,
  ]);
};
