import styled from 'styled-components';
import { SwipeModal } from '@hqo/react-components-library/dist/molecules/modals/swipe-modal';
import { Button } from '@hqo/react-components-library/dist/atoms/button';

export const StyledSwipeModal = styled(SwipeModal)`
  .content {
    height: 100%;
    box-shadow: ${({ theme }) => `0 -4px 10px 0 ${theme.colors.greys.darkGrey7}40`};
  }

  .container-content {
    height: auto;
  }

  .content-wrapper {
    height: calc(100% - 48px);
  }
`;

export const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const BuildingContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 13px;
  user-select: none;
`;

export const Text = styled.div`
  font: 500 20px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.systemBlack};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledButton = styled(Button)`
  font: 500 14px/22px ${({ theme }) => theme.fonts.join()};
  padding: 0;
`;
