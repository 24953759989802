import { BuildingContainer, Text, StyledButton } from './styles';
import React from 'react';
import { useIntl } from 'react-intl';
import { useBuildingInfo } from './hooks/use-building-info.hook';

export const BuildingInfo = (): JSX.Element => {
  const intl = useIntl();
  const { showFloorPlanLink, locationName, handleLinkClick } = useBuildingInfo();

  return (
    <BuildingContainer>
      <Text>{locationName}</Text>
      {showFloorPlanLink && (
        <StyledButton
          variant="link"
          onClick={handleLinkClick}
          text={intl.formatMessage({ id: 'see_floor_plan' })}
          multiline={false}
        />
      )}
    </BuildingContainer>
  );
};
