interface Item {
  label: string;
  value: Date;
  shouldBeSkipped?: boolean;
}

const millisecondsInMin = 60_000;

export const addMinutes = (date: Date, minutes: number) => {
  return new Date(date.getTime() + minutes * millisecondsInMin);
};

export const getStartTimeArray = (locale: string): Item[] => {
  const startTime = new Date().setHours(0, 0, 0, 0);
  let startTimeDate = new Date(startTime);

  const array = [];

  do {
    const timeObject = {
      value: new Date(startTimeDate),
      label: `${startTimeDate.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' })}`,
    };
    startTimeDate = addMinutes(startTimeDate, 15);
    array.push(timeObject);
  } while (startTimeDate.getDate() === new Date(startTime).getDate());
  const midnightTime = addMinutes(new Date(array[array.length - 1].value), 15);
  const midNightTimeObject = {
    value: midnightTime,
    label: `${midnightTime.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' })}`,
  };

  return [...array, midNightTimeObject];
};

export const getClosestTimeSlot = (timeStep = 900_000): Date => {
  const dateNow = new Date();
  const roundedDate = new Date(Math.floor((dateNow.getTime() + timeStep) / timeStep) * timeStep);

  return roundedDate;
};
