import React from 'react';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks';
import { Container, Header, Title, Icon, Content } from './styles';

export interface CollapsableBlockProps {
  title: string;
  children: React.ReactNode;
}

export const CollapsableBlock = ({ title, children }: CollapsableBlockProps): JSX.Element => {
  const { value: isInfoExpanded, toggle: toggleInfoExpanded } = useBooleanState(false);

  return (
    <Container>
      <Header onClick={toggleInfoExpanded}>
        <Title>{title}</Title>
        <Icon icon={['fal', isInfoExpanded ? 'angle-up' : 'angle-down']} size="2x" />
      </Header>
      <Content>{isInfoExpanded && children}</Content>
    </Container>
  );
};
