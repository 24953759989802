import { Select } from '@hqo/react-components-library/dist/select';
import { FloorPickerWheel } from 'components/floor-picker-wheel';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Floor, FloorItem } from 'store/spaceManager/types';
import { TitleComponent } from './styles';

interface FloorSelectModalContentProps {
  onWheelChange: React.Dispatch<React.SetStateAction<string>>;
  pickedFloor?: Floor | null;
  options: FloorItem[];
  isFloorPickerWheelShown: boolean;
  toggleFloorPickerWheel: VoidFunction;
}

export const FloorSelectModalContent = ({
  onWheelChange,
  pickedFloor,
  options,
  isFloorPickerWheelShown,
  toggleFloorPickerWheel,
}: FloorSelectModalContentProps): JSX.Element => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const intl = useIntl();
  const selectedValue = pickedFloor ? pickedFloor.name : intl.formatMessage({ id: 'noFloorSelected' });
  const onOpen = useCallback(() => {
    setIsSelectOpen(false);
    if (!isFloorPickerWheelShown) {
      toggleFloorPickerWheel();
    }
  }, [setIsSelectOpen, toggleFloorPickerWheel, isFloorPickerWheelShown]);

  return (
    <>
      <Select
        value={selectedValue}
        titleComponent={<TitleComponent>{intl.formatMessage({ id: 'floor' })}</TitleComponent>}
        open={isSelectOpen}
        displayEmpty
        onOpen={onOpen}
      />
      {isFloorPickerWheelShown && (
        <FloorPickerWheel
          onWheelChange={onWheelChange}
          options={options}
          pickedFloorUuid={pickedFloor?.uuid}
          toggleFloorPickerWheel={toggleFloorPickerWheel}
        />
      )}
    </>
  );
};
