export interface Item {
  label: string;
  value: Date;
  // flag indicating whether the object should be skipped due to invalid display time of wheel picker elements(but needs for label in the time selector)
  shouldBeSkipped?: boolean;
}

export const timeFormatOptions: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: 'numeric',
  hourCycle: 'h23',
};

export type searchParams = { [key: string]: string };

/**
 * Options for configuring the time selector behavior.
 */
export interface UseTimeSelectorOptions {
  /**
   * When true, filters out time slots that are in the past.
   * This helps prevent users from selecting invalid times.
   * @default true
   */
  filterPastTimes?: boolean;
}

export const useTimeSelectorDefaultOptions: UseTimeSelectorOptions = {
  filterPastTimes: true,
};
