import styled from 'styled-components';
import { ArrowLeftChevron } from '@hqo/react-components-library/dist/icons';

export const ArrowIcon = styled(ArrowLeftChevron)<{ isMultipleBookingsVisible: boolean }>`
  color: ${({ theme }) => theme.colors.fontColor};
  transform: ${({ isMultipleBookingsVisible }) => (isMultipleBookingsVisible ? 'rotate(90deg)' : 'rotate(270deg)')};
  cursor: pointer;
  width: 12px;
  height: 12px;
  margin-left: 10px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const DatesPill = styled.div`
  background-color: ${({ theme }) => theme.colors.greys.sharedGreyBackground};
  border-radius: 4px;
  margin-right: 10px;
  padding: 5px 10px;
  font: 500 16px/18px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.adminBlack};
`;

export const StyledText = styled.div<{ isKiosk?: boolean }>`
  color: ${({ theme }) => theme.colors.fontColor};
  font: 400 ${({ theme, isKiosk }) => `${isKiosk ? '24px' : '14px'} ${theme.fonts.join()}`};
`;
