import React, { useMemo } from 'react';
import { TouchSearchBar } from '@hqo/react-components-library/dist/molecules/touch-search-bar';
import { Wrapper, StyledCalendarIcon } from './styles';
import { bookingTimeHandler } from 'utils/bookingTimeHandler';
import { useIntl } from 'react-intl';
import { useLocale } from 'hooks/use-locale.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useTouchSearchBar } from 'hooks/use-touch-search-bar.hook';
import { DateTimeSelectPage } from 'pages/date-time-select-page';
import { Route, Routes } from 'react-router-dom';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons/faCalendar';
import { presetWindowLabelMapper } from 'shared/consts';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';

interface TouchSearchBarComponentProps {
  isResourcesPage?: boolean;
}

export const TouchSearchBarComponent = ({ isResourcesPage }: TouchSearchBarComponentProps): JSX.Element => {
  const { startDate, startDates, startTime, endTime, presetWindows } = useSearchParams();
  const { isMultiDayBookingEnabled } = useAppInstanceConfigFeature();
  const intl = useIntl();
  const locale = useLocale();
  const timeRange = useMemo(
    () => ({ startTime: startTime as string, endTime: endTime as string, locale }),
    [startTime, endTime, locale],
  );
  const startDatesArray: Array<string> = (startDates as string)?.split(',');
  const datesRangeValue = isMultiDayBookingEnabled
    ? bookingTimeHandler(timeRange).getDatesRangeString((startDate as string) ?? startDatesArray)
    : bookingTimeHandler(timeRange).getDateRangeString(startDate as string);

  const {
    reverseHorizontalAnimation,
    reverseAnimation,
    onPressBack,
    onDateClick,
    onNextClick,
    onTimeClick,
    onSearchClick,
    isDateStep,
    onClose,
    backToDateStep,
    onSkipTimeClick,
    isModalWithoutSlideAnimation,
  } = useTouchSearchBar();

  const rightLabel = useMemo(() => {
    return presetWindows
      ? intl.formatMessage({ id: presetWindowLabelMapper.get(String(presetWindows)) })
      : bookingTimeHandler(timeRange).getTimeRangeString();
  }, [presetWindows, intl, timeRange]);

  return (
    <Wrapper isResourcesPage={isResourcesPage} className="touch-search-bar-component">
      <Routes>
        <Route
          path="date-time-select"
          element={
            <DateTimeSelectPage
              isModalWithoutSlideAnimation={isModalWithoutSlideAnimation}
              reverseHorizontalAnimation={reverseHorizontalAnimation}
              reverseAnimation={reverseAnimation}
              onClose={onClose}
              onPressBack={onPressBack}
              onNextClick={onNextClick}
              onSkipClick={onSkipTimeClick}
              onSearchClick={onSearchClick}
              isDateStep={isDateStep}
              backToDateStep={backToDateStep}
            />
          }
        />
      </Routes>
      {isResourcesPage ? (
        <TouchSearchBar
          leftLabel={datesRangeValue}
          rightLabel={rightLabel}
          leftPlaceholder={intl.formatMessage({ id: 'date_and_time' })}
          className="touch-search-bar"
          isLeftEnabled
          isRightEnabled
          onLeftLabelClick={onDateClick}
          onRightLabelClick={onTimeClick}
          customIcon={<StyledCalendarIcon icon={faCalendar} />}
        />
      ) : (
        <TouchSearchBar
          leftLabel={datesRangeValue}
          rightLabel={bookingTimeHandler(timeRange).getTimeRangeString()}
          leftPlaceholder={intl.formatMessage({ id: 'when_booking' })}
          className="touch-search-bar"
          isLeftEnabled
          isRightEnabled
          onLeftLabelClick={onDateClick}
          onRightLabelClick={onTimeClick}
          customIcon={<StyledCalendarIcon icon={faCalendar} />}
        />
      )}
    </Wrapper>
  );
};
