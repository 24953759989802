import { MappedBookingObject } from 'components/payment/receipt/components/swipe-receipt-content';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { searchParams } from 'shared/types/search-params';
import { selectCart } from 'store/cart/selectors';
import { TransactionDetails } from 'store/transactions/types';

export const useBookingObject = (transaction: TransactionDetails): MappedBookingObject => {
  const cart = useSelector(selectCart);

  const { startDates } = useSearchParams<searchParams>();

  const isMultidayBooking = !!startDates?.length;

  return useMemo(
    () =>
      isMultidayBooking
        ? {
            isMultiday: true,
            items: cart?.items,
            currency_type: cart?.total_summary?.currency_type,
            total_price: cart?.total_summary?.subtotal,
            total_tax: cart?.total_summary?.taxes_total,
            total_charge: cart?.total_summary?.total,
            type: cart?.type,
            details: {
              discount_applied: cart?.total_summary?.discount_applied,
              name: cart?.items[0].resource_booking?.name,
              user_info: [...cart?.items[0].resource_booking?.form_responses],
              resource: {
                id: cart?.items[0].id,
                name: cart?.items[0].resource_booking?.name,
              },
            },
            vendor: { name: cart?.vendor?.name },
          }
        : { ...transaction, isMultiday: false, items: null, vendor: { name: transaction?.details?.location_name } },
    [
      cart?.items,
      cart?.total_summary?.currency_type,
      cart?.total_summary?.discount_applied,
      cart?.total_summary?.subtotal,
      cart?.total_summary?.taxes_total,
      cart?.total_summary?.total,
      cart?.type,
      cart?.vendor?.name,
      isMultidayBooking,
      transaction,
    ],
  );
};
