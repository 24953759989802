import React from 'react';
import { ResourcesCounter } from '../styles';
import { useResourcesCounter } from './hooks/use-resources-counter.hook';
import { useIntl } from 'react-intl';

export const ResourcesCounterBlock = (): JSX.Element => {
  const intl = useIntl();
  const { isResourcesCounterBlockVisible, numberOfResources } = useResourcesCounter();

  if (!isResourcesCounterBlockVisible) return null;

  return (
    <ResourcesCounter>
      {intl.formatMessage({ id: 'results' }, { resourcesQuantity: numberOfResources })}
    </ResourcesCounter>
  );
};
