import qs from 'qs';
import { QUERY_PARAMS } from 'shared/consts';
import { pick } from 'utils/pickObjectProperty';

export const handleClearedString = (
  selectedTime: Date,
  queryParams: qs.ParsedQs,
  callback: (arg: string) => void,
): void => {
  const dateNow = new Date();
  if (dateNow > selectedTime) {
    const queryString = qs.stringify(
      pick(
        queryParams,
        QUERY_PARAMS.filter(
          param =>
            param !== 'startTime' &&
            param !== 'startDate' &&
            param !== 'endTime' &&
            param !== 'isExact' &&
            param !== 'duration',
        ),
      ),
    );

    callback(queryString);
  }
};
