import { FilterSpacesQueryParams, Floor } from 'store/spaceManager/types';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { FailureActionPayload } from '../errors/types';
import { Space } from './types';

export const setSelectedFloor = createAction('SET_SELECTEDFLOOR')<Floor>();

export const resetFloor = createAction('RESET_FLOOR')<void>();

export const getFloors = createAsyncAction('GET_FLOORS_REQUEST', 'GET_FLOORS_SUCCESS', 'GET_FLOORS_FAILURE')<
  { buildingUuid: string; page?: number },
  { floors: Floor[]; buildingUuid: string },
  FailureActionPayload
>();

export const resetFloors = createAction('RESET_FLOORS')<void>();

export const getSpaces = createAsyncAction('GET_SPACES_REQUEST', 'GET_SPACES_SUCCESS', 'GET_SPACES_FAILURE')<
  FilterSpacesQueryParams,
  Array<Space>,
  FailureActionPayload
>();

export const resetSpaces = createAction('RESET_SPACES')<void>();
