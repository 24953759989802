import React from 'react';
import { Resource } from 'store/resource/types';
import { NoResults } from 'components/no-result';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { FlexContent } from 'pages/resources/content/flex-content';
import { InifiniteContent } from 'pages/resources/content/infinite-content';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';

export interface ContentProps {
  resources: Array<Resource>;
  scrollContainerRef: React.MutableRefObject<HTMLDivElement>;
}

export const Content = ({ resources, scrollContainerRef }: ContentProps): JSX.Element => {
  const { isCompactUiEnabled } = useAppInstanceConfigFeature();
  const { floorPlanRedirection } = useSearchParams();

  const getContent = () => {
    if (isCompactUiEnabled) {
      return <InifiniteContent scrollContainerRef={scrollContainerRef} />;
    }
    return <FlexContent />;
  };

  return resources?.length > 0 || +floorPlanRedirection ? getContent() : <NoResults />;
};
