import { useSelector } from 'react-redux';

import { selectAccessToken } from 'store/config/selectors';
import { useEffect, useMemo } from 'react';
import { jwtDecode } from 'jwt-decode';
import { SDK_APP_UUID } from 'shared/consts';
import { useMiniappSdkClientWithoutInit } from 'components/miniapp-sdk-provider/miniapp-sdk.hook';

const millisecondsInSecond = 1000;

export const useRefreshToken = (): void => {
  const accessToken: string = useSelector(selectAccessToken);
  const sdkClient = useMiniappSdkClientWithoutInit(SDK_APP_UUID);

  const tokenExpireDate = useMemo(() => {
    if (accessToken) {
      return jwtDecode(accessToken).exp;
    }
  }, [accessToken]);

  const getExpireInSeconds = (expireTimeInSeconds: number) => {
    return (expireTimeInSeconds - Date.now() / millisecondsInSecond) * 0.9;
  };

  useEffect(() => {
    if (sdkClient && tokenExpireDate && getExpireInSeconds(tokenExpireDate) > 0) {
      const refreshTimer = setTimeout(() => {
        sdkClient.refreshToken();
      }, getExpireInSeconds(tokenExpireDate) * millisecondsInSecond);
      return () => {
        clearTimeout(refreshTimer);
      };
    }
  }, [tokenExpireDate, sdkClient]);

  useEffect(() => {
    if (sdkClient && tokenExpireDate && getExpireInSeconds(tokenExpireDate) < 0) {
      sdkClient.refreshToken();
    }
  }, [tokenExpireDate, sdkClient]);
};
