import {
  GetResourceTimeRangesParams,
  MultidayTimeRangesParams,
  SingleDayTimeRangesParams,
} from 'store/resource-time-ranges/types';
import { pick } from 'utils/pickObjectProperty';

export const resolveGetResourceTimeRangesParams = (
  args: MultidayTimeRangesParams | SingleDayTimeRangesParams,
): GetResourceTimeRangesParams => {
  if ('startDates' in args) return pick(args, ['startDates', 'ownerType', 'resourceId']);

  return pick(args, ['end', 'start', 'ownerType', 'resourceId']);
};
