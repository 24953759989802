import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { ViewParams } from 'shared/types';
import { configSelector } from 'store/config/selectors';
import { saveExternalConfig } from 'store/config/actions';
import { useSearchParams } from 'hooks/use-search-params.hook';

export const useViewParams = () => {
  const searchParams = useSearchParams<Partial<ViewParams>>();
  const config = useSelector(configSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchParams) {
      dispatch(saveExternalConfig(searchParams));
    }
  }, [dispatch, searchParams]);

  return config;
};
