import { SMALL_DEVICE_MAX_WIDTH } from 'shared/consts';
import styled from 'styled-components';
import { VerticalContentTile } from '@hqo/react-components-library/dist/molecules/tiles/vertical-content-tile';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';

export const StyledVerticalContentTile = styled(VerticalContentTile)<{ isCompactUiEnabled?: boolean }>`
  @media (max-width: ${DIMENSIONS.MEDIUM}) {
    .text-block {
      height: 100%;
      display: flex;
      flex-direction: column;
      word-break: initial;
    }
    .status-label-container {
      ${({ isCompactUiEnabled }) => isCompactUiEnabled && 'margin-top: auto;'}
    }
  }
  @media (min-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    width: 240px;
    .responsive-image {
      width: 240px;
    }
  }
`;
