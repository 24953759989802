import { skipToken } from '@reduxjs/toolkit/query';
import { useFilteringEnabled } from 'hooks/use-filtering-enabled.hook';
import qs from 'qs';
import { useSelector } from 'react-redux';
import {
  appInstanceConfigsSelector,
  selectCurrentAppInstanceConfigUuid,
  selectCurrentLocationId,
  selectSelectedAppInstanceConfigsLocations,
} from 'store/app-instance-configs/selectors';
import { resourcesApi } from 'store/resources/api';
import { ResourceType } from 'store/resources/types';
import { getSelectedAppInstanceConfigs } from 'utils/getSelectedAppInstanceConfigs';

export type UseGetResourcesTypesQueryType = typeof resourcesApi.useGetResourcesTypesQuery;
export type UseGetResourcesTypesQueryResultType = ReturnType<UseGetResourcesTypesQueryType> & { data?: ResourceType[] };

export function useGetCurrentLocationResourcesTypesQuery(
  options: Parameters<UseGetResourcesTypesQueryType>[1] = {},
): UseGetResourcesTypesQueryResultType {
  const currentLocationId = useSelector(selectCurrentLocationId);
  const currentAppInstanceConfigUuid = useSelector(selectCurrentAppInstanceConfigUuid);
  const selectedAppInstanceConfigsLocations = useSelector(selectSelectedAppInstanceConfigsLocations);
  const isFilteringEnabled = useFilteringEnabled();
  const { skip, ...rest } = options;
  const doSkip = !isFilteringEnabled || !currentLocationId || !currentAppInstanceConfigUuid || skip === true;
  const appInstanceConfigsState = useSelector(appInstanceConfigsSelector);
  const selectedAppInstanceConfigs = getSelectedAppInstanceConfigs(appInstanceConfigsState);

  return resourcesApi.useGetResourcesTypesQuery(
    doSkip
      ? skipToken
      : qs.stringify({
          locationId: currentLocationId,
          appInstanceConfigUuid: currentAppInstanceConfigUuid,
          ...(selectedAppInstanceConfigs.length > 1 && {
            appInstanceConfigs: selectedAppInstanceConfigsLocations,
          }),
        }),
    rest,
  );
}
