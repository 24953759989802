import React from 'react';
import { useIntl } from 'react-intl';
import { BOOKING_DAYS_LIMIT } from 'shared/consts';
import { MultidayWarningMessageContainer } from 'components/multiday-warning-message/styles';

export const MultidayWarningMessage = (): JSX.Element => {
  const intl = useIntl();

  return (
    <MultidayWarningMessageContainer data-testid="warning-cart-overloaded">
      {intl.formatMessage({ id: 'multidayLimitDaysWarning' }, { daysLimitation: BOOKING_DAYS_LIMIT })}
    </MultidayWarningMessageContainer>
  );
};
