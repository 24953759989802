import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { configSelector, hasToken } from 'store/config/selectors';
import { getBuildingTheme } from 'store/theme/actions';
import { selectBrandTheme } from 'store/theme/selectors';

export const useBuildingTheme = (): void => {
  const dispatch = useDispatch();
  const brandTheme = useSelector(selectBrandTheme);
  const { buildingUuid } = useSelector(configSelector);
  const hasUserToken = useSelector(hasToken);
  const isFullTheme = !!brandTheme && brandTheme.font !== undefined && brandTheme.primary_font_color !== undefined;

  useEffect(() => {
    const getBuildingThemeTimer = setTimeout(() => {
      if (buildingUuid && !isFullTheme && hasUserToken && !brandTheme) {
        dispatch(getBuildingTheme.request(buildingUuid));
      }
    }, 1000);

    return () => {
      clearTimeout(getBuildingThemeTimer);
    };
  }, [buildingUuid, isFullTheme, hasUserToken, brandTheme]);
};
