import styled from 'styled-components';

export const ScheduleTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.join()};
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.greys.darkGrey7};
  margin-bottom: 13px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 1px 12px 0;
  width: 100%;
`;

export const ScheduleHeader = styled.div`
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
