import React from 'react';
import { TimeFilterContainer, Background } from './styles';
import { TimeFilter } from 'pages/filter-modal/time-filter';
import { PresetWindowToggle } from 'components/presetWindowToggle';
import { useTimeSelectPageContent } from './hooks/use-time-select-page-content.hook';

export const TimeSelectPageContent = (): JSX.Element => {
  const { isPresetWindowFilterVisible, onChangeToggle, isOverlayVisible, toggleOverlay } = useTimeSelectPageContent();

  return (
    <TimeFilterContainer>
      {isOverlayVisible && <Background data-testid="overlay-filter-content" />}
      <TimeFilter toggleOverlay={toggleOverlay} />
      {isPresetWindowFilterVisible && <PresetWindowToggle onChangeToggle={onChangeToggle} />}
    </TimeFilterContainer>
  );
};
