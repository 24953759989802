import styled from 'styled-components';

interface ContainerProps {
  width: number;
  height: number;
}

export const Container = styled.div<ContainerProps>`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  -webkit-tap-highlight-color: transparent;
  -webkit-mask-image: ${({ theme }) =>
    `linear-gradient(to bottom, transparent 0%, ${theme.colors.universal.black} 50%, transparent 100%);`};
  mask-image: ${({ theme }) =>
    `linear-gradient(to bottom, transparent 0%, ${theme.colors.universal.black} 50%, transparent 100%);`};
  position: relative;

  &::after {
    pointer-events: initial;
    content: '';
    width: 100%;
    height: 32px;
    background: ${({ theme }) => theme.colors.greys.adminGreyMedium};
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: -1;
  }
`;
