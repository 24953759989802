import { SavePaymentMethodRequest } from '../store/payment/types';
import { SpreedlyPaymentMethod } from 'store/add-card/types';
import { TOKENIZATION_METHOD } from 'shared/consts';

export const formatSavePaymentMethodRequestBody = (paymentMethod: SpreedlyPaymentMethod): SavePaymentMethodRequest => ({
  payment_method_type: paymentMethod?.payment_method_type ?? '',
  payment_token: paymentMethod?.token ?? '',
  payment_method_tokenization_method: TOKENIZATION_METHOD ?? '',
  payment_method_name: paymentMethod?.full_name ?? '',
  payment_method_address_city: paymentMethod?.city ?? '',
  payment_method_address_country: paymentMethod?.country ?? '',
  payment_method_address_line1: paymentMethod?.address1 ?? '',
  payment_method_address_line2: paymentMethod?.address2 ?? '',
  payment_method_address_state: paymentMethod?.state ?? '',
  payment_method_address_zip: paymentMethod?.zip ?? '',
  payment_method_country: paymentMethod?.country ?? '',
  card_brand: paymentMethod?.card_type ?? '',
  card_last_four: paymentMethod?.last_four_digits ?? '',
  card_exp_month: paymentMethod?.month?.toString() ?? '',
  card_exp_year: paymentMethod?.year?.toString() ?? '',
});
