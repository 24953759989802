import React, { useMemo } from 'react';
import { GhostCalendarViewContainer, GhostCalendarViewTitle, GhostDatesContainer, GhostDate } from './styles';
import { v4 } from 'uuid';

export const GhostCalendarView = (): JSX.Element => {
  const dates = useMemo(() => [...Array.from({ length: 35 })].fill('1', 0, 35), []);

  return (
    <GhostCalendarViewContainer data-testid="ghost-calendar-view">
      <GhostCalendarViewTitle data-testid="ghost-calendar-view-title" />
      <GhostDatesContainer data-testid="ghost-calendar-view-dates">
        {dates.map(() => (
          <GhostDate data-testid="ghost-calendar-view-date" key={v4()} />
        ))}
      </GhostDatesContainer>
    </GhostCalendarViewContainer>
  );
};
