import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { SwipeModal } from '@hqo/react-components-library/dist/molecules/modals/swipe-modal';
import { Button } from 'components/payment/quick-checkout/components/button/button';
import { MultidayQuickCheckoutContent } from 'components/payment/quick-checkout/components/multiday-checkout-content/multidayQuickCheckoutContent';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentLocationId } from 'store/app-instance-configs/selectors';
import { selectCart, selectCartDiscountSummary, selectCartId, selectSubmitCartStatus } from 'store/cart/selectors';
import { getCart, submitCart } from 'store/cart/actions';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'shared/types/search-params';
import { track } from '@hqo/web-tracking';
import { TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';
import { formatSummaryValues } from 'utils/formatSummaryValues';
import { formatCartItemsIds } from 'utils/formatTrackEventProps';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { StyledModalWrapper } from './styles';
import { useQuickCheckoutHeight } from '../../useQuickCheckoutHeight.hook';
import { MultidayQuickCheckoutDesktopContent } from './multidayQuickCheckoutDesktopContent';

export interface MultidayCheckoutContainerProps {
  reverseAnimation: boolean;
  closeQuickCheckoutHandler: VoidFunction;
}

export const MultidayCheckoutContainer = ({
  reverseAnimation,
  closeQuickCheckoutHandler,
}: MultidayCheckoutContainerProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { locationId: currentLocationId } = useSearchParams<searchParams>();

  const locationId = useSelector(selectCurrentLocationId) || currentLocationId;
  const cart_id = useSelector(selectCartId);
  const submitCartStatus = useSelector(selectSubmitCartStatus);
  const cart = useSelector(selectCart);
  const discountSummary = useSelector(selectCartDiscountSummary);
  const isMobileDevice = useIsSmallViewportWidth();
  const quickCheckoutHeight = useQuickCheckoutHeight();

  useEffect(() => {
    dispatch(getCart.request(cart_id));
  }, []);

  const onCTAClick = useCallback(() => {
    const { total, currencyType } = formatSummaryValues(cart?.total_summary);
    const cartItemsIds = formatCartItemsIds(cart);

    track(
      TRACK_EVENT_NAMES.CHECKOUT_CLICK,
      {
        type: TRACK_EVENT_TYPES.ACTION,
        cart_total: total,
        currency_code: currencyType,
        promo_code_applied: !!discountSummary,
        cart_type: cart?.type,
        items: cartItemsIds,
        adapter: cart?.config?.config?.adapter,
      },
      { sendToHqoTracking: true },
    );
    dispatch(
      submitCart.request({
        cart_id,
        locationId,
      }),
    );
  }, [cart_id, locationId, cart, discountSummary]);

  return isMobileDevice ? (
    <SwipeModal
      title={intl.formatMessage({ id: 'checkout' })}
      reverseAnimation={reverseAnimation}
      content={<MultidayQuickCheckoutContent />}
      onClose={closeQuickCheckoutHandler}
      withLeftExitButton
      footerContent={<Button isFreeCart submitCartStatus={submitCartStatus} onCTAClick={onCTAClick} />}
    />
  ) : (
    <StyledModalWrapper
      quickCheckoutHeight={quickCheckoutHeight}
      content={
        <MultidayQuickCheckoutDesktopContent
          onCTAClick={onCTAClick}
          closeQuickCheckoutHandler={closeQuickCheckoutHandler}
        />
      }
    />
  );
};
