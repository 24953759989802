import styled from 'styled-components';
import { skeletonAnimation } from 'components/skeleton/styles';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';

interface PriceBlockProps {
  hasContentBelow: boolean;
}

export const PriceBlock = styled.div<PriceBlockProps>`
  font: 500 16px/18px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.adminBlack};
  background-color: ${({ theme }) => theme.colors.greys.sharedGreyBackground};
  border-radius: 18px;
  padding: 5px 10px;
  margin-bottom: ${({ hasContentBelow }) => (hasContentBelow ? '10px' : 'none')};

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    margin-bottom: 0;
  }
`;

export const EmptyPriceBlock = styled.div`
  width: 70px;
  height: 18px;
`;

export const GhostPriceBlock = styled.div<PriceBlockProps>`
  width: 70px;
  height: 28px;
  background: ${({ theme }) => theme.colors.greys.adminGreyLight};
  ${skeletonAnimation};
  margin-bottom: ${({ hasContentBelow }) => (hasContentBelow ? '10px' : 'none')};

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    margin-bottom: 0;
  }
`;
