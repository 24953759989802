import { DEFAULT_MARKED_OPTIONS } from 'shared/consts';
import { marked } from 'marked';
import { useMemo } from 'react';
import useFormatHtmlText from 'shared/hooks/useFormatHtmlText/useFormatHtmlText.hook';

interface UseMarkdownTextOptions {
  openInOsBrowserExternalLink?: boolean;
}

const useMarkdownText = (markdownText: string, { openInOsBrowserExternalLink = true }: UseMarkdownTextOptions = {}) => {
  const html = useMemo(
    () => marked.parse(markdownText.replace(/\n(?=\n)/g, '<br/>\n'), DEFAULT_MARKED_OPTIONS),
    [markdownText],
  );

  const formatedHtml = useFormatHtmlText(html, {
    openInOsBrowserExternalLink,
  });

  return { html: formatedHtml };
};

export default useMarkdownText;
