import { TimeRange } from 'store/resource-time-ranges/types';

interface GetTimeRangesWithLastTimeSlotProps {
  hours: number[];
  resourceTimeRanges: TimeRange[];
}

export const getTimeRangesWithLastTimeSlot = ({
  hours,
  resourceTimeRanges,
}: GetTimeRangesWithLastTimeSlotProps): TimeRange[] => {
  const lastHour = hours?.[hours.length - 1];

  return (lastHour === 23 || lastHour === 24) && resourceTimeRanges?.length
    ? [...resourceTimeRanges, { ...resourceTimeRanges[0], available: false, time: '24:00' }]
    : resourceTimeRanges;
};
