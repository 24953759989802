import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectAppInstanceConfigs, selectCurrentAppInstanceConfigUuid } from 'store/app-instance-configs/selectors';
import { DefaultFilterEnum } from '../shared/consts';
import { ResourceBookingEnum } from 'store/app-instance-configs/enums';

interface TermsAndConditionsResponse {
  isTermsAndConditionsEnabled: boolean;
  termsAndConditionsText: string;
}

interface UseAppInstanceConfigFeatureReturnValues {
  isAvailableNowEnabled: boolean;
  isPresetBookingWindowsEnabled: boolean;
  termsAndConditionsValues: TermsAndConditionsResponse;
  isMultiDayBookingEnabled: boolean;
  defaultFilter: DefaultFilterEnum;
  isCompactUiEnabled: boolean;
  isNativeAdapter: boolean;
  hideWhenIsBooking: boolean;
  isMapViewEnabled: boolean;
}

export const useAppInstanceConfigFeature = (): UseAppInstanceConfigFeatureReturnValues => {
  const appInstanceConfigs = useSelector(selectAppInstanceConfigs);
  const currentAppInstanceConfigUuid = useSelector(selectCurrentAppInstanceConfigUuid);
  const {
    showResourceBookingTermsAndConditions,
    showResourceBookingFullDayToggle,
    resourceBookingEnableMultiDayFeature,
    resourceBookingShowMapView,
  } = useFlags();
  const { pathname } = useLocation();

  const currentAppInstanceConfig = appInstanceConfigs?.find(config => config.uuid === currentAppInstanceConfigUuid);

  const isPresetBookingWindowsEnabled = useMemo((): boolean => {
    if (pathname.includes('/floorplan/')) {
      return (
        appInstanceConfigs.find(appInstanceConfig => appInstanceConfig.config?.preset_booking_windows_enabled) &&
        showResourceBookingFullDayToggle
      );
    }
    return currentAppInstanceConfig?.config?.preset_booking_windows_enabled && showResourceBookingFullDayToggle;
  }, [appInstanceConfigs, currentAppInstanceConfig, showResourceBookingFullDayToggle]);

  const termsAndConditionsValues = useMemo((): TermsAndConditionsResponse => {
    return {
      isTermsAndConditionsEnabled:
        currentAppInstanceConfig?.config?.terms_and_conditions_enabled && showResourceBookingTermsAndConditions,
      termsAndConditionsText: currentAppInstanceConfig?.terms_and_conditions,
    };
  }, [currentAppInstanceConfig, showResourceBookingTermsAndConditions]);

  const isMultiDayBookingEnabled = useMemo((): boolean => {
    return currentAppInstanceConfig?.config?.multi_day_booking_enabled && resourceBookingEnableMultiDayFeature;
  }, [currentAppInstanceConfig, resourceBookingEnableMultiDayFeature]);

  const defaultConfigValues = useMemo(() => {
    return {
      defaultFilter: currentAppInstanceConfig?.config?.default_filter,
      isCompactUiEnabled: currentAppInstanceConfig?.config?.compact_ui_enabled,
      isAvailableNowEnabled: currentAppInstanceConfig?.config?.available_now_enabled,
      isNativeAdapter: currentAppInstanceConfig?.vertical_adapter?.name === ResourceBookingEnum.NativeAdapter,
      hideWhenIsBooking: currentAppInstanceConfig?.config?.hide_when_is_booking,
    };
  }, [currentAppInstanceConfig]);

  const isMapViewEnabled = useMemo((): boolean => {
    return (
      (currentAppInstanceConfig?.config?.portfolio_resources_enabled ||
        currentAppInstanceConfig?.vertical_adapter?.name === ResourceBookingEnum.EssensysAdapter) &&
      resourceBookingShowMapView
    );
  }, [currentAppInstanceConfig, resourceBookingShowMapView]);

  return {
    ...defaultConfigValues,
    isPresetBookingWindowsEnabled,
    termsAndConditionsValues,
    isMultiDayBookingEnabled,
    isMapViewEnabled,
  };
};
