import { GetCurrentUserPayload, User } from './types';

import { FailureActionPayload } from '../errors/types';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const getCurrentUser = createAsyncAction(
  'user/GET_CURRENT_REQUEST',
  'user/GET_CURRENT_RESPONSE',
  'user/GET_CURRENT_FAILURE',
)<GetCurrentUserPayload, { user: User }, FailureActionPayload>();

export const refreshToken = createAction('config/REFRESH_TOKEN')<undefined>();
