import React from 'react';
import { ResourceBookingHeader } from 'components/headers/resourceBookingHeader';
import { useBackButtonVisibility } from 'hooks/use-back-button-visibility.hook';
import { useHeader } from './hooks/use-header.hook';
import { useLocation } from 'react-router-dom';
import { LANDING_PATH } from 'shared/consts';

export const Header = (): JSX.Element => {
  const isBackButtonVisible = useBackButtonVisibility();
  const { currentConfigName, isHeaderVisible, onPressBack, showMyBookingsSection, onMyBookingsClick } = useHeader();
  const { pathname } = useLocation();
  const withBackButton = isBackButtonVisible && pathname !== LANDING_PATH;

  if (!isHeaderVisible) return null;

  return (
    <ResourceBookingHeader
      title={currentConfigName}
      withBackButton={withBackButton}
      onBackClick={onPressBack}
      showMyBookingsSection={showMyBookingsSection}
      onMyBookingsClick={onMyBookingsClick}
    />
  );
};
