import { BookingWindow } from 'store/resource/types';
import { DEFAULT_LOCALE } from 'shared/consts';

interface Props {
  bookingWindows: BookingWindow[];
  date: string;
}

interface ReturnValues {
  currentBookingWindow: BookingWindow;
  count: number;
}

export const getCurrentWeekday = (date: string): string => {
  return new Date(date.replace(/-/g, '/'))
    .toLocaleDateString(DEFAULT_LOCALE, { weekday: 'long' })
    .substr(0, 2)
    .toUpperCase();
};

export const getCurrentBookingWindow = ({ bookingWindows, date }: Props): ReturnValues | null => {
  if (!bookingWindows.length || !date) {
    return null;
  }
  const filteredBookingWindows = bookingWindows.filter(window => window.type !== 'Unavailable');
  const currentWeekday = getCurrentWeekday(date);
  const currentBookingWindow = filteredBookingWindows.filter(window =>
    window.rrule
      .split(';')
      .filter(rrule => rrule.includes('BYDAY'))[0]
      .includes(currentWeekday),
  )?.[0];
  const count = filteredBookingWindows.filter(window =>
    window.rrule
      .split(';')
      .filter(rrule => rrule.includes('BYDAY'))[0]
      .includes(currentWeekday),
  )?.length;

  return { currentBookingWindow, count };
};
