import React, { useEffect } from 'react';
import { DateSelectPageProps } from '../interface';
import { DateSelectPageContent } from '../components/date-select-page-content';
import { DateSelectPageFooter } from '../components/date-select-page-footer';
import { DayLabels } from '../components/day-labels';
import { useIntl } from 'react-intl';
import { Content } from 'components/date-desktop-modal/styles';
import { DateDesktopModal } from 'components/date-desktop-modal';

export const DateSelectDesktopPage = ({ onClose, onNextClick }: DateSelectPageProps): JSX.Element => {
  const intl = useIntl();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, []);

  return (
    <DateDesktopModal
      onClose={onClose}
      className="date-select-page-desktop"
      title={intl.formatMessage({ id: 'whenBooking' })}
      headerContent={<DayLabels />}
      footerContent={<DateSelectPageFooter onNextClick={onNextClick} />}
    >
      <Content>
        <DateSelectPageContent />
      </Content>
    </DateDesktopModal>
  );
};
