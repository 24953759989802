import React from 'react';
import { ItemsContainer } from './styles';
import { WheelPickerComponentProps } from './component.interface';
import { useWheelPicker } from './wheel-picker.hooks';
import { WheelPickerItem } from './wheel-picker-item';
import { WheelPickerContainer } from './wheel-picker-container';

const WheelPickerComponent = ({
  items,
  value,
  onChange: handleChange,
  containerHeight = 210,
  itemHeight = 30,
  width = 210,
  onItemClick,
  onClickOutside,
  outsideClickRef,
}: WheelPickerComponentProps): JSX.Element => {
  const { itemRefs, itemsContainerRef } = useWheelPicker(items, value, containerHeight, itemHeight, handleChange);

  return (
    <WheelPickerContainer
      height={containerHeight}
      width={width}
      onClickOutside={onClickOutside}
      outsideClickRef={outsideClickRef}
      onItemClick={onItemClick}
    >
      <ItemsContainer ref={itemsContainerRef} data-testid="items-container">
        {items.map((item, index) => {
          function setRef(node: HTMLLIElement) {
            if (itemRefs.current) itemRefs.current[index] = node;
          }

          return (
            <WheelPickerItem
              key={item.value.toString()}
              item={item}
              itemHeight={itemHeight}
              // eslint-disable-next-line react/jsx-no-bind
              setRef={setRef}
            />
          );
        })}
      </ItemsContainer>
    </WheelPickerContainer>
  );
};

export const WheelPicker = React.memo(WheelPickerComponent);
