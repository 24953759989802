import { go, goBack, replace } from 'store/router/actions';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCartId } from 'store/cart/selectors';
import { selectCurrentLocationId } from 'store/app-instance-configs/selectors';
import {
  ACTION_STATUSES,
  CHECKOUT_PATH,
  HISTORICAL_RECEIPT_PATH,
  PAYMENT_MINIAPP_POST_MESSAGES,
  QUERY_PARAMS,
  QUICK_CHECKOUT_PATH,
  RECEIPT_PATH,
} from 'shared/consts';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { useIframeParams } from './use-iFrame-params.hook';
import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hook';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from '../use-search-params.hook';
import { searchParams } from 'pages/date-select-page/interface';
import qs from 'qs';
import { pick } from 'utils/pickObjectProperty';
import { getTransactions, patchTransaction } from 'store/transactions/actions';
import { selectPatchTransactionsStatus } from 'store/transactions/selectors';

interface UseIFrameReturnValues {
  isContentLoading: boolean;
  handleIframeLoading: VoidFunction;
  onPressBack: VoidFunction;
  isCheckoutDesktop: boolean;
  isQuickCheckout: boolean;
  isCancelBookingPage: boolean;
  iFrameUrl: string;
  quickCheckout: boolean;
  isSwipeModalContent: boolean;
  handleOnClose: VoidFunction;
  isReceipt: boolean;
}

const mapPaymentIFramePath: Record<string, string> = {
  receipt: RECEIPT_PATH,
  checkout: CHECKOUT_PATH,
};

export const useIFrame = (
  bookingId: number | string,
  setBookingId?: React.Dispatch<React.SetStateAction<number | string>>,
): UseIFrameReturnValues => {
  const dispatch = useDispatch();
  const cartId = useSelector(selectCartId);
  const locationId = useSelector(selectCurrentLocationId);
  const patchTransactionStatus = useSelector(selectPatchTransactionsStatus);
  const { pathname } = useLocation();
  const {
    swipePaymentIFramePath,
    transactionId: getTransactionId,
    error,
    paymentId,
    stepsCount,
    ...restQueryParams
  } = useSearchParams<searchParams>();

  const defaultPath: string = swipePaymentIFramePath
    ? mapPaymentIFramePath[swipePaymentIFramePath]
    : QUICK_CHECKOUT_PATH;
  const [isContentLoading, setContentLoading] = useState<boolean>(true);
  const [isCheckoutDesktop, setIsCheckoutDesktop] = useState<boolean>(false);
  const [isQuickCheckout, setIsQuickCheckout] = useState<boolean>(false);
  const [isReceipt, setIsReceipt] = useState<boolean>(false);
  const [isFullCheckout, setIsFullCheckout] = useState<boolean>(false);
  const transactionId = bookingId || getTransactionId;
  const miniappPaymentPath: string = transactionId ? HISTORICAL_RECEIPT_PATH : defaultPath;
  const [isCancelBookingPage, setIsCancelBookingPage] = useState<boolean>(false);
  const { showMiniappPaymentsNavigation } = useFlags();
  const isMobileDevice = useIsSmallViewportWidth();
  const isQuickCheckoutIframe = miniappPaymentPath === QUICK_CHECKOUT_PATH;
  const isSwipeModalContent: boolean = showMiniappPaymentsNavigation && isMobileDevice && !isQuickCheckoutIframe;
  const iframe = document.getElementById('payment-iframe') as unknown as HTMLIFrameElement;
  const quickCheckout = isQuickCheckoutIframe;
  const isSafari: boolean =
    navigator.vendor &&
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 &&
    navigator.userAgent.indexOf('FxiOS') === -1;
  const iFrameUrl = useIframeParams(
    cartId,
    quickCheckout,
    transactionId,
    locationId,
    miniappPaymentPath,
    showMiniappPaymentsNavigation,
    isQuickCheckoutIframe,
    error,
    paymentId,
  );
  const client = useMiniappSdk();

  useEffect(() => {
    if ((!showMiniappPaymentsNavigation && (isReceipt || isFullCheckout)) || isSwipeModalContent) {
      client?.header.hideHeader();
      client?.swipe.disableBackSwipe();
    }

    return () => {
      client?.header.showHeader();
      client?.swipe.enableBackSwipe();
    };
  }, [client, isFullCheckout, isReceipt, isSwipeModalContent, showMiniappPaymentsNavigation]);

  const redirectToTheLanding = useCallback(() => {
    const queryString = qs.stringify(
      pick(
        restQueryParams,
        QUERY_PARAMS.filter(
          param =>
            param !== 'startTime' &&
            param !== 'startDate' &&
            param !== 'endTime' &&
            param !== 'isExact' &&
            param !== 'duration',
        ),
      ),
    );
    dispatch(replace(`/?${queryString}`));
  }, [dispatch, restQueryParams]);

  const handleOnClose = useCallback(() => {
    if (setBookingId) {
      setBookingId(null);
    }
    if (showMiniappPaymentsNavigation) {
      if (isQuickCheckout || (isFullCheckout && !isSafari)) {
        dispatch(goBack());
      } else {
        redirectToTheLanding();
      }
    } else {
      const bookingComplete = !(transactionId || isFullCheckout);
      if (isFullCheckout) {
        dispatch(goBack());
      }
      if ((isReceipt && bookingComplete) || transactionId) {
        if (window.history?.state?.idx) {
          dispatch(go(-window.history.state.idx));
        } else {
          dispatch(go(-Number(stepsCount) - 2));
        }
      } else {
        dispatch(goBack());
      }
    }
    dispatch(getTransactions.request({}));
  }, [
    isSafari,
    isQuickCheckout,
    showMiniappPaymentsNavigation,
    redirectToTheLanding,
    transactionId,
    isFullCheckout,
    isReceipt,
    dispatch,
    stepsCount,
  ]);

  const onMessage = (event: MessageEvent): void => {
    if (event.data === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_CLOSE) {
      handleOnClose();
    }
    if (event.data === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_RECEIPT) {
      setIsQuickCheckout(false);
      setIsCheckoutDesktop(false);
      setIsReceipt(true);
      setIsFullCheckout(false);
      setIsCancelBookingPage(false);
    }
    if (event.data === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_CHECKOUT_MOBILE) {
      setIsQuickCheckout(false);
      setIsReceipt(true);
      setIsFullCheckout(true);
    }
    if (event.data === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_CHECKOUT_DESKTOP) {
      setIsQuickCheckout(false);
      setIsCheckoutDesktop(true);
    }
    if (event.data?.message === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_QUICK_CHECKOUT) {
      setIsQuickCheckout(true);
    }
    if (event.data?.message === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_EDIT_BUTTON_CLICK) {
      if (showMiniappPaymentsNavigation && isMobileDevice) {
        const queryParams = {
          ...restQueryParams,
          swipePaymentIFramePath: 'checkout',
          stepsCount: window.history?.state?.idx,
        };
        const queryString = qs.stringify(queryParams);
        dispatch(replace(`${pathname.replace('payment', 'swipe-payment')}?${queryString}`));
      }
    }
    if (event.data?.message === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_3DS_REDIRECT_ERROR) {
      if (showMiniappPaymentsNavigation && isMobileDevice) {
        const queryParams = {
          ...restQueryParams,
          swipePaymentIFramePath: 'checkout',
          error: true,
          paymentId: event.data.paymentId,
        };
        const queryString = qs.stringify(queryParams);
        dispatch(replace(`${pathname.replace('/payment', '/swipe-payment')}?${queryString}`));
      }
    }
    if (event.data?.message === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_3DS_REDIRECT_SUCCEEDED) {
      if (showMiniappPaymentsNavigation && isMobileDevice) {
        const queryParams = {
          ...restQueryParams,
          transactionId: event.data.transactionId,
        };
        const queryString = qs.stringify(queryParams);
        dispatch(replace(`${pathname.replace('/payment', '/swipe-payment')}?${queryString}`));
      }
    }
    if (event.data?.message === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_3DS_STATUS_UPDATE) {
      const { transaction_id, status_3ds } = event.data.value;
      if (patchTransactionStatus !== ACTION_STATUSES.PENDING) {
        dispatch(
          patchTransaction.request({
            transactionId: transaction_id,
            status_3ds,
            cartId,
          }),
        );
      }
    }
    if (event.data?.message === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_DONE_BUTTON_CLICK) {
      if (showMiniappPaymentsNavigation && isMobileDevice) {
        const queryParams = {
          ...restQueryParams,
          transactionId: event.data.transactionId,
        };
        const queryString = qs.stringify(queryParams);
        dispatch(replace(`${pathname.replace('payment', 'swipe-payment')}?${queryString}`));
      }
    }
    if (event.data === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_GO_BACK) {
      dispatch(goBack());
      setIsReceipt(false);
    }
    if (event.data === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_CANCEL_BOOKING_PAGE) {
      setIsCancelBookingPage(true);
    }
    if (event.data === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_CLOSE_CANCEL_BOOKING_PAGE) {
      setIsCancelBookingPage(false);
    }
  };

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  const handleIframeLoading = useCallback(() => {
    setContentLoading(false);
  }, [setContentLoading]);

  const onPressBack = useCallback(() => {
    if (isCancelBookingPage) {
      setIsCancelBookingPage(false);
    }
    iframe?.contentWindow?.postMessage(`${PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_GO_BACK}`, '*');
  }, [iframe?.contentWindow, isCancelBookingPage]);

  return {
    isContentLoading,
    handleIframeLoading,
    onPressBack,
    iFrameUrl,
    isCancelBookingPage,
    isCheckoutDesktop,
    isQuickCheckout,
    quickCheckout,
    isSwipeModalContent,
    handleOnClose,
    isReceipt,
  };
};
