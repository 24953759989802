import { BookingFlowState } from './types';

import * as actions from './actions';
import { ActionType, createReducer } from 'typesafe-actions';

export const getInitialState = (): BookingFlowState => ({ selectedSlotByUserAt: undefined });

export const initialState: BookingFlowState = getInitialState();

const changeSelectedSlotHandler = (
  state: BookingFlowState,
  { payload }: ActionType<typeof actions.changeSelectedSlot>,
): BookingFlowState => {
  if (payload.initiator === 'user') {
    return { ...state, selectedSlotByUserAt: Date.now() };
  }

  return { ...state };
};

const resetSelectedSlotHandler = (state: BookingFlowState): BookingFlowState => ({
  ...state,
  selectedSlotByUserAt: undefined,
});

const bookingFlowReducer = createReducer(initialState)
  .handleAction(actions.changeSelectedSlot, changeSelectedSlotHandler)
  .handleAction(actions.resetSelectedSlot, resetSelectedSlotHandler);

export default bookingFlowReducer;
