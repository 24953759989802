import React from 'react';
import { SwipeModal } from '@hqo/react-components-library/dist/molecules/modals/swipe-modal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { ModalContainer } from 'components/paymentIFrame/styles';
import { Container } from './styles';

interface SwipeModalContentProps {
  content: JSX.Element;
  onPressBack?: VoidFunction;
  onClose: VoidFunction;
  withLeftArrowButton?: boolean;
}

export const ReceiptBooking = ({
  content,
  onPressBack,
  withLeftArrowButton = false,
  onClose,
}: SwipeModalContentProps) => {
  const { showMiniappPaymentsNavigation } = useFlags();
  const isMobileDevice = useIsSmallViewportWidth();
  const isSwipeModalContent: boolean = showMiniappPaymentsNavigation && isMobileDevice;

  const buildContent = (): JSX.Element => (
    <ModalContainer isSwipeModalContent={isSwipeModalContent}>{content}</ModalContainer>
  );

  return isSwipeModalContent ? (
    <SwipeModal
      reverseAnimation={false}
      content={buildContent()}
      onPressBack={onPressBack}
      onClose={onClose}
      withLeftArrowButton={withLeftArrowButton}
      withLeftExitButton={!withLeftArrowButton}
    />
  ) : (
    <Container>{buildContent()}</Container>
  );
};
