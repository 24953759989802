import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideNotification, showNotification } from 'store/notifications/actions';
import { currentNotification } from 'store/notifications/selectors';
import { Notification } from 'store/notifications/types';

export interface UseNotificationsResult {
  notification?: Notification;
  hide: () => void;
  setNotification(newNotification: Notification): void;
}

export default function useNotifications(): UseNotificationsResult {
  const dispatch = useDispatch();
  const notification = useSelector(currentNotification);

  const hide = useCallback(() => {
    dispatch(hideNotification());
  }, [dispatch]);

  const setNotification = useCallback(
    (newNotification: Notification) => {
      dispatch(showNotification(newNotification));
    },
    [dispatch],
  );

  return {
    notification,
    hide,
    setNotification,
  };
}
