import { AvailableNow } from 'store/resource/types';

export interface AvailableNowSubtitles {
  availableAllDay: string;
  availableFor: string;
  hours: string;
  minutes: string;
}

export function renderAvailabilitySubtitleText(availableNow: AvailableNow, subtitles: AvailableNowSubtitles) {
  const currentDate = new Date();
  const resourceAvailabilityEndDate = new Date(availableNow?.end_at);

  if (
    resourceAvailabilityEndDate > currentDate &&
    resourceAvailabilityEndDate.getHours() === 0 &&
    resourceAvailabilityEndDate.getMinutes() === 0 &&
    resourceAvailabilityEndDate.getSeconds() === 0
  ) {
    return subtitles.availableAllDay;
  }

  const resourceAvailabilityText =
    availableNow?.available_minutes >= 60
      ? `${Math.floor(availableNow?.available_minutes / 60)} ${subtitles.hours}`
      : `${availableNow?.available_minutes} ${subtitles.minutes}`;

  return `${subtitles.availableFor} ${resourceAvailabilityText}`;
}
