import React, { useEffect } from 'react';

import { ReceiptContent } from './components/receipt-content';
import { SwipeModalContent } from './components/swipe-modal-content';
import { useReceipt } from './use-receipt.hook';

interface ReceiptProps {
  transactionId?: number | string;
  onCloseReceipt: VoidFunction;
  isCancelBookingStep: boolean;
  setIsCancelBookingStep: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Receipt = ({
  transactionId,
  onCloseReceipt,
  isCancelBookingStep,
  setIsCancelBookingStep,
}: ReceiptProps) => {
  const {
    isSwipeModalContent,
    onCancelBookingClick,
    patchTransactionData,
    transaction,
    onCheckInClick,
    isDeepLinkAccess,
  } = useReceipt({ transactionId, setIsCancelBookingStep });
  useEffect(() => {
    if (isDeepLinkAccess && !transaction) {
      onCloseReceipt();
    }
  }, [transaction, isDeepLinkAccess, onCloseReceipt]);

  return isSwipeModalContent ? (
    <SwipeModalContent
      onCloseClick={onCloseReceipt}
      transaction={transaction}
      onCancelBookingClick={onCancelBookingClick}
      patchTransactionData={patchTransactionData}
      isSwipeModalContent={isSwipeModalContent}
      isCancelBookingStep={isCancelBookingStep}
      setIsCancelBookingStep={setIsCancelBookingStep}
      onCheckInClick={onCheckInClick}
    />
  ) : (
    <ReceiptContent
      transaction={transaction}
      onCancelBookingClick={onCancelBookingClick}
      patchTransactionData={patchTransactionData}
      onCloseClick={onCloseReceipt}
      isCancelBookingStep={isCancelBookingStep}
      setIsCancelBookingStep={setIsCancelBookingStep}
      onCheckInClick={onCheckInClick}
    />
  );
};
