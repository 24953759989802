import { KioskState } from './types';

import * as actions from './actions';
import { createReducer } from 'typesafe-actions';

export const getInitialState = (): KioskState => ({ enabled: false });

export const initialState: KioskState = getInitialState();

const enableKioskHandler = (state: KioskState): KioskState => {
  return { ...state, enabled: true };
};

const disableKioskHandler = (state: KioskState): KioskState => {
  return {
    ...state,
    enabled: false,
  };
};

const kioskReducer = createReducer(initialState)
  .handleAction(actions.enableKiosk, enableKioskHandler)
  .handleAction(actions.disableKiosk, disableKioskHandler);

export default kioskReducer;
