import React, { useMemo, forwardRef } from 'react';
import { useIntl } from 'react-intl';

import { CheckoutTermsAndConditions } from 'components/payment/quick-checkout/components/terms-and-conditions';
import { BookingConfirmationModalBaseProps } from 'components/payment/quick-checkout/components/types';
import { ACTION_STATUSES } from 'shared/consts';
import BookOrCancelModal from 'components/payment/quick-checkout/components/booking-kiosk-confirmation-modal/components/book-or-cancel-modal/book-or-cancel-modal';
import { BookingTimeText } from 'components/payment/quick-checkout/components/booking-kiosk-confirmation-modal/styles';
import { TimeStringFormats, formatTime, getTimeInDayInMinutesFromUTCISO8601 } from 'shared/utils/time';
import ErrorModal from 'components/payment/quick-checkout/components/booking-kiosk-confirmation-modal/components/error-modal/error-modal';
import { getCartErrorMessage } from 'utils/getCartErrorMessage';

export type BookingKioskConfirmationModalProps = BookingConfirmationModalBaseProps;

const BookingKioskConfirmationModal = forwardRef<HTMLDivElement, BookingKioskConfirmationModalProps>(
  function BookingKioskConfirmationModalComponent(
    {
      checkoutDisabled,
      closeQuickCheckoutHandler,
      onCTAClick,
      isTermsVisible,
      isTermsChecked,
      onIsTermsChecked,
      submitCartStatus,
      cart,
      resourceTimezone,
      locale,
      cartError,
    },
    ref,
  ) {
    const intl = useIntl();

    const buttonDisabled = useMemo(
      () =>
        checkoutDisabled ||
        submitCartStatus === ACTION_STATUSES.PENDING ||
        submitCartStatus === ACTION_STATUSES.FULFILLED,
      [submitCartStatus, checkoutDisabled],
    );

    const resourceName = useMemo(() => {
      return cart?.items[0]?.display_info?.title || '';
    }, [cart]);

    const { startTime, endTime } = useMemo(() => {
      const startAt = cart?.items[0]?.resource_booking?.start_at;
      const endAt = cart?.items[0]?.resource_booking?.end_at;

      const startTimeStr = startAt
        ? formatTime(getTimeInDayInMinutesFromUTCISO8601(startAt, resourceTimezone), locale, TimeStringFormats.HH_MM_UP)
        : '';
      const endTimeStr = startAt
        ? formatTime(getTimeInDayInMinutesFromUTCISO8601(endAt, resourceTimezone), locale, TimeStringFormats.HH_MM_UP)
        : '';
      return {
        startTime: startTimeStr,
        endTime: endTimeStr,
      };
    }, [cart, locale, resourceTimezone]);

    const bookingCreationError = useMemo(() => {
      if (!cartError) {
        return undefined;
      }

      return getCartErrorMessage(cartError);
    }, [cartError]);

    if (bookingCreationError) {
      return <ErrorModal onClose={closeQuickCheckoutHandler} bookingCreationError={bookingCreationError} />;
    }

    return (
      <BookOrCancelModal
        data-testid="booking-kiosk-confirmation-modal"
        onCancel={closeQuickCheckoutHandler}
        onBook={onCTAClick}
        resourceName={resourceName}
        disabled={buttonDisabled}
        ref={ref}
      >
        <BookingTimeText>
          {intl.formatMessage({ id: 'kiosk_booking_confirmation' }, { startTime, endTime })}
        </BookingTimeText>
        {isTermsVisible && (
          <CheckoutTermsAndConditions termsChecked={isTermsChecked} setIsTermsChecked={onIsTermsChecked} />
        )}
      </BookOrCancelModal>
    );
  },
);

export default BookingKioskConfirmationModal;
