import { Order } from 'store/cart/types';

import { formatCurrency } from './formatCurrency';
import { formatSummaryValues } from './formatSummaryValues';

export const getTaxSummary = (cart: Order, locale: string): string | null => {
  const { currencyType, taxSummary } = formatSummaryValues(cart?.total_summary);
  if (!currencyType || !taxSummary) {
    return null;
  }

  return formatCurrency(taxSummary, currencyType, locale);
};
