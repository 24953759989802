import { useMemo } from 'react';
import qs from 'qs';
import { useLocation } from 'react-router';

export const useDisableState = (): boolean => {
  const { search } = useLocation();
  const { startDate, presetWindows, startDates } = useMemo(
    () => qs.parse(search, { ignoreQueryPrefix: true }),
    [search],
  );

  return !(startDate || startDates) || !!presetWindows;
};
