import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { RESOURCES_PATH } from 'shared/consts';
import { useAppInstanceConfigFeature } from './use-app-instance-config-feature.hook';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';

export const useMapViewOverflow = (): void => {
  const { pathname } = useLocation();
  const { isMapViewEnabled } = useAppInstanceConfigFeature();
  const isMobileDevice = useIsSmallViewportWidth();
  const isResourcesPage = pathname.includes(RESOURCES_PATH);

  useEffect(() => {
    return () => {
      document.body.style.overflow = isResourcesPage && isMapViewEnabled && isMobileDevice ? 'hidden' : 'initial';
    };
  }, [isMapViewEnabled, isMobileDevice, isResourcesPage]);
};
