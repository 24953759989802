import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseDragAreaProps } from '../interface';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'pages/date-select-page/interface';
import qs from 'qs';
import { replace } from 'store/router/actions';
import { useDispatch } from 'react-redux';
import { DEFAULT_HEIGHT, DEFAULT_TIME_INTERVAL, RADIX } from '../const';
import { bookingTimeHandler } from 'utils/bookingTimeHandler';

export const useDragArea = ({
  resizableBottomRef,
  resizableTopRef,
  listRefs,
  top,
  setIsScrollToSelectedValue,
  timeRangeByDuration,
  availableTimeRangeByMinDuration,
  availableTimeRangeByStartTime,
  durations,
  timeInterval,
  isTimeIntervalFromTimeRanges,
}: UseDragAreaProps) => {
  const [initialPos, setInitialPos] = useState<number>(null);
  const [initialSize, setInitialSize] = useState<number>(null);
  const [initialTop, setInitialTop] = useState<number>(null);
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [isTopCircle, setIsTopCircle] = useState<boolean>(false);
  const [isTransition, setIsTransition] = useState<boolean>(false);
  const resizableBottom = document.getElementById('resizable-bottom');
  const resizableTop = document.getElementById('resizable-top');
  const scheduleContainer = document.getElementById('schedule-container');
  const scrollContainer = document.getElementById('scroll-container');
  const { startTime, endTime, duration, isExact, presetWindows, ...restQueryParams } = useSearchParams<searchParams>();
  const { max: maxDuration, min: minDuration } = durations || {};
  const minNumberOfSelectedIntervals = isTimeIntervalFromTimeRanges
    ? minDuration / DEFAULT_TIME_INTERVAL
    : minDuration / timeInterval;
  const maxNumberOfSelectedIntervals = isTimeIntervalFromTimeRanges
    ? maxDuration / DEFAULT_TIME_INTERVAL
    : maxDuration / timeInterval;
  const minHeight = minNumberOfSelectedIntervals * DEFAULT_HEIGHT;
  const maxHeight = maxNumberOfSelectedIntervals * DEFAULT_HEIGHT;

  const dispatch = useDispatch();
  const unavailableRefs = listRefs?.filter(ref => !ref.available);
  const isCurrentDurationMoreThanMax = useMemo(() => {
    return startTime && endTime && bookingTimeHandler({ startTime, endTime }).getTimeDiff() > maxDuration;
  }, [endTime, maxDuration, startTime]);

  useEffect(() => {
    if (
      startTime &&
      !endTime &&
      !duration &&
      availableTimeRangeByStartTime?.startTimeByDuration &&
      availableTimeRangeByStartTime?.endTimeByDuration &&
      (!isExact || isExact === 'false')
    ) {
      const queryParams = {
        ...restQueryParams,
        startTime: availableTimeRangeByStartTime?.startTimeByDuration,
        endTime: availableTimeRangeByStartTime?.endTimeByDuration,
        duration: minDuration,
        isExact: true,
      };
      const queryString = qs.stringify(queryParams);
      dispatch(replace(`${location.pathname}?${queryString}`));
    }
  }, [
    dispatch,
    isExact,
    startTime,
    endTime,
    duration,
    availableTimeRangeByStartTime?.startTimeByDuration,
    availableTimeRangeByStartTime?.endTimeByDuration,
    minDuration,
  ]);

  useEffect(() => {
    if (
      startTime &&
      endTime &&
      duration &&
      timeRangeByDuration?.startTimeByDuration &&
      timeRangeByDuration?.endTimeByDuration &&
      (!isExact || isExact === 'false')
    ) {
      const queryParams = {
        ...restQueryParams,
        startTime: timeRangeByDuration?.startTimeByDuration,
        endTime: timeRangeByDuration?.endTimeByDuration,
        duration,
        isExact: true,
      };
      const queryString = qs.stringify(queryParams);
      dispatch(replace(`${location.pathname}?${queryString}`));
    }
  }, [
    dispatch,
    location,
    isExact,
    startTime,
    endTime,
    duration,
    timeRangeByDuration?.startTimeByDuration,
    timeRangeByDuration?.endTimeByDuration,
  ]);

  useEffect(() => {
    if (
      startTime &&
      endTime &&
      isCurrentDurationMoreThanMax &&
      timeRangeByDuration?.startTimeByDuration &&
      timeRangeByDuration?.endTimeByDuration &&
      !presetWindows
    ) {
      const queryParams = {
        ...restQueryParams,
        startTime: timeRangeByDuration?.startTimeByDuration,
        endTime: timeRangeByDuration?.endTimeByDuration,
        duration,
        isExact: true,
      };
      const queryString = qs.stringify(queryParams);
      dispatch(replace(`${location.pathname}?${queryString}`));
    }
  }, [
    presetWindows,
    dispatch,
    isExact,
    startTime,
    endTime,
    duration,
    timeRangeByDuration?.startTimeByDuration,
    timeRangeByDuration?.endTimeByDuration,
    isCurrentDurationMoreThanMax,
  ]);

  const setInitialTopAndSize = useCallback(
    (currentPosition: number) => {
      setInitialPos(currentPosition);
      setInitialTop(resizableTopRef.current.offsetTop);
      setInitialSize(resizableBottomRef.current.offsetHeight);
    },
    [resizableBottomRef, resizableTopRef],
  );

  const handleBottomClick = useCallback(
    (event: any) => {
      event.preventDefault();
      setIsResizing(true);
      setIsTransition(false);
      setInitialTopAndSize(event.clientY);
      setIsScrollToSelectedValue(false);
    },
    [setInitialTopAndSize],
  );

  const handleTopClick = useCallback(
    (event: any) => {
      event.preventDefault();
      setIsResizing(true);
      setIsTopCircle(true);
      setIsTransition(false);
      setInitialTopAndSize(event.clientY);
      setIsScrollToSelectedValue(false);
    },
    [setInitialTopAndSize],
  );

  const handleBottomTouch = useCallback(
    (event: any) => {
      event.preventDefault();
      const touch = event.changedTouches[0];
      setIsResizing(true);
      setInitialTopAndSize(touch.clientY);
      setIsScrollToSelectedValue(false);
    },
    [setInitialTopAndSize],
  );

  const handleTopTouch = useCallback(
    (event: any) => {
      event.preventDefault();
      const touch = event.changedTouches[0];
      setIsResizing(true);
      setIsTopCircle(true);
      setInitialTopAndSize(touch.clientY);
      setIsScrollToSelectedValue(false);
    },
    [setInitialTopAndSize],
  );

  const getClosest = (counts: number[], goal: number, isTop: boolean): number => {
    const filterCountsForTopCircle = counts.filter(item => item < goal);
    const filterCountsForBottomCircle = counts.filter(item => item > goal);
    if (isTop) {
      return (
        filterCountsForTopCircle?.length &&
        filterCountsForTopCircle?.reduce((prev, curr) => (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev))
      );
    }
    return (
      filterCountsForBottomCircle?.length &&
      filterCountsForBottomCircle?.reduce((prev, curr) => (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev))
    );
  };

  const getDragAreaDimensions = useCallback(
    (currentPosition: number) => {
      const isUnavailableTop = !!unavailableRefs?.find(ref => ref.offsetTop === initialTop - DEFAULT_HEIGHT);
      const isUnavailableHeight = !!unavailableRefs?.find(ref => ref.offsetTop === initialTop + initialSize);
      const unavailableTops: number[] = [];
      unavailableRefs?.forEach(unavailableRef => unavailableTops.push(unavailableRef.offsetTop));
      if (isTopCircle) {
        const shiftBottomHeight = initialSize - parseInt(String(currentPosition - initialPos), RADIX);
        const shiftTop = initialTop + parseInt(String(currentPosition - initialPos), RADIX);
        const closetTopPosition = getClosest(unavailableTops, initialTop, true);
        const closetUnavailableTop = closetTopPosition ? closetTopPosition + DEFAULT_HEIGHT : closetTopPosition;
        if (closetUnavailableTop && shiftTop < closetUnavailableTop) {
          return;
        }
        if (shiftBottomHeight < minHeight || (maxHeight && shiftBottomHeight > maxHeight)) {
          return;
        }
        resizableTop.style.top = `${
          (initialSize === DEFAULT_HEIGHT && initialTop < shiftTop) ||
          (isUnavailableTop && initialTop > shiftTop) ||
          (!initialTop && initialTop > shiftTop)
            ? initialTop
            : shiftTop
        }px`;
        const currentHeight = maxHeight && shiftBottomHeight > maxHeight ? maxHeight : shiftBottomHeight;
        resizableBottom.style.height = `${
          (initialSize === DEFAULT_HEIGHT && initialTop < shiftTop) ||
          (isUnavailableTop && initialTop > shiftTop) ||
          (!initialTop && initialTop > shiftTop)
            ? initialSize
            : currentHeight
        }px`;
      } else {
        const shiftHeight = initialSize + parseInt(String(currentPosition - initialPos), RADIX);
        const isExtremeTimeSlot =
          listRefs[listRefs.length - 1]?.offsetTop + DEFAULT_HEIGHT === initialTop + initialSize &&
          initialSize < shiftHeight;
        if (
          getClosest(unavailableTops, initialTop, false) &&
          shiftHeight > getClosest(unavailableTops, initialTop, false) - initialTop
        ) {
          return;
        }
        if (shiftHeight < minHeight) {
          return;
        }
        const currentHeight = maxHeight && shiftHeight > maxHeight ? maxHeight : shiftHeight;
        resizableBottom.style.height = `${
          (isUnavailableHeight && initialSize < shiftHeight) || isExtremeTimeSlot ? initialSize : currentHeight
        }px`;
      }
    },
    [initialPos, initialSize, initialTop, isTopCircle],
  );

  const handleToucheMove = useCallback(
    (event: TouchEvent) => {
      if (!isResizing || !scheduleContainer || !resizableTop || !resizableBottom) {
        return;
      }
      scrollContainer.style.overflow = 'hidden';
      scheduleContainer.style.overflow = 'hidden';
      const touch = event.changedTouches[0];
      getDragAreaDimensions(touch.clientY);
    },
    [getDragAreaDimensions, isResizing],
  );

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (!isResizing || !scheduleContainer || !resizableTop || !resizableBottom) {
        return;
      }
      getDragAreaDimensions(event.clientY);
    },
    [getDragAreaDimensions, isResizing],
  );

  const getClosestPosition = useCallback(() => {
    setIsTransition(true);
    const heightDiff = isTimeIntervalFromTimeRanges
      ? DEFAULT_HEIGHT * (timeInterval / DEFAULT_TIME_INTERVAL)
      : DEFAULT_HEIGHT;
    const currentHeight = +resizableBottom.style.height.replace('px', '') || minHeight;
    const currentTop = resizableTopRef.current.offsetTop;
    if (isTopCircle) {
      const shiftHeight = Math.round(currentHeight / DEFAULT_HEIGHT) * DEFAULT_HEIGHT;
      const shiftTop = currentTop - (shiftHeight - currentHeight);
      resizableBottom.style.height = `${shiftHeight}px`;
      resizableTop.style.top = `${shiftTop}px`;
    }
    const shiftHeight = Math.round(currentHeight / heightDiff) * heightDiff;
    resizableBottom.style.height = `${shiftHeight === 0 ? shiftHeight + heightDiff : shiftHeight}px`;
    setIsResizing(false);
    setIsTopCircle(false);
  }, [
    isTimeIntervalFromTimeRanges,
    isTopCircle,
    minHeight,
    resizableBottom,
    resizableTop,
    resizableTopRef,
    timeInterval,
  ]);

  const handleBookingTime = useCallback(() => {
    const currentHeight = +resizableBottom.style.height.replace('px', '');
    const currentTop = resizableTopRef.current.offsetTop;
    const startTimeValue = listRefs?.find(ref => ref.offsetTop === currentTop)?.queryStringTime;
    const endTimeValue = listRefs?.find(ref => ref.offsetTop === currentTop + currentHeight)?.queryStringTime;
    const queryParams = {
      ...restQueryParams,
      startTime: startTimeValue,
      endTime: endTimeValue,
      isExact: true,
    };
    const queryString = qs.stringify(queryParams);
    dispatch(replace(`${location.pathname}?${queryString}`));
  }, [resizableTop, resizableBottom, listRefs, dispatch, restQueryParams]);

  const handleTouchEnd = useCallback(() => {
    if (!isResizing || !resizableTop || !resizableBottom) {
      return;
    }
    getClosestPosition();
    scrollContainer.style.overflow = 'auto';
    scheduleContainer.style.overflow = 'auto';
    handleBookingTime();
  }, [getClosestPosition, isResizing, handleBookingTime]);

  const handleMouseUp = useCallback(() => {
    if (!isResizing || !resizableTop || !resizableBottom) {
      return;
    }
    getClosestPosition();
    handleBookingTime();
  }, [getClosestPosition, isResizing, handleBookingTime]);

  useEffect(() => {
    const bottomContainer = document.getElementById('resizable-bottom');
    if (!startTime && !endTime && !presetWindows) {
      const queryParams = {
        ...restQueryParams,
        startTime: availableTimeRangeByMinDuration?.startTimeByDuration,
        endTime: availableTimeRangeByMinDuration?.endTimeByDuration,
        isExact: true,
      };
      bottomContainer.style.height = `${minHeight}px`;
      const queryString = qs.stringify(queryParams);
      dispatch(replace(`${location.pathname}?${queryString}`));
    }

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('touchmove', handleToucheMove);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchmove', handleToucheMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [
    presetWindows,
    handleMouseMove,
    resizableBottom,
    handleMouseUp,
    handleToucheMove,
    handleTouchEnd,
    dispatch,
    startTime,
    endTime,
    top,
    location,
    availableTimeRangeByMinDuration?.startTimeByDuration,
    availableTimeRangeByMinDuration?.endTimeByDuration,
  ]);

  return {
    handleBottomClick,
    handleTopClick,
    handleBottomTouch,
    handleTopTouch,
    isTransition,
  };
};
