import resourceBookingApi from 'api/helix/resourceBooking';
import { HelixApiResourcesTypesResponse, ResourceType } from 'store/resources/types';

export const resourcesApi = resourceBookingApi.injectEndpoints({
  endpoints: build => ({
    getResourcesTypes: build.query<ResourceType[], string>({
      query: params => ({
        url: `resources/types?${params}&include=configured`,
      }),
      transformResponse: (result: HelixApiResourcesTypesResponse) => result.types.filter(type => !!type.length),
    }),
  }),
});
