export interface UseStepsNavigationReturnValues {
  step: string;
  handleChangeStep: VoidFunction;
}

export enum TermsAndConditionsSteps {
  QUICK_APPLY_STEP = 'QUICK_APPLY_STEP',
  TERMS_AND_CONDITIONS_TEXT_STEP = 'TERMS_AND_CONDITIONS_TEXT_STEP',
}

export interface ModalContentProps {
  handleChangeStep: VoidFunction;
}

export interface QuickTermsAndConditionsProps {
  handleChangeStep: VoidFunction;
}

export interface UseModalContentReturnValues {
  onAgreeClick: VoidFunction;
  onDeclineClick: VoidFunction;
  isAgreeButtonLoading: boolean;
  isDeclineButtonDisabled: boolean;
  isDeclineButtonLoading: boolean;
}
