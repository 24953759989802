import { useMemo } from 'react';
import { BookingNotCancelableReasons } from 'store/bookings/types';
import { TransactionDetails } from 'store/transactions/types';

import { MILLISECONDS_IN_MIN } from 'shared/consts/time';

export interface UseIsBookingCancelableResult {
  cancelable: boolean;
  notCancelableReason?: BookingNotCancelableReasons;
}

export default function useIsBookingCancelable(transaction: TransactionDetails): UseIsBookingCancelableResult {
  const isCancellationPeriodExpired = useMemo(() => {
    const bookingStartAt = transaction.details?.start_at;
    const disallowedMinutesBeforeStart = transaction.details?.cancellation_window_end_minutes;
    if (!bookingStartAt || !disallowedMinutesBeforeStart) {
      return false;
    }
    const bookingStartAtDatetime = new Date(bookingStartAt);
    const now = new Date();
    const limitDatetime = new Date(
      bookingStartAtDatetime.getTime() - disallowedMinutesBeforeStart * MILLISECONDS_IN_MIN,
    );

    return now.getTime() > limitDatetime.getTime();
  }, [transaction]);

  const isBookingInThePast = useMemo(() => {
    return transaction?.details.start_at && new Date() > new Date(transaction?.details.start_at);
  }, [transaction]);

  const notCancelableReason = useMemo(() => {
    if (isBookingInThePast) {
      return BookingNotCancelableReasons.BOOKING_IN_THE_PAST;
    }
    if (isCancellationPeriodExpired) {
      return BookingNotCancelableReasons.CANCELATION_PERIOD_CLOSED;
    }
    return undefined;
  }, [isCancellationPeriodExpired, isBookingInThePast]);

  return {
    cancelable: !transaction || (!isCancellationPeriodExpired && !isBookingInThePast),
    notCancelableReason,
  };
}
