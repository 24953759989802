import { Resource } from 'store/resource/types';

export const getResourceTrackInfo = (
  resource: Resource,
): Pick<Resource, 'type' | 'uuid' | 'name' | 'capacity'> & { resource_id: string } => {
  return {
    type: resource.type,
    uuid: resource.uuid,
    resource_id: resource.id?.toString(),
    name: resource.name,
    ...(!!resource.capacity && { capacity: resource.capacity }),
  };
};
