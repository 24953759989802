import styled from 'styled-components';
import { Button } from '@hqo/react-components-library/dist/atoms/button';

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  height: 100vh;
  width: 100vw;
  gap: 32px;
`;

export const StyledButton = styled(Button)`
  height: 32px;
`;
