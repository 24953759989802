import { formatStartEndTimeItemsArray } from 'components/time-selector/hooks/use-resource-details-time-selector.hook';
import {
  generateTimeSlots,
  getIsUnavailableSlotIncluded,
} from 'pages/resource/components/timeline/components/selected-timeline-block/utils/selected-timeline-block.utils';
import { TimeRange } from 'store/resource-time-ranges/types';

export enum MAPPER_ITEMS_MODE {
  START_TIME = 'startTime',
  END_TIME = 'endTime',
}

export const mapFormattedTimeRangesByMode = (
  { startTime, endTime, selectedDate }: { startTime: string; endTime: string; selectedDate: string },
  timeRanges: Array<TimeRange>,
  timeLables: Array<string>,
  locale: string,
  mode: MAPPER_ITEMS_MODE,
) => {
  const selectedTimeSlots: Array<string> = generateTimeSlots(startTime, endTime);
  const isUnavailableSlotIncluded = getIsUnavailableSlotIncluded<TimeRange>(
    timeRanges,
    selectedTimeSlots,
    'time',
    'available',
  );
  if (!isUnavailableSlotIncluded) return formatStartEndTimeItemsArray(timeLables, selectedDate, locale);

  const timeArgument = mode === MAPPER_ITEMS_MODE.START_TIME ? startTime : endTime;
  const unavailableTimeItem = formatStartEndTimeItemsArray([timeArgument], selectedDate, locale, false, {
    shouldBeSkipped: true,
  });

  return [...formatStartEndTimeItemsArray(timeLables, selectedDate, locale), ...unavailableTimeItem];
};
