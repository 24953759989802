import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { useFilteringEnabled } from 'hooks/use-filtering-enabled.hook';
import { useResourceLoadingHandler } from 'pages/resources/hooks';
import { useCallback, useMemo } from 'react';
import { useCurrentConfig } from 'hooks/use-current-config.hook';
import { useIntl } from 'react-intl';
import { goBack, push } from 'store/router/actions';
import { resetResourcesState } from 'store/resources/actions';
import { useDispatch } from 'react-redux';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';
import { useLocation } from 'react-router-dom';
import { LANDING_PATH } from 'shared/consts';

interface UseHeaderReturnValues {
  currentConfigName: string;
  isHeaderVisible: boolean;
  showMyBookingsSection: boolean;
  onPressBack: VoidFunction;
  onMyBookingsClick: VoidFunction;
}

export const useHeader = (): UseHeaderReturnValues => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const isMobileDevice = useIsSmallViewportWidth();
  const isFilteringEnabled = useFilteringEnabled();
  const isLoadingState = useResourceLoadingHandler(isFilteringEnabled);
  const currentConfig = useCurrentConfig();
  const { isMapViewEnabled } = useAppInstanceConfigFeature();
  const isHeaderVisible = useMemo<boolean>(() => !isMobileDevice && !isLoadingState, [isLoadingState, isMobileDevice]);

  const currentConfigName = useMemo<string>(
    () => currentConfig?.name || intl.formatMessage({ id: 'mySuite' }),
    [currentConfig, intl],
  );

  const pathnameForPushAction = useMemo(
    () => (pathname === LANDING_PATH ? `bookings${search}` : `${pathname}/bookings${search}`),
    [pathname, search],
  );

  const showMyBookingsSection = useMemo<boolean>(
    () => !isMobileDevice && isMapViewEnabled,
    [isMapViewEnabled, isMobileDevice],
  );

  const onPressBack = useCallback(() => {
    dispatch(goBack());
    dispatch(resetResourcesState());
  }, [dispatch]);

  const onMyBookingsClick = useCallback(() => {
    dispatch(push(pathnameForPushAction));
  }, [dispatch, pathnameForPushAction]);

  return {
    currentConfigName,
    isHeaderVisible,
    onPressBack,
    showMyBookingsSection,
    onMyBookingsClick,
  };
};
