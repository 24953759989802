import { Background, Container, Content, CloseButtonContainer, ModalWrapper } from './styles';
import React, { FunctionComponent, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ModalContent } from '../components/modal-content';
import { CloseButton } from 'components/modals/checkoutModalWrapper/components/close-button';
import { useIntl } from 'react-intl';

export interface WrapperProps {
  handleChangeStep: VoidFunction;
}

export const DesktopModal: FunctionComponent<WrapperProps> = ({ handleChangeStep }: WrapperProps) => {
  const intl = useIntl();

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'initial';
    };
  }, []);

  const modal = (
    <ModalWrapper className="modal-wrapper" data-testid="modal-wrapper">
      <Background />
      <Container>
        <CloseButtonContainer>
          <CloseButton onClose={handleChangeStep} />
        </CloseButtonContainer>
        <Content>
          <ModalContent title={intl.formatMessage({ id: 'termsAndConditionsTitle' })} />
        </Content>
      </Container>
    </ModalWrapper>
  );

  return ReactDOM.createPortal(modal, document.body);
};
