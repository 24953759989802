export const RESOURCES_MAX_CHUNK_SIZE = 100;
export const FLOORPLAN_BACKGROUND_HEX = '#F3F5F8';
export const FLOORPLAN_COLORS = {
  green: [9, 121, 78],
  blue: [3, 124, 194],
  unavailable_unselected: [255, 153, 1],
};

export const FLOORPLAN_ROOMSTAM_DISPLAY = ['usage'];

export const FLOORPLAN_SPACE_TYPES = [
  'privateOffice',
  'focusRoom',
  'openWorkspace',
  'enclosedWorkspace',
  'meetingRoom',
  'hub',
  'assembly',
  'boardRoom',
  'trainingRoom',
  'living',
  'dining',
  'dining_living',
  'homeOffice',
  'bedroom',
  'lobby',
  'reception',
  'lounge',
  'cafe',
  'canteen',
  'pantry',
  'terrace',
  'balcony',
  'rooftop',
  'courtyard',
  'garden',
  'serviceKitchen',
  'printStation',
  'storage',
  'mailRoom',
  'operationalRoom',
  'serviceRoom',
  'laundryRoom',
  'kitchen',
  'wellnessRoom',
  'restroom',
  'bathroom',
  'shower',
  'toilet',
  'corridor',
  'foyer',
  'staircase',
  'elevator',
  'parkingSpot',
  'driveway',
  'garage',
  'shaft',
  'buildingOpening',
  'floorOpening',
];

export const FLOORPLAN_SELECT_FLOOR_STORAGE_KEY = 'Floorplan.selectedFloor';
