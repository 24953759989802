import { DateTimeSelectPageContent } from './components/date-time-select-page-content';
import { DateTimeSelectPageDesktop } from './components/date-time-select-page-desktop';
import { DateTimeSelectPageFooter } from './components/date-time-select-page-footer';
import { DateTimeSelectPageProps } from './interface';
import { DayLabels } from 'pages/date-select-page/components/day-labels';
import React from 'react';
import { useIntl } from 'react-intl';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport/';
import { useToggleNativeHeader } from 'hooks/use-toggle-native-header/use-toggle-native-header.hook';
import { useToggleSwipeGestures } from 'hooks/use-toggle-swipe-gestures/use-toggle-swipe-gestures.hook';
import { StyledClearButton, StyledSwipeModal } from './styles';
import { useClearButton } from './hooks/use-clear-button.hook';
import { useMapViewOverflow } from 'hooks/use-map-view-overflow.hook';

export const DateTimeSelectPage = ({
  onClose,
  onPressBack,
  onSearchClick,
  isDateStep,
  onSkipClick,
  onNextClick,
  backToDateStep,
  reverseHorizontalAnimation,
  isModalWithoutSlideAnimation,
}: DateTimeSelectPageProps): JSX.Element => {
  const intl = useIntl();
  const isMobileDevice = useIsSmallViewportWidth();
  const dateTimeTitle = isDateStep ? intl.formatMessage({ id: 'whenBooking' }) : intl.formatMessage({ id: 'whatTime' });
  useToggleNativeHeader();
  useToggleSwipeGestures();
  const { isActive, isClearAllButtonVisible, onClearAllClick } = useClearButton({ isDateStep });
  useMapViewOverflow();

  const clearButton: JSX.Element = (
    <StyledClearButton
      isActive={isActive}
      size="xxs"
      text={intl.formatMessage({ id: 'clearAll' })}
      variant="closeButton"
      onClick={onClearAllClick}
      multiline={false}
      disabled={!isActive}
    />
  );

  return isMobileDevice ? (
    <StyledSwipeModal
      isModalWithoutSlideAnimation={isModalWithoutSlideAnimation}
      reverseHorizontalAnimation={reverseHorizontalAnimation}
      reverseAnimation={false}
      title={dateTimeTitle}
      subtitle={isDateStep && <DayLabels />}
      className="date-time-select-page"
      content={<DateTimeSelectPageContent isDateStep={isDateStep} />}
      footerContent={
        <DateTimeSelectPageFooter
          onSearchClick={onSearchClick}
          isDateStep={isDateStep}
          onNextClick={onNextClick}
          onSkipClick={onSkipClick}
        />
      }
      onClose={onClose}
      onPressBack={onPressBack}
      withLeftArrowButton={backToDateStep}
      withLeftExitButton={!backToDateStep}
      rightIcon={isClearAllButtonVisible && clearButton}
    />
  ) : (
    <DateTimeSelectPageDesktop
      onClose={onClose}
      onSearchClick={onSearchClick}
      onNextClick={onNextClick}
      onSkipClick={onSkipClick}
      isDateStep={isDateStep}
      backToDateStep={backToDateStep}
      onPressBack={onPressBack}
    />
  );
};
