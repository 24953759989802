import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';
import { track } from '@hqo/web-tracking';
import { useCurrentLocation } from 'hooks/use-current-location.hook';
import { useSelector } from 'react-redux';
import { resourcesState } from 'store/resources/selectors';
import { useResourceLoadingHandler } from './resourceLoadingHandler.hook';
import { useFilteringEnabled } from 'hooks/use-filtering-enabled.hook';
import { getResourceTrackInfo } from 'utils/getResourceTrackInfo';
import { useLocation } from 'react-router-dom';

export const useWebTracking = (): void => {
  const { ...queryParams } = useSearchParams();
  const isFilteringEnabled = useFilteringEnabled();
  const { filterByType, startDates, startDate, startTime, endTime, duration, filterByFloor } = queryParams;
  const currentLocationName = useCurrentLocation();
  const { resources } = useSelector(resourcesState);
  const isLoadingState = useResourceLoadingHandler(isFilteringEnabled);
  const multiDay = !!startDates;
  const { pathname } = useLocation();
  const isModalOpen = pathname.includes('/date-time-select') || pathname.includes('/filter-by');

  const filterEntries = useMemo(
    () => Object?.entries({ filterByType, startDate, startTime, endTime, duration, filterByFloor, multiDay }),
    [duration, endTime, filterByType, startDate, multiDay, startTime, filterByFloor],
  );
  const filtersForTracking = useMemo(
    () => filterEntries?.map(entry => (entry[1] ? entry[0] : null)).filter(value => value !== null),
    [filterEntries],
  );

  useEffect(() => {
    if (!isLoadingState && !!resources?.length && !isModalOpen) {
      track(
        TRACK_EVENT_NAMES.RESOURCE_LIST_IMPRESSION,
        {
          type: TRACK_EVENT_TYPES.IMPRESSION,
          filters_applied: filtersForTracking,
          location_name: currentLocationName,
          resources: resources.reduce(
            (resourceList, resource) => ({ ...resourceList, [resource.id]: getResourceTrackInfo(resource) }),
            [],
          ),
        },
        { sendToHqoTracking: true },
      );
    }
  }, [isLoadingState, filtersForTracking]);
};
