import { useOwnerParams } from 'hooks/use-owner-params.hook';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getFloors } from 'store/spaceManager/actions';

export const useSpaceManager = () => {
  const dispatch = useDispatch();
  const { ownerUuid } = useOwnerParams();

  useEffect(() => {
    dispatch(getFloors.request({ buildingUuid: ownerUuid }));
  }, [ownerUuid]);
};
