import { useCallback, useEffect, useMemo } from 'react';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { push } from 'store/router/actions';
import { handleClearedString } from './utils/handleClearedString';
import moment from 'moment';
import { useTimeZone } from 'hooks/use-timezone.hook';

export const usePassedTime = (): void => {
  const { pathname, search } = useLocation();
  const { startDate, startTime } = useSearchParams();
  const timezone = useTimeZone();
  const queryParams = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]);
  const dispatch = useDispatch();

  const pushCallback = useCallback(
    (queryString: any) => {
      if (!pathname.includes('date-schedule-select')) {
        dispatch(push(`${pathname}?${queryString}`));
      }
    },
    [pathname],
  );

  useEffect(() => {
    const dateNow = moment().utc().format('DD.MM.YYYY');

    if (startTime) {
      const selectedDate = new Date(startDate as string);
      const [hours, minutes] = (startTime as string)?.split(':');
      const selectedUTCDate = new Date(
        selectedDate.getUTCFullYear(),
        selectedDate.getUTCMonth(),
        selectedDate.getUTCDate(),
      );
      selectedUTCDate.setHours(+hours, +minutes);

      handleClearedString(selectedUTCDate, queryParams, pushCallback);
    }

    if (startDate && !startTime) {
      const selectedTime = moment(startDate as string)
        .tz(timezone, true)
        .format('DD.MM.YYYY');

      if (dateNow !== selectedTime) {
        handleClearedString(new Date(startDate as string), queryParams, pushCallback);
      }
    }
  }, [startDate, startTime, dispatch]);
};
