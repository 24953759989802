import React, { useCallback, useEffect, useState } from 'react';

import { SelectedDateTimeFilter } from 'components/selected-date-time-filter/selected-date-time-filter';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ACTION_STATUSES } from 'shared/consts';
import { selectPriceStatus } from 'store/price/selectors';

import { ScheduleSelectPageFooterProps } from '../interface';
import { ButtonsWrapper, Container, StyledButton } from './styles';
import { useRedirectToNextStep } from './use-on-submit-booking-request.hook';
import { selectResourceTimeRanges } from 'store/resource-time-ranges/selectors';

export const ScheduleSelectPageFooter = ({
  onDateLinkClick,
  onTimeLinkClick,
  onPressSave,
}: ScheduleSelectPageFooterProps): JSX.Element => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const { showResourceDetailsDateTimeInputs } = useFlags();
  const { startTime, endTime, startDate, startDates } = useSearchParams();
  const [isDisabled, setIsDisabled] = useState(true);
  const isPriceStatusPending = useSelector(selectPriceStatus) === ACTION_STATUSES.PENDING;
  const showDateTimeLinks = !showResourceDetailsDateTimeInputs || pathname.includes('resources');
  const handleOnClick = useRedirectToNextStep();
  const isTimeRangesExist = useSelector(selectResourceTimeRanges) === null;

  const handleOnPressContinue = useCallback(() => {
    onPressSave();
    handleOnClick();
  }, [handleOnClick, onPressSave]);

  useEffect(() => {
    setIsDisabled(!(startTime && endTime && (startDate || startDates)));
  }, [startTime, endTime, isDisabled, startDate, startDates]);
  const disabledBasedOnParams = isDisabled || isPriceStatusPending || isTimeRangesExist;
  return (
    <ButtonsWrapper data-testid="time-select-buttons-wrapper" setWrapperWidth={!showDateTimeLinks}>
      <Container>
        <SelectedDateTimeFilter
          onDateClick={onDateLinkClick}
          onTimeClick={onTimeLinkClick}
          showDateTimeLinks={showDateTimeLinks}
        />
        <StyledButton
          data-testid="continue-button"
          onClick={handleOnPressContinue}
          text={intl.formatMessage({ id: 'save' })}
          disabled={disabledBasedOnParams}
          multiline={false}
        />
      </Container>
    </ButtonsWrapper>
  );
};
