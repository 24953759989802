import styled from 'styled-components';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';

export const Container = styled.div`
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ScheduleContainer = styled.div`
  width: 100%;
  height: 400px;
  flex-grow: 1;
  overflow: auto;
  padding: 10px 24px 0 24px;
  scroll-behavior: smooth;
  transition: all 0.3s ease-out;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding: 10px 0 0 0;
  }
`;

export const ActiveBlockContainer = styled.div`
  display: flex;
  position: relative;
`;
