import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { ACTION_STATUSES } from 'shared/consts';
import { CartState } from './types';

const initialState: CartState = {
  cart_id: null,
  createCart: {
    status: null,
    error: null,
  },
  cart: null,
  transaction: null,
  getCart: {
    status: null,
    error: null,
  },
  submitCart: {
    status: null,
    error: null,
  },
  complete3DSCart: {
    status: null,
    error: null,
  },
};

const createCartRequestHandler = (state: CartState): CartState => ({
  ...state,
  createCart: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const createCartSuccessHandler = (
  state: CartState,
  { payload }: ActionType<typeof actions.createCart.success>,
): CartState => {
  return {
    ...state,
    cart_id: payload.cart_id,
    createCart: {
      error: null,
      status: ACTION_STATUSES.FULFILLED,
    },
  };
};

const createCartFailureHandler = (
  state: CartState,
  { payload: error }: ActionType<typeof actions.createCart.failure>,
): CartState => ({
  ...state,
  createCart: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const resetCreateCartHandler = (state: CartState): CartState => ({
  ...state,
  createCart: {
    ...initialState.createCart,
  },
});

const getCartRequestHandler = (state: CartState): CartState => ({
  ...state,
  getCart: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getCartSuccessHandler = (
  state: CartState,
  { payload }: ActionType<typeof actions.getCart.success>,
): CartState => ({
  ...state,
  cart: payload,
  getCart: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getCartFailureHandler = (
  state: CartState,
  { payload: error }: ActionType<typeof actions.getCart.failure>,
): CartState => ({
  ...state,
  getCart: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const complete3DSCartRequestHandler = (state: CartState): CartState => ({
  ...state,
  complete3DSCart: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const complete3DSCartSuccessHandler = (
  state: CartState,
  { payload }: ActionType<typeof actions.complete3DSCart.success>,
): CartState => ({
  ...state,
  transaction: payload,
  complete3DSCart: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const complete3DSCartFailureHandler = (
  state: CartState,
  { payload: error }: ActionType<typeof actions.complete3DSCart.failure>,
): CartState => ({
  ...state,
  complete3DSCart: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const complete3DSCartResetHandler = (state: CartState): CartState => ({
  ...state,
  transaction: null,
  complete3DSCart: {
    error: null,
    status: null,
  },
});

const submitCartRequestHandler = (state: CartState): CartState => ({
  ...state,
  submitCart: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const submitCartSuccessHandler = (
  state: CartState,
  { payload }: ActionType<typeof actions.submitCart.success>,
): CartState => ({
  ...state,
  cart: payload,
  submitCart: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const submitCartFailureHandler = (
  state: CartState,
  { payload: error }: ActionType<typeof actions.submitCart.failure>,
): CartState => ({
  ...state,
  submitCart: {
    error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const resetSubmitCartStatusHandler = (state: CartState): CartState => ({
  ...state,
  submitCart: {
    error: null,
    status: null,
  },
});

const resetGetCartStatusHandler = (state: CartState): CartState => ({
  ...state,
  getCart: {
    error: null,
    status: null,
  },
});

const resetCartHandler = (state: CartState): CartState => ({
  ...state,
  cart_id: null,
  cart: null,
  getCart: {
    error: null,
    status: null,
  },
  createCart: {
    error: null,
    status: null,
  },
});

const cartReducer = createReducer(initialState)
  .handleAction(actions.createCart.request, createCartRequestHandler)
  .handleAction(actions.createCart.success, createCartSuccessHandler)
  .handleAction(actions.createCart.failure, createCartFailureHandler)
  .handleAction(actions.resetCreateCartStatus, resetCreateCartHandler)
  .handleAction(actions.getCart.request, getCartRequestHandler)
  .handleAction(actions.getCart.success, getCartSuccessHandler)
  .handleAction(actions.getCart.failure, getCartFailureHandler)
  .handleAction(actions.complete3DSCart.request, complete3DSCartRequestHandler)
  .handleAction(actions.complete3DSCart.success, complete3DSCartSuccessHandler)
  .handleAction(actions.complete3DSCart.failure, complete3DSCartFailureHandler)
  .handleAction(actions.resetComplete3DSCart, complete3DSCartResetHandler)
  .handleAction(actions.submitCart.request, submitCartRequestHandler)
  .handleAction(actions.submitCart.success, submitCartSuccessHandler)
  .handleAction(actions.submitCart.failure, submitCartFailureHandler)
  .handleAction(actions.resetGetCartStatus, resetGetCartStatusHandler)
  .handleAction(actions.resetCart, resetCartHandler)
  .handleAction(actions.resetSubmitCartStatus, resetSubmitCartStatusHandler);

export default cartReducer;
