import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useCallback, useMemo } from 'react';
import qs from 'qs';
import { push } from 'store/router/actions';
import { resetResourcesState } from 'store/resources/actions';
import { pick } from 'utils/pickObjectProperty';
import { QUERY_PARAMS } from 'shared/consts';
import { useFilteringEnabled } from 'hooks/use-filtering-enabled.hook';

interface useCapacityContentReturnValues {
  isFilteringEnabled: boolean;
  onLabelClick: (value: number) => void;
  onAllCapacityLabelClick: VoidFunction;
}

export const useCapacityContent = (): useCapacityContentReturnValues => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const isFilteringEnabled = useFilteringEnabled();

  const queryParams = useMemo<qs.ParsedQs>(() => {
    return qs.parse(search, { ignoreQueryPrefix: true });
  }, [search]);

  const onLabelClick = useCallback(
    (value: number) => {
      const queryString = qs.stringify({ ...queryParams, capacity: value });

      dispatch(push(`resource-booking/resources?${queryString}`));
      dispatch(resetResourcesState());
    },
    [dispatch, queryParams],
  );

  const onAllCapacityLabelClick = useCallback(() => {
    const allCapacity = qs.stringify(
      pick(
        queryParams,
        QUERY_PARAMS.filter(param => param !== 'capacity'),
      ),
    );

    dispatch(push(`resource-booking/resources?${allCapacity}`));
    dispatch(resetResourcesState());
  }, [dispatch, queryParams]);

  return { isFilteringEnabled, onLabelClick, onAllCapacityLabelClick };
};
