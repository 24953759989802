import { StyledChipComponent } from 'pages/resources/filter-bar/styles';
import React from 'react';

import { useFloorPill } from './use-floor-pill.hook';

export const FloorPill = (): JSX.Element => {
  const { selectedFloor, onCancelClick } = useFloorPill();

  if (!selectedFloor) {
    return <></>;
  }

  return (
    <StyledChipComponent
      label={selectedFloor?.name}
      value={selectedFloor?.uuid}
      isActive
      cancelEnabled
      onCancel={onCancelClick}
    />
  );
};
