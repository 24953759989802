import React, { useEffect } from 'react';

import { ArrowBackLink } from 'components/arrow-back-link';
import { ModalPortal } from 'components/modals/modal-portal/modal-portal';
import { useDateScheduleModalNavigation } from 'hooks/use-date-schedule-modal-navigation.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useClearButton } from 'pages/date-time-select-page/hooks/use-clear-button.hook';
import { ScheduleSelectPageFooter } from 'pages/schedule-select-page/components/schedule-select-footer';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { TimeSelectPageContent } from '../components/time-select-page-content';
import { TimeSelectPageFooter } from '../components/time-select-page-footer';
import { TimeSelectPageProps } from '../interface';
import {
  BackLinkWrapper,
  Container,
  Content,
  FooterContainer,
  Header,
  StyledClearButton,
  StyledModal,
  Title,
} from './styles';

export const TimeSelectDesktopPage = ({
  onClose,
  onSearchClick,
  onSkipClick,
  onPressBack,
  onPressSave,
  backToDateStep,
}: TimeSelectPageProps): JSX.Element => {
  const intl = useIntl();
  const { isActive, isClearAllButtonVisible, onClearAllClick } = useClearButton({ isDateStep: false });
  const { showResourceDetailsDateTimeInputs } = useFlags();
  const { pathname } = useLocation();

  const { onDateTimeSaveClick } = useDateScheduleModalNavigation();

  const showScheduleSelectPageFooter = pathname.includes('date-schedule-select') && showResourceDetailsDateTimeInputs;

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, []);

  return (
    <ModalPortal>
      <StyledModal opened onClose={onClose} dataTestId="modal-window" data-cy="modal-window">
        <Container className="time-select-page-desktop" data-testid="time-select-page-desktop">
          {backToDateStep && (
            <BackLinkWrapper>
              <ArrowBackLink onClick={onPressBack} />
            </BackLinkWrapper>
          )}
          <Header>
            <Title>{intl.formatMessage({ id: 'whatTime' })}</Title>
            {isClearAllButtonVisible && (
              <StyledClearButton
                isActive={isActive}
                size="xxs"
                text={intl.formatMessage({ id: 'clearAll' })}
                variant="closeButton"
                onClick={onClearAllClick}
                multiline={false}
                disabled={!isActive}
              />
            )}
          </Header>
          <Content>
            <TimeSelectPageContent />
          </Content>
          <FooterContainer>
            {showScheduleSelectPageFooter ? (
              <ScheduleSelectPageFooter onPressSave={onDateTimeSaveClick} />
            ) : (
              <TimeSelectPageFooter onSearchClick={onSearchClick} onSkipClick={onSkipClick} onPressSave={onPressSave} />
            )}
          </FooterContainer>
        </Container>
      </StyledModal>
    </ModalPortal>
  );
};
