import { useMemo } from 'react';
import { Resource } from 'store/resource/types';

export default function useResourcePaymentInfo(resource: Resource) {
  return useMemo(
    () => ({
      isPaid: resource.booking_windows.some(
        window => window.pricing_rules && !!window.pricing_rules[0]?.min_interval_price,
      ),
      isFree: resource.booking_windows.some(
        window => window.pricing_rules && window.pricing_rules[0]?.min_interval_price === 0,
      ),
    }),
    [resource],
  );
}
