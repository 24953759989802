import { Resource } from 'store/resource/types';
import { ResourceParams } from 'shared/types';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { FailureActionPayload } from '../errors/types';

export const getResource = createAsyncAction('GET_RESOURCE_REQUEST', 'GET_RESOURCE_SUCCESS', 'GET_RESOURCE_FAILURE')<
  ResourceParams,
  Resource,
  FailureActionPayload
>();

export const resetResource = createAction('RESET_RESOURCE')<void>();

export const imageIsLoaded = createAction('IMAGE_IS_LOADED')<boolean>();

export const setInitialQueryString = createAction('SET_INITIAL_QUERY_STRING')<string>();
