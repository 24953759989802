import { createSelector } from 'reselect';
import { ConfigState } from 'store/config/types';
import { RootState } from 'store/reducer';

export const configSelector = (state: RootState): ConfigState => state.config;

export const hasToken = createSelector(configSelector, ({ authToken }: RootState['config']): boolean => !!authToken);

export const selectAccessToken = createSelector(
  configSelector,
  ({ authToken }: RootState['config']): string => authToken,
);
