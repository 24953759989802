import React from 'react';
import { Container, Square, Bar, FlexContainer, CounterBar, DesktopBar, TopCounterBar } from './styles';
import { Resource } from './components/resource';
import { CompactSkeleton } from '../compact-skeleton';

interface SkeletonProps {
  isCompactUiEnabled?: boolean;
}

export const Skeleton = ({ isCompactUiEnabled = false }: SkeletonProps): JSX.Element => {
  return isCompactUiEnabled ? (
    <CompactSkeleton />
  ) : (
    <Container data-testid="skeleton">
      <FlexContainer gap={48} isWidth isColumnDirection className="desktop">
        <TopCounterBar />
        <FlexContainer gap={28}>
          <Square /> <DesktopBar />
        </FlexContainer>
        <CounterBar />
        <FlexContainer gap={48} isWrap>
          <Resource isDesktop />
          <Resource isDesktop />
          <Resource isDesktop />
          <Resource isDesktop />
          <Resource isDesktop />
          <Resource isDesktop />
        </FlexContainer>
      </FlexContainer>
      <FlexContainer gap={25} isColumnDirection isWidth className="mobile">
        <TopCounterBar />
        <FlexContainer gap={19} isWidth>
          <Square />
          <FlexContainer isColumnDirection gap={11} isWidth>
            <Bar />
          </FlexContainer>
        </FlexContainer>
        <CounterBar />
        <Resource />
        <Resource />
        <Resource />
      </FlexContainer>
    </Container>
  );
};
