import { WheelPicker } from 'components/wheel-picker-view';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { BuildingGroupSelectStyledModalWrapper, SaveButton, SaveButtonWrapper } from './styles';
import { ModalPortal } from 'components/modals/modal-portal/modal-portal';
import { BuildingGroupItem } from 'store/spaceManager/types';

interface BuildingGroupPickerContentProps {
  onWheelChange: React.Dispatch<React.SetStateAction<string>>;
  pickedBuildingGroupValue: string;
  options: BuildingGroupItem[];
  toggleBuildingGroupPickerWheel: VoidFunction;
}

export const BuildingGroupPickerWheel = ({
  onWheelChange,
  pickedBuildingGroupValue,
  options,
  toggleBuildingGroupPickerWheel,
}: BuildingGroupPickerContentProps): JSX.Element => {
  const intl = useIntl();

  const onChange = useCallback(
    (value: any) => {
      onWheelChange(value);
    },
    [onWheelChange],
  );

  const onClick = useCallback(toggleBuildingGroupPickerWheel, [toggleBuildingGroupPickerWheel]);

  return (
    <ModalPortal>
      <BuildingGroupSelectStyledModalWrapper>
        <SaveButtonWrapper>
          <SaveButton onClick={onClick}>{intl.formatMessage({ id: 'save' })}</SaveButton>
        </SaveButtonWrapper>
        <WheelPicker items={options} value={pickedBuildingGroupValue} onChange={onChange} />
      </BuildingGroupSelectStyledModalWrapper>
    </ModalPortal>
  );
};
