import { AddOnsState } from './types';
import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';

export const addOnsSelector = (state: RootState): AddOnsState => state.addOns;

export const selectAllAddOns = createSelector([addOnsSelector], addons => addons.addOns);

export const selectAddonWithId = createSelector([addOnsSelector, (_, addOnId) => addOnId], (state, addOnId) => {
  const [selectedAddon] = state.addOns?.filter(addon => addon.id === addOnId);

  return selectedAddon;
});

export const selectAllAddonsPrice = createSelector([selectAllAddOns], allAddOns =>
  allAddOns.reduce((partialSum, addOn) => partialSum + addOn.price * addOn.quantity, 0),
);

export const selectAllNonRequiredAddonsPrice = createSelector([selectAllAddOns], allAddOns => {
  const selectedAddon = allAddOns?.filter(addon => !addon.required);

  return selectedAddon.reduce((partialSum, addOn) => partialSum + addOn.price * addOn.quantity, 0);
});

export const selectAddonsPriceWithId = createSelector(
  [selectAllAddOns, (_, addOnId) => addOnId],
  (allAddOns, addOnId) => {
    const [selectedAddon] = allAddOns.filter(addon => addon.id === addOnId);

    return selectedAddon.price * selectedAddon.quantity;
  },
);
