import { selectAppInstanceConfigs, selectCurrentAppInstanceConfigUuid } from 'store/app-instance-configs/selectors';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useCurrentAdapterName = (): string => {
  const appInstancesConfigs = useSelector(selectAppInstanceConfigs);
  const currentConfigUuid = useSelector(selectCurrentAppInstanceConfigUuid);

  return useMemo(() => {
    return appInstancesConfigs?.find(config => config?.uuid === currentConfigUuid)?.vertical_adapter?.name;
  }, [appInstancesConfigs, currentConfigUuid]);
};
