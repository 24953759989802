import styled from 'styled-components';

export const DurationFilterContainer = styled.div`
  margin-top: 35px;
`;

export const DurationFilterTitle = styled.div<{ isDisabled: boolean }>`
  margin-bottom: 16px;
  font: normal 16px/19px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};
  ${({ isDisabled, theme }) => isDisabled && `color: ${theme.colors.greys.sharedMediumGrey};`}
`;
