import * as actions from './actions';

import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { AjaxResponse } from 'rxjs/ajax';
import { Epic } from 'redux-observable';
import { GetPriceAPIResponse } from './types';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';
import { transformErrorIntoSerializableError } from 'store/utils';

export const getPriceEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getPrice.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      return apiClient(state)
        .getPrice(payload)
        .pipe(
          map(({ response }: AjaxResponse<GetPriceAPIResponse>) => {
            return actions.getPrice.success({
              price: response.data.price,
            });
          }),
          catchError((error: Error) =>
            of(actions.getPrice.failure({ error: transformErrorIntoSerializableError(error) })),
          ),
        );
    }),
  );
