import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';
import styled from 'styled-components';
import { EXTRA_LARGE_DIMENSION, PLUS_LARGE_DIMENSION } from 'shared/consts';

const TOP_DESKTOP_CONTENT_HEIGHT = 350;
const TOP_DESKTOP_CONTENT_HEIGHT_WITH_BOOKINGS = 530;
const TOP_MOBILE_CONTENT_HEIGHT = 180;
const TOP_MOBILE_CONTENT_HEIGHT_WITH_BOOKINGS = 390;

export const Container = styled.div<{ isLoading?: boolean }>`
  padding: 34px 52px 0 52px;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${({ isLoading }) => isLoading && 'padding: 0;'};

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding: 32px 16px 0 16px;
    ${({ isLoading }) => isLoading && 'padding: 0;'}
  }

  @media only screen and (max-width: ${DIMENSIONS.SMALL}) {
    padding: 28px 0 0 0;
  }
`;

export const HeaderContentContainer = styled.div`
  width: 100%;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding: 0 13px;
  }
`;

export const Grid = styled.div<{
  isNoResources: boolean;
  isCompactUiEnabled: boolean;
  isSwipeModalContent?: boolean;
  isUpcomingBookingsSectionVisible?: boolean;
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${({ isSwipeModalContent }) => !isSwipeModalContent && 'overflow: auto;'}
  height: calc(100vh - ${TOP_DESKTOP_CONTENT_HEIGHT}px);
  ${({ isUpcomingBookingsSectionVisible }) =>
    isUpcomingBookingsSectionVisible && `height: calc(100vh - ${TOP_DESKTOP_CONTENT_HEIGHT_WITH_BOOKINGS}px);`}
  gap: 24px;

  &:last-child {
    padding-bottom: 24px;
  }

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding: 0 13px;
    flex-direction: column;
    height: calc(100vh - ${TOP_MOBILE_CONTENT_HEIGHT}px);
    ${({ isUpcomingBookingsSectionVisible }) =>
      isUpcomingBookingsSectionVisible && `height: calc(100vh - ${TOP_MOBILE_CONTENT_HEIGHT_WITH_BOOKINGS}px);`}
    flex-wrap: nowrap;
  }
`;

export const ResourcesCounter = styled.div`
  font: normal 16px/19px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  margin-bottom: 27px;
  gap: 14px;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding: 0 13px;
  }
`;

export const StyledBackLink = styled.div`
  margin-bottom: 18px;
`;

export const ContentContainer = styled.div<{ shouldShowMapView?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ResourcesListContainer = styled.div<{ isMapViewEnabled?: boolean; isLoadingState?: boolean }>`
  width: ${({ isMapViewEnabled, isLoadingState }) => (isMapViewEnabled && !isLoadingState ? '65%' : '100%')};
  height: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${EXTRA_LARGE_DIMENSION}) {
    width: ${({ isMapViewEnabled, isLoadingState }) => (isMapViewEnabled && !isLoadingState ? '50%' : '100%')};
  }

  @media only screen and (max-width: ${PLUS_LARGE_DIMENSION}) {
    width: ${({ isMapViewEnabled, isLoadingState }) => (isMapViewEnabled && !isLoadingState ? '35%' : '100%')};
  }

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    width: 100%;
  }
`;
