import { searchParams } from 'components/paymentIFrame/paymentIFrame.interface';
import { useLocale } from 'hooks/use-locale.hook';
import { useOwnerParams } from 'hooks/use-owner-params.hook';
import { FormattedDates } from 'hooks/use-preselected-day-loading/use-preselected-day-loading.interface';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { OwnerTypesEnum } from 'store/app-instance-configs/enums';
import { resourceState } from 'store/bookings/selectors';
import { getResourceInfiniteAvailableDates } from 'store/resource-available-dates/actions';
import { selectResourceInfiniteAvailableDates } from 'store/resource-available-dates/selectors';
import { getFormattedDates } from 'utils/getFormattedDates';
import { getMonthOffset } from 'utils/getMonthOffset';

export const usePreselectedDayLoading = (calendarContainerRef: React.MutableRefObject<HTMLDivElement>): void => {
  const dispatch = useDispatch();
  const { startDate } = useSearchParams<searchParams>();
  const { ownerUuid } = useOwnerParams();
  const { pathname } = useLocation();
  const isDateTimeSelect = pathname.includes('date-time-select');
  const resourceId = useSelector(resourceState).resource.id;
  const resourceAvailableDates = useSelector(selectResourceInfiniteAvailableDates);
  const numberOfMonths = Object.keys(resourceAvailableDates).length;
  const locale = useLocale();
  const monthOffset = useMemo<number>(() => getMonthOffset(startDate), [startDate]);
  const monthsLeftToLoad = Math.max(monthOffset - numberOfMonths, 0);
  const { formattedFirstDay, formattedLastDay } = useMemo<FormattedDates>(() => {
    return getFormattedDates(numberOfMonths, locale);
  }, [locale, monthsLeftToLoad]);

  useEffect(() => {
    if (monthsLeftToLoad && !calendarContainerRef.current.scrollTop) {
      dispatch(
        getResourceInfiniteAvailableDates.request({
          ownerType: OwnerTypesEnum.BUILDING,
          ownerUuid,
          resourceId,
          from: formattedFirstDay,
          to: formattedLastDay,
          indexOfMonth: numberOfMonths,
        }),
      );
    }
  }, [startDate, formattedFirstDay, formattedLastDay, monthsLeftToLoad]);

  useEffect(() => {
    const monthCollection = calendarContainerRef.current.querySelectorAll(`
    [data-testid="calendar-view-container"] > div,
    [data-testid="calendar-view-container"] > section
  `);
    let penultMonth: HTMLElement;

    if (startDate && !monthsLeftToLoad && !isDateTimeSelect) {
      const isNextTwoMonthsSelected = monthOffset <= 1;
      if (isNextTwoMonthsSelected) {
        penultMonth = monthCollection[monthOffset] as HTMLElement;
      } else {
        penultMonth = monthCollection[monthCollection.length - 2] as HTMLElement;
      }
    } else if (isDateTimeSelect) {
      penultMonth = monthCollection[monthOffset] as HTMLElement;
    }
    penultMonth?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }, [monthsLeftToLoad, startDate]);
};
