import React from 'react';
import { DateTimeSelectModalDesktopProps } from '../interface';
import { DateSelectDesktopModal } from 'pages/resource/date-select-modal/desktop';
import { ScheduleSelectDesktopPage } from 'pages/schedule-select-page/desktop';
import { TimeSelectDesktopPage } from 'pages/time-select-page/desktop';
import { useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const DateTimeSelectModalDesktop = ({
  isDateStep,
  onDateLinkClick,
  onTimeLinkClick,
  onNextClick,
  onClose,
  handleOnClose,
  onPressBack,
  backToDateStep,
  onPressSave,
  onDateTimeSaveClick,
}: DateTimeSelectModalDesktopProps): JSX.Element => {
  const { showResourceDetailsDateTimeInputs } = useFlags();
  const { pathname } = useLocation();

  const TimeSelectDesktopModal =
    showResourceDetailsDateTimeInputs && !pathname.includes('resources') && !pathname.includes('floorplan') ? (
      <TimeSelectDesktopPage onPressSave={onPressSave} onClose={onClose} />
    ) : (
      <ScheduleSelectDesktopPage
        onPressSave={onPressSave}
        onClose={handleOnClose}
        onDateLinkClick={onDateLinkClick}
        onTimeLinkClick={onTimeLinkClick}
        onPressBack={onPressBack}
        backToDateStep={backToDateStep}
      />
    );

  return isDateStep ? (
    <DateSelectDesktopModal
      onClose={onClose}
      onDateLinkClick={onDateLinkClick}
      onNextClick={onNextClick}
      onTimeLinkClick={onTimeLinkClick}
      onSaveClick={onDateTimeSaveClick}
    />
  ) : (
    TimeSelectDesktopModal
  );
};
