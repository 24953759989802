import { RRule } from 'rrule';
import { BookingWindow, Resource } from 'store/resource/types';
import { TimeRange } from 'store/resource-time-ranges/types';
import { getCurrentBookingWindow, getCurrentWeekday } from './getCurrentBookingWindow';
import { getTimeRangesWithLastTimeSlot } from './getTimeRangesWithLastTimeSlot';
import {
  DEFAULT_TIME_INTERVAL,
  EMPTY_STRING,
  MIDNIGHT_HOURS,
  MIDNIGHT_HOURS_24H,
} from '../components/schedule-select/const';
import { getOpeningHours } from './getOpeningHours/getOpeningHours';
import { daysOfWeek, MappedWeekDays } from './getOpeningHours/getOpeningHours.enum';

export interface getResourceTimeIndicesOptions {
  isMultiday?: boolean;
}

interface getResourceTimeIndicesProps {
  resource: Resource;
  startDate: string;
  startDates?: Array<string>;
  locale: string;
  resourceTimeRanges: TimeRange[];
  options?: getResourceTimeIndicesOptions;
}

interface getResourceTimeIndicesReturnValues {
  startResourceTimeIndex: number;
  endResourceTimeIndex: number;
  timeRangesWithLastTimeSlot: TimeRange[];
  minimumDuration: number;
  maximumDuration: number;
  hours: number[];
}

interface MultidayOpenCloseTime {
  closingTime: string;
  openingTime: string;
}

export const getTimeValue = (hours: number): string => {
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(0);
  if (hours === 0) {
    return '00:00';
  }
  if (hours === 24) {
    return '24:00';
  }

  return date.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: false });
};

export const getMinimumDuration = (resourceTimeRanges: TimeRange[], currentBookingWindow: BookingWindow): number => {
  const timeRangeDuration = resourceTimeRanges?.reduce(
    (acc, { minimum_duration }) => (acc > minimum_duration ? acc : minimum_duration),
    0,
  );
  const timeInterval = currentBookingWindow?.time_interval || DEFAULT_TIME_INTERVAL;
  const minimumDuration = currentBookingWindow?.minimum_duration || timeRangeDuration || timeInterval;
  if (minimumDuration < timeInterval) {
    return timeInterval;
  }

  return minimumDuration;
};

export const getHours = (openingHour: number, closingHour: number): number[] | null => {
  const formattedClosingHour = closingHour === 0 ? 24 : closingHour;
  if ((!openingHour && openingHour !== 0) || !formattedClosingHour) {
    return null;
  }

  return Array.from(Array(1 + (formattedClosingHour - openingHour)).keys())?.map(hour => hour + openingHour);
};

export const getMultidayOpenCloseTime = (
  mappedWeekDays: MappedWeekDays,
  selectedDates: Array<string>,
): MultidayOpenCloseTime | null => {
  if (!Object.keys(mappedWeekDays)?.length || !selectedDates.length) {
    return null;
  }

  let earliestOpenHour = EMPTY_STRING;
  let latestCloseHour = EMPTY_STRING;

  selectedDates.forEach(selectedDate => {
    for (const day in mappedWeekDays) {
      const openingHours = mappedWeekDays[day].openingHours[0];
      const date = new Date(selectedDate);

      if (day === daysOfWeek[date.getUTCDay()]) {
        if (!earliestOpenHour || openingHours?.open < earliestOpenHour) {
          earliestOpenHour = openingHours?.open;
        }

        if (!latestCloseHour || openingHours?.close > latestCloseHour) {
          latestCloseHour = openingHours?.close;
        }
      }
    }
  });

  return { openingTime: earliestOpenHour, closingTime: latestCloseHour };
};

export const getResourceTimeIndices = ({
  resource,
  startDate,
  startDates,
  resourceTimeRanges,
  locale,
  options,
}: getResourceTimeIndicesProps): getResourceTimeIndicesReturnValues | null => {
  const { currentBookingWindow, count } =
    getCurrentBookingWindow({
      bookingWindows: resource.booking_windows,
      date: startDate as string,
    }) || {};
  if (!currentBookingWindow) {
    return null;
  }
  const minimumDuration = getMinimumDuration(resourceTimeRanges, currentBookingWindow);
  const maximumDuration = currentBookingWindow?.maximum_duration;
  const bookingWindowRule = RRule.fromString(currentBookingWindow?.rrule);
  const mappedWeekDays = getOpeningHours(resource.booking_windows, resource.timezone, locale, false);
  const currentWeekday = getCurrentWeekday(startDate);
  const hours =
    count > 1
      ? getHours(+mappedWeekDays[currentWeekday]?.openingHour, +mappedWeekDays[currentWeekday]?.closingHour)
      : bookingWindowRule.options.byhour;
  const timeRangesWithLastTimeSlot = getTimeRangesWithLastTimeSlot({ hours, resourceTimeRanges });

  const closingTime = options?.isMultiday
    ? getMultidayOpenCloseTime(mappedWeekDays, startDates)?.closingTime
    : mappedWeekDays[currentWeekday]?.closingTime;

  const startResourceTime = options?.isMultiday
    ? getMultidayOpenCloseTime(mappedWeekDays, startDates)?.openingTime
    : mappedWeekDays[currentWeekday]?.openingTime;

  const endResourceTime = closingTime === MIDNIGHT_HOURS ? MIDNIGHT_HOURS_24H : closingTime;

  const startResourceTimeIndex = timeRangesWithLastTimeSlot?.indexOf(
    timeRangesWithLastTimeSlot.filter(resourceTimeRange => resourceTimeRange.time === startResourceTime)?.[0],
  );
  const endResourceTimeIndex = timeRangesWithLastTimeSlot?.indexOf(
    timeRangesWithLastTimeSlot.filter(resourceTimeRange => resourceTimeRange.time === endResourceTime)?.[0],
  );

  return {
    startResourceTimeIndex,
    endResourceTimeIndex,
    timeRangesWithLastTimeSlot,
    minimumDuration,
    hours,
    maximumDuration,
  };
};
