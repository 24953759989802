import { ERROR_PATH } from 'shared/consts';

import React from 'react';
import { Navigate } from 'react-router';
import { useCurrentUser } from 'hooks/use-current-user.hook';

export const DefaultRedirect: React.FC = (): JSX.Element => {
  const [user, loading] = useCurrentUser();
  if (user || loading) {
    return <Navigate to={`/${location.search}`} replace />;
  }

  return <Navigate to={ERROR_PATH} />;
};
