import { hasToken as hasTokenSelector } from 'store/config/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { User } from 'store/user/types';
import { getCurrentUser } from 'store/user/actions';
import { useEffect } from 'react';
import { currentUser } from 'store/user/selectors';
import { useSearchParams } from 'hooks/use-search-params.hook';

export const useCurrentUser = (): [User | null, boolean] => {
  const user = useSelector(currentUser);
  const hasToken = useSelector(hasTokenSelector);
  const { authToken, appBrand, buildingUuid } = useSearchParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const getUserTimeout = setTimeout(() => {
      if ((!user && hasToken) || !user?.id || !user?.roles) {
        dispatch(getCurrentUser.request({ authToken, appBrand, buildingUuid }));
      }
    }, 1000);

    return () => {
      clearTimeout(getUserTimeout);
    };
  }, [user, hasToken, dispatch]);

  return [user, !user && hasToken];
};
