import { ACTION_STATUSES, DefaultFilterEnum, TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';
import { getResources } from 'store/resources/actions';
import {
  selectAppInstanceConfigsStatus,
  selectCurrentAppInstanceConfigUuid,
  selectCurrentLocationId,
} from 'store/app-instance-configs/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getTransactions } from 'store/transactions/actions';
import { selectPatchTransactionsStatus } from 'store/transactions/selectors';
import { useOwnerParams } from 'hooks/use-owner-params.hook';
import { resetResource } from 'store/resource/actions';
import { useCurrentAdapterName } from 'hooks/use-current-adapter-name';
import { useCurrentLocation } from 'hooks/use-current-location.hook';
import { track } from '@hqo/web-tracking';
import { resetAddOnsState } from 'store/add-ons/actions';
import { selectAllAddOns } from 'store/add-ons/selectors';
import { resetResourceAvailableDates } from 'store/resource-available-dates/actions';
import { resetResourceTimeRanges } from 'store/resource-time-ranges/actions';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const useLandingPageRequests = (): void => {
  const { ownerUuid, ownerType } = useOwnerParams();
  const dispatch = useDispatch();
  const currentLocationId = useSelector(selectCurrentLocationId);
  const appInstanceConfigsStatus = useSelector(selectAppInstanceConfigsStatus);
  const patchTransactionsStatus = useSelector(selectPatchTransactionsStatus);
  const currentLocationName = useCurrentLocation();
  const currentAdapterName = useCurrentAdapterName();
  const selectedAddOns = useSelector(selectAllAddOns);
  const { isAvailableNowEnabled, defaultFilter } = useAppInstanceConfigFeature();
  const currentAppInstanceConfigUuid = useSelector(selectCurrentAppInstanceConfigUuid);
  const { hideResourceBookingAvailableNow: isAvailableNowFeatureHidden } = useFlags();

  useEffect(() => {
    if (appInstanceConfigsStatus === ACTION_STATUSES.FULFILLED && currentLocationId) {
      if (patchTransactionsStatus !== ACTION_STATUSES.PENDING) {
        dispatch(getTransactions.request({}));
      }

      if (isAvailableNowEnabled && !isAvailableNowFeatureHidden && defaultFilter !== DefaultFilterEnum.NONE) {
        dispatch(getResources.request({ ownerType, ownerUuid, filterParams: { availableNow: 'true' } }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    defaultFilter,
    appInstanceConfigsStatus,
    dispatch,
    ownerType,
    ownerUuid,
    currentLocationId,
    isAvailableNowFeatureHidden,
    currentAppInstanceConfigUuid,
    // patchTransactionsStatus, // Removed - breaks cancellation of bookings and shows other bookings are being cancelled
    isAvailableNowEnabled,
  ]);

  useEffect(() => {
    dispatch(resetResourceAvailableDates());
    dispatch(resetResource());
    dispatch(resetResourceTimeRanges());
  }, []);

  useEffect(() => {
    if (selectedAddOns?.length) {
      dispatch(resetAddOnsState());
    }
  }, [dispatch, selectedAddOns]);

  useEffect(() => {
    if (defaultFilter === DefaultFilterEnum.NONE) {
      dispatch(getResources.request({ ownerType, ownerUuid }));
    }
  }, [dispatch, ownerType, ownerUuid, defaultFilter]);

  useEffect(() => {
    track(
      TRACK_EVENT_NAMES.RESOURCE_BOOKING_LANDING_IMPRESSION,
      {
        type: TRACK_EVENT_TYPES.IMPRESSION,
        service_provider_name: currentAdapterName,
        location_name: currentLocationName,
      },
      { sendToHqoTracking: true },
    );
  }, []);
};
