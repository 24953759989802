import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const Icon = styled(FontAwesomeIcon)`
  font-size: 60px;
  color: #696e72;
  margin-bottom: 24px;
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  height: 100vh;
  width: 100vw;
  gap: 32px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 380px;
`;

export const Button = styled.button`
  padding: 4px 24px;
  justify-content: center;
  height: inherit;
  cursor: pointer;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  margin: 0;
  text-align: center;
  font-family: Roboto, Helvetica Neue, Sans-Serif;
  font-style: normal;
  line-height: 22px;
  border-color: #257bad;
  color: #ffffff;
  background-color: #257bad;
  align-items: center;
  position: relative;
  display: flex;
  font-size: 14px;
  margin-top: 24px;
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #2d3338;
  margin: 0 0 18px 0;
  width: 256px;
  box-sizing: border-box;
  display: block;
  text-align: center;
  font-family: Roboto, Helvetica Neue, Sans-Serif;
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: #696e72;
  margin: 0;
  box-sizing: border-box;
  text-align: center;
  font-family: Roboto, Helvetica Neue, Sans-Serif;
`;
