import styled from 'styled-components';
import { SwipeModal } from '@hqo/react-components-library/dist/molecules/modals/swipe-modal';

export const StyledSwipeModal = styled(SwipeModal)`
  .content {
    height: 100%;
    box-shadow: ${({ theme }) => `0 -4px 10px 0 ${theme.colors.greys.darkGrey7}40`};
  }

  .container-content {
    height: auto;
  }

  .content-wrapper {
    height: calc(100% - 48px);
  }
`;
