import React from 'react';
import { Duration } from 'shared/consts/duration';
import { useIntl } from 'react-intl';
import { Wrapper, StyledChip } from './styles';

interface DurationComponentProps {
  durations: Duration[];
  selectedDuration: Duration;
  onClickHandler: (arg: number) => void;
  disabledDurations: Array<Duration>;
}

export const DurationComponent = ({
  durations,
  selectedDuration,
  disabledDurations,
  onClickHandler,
}: DurationComponentProps): JSX.Element => {
  const intl = useIntl();

  return (
    <Wrapper>
      {durations?.map(duration => (
        <StyledChip
          isDisabled={disabledDurations.some(disabledDuration => disabledDuration.value === duration.value)}
          key={duration.value}
          label={intl.formatMessage({ id: duration.label })}
          value={duration.value}
          isActive={duration.value === selectedDuration?.value}
          className="duration-chip"
          data-test={`duration-filter-chip-${duration}`}
          onChipClick={
            !disabledDurations.some(disabledDuration => disabledDuration.value === duration.value)
              ? onClickHandler
              : null
          }
        />
      ))}
    </Wrapper>
  );
};
