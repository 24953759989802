import { MIDNIGHT_HOURS_24H, MIDNIGHT_HOURS_24H_SAFARI } from 'components/schedule-select/const';

interface FormatOptions {
  locale: string;
  timeFormatOptions: Intl.DateTimeFormatOptions;
  hourFormat: HourFormat;
}

interface HourFormat {
  hour12?: boolean;
}

export const resolveSafariMidnight = (value: Date, formatOptions: FormatOptions) => {
  if (!value) return undefined;

  const { locale, timeFormatOptions, hourFormat } = formatOptions;
  const formattedTime = value.toLocaleString(locale, {
    ...timeFormatOptions,
    ...hourFormat,
  });

  const isMidnightSafari = formattedTime === MIDNIGHT_HOURS_24H_SAFARI;

  return isMidnightSafari ? MIDNIGHT_HOURS_24H : formattedTime;
};
