import React from 'react';
import { TimeSelector } from 'components/time-selector';
import { useDisableState } from './use-disable-state.hook';

export interface TimeFilterProps {
  toggleOverlay?: VoidFunction;
}

export const TimeFilter = ({ toggleOverlay }: TimeFilterProps): JSX.Element => {
  const isDisabled = useDisableState();

  return <TimeSelector isDisabled={isDisabled} toggleOverlay={toggleOverlay} />;
};
