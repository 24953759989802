import * as bookingsEpics from 'store/bookings/epics';
import * as cartEpics from 'store/cart/epics';
import * as priceEpics from 'store/price/epics';
import * as errorsEpics from 'store/errors/epics';
import * as resourceEpics from 'store/resource/epics';
import * as resourcesEpics from 'store/resources/epics';
import * as themeEpics from 'store/theme/epics';
import * as buildingEpics from 'store/building/epics';
import * as userEpics from 'store/user/epics';
import * as appInstanceConfigs from 'store/app-instance-configs/epics';
import * as floorPlanAppInstanceConfig from 'store/floorplan-app-instance-config/epics';
import * as floorPlanResources from 'store/floorplan-resources/epics';
import * as resourceAvailableDates from 'store/resource-available-dates/epics';
import * as resourceTimeRanges from 'store/resource-time-ranges/epics';
import * as transactionsEpics from './transactions/epics';
import * as routerEpics from 'store/router/epics';
import * as floorEpics from 'store/spaceManager/epics';
import * as uiMetadataEpics from 'store/ui-metadata/epics';
import * as addCardEpics from 'store/add-card/epics';
import * as paymentEpics from 'store/payment/epics';

import { combineEpics } from 'redux-observable';

export default combineEpics(
  ...Object.values(routerEpics),
  ...Object.values(userEpics),
  ...Object.values(resourceEpics),
  ...Object.values(resourcesEpics),
  ...Object.values(bookingsEpics),
  ...Object.values(cartEpics),
  ...Object.values(priceEpics),
  ...Object.values(themeEpics),
  ...Object.values(errorsEpics),
  ...Object.values(buildingEpics),
  ...Object.values(appInstanceConfigs),
  ...Object.values(floorPlanAppInstanceConfig),
  ...Object.values(floorPlanResources),
  ...Object.values(resourceAvailableDates),
  ...Object.values(resourceTimeRanges),
  ...Object.values(transactionsEpics),
  ...Object.values(floorEpics),
  ...Object.values(uiMetadataEpics),
  ...Object.values(addCardEpics),
  ...Object.values(paymentEpics),
);
