import { LabelBlock } from '@hqo/react-components-library/dist/molecules/label-block';
import { SMALL_DEVICE_MAX_WIDTH } from 'shared/consts';
import styled from 'styled-components';

export const StyledLabelBlock = styled(LabelBlock)`
  width: 100%;
  @media (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    width: 100%;
  }
  .resources-types {
    overflow-y: hidden;
  }

  .resources-types-text {
    -webkit-line-clamp: 3;
    display: -webkit-inline-box;
    -webkit-box-orient: vertical;
    font-weight: 500;
  }
`;
