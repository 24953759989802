import { useAvailableNow } from 'components/available-now-block/hooks/use-available-now.hook';
import { AvailableNowContainer } from './styles';
import { Carousel } from '@hqo/react-components-library/dist/molecules/carousel';
import { EmptyAvailableBlock } from './components/empty-available-block';
import React from 'react';
import { resourcesState } from 'store/resources/selectors';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { AvailableNowSkeleton } from 'components/available-now-block/components/available-now-skeleton/availableNowSkeleton';
import AvailableNowItemContainer from 'components/available-now-block/components/available-now-item-container/available-now-item-container';

export const AvailableNowBlockComponent = (): JSX.Element => {
  const intl = useIntl();
  const { resources } = useSelector(resourcesState);
  const { hasAvailableResources, onAvailableNowBlockClick, isGetResourcesLoading } = useAvailableNow();

  return isGetResourcesLoading ? (
    <AvailableNowSkeleton />
  ) : (
    <AvailableNowContainer isEmpty={!hasAvailableResources}>
      {hasAvailableResources ? (
        <Carousel title={intl.formatMessage({ id: 'available_now' })} orientation="horizontal" height={750} width={239}>
          {resources?.map(resource => (
            <AvailableNowItemContainer key={resource.uuid} resource={resource} onClick={onAvailableNowBlockClick} />
          ))}
        </Carousel>
      ) : (
        <EmptyAvailableBlock />
      )}
    </AvailableNowContainer>
  );
};
