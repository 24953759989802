import { GetResourceAvailableDatesParams, ResourceAvailableDatesResponse } from './types';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { FailureActionPayload } from 'store/errors/types';

export const getResourceAvailableDates = createAsyncAction(
  'GET_RESOURCE_AVAILABLE_DATES_REQUEST',
  'GET_RESOURCE_AVAILABLE_DATES_SUCCESS',
  'GET_RESOURCE_AVAILABLE_DATES_FAILURE',
)<GetResourceAvailableDatesParams, ResourceAvailableDatesResponse, FailureActionPayload>();

export const resetResourceAvailableDates = createAction('RESET_RESOURCE_AVAILABLE_DATES')<undefined>();

export const getResourceInfiniteAvailableDates = createAsyncAction(
  'GET_RESOURCE_INFINITE_AVAILABLE_DATES_REQUEST',
  'GET_RESOURCE_INFINITE_AVAILABLE_DATES_SUCCESS',
  'GET_RESOURCE_INFINITE_AVAILABLE_DATES_FAILURE',
)<GetResourceAvailableDatesParams, ResourceAvailableDatesResponse, FailureActionPayload>();
