import { CalendarDateInfo, UseCalendarReturnValues, searchParams } from 'pages/resource/date-select-modal/interface';
/* eslint-disable no-nested-ternary */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BOOKING_DAYS_LIMIT, QUERY_PARAMS } from 'shared/consts';
import { replace } from 'store/router/actions';
import { bookingTimeHandler } from 'utils/bookingTimeHandler';
import moment from 'moment-timezone';
import { pick } from 'utils/pickObjectProperty';
import qs from 'qs';
import { selectResourceInfiniteAvailableDates } from 'store/resource-available-dates/selectors';
import { useLocale } from 'hooks/use-locale.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { getCalendarViewDateStatus } from 'utils/getCalendarViewDateStatus';
import { getCalendarViewDateParams } from 'utils/getCalendarViewDateParams';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';
import { selectResource } from 'store/resource/selectors';

export const useInfiniteCalendar = (indexOfMonth: number): UseCalendarReturnValues => {
  const { startDate, startDates, ...restQueryParams } = useSearchParams<searchParams>();
  const locale = useLocale();
  const timezone = moment.tz.guess();
  const month = moment()
    .tz(timezone)
    .clone()
    .add(indexOfMonth + 2, 'M');

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const firstDay = useMemo(() => new Date(currentYear, currentMonth + indexOfMonth + 2, 1), [indexOfMonth]);

  const lastDay = new Date(currentYear, currentMonth + 1 + indexOfMonth + 2, 0);

  const firstDayTime = firstDay.toLocaleTimeString(locale, { hour: 'numeric', minute: 'numeric' });
  const lastDayTime = lastDay.toLocaleTimeString(locale, { hour: 'numeric', minute: 'numeric' });
  const timeRange = { startTime: firstDayTime, endTime: lastDayTime, locale };
  const title = bookingTimeHandler(timeRange).getFormatDate(firstDay, 'MMMM YYYY');
  const dispatch = useDispatch();
  const availableDates = useSelector(selectResourceInfiniteAvailableDates)[indexOfMonth.toString()];
  const { supports_multi_day_bookings: supportsMultiDayBookings } = useSelector(selectResource);
  const { isMultiDayBookingEnabled } = useAppInstanceConfigFeature();
  const [days, setDays] = useState<CalendarDateInfo[]>([]);
  const formattedStartDates = startDates?.split(',') || [];

  const isCartOverloaded = formattedStartDates.length >= BOOKING_DAYS_LIMIT;

  const startDatesList = useMemo(() => {
    return startDates?.split(',') || [];
  }, [startDates]);

  useEffect(() => {
    if (availableDates) {
      const firstDayOfMonth = month.clone().startOf('month').startOf('week');
      const day = firstDayOfMonth.clone();
      const lastDayOfMonth = month.clone().endOf('month').endOf('week');
      const data: CalendarDateInfo[] = [];

      while (day.isSameOrBefore(lastDayOfMonth)) {
        data.push({
          date: +day.format('DD'),
          month: +day.format('MM'),
          year: +day.format('YYYY'),
          status: getCalendarViewDateStatus(day, availableDates, startDatesList, startDate, isCartOverloaded),
        });
        day.add(1, 'day');
      }
      const filterData = data.map(date => (date.month !== firstDay.getMonth() + 1 ? ({} as CalendarDateInfo) : date));
      setDays(filterData);
    }
  }, [availableDates, startDate, startDates]);

  const onDateClick = useCallback(
    (_: any, data: any) => {
      const date = `${data.year}/${data.month}/${data.date}`;
      const formattedDateValue = bookingTimeHandler(timeRange).getFormatDate(new Date(date), 'YYYY-MM-DD');
      const dateParams =
        isMultiDayBookingEnabled && supportsMultiDayBookings
          ? getCalendarViewDateParams(formattedDateValue, startDatesList, startDate)
          : { startDate: formattedDateValue };
      const queryParams = date ? { ...restQueryParams, ...dateParams } : restQueryParams;
      const queryString = qs.stringify(
        pick(
          queryParams,
          QUERY_PARAMS.filter(param => param !== 'duration'),
        ),
      );
      dispatch(replace(`${location.pathname}?${queryString}`));
    },
    [
      timeRange,
      isMultiDayBookingEnabled,
      supportsMultiDayBookings,
      startDate,
      startDatesList,
      restQueryParams,
      dispatch,
    ],
  );

  return {
    onDateClick,
    title,
    days,
  };
};
