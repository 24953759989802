import styled from 'styled-components';

interface LoadingProps {
  hide?: boolean;
  isRoomLayoutImage?: boolean;
}

export const DivVisibilityContainer = styled.div<LoadingProps>`
  ${({ hide }) => hide && 'display: none;'}
  ${({ isRoomLayoutImage, theme, hide }) =>
    isRoomLayoutImage &&
    !hide &&
    `
  background-size: contain;
  background-repeat: no-repeat;
  background-color: ${theme?.colors?.greys?.sharedGreyBackground};
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 14px;
  height: 200px;
  `}
`;
