import { useFilteringEnabled } from 'hooks/use-filtering-enabled.hook';
import { useResourceLoadingHandler } from 'pages/resources/hooks';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { selectInfiniteResourcesTotal } from 'store/resources/selectors';

interface UseResourcesCounterReturnValues {
  isResourcesCounterBlockVisible: boolean;
  numberOfResources: number;
}

export const useResourcesCounter = (): UseResourcesCounterReturnValues => {
  const isFilteringEnabled = useFilteringEnabled();
  const isLoadingState = useResourceLoadingHandler(isFilteringEnabled);
  const { showResourceBookingMiniappFiltering: isResourceFilteringVisible } = useFlags();
  const numberOfResources = useSelector(selectInfiniteResourcesTotal);

  const isResourcesCounterBlockVisible = useMemo<boolean>(
    () => isResourceFilteringVisible && !isLoadingState,
    [isLoadingState, isResourceFilteringVisible],
  );

  return {
    isResourcesCounterBlockVisible,
    numberOfResources,
  };
};
