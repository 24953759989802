import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { FloorState } from './types';
import { ACTION_STATUSES } from 'shared/consts';

export const initialState: FloorState = {
  floors: [],
  getFloors: {
    status: null,
    error: null,
  },
  selectedFloor: null,
  buildingUuid: null,
  getSpaces: {
    status: null,
    error: null,
  },
};

const getFloorsRequestHandler = (state: FloorState): FloorState => ({
  ...state,
  getFloors: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getFloorsSuccessHandler = (
  state: FloorState,
  { payload }: ActionType<typeof actions.getFloors.success>,
): FloorState => {
  const oldFloors = state.floors;
  const newFloors = payload.floors;

  // Create a map to keep track of the unique IDs
  const uniqueIdsMap: { [id: string]: boolean } = {};

  // Filter the merged array to keep only objects with unique IDs
  const uniqueFloors = oldFloors.concat(newFloors).filter(floor => {
    if (!uniqueIdsMap[floor.uuid]) {
      uniqueIdsMap[floor.uuid] = true;
      return true;
    }
    return false;
  });

  return {
    ...state,
    floors: uniqueFloors,
    getFloors: {
      status: ACTION_STATUSES.FULFILLED,
      error: null,
    },
    buildingUuid: payload.buildingUuid,
  };
};

const getFloorsFailureHandler = (
  state: FloorState,
  { payload: error }: ActionType<typeof actions.getFloors.failure>,
): FloorState => ({
  ...state,
  getFloors: {
    status: ACTION_STATUSES.REJECTED,
    error: error.error,
  },
});

const resetFloorsHandler = (): FloorState => ({
  ...initialState,
});

const setFloorHandler = (state: FloorState, { payload }: ActionType<typeof actions.setSelectedFloor>): FloorState => ({
  ...state,
  selectedFloor: { ...payload },
});

const resetFloorHandler = (state: FloorState): FloorState => ({
  ...state,
  selectedFloor: null,
});

const getSpacesRequestHandler = (state: FloorState): FloorState => ({
  ...state,
  getSpaces: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getSpacesSuccessHandler = (
  state: FloorState,
  { payload }: ActionType<typeof actions.getSpaces.success>,
): FloorState => ({
  ...state,
  selectedFloor: {
    ...state.selectedFloor,
    spaces: payload,
  },
  getSpaces: {
    status: ACTION_STATUSES.FULFILLED,
    error: null,
  },
});

const getSpacesFailureHandler = (
  state: FloorState,
  { payload: error }: ActionType<typeof actions.getSpaces.failure>,
): FloorState => ({
  ...state,
  getSpaces: {
    status: ACTION_STATUSES.REJECTED,
    error: error.error,
  },
});

const resetSpacesHandler = (state: FloorState): FloorState => ({
  ...state,
  selectedFloor: {
    ...state.selectedFloor,
    spaces: [],
  },
});

const floorReducer = createReducer(initialState)
  .handleAction(actions.getFloors.request, getFloorsRequestHandler)
  .handleAction(actions.getFloors.success, getFloorsSuccessHandler)
  .handleAction(actions.getFloors.failure, getFloorsFailureHandler)
  .handleAction(actions.resetFloors, resetFloorsHandler)
  .handleAction(actions.setSelectedFloor, setFloorHandler)
  .handleAction(actions.resetFloor, resetFloorHandler)
  .handleAction(actions.getSpaces.request, getSpacesRequestHandler)
  .handleAction(actions.getSpaces.success, getSpacesSuccessHandler)
  .handleAction(actions.getSpaces.failure, getSpacesFailureHandler)
  .handleAction(actions.resetSpaces, resetSpacesHandler);

export default floorReducer;
