import { useCallback, useMemo } from 'react';

import { searchParams } from 'components/paymentIFrame/paymentIFrame.interface';
import { useLocale } from 'hooks/use-locale.hook';
import { useOwnerParams } from 'hooks/use-owner-params.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment-timezone';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { OwnerType, RESOURCE_PATH, TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';
import { AddOnDto } from 'store/add-ons/types';
import { selectBookingRequest } from 'store/bookings/selectors';
import { selectBuilding } from 'store/building/selectors';
import { createCart } from 'store/cart/actions';
import { LocaleEnum, OrderType } from 'store/cart/types';
import { configSelector } from 'store/config/selectors';
import { selectPrice } from 'store/price/selectors';
import { resetResourceTimeRanges } from 'store/resource-time-ranges/actions';
import { resourceState } from 'store/resource/selectors';
import { Resource, RoomLayout } from 'store/resource/types';
import { replace } from 'store/router/actions';
import { bookingTimeHandler } from 'utils/bookingTimeHandler';
import { UNICODE_CHARS } from 'utils/constants';
import { formatLocale } from 'utils/formatLocale';
import { getResourceTrackInfo } from 'utils/getResourceTrackInfo';
import { handleNonISOLocales } from 'utils/handleNonISOLocales';
import { resolveCartItems } from 'utils/resolveCartItems';

import { track } from '@hqo/web-tracking';
import { setBookingRequest } from 'store/bookings/actions';
import { useTrackingInfo } from 'hooks/use-tracking-information.hook';
import { selectCurrentAppInstanceConfig } from 'store/app-instance-configs/selectors';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';

interface SubmitBookingProps {
  availableNowStartDate?: string;
  availableNowStartTime?: string;
  availableNowEndTime?: string;
  availableNowResource?: Resource;
  currentRoomLayout?: RoomLayout;
  selectedAddOns?: Array<AddOnDto>;
  addOnsPrice?: number;
  unitCode?: string;
}

export const useOnSubmitBookingRequest = () => {
  const dispatch = useDispatch();
  const { ownerUuid } = useOwnerParams();
  const { startDate, startTime, endTime, startDates } = useSearchParams<searchParams>();
  const getBookingRequest = useSelector(selectBookingRequest);
  const { resource } = useSelector(resourceState);
  const { buildingUuid, locale: language } = useSelector(configSelector);
  const activeAppInstanceConfig = useSelector(selectCurrentAppInstanceConfig);
  const price = useSelector(selectPrice);
  const locale = useLocale();
  const building = useSelector(selectBuilding);
  const { source: trackingSource } = useTrackingInfo();

  const itemSubtitleText = useMemo<string | null>(() => {
    if (startTime && endTime && resource.timezone) {
      const startBookingDate = moment(startDate?.toString()).tz(resource.timezone, true);
      const startBookingTime = moment(startTime.toString(), 'H:m').tz(resource.timezone, true).format('HH:mm');

      const endBookingTime = moment(endTime.toString(), 'H:m').tz(resource.timezone, true).format('HH:mm');

      const timeRange = { startTime: startBookingTime as string, endTime: endBookingTime as string, locale };
      const startDateText = bookingTimeHandler(timeRange).getDateRangeString(
        startBookingDate.format('YYYY-MM-DD') as string,
      );
      const endDateText = bookingTimeHandler(timeRange).getTimeRangeString();

      return `${startDateText} ${UNICODE_CHARS.BULLET} ${endDateText}`;
    }

    return null;
  }, [resource, getBookingRequest.startAt, getBookingRequest.endAt, startDate, startTime, endTime]);

  return useCallback(
    (submitBookingProps: SubmitBookingProps = {}) => {
      track(
        TRACK_EVENT_NAMES.RESOURCE_BOOK_CLICK,
        {
          source: trackingSource,
          type: TRACK_EVENT_TYPES.ACTION,
          resource: getResourceTrackInfo(resource),
        },
        { sendToHqoTracking: true },
      );
      const {
        availableNowStartDate,
        availableNowStartTime,
        availableNowEndTime,
        availableNowResource,
        currentRoomLayout,
        selectedAddOns,
        unitCode,
      } = submitBookingProps;

      const resourceForCart = availableNowResource ?? resource;
      const lang = handleNonISOLocales(language, building?.locale);
      const startDatesArray = startDates ? startDates?.split(',') : [];

      if (unitCode) {
        dispatch(
          setBookingRequest({
            ...getBookingRequest,
            unit_code: unitCode,
          }),
        );
      }

      dispatch(
        createCart.request({
          type: OrderType.RESOURCE_BOOKING,
          owner_type: OwnerType.BUILDING,
          owner_id: activeAppInstanceConfig?.building_uuid ?? ownerUuid,
          building_uuid: activeAppInstanceConfig?.building_uuid ?? buildingUuid,
          lang: formatLocale(lang) as LocaleEnum,
          items: resolveCartItems(
            [startDate, ...startDatesArray],
            {
              resource: resourceForCart,
              getBookingRequest,
              price,
              itemSubtitleText,
              currentRoomLayout,
              selectedAddOns,
              timeRangeParam: { startTime, endTime },
              availableNowParams: { availableNowStartTime, availableNowEndTime, availableNowStartDate },
            },
            unitCode,
          ),
          locationId: resourceForCart.location.id,
        }),
      );
    },
    [
      dispatch,
      ownerUuid,
      getBookingRequest,
      resource,
      startDate,
      startTime,
      endTime,
      price,
      building?.locale,
      buildingUuid,
      itemSubtitleText,
      language,
      activeAppInstanceConfig,
      startDates,
      trackingSource,
    ],
  );
};

export const useRedirectToNextStep = () => {
  const dispatch = useDispatch();
  const { resource } = useSelector(resourceState);
  const { pathname } = useLocation();
  const hasAdditionalInfo = !!resource?.user_prompts?.length;
  const onSubmitBookingRequest = useOnSubmitBookingRequest();
  const params = useSearchParams();
  const { isCompactUiEnabled } = useAppInstanceConfigFeature();
  const isUserOnCompactUi = pathname.includes('/resources');
  const queryParams = qs.stringify({ ...params, step: undefined });
  const { showResourceDetailsDateTimeInputs } = useFlags();
  const { source: trackingSource } = useTrackingInfo();

  return useCallback(() => {
    if (isCompactUiEnabled && !hasAdditionalInfo) {
      dispatch(replace(`${pathname.replace('/date-schedule-select', '')}?${queryParams}`));
      onSubmitBookingRequest();
    } else if (isCompactUiEnabled && hasAdditionalInfo && !pathname.includes('additional-information')) {
      dispatch(replace(`${RESOURCE_PATH}/${resource.uuid}/additional-information?${queryParams}`));
    } else if (
      (hasAdditionalInfo && !pathname.includes('additional-information')) ||
      pathname.includes('additional-information/date-schedule-select') ||
      resource?.room_layouts?.length ||
      resource?.add_ons?.length ||
      (showResourceDetailsDateTimeInputs && !isUserOnCompactUi)
    ) {
      dispatch(replace(`${pathname.replace('/date-schedule-select', '')}?${queryParams}`));
    } else {
      track(
        TRACK_EVENT_NAMES.RESOURCE_BOOK_CLICK,
        {
          type: TRACK_EVENT_TYPES.ACTION,
          source: trackingSource,
          resource: {
            type: resource.type,
            uuid: resource.uuid,
            resource_id: resource.id.toString(),
            name: resource.name,
            capacity: resource.capacity,
          },
        },
        { sendToHqoTracking: true },
      );
      dispatch(replace(`${pathname.replace('/date-schedule-select', '')}?${queryParams}`));
      onSubmitBookingRequest();
    }
    dispatch(resetResourceTimeRanges());
  }, [
    isCompactUiEnabled,
    hasAdditionalInfo,
    dispatch,
    pathname,
    queryParams,
    onSubmitBookingRequest,
    showResourceDetailsDateTimeInputs,
    isUserOnCompactUi,
    resource?.uuid,
    resource?.type,
    resource?.room_layouts,
    resource?.add_ons,
  ]);
};
