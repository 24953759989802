import React from 'react';
import { useSelector } from 'react-redux';
import { selectInfiniteResources } from 'store/resources/selectors';
import { useInfiniteContent } from '../use-infinite-content.hook';
import { ResourcesSet } from './resources-set';

interface InifiniteViewProps {
  toggleReverseAnimation: VoidFunction;
  toggleModalSlideAnimation: VoidFunction;
  scrollContainerRef: React.MutableRefObject<HTMLDivElement>;
}

export const InifiniteView = ({
  toggleReverseAnimation,
  toggleModalSlideAnimation,
  scrollContainerRef,
}: InifiniteViewProps): JSX.Element => {
  const { limitOfElements } = useInfiniteContent(scrollContainerRef);
  const infiniteResources = useSelector(selectInfiniteResources);

  return (
    <>
      {Object.keys(infiniteResources)?.map(indexOfSet => (
        <ResourcesSet
          key={indexOfSet}
          indexOfSet={+indexOfSet}
          limitOfElements={limitOfElements}
          toggleReverseAnimation={toggleReverseAnimation}
          toggleModalSlideAnimation={toggleModalSlideAnimation}
        />
      ))}
    </>
  );
};
