import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';

import { selectAppInstanceConfigs, selectCurrentLocationId } from 'store/app-instance-configs/selectors';
import { updateCurrentLocationId } from 'store/app-instance-configs/actions';

export const useLocationPicker = (locationName: string): void => {
  const dispatch = useDispatch();

  const appInstanceConfigs = useSelector(selectAppInstanceConfigs);
  const locationId = useSelector(selectCurrentLocationId);
  const currentLocationId = useMemo<string>(() => {
    const appInstanceConfig = appInstanceConfigs?.find(config => config.location_name === locationName);

    return appInstanceConfig?.location_id;
  }, [appInstanceConfigs, locationName]);

  useEffect(() => {
    if (!locationId) {
      dispatch(updateCurrentLocationId({ currentLocationId }));
    }
  }, [dispatch, location, locationId, currentLocationId]);
};
