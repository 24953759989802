import React from 'react';
import { AddOnItem } from '@hqo/react-components-library/dist/payment/add-on-item';
import { useSelector } from 'react-redux';
import { selectBuildingLocale } from 'store/building/selectors';
import { formatCurrency } from 'utils/formatCurrency';
import { PaymentDetailsItemContainer } from './styles';
import { AddOnDto } from 'store/add-ons/types';

interface ReceiptDetailsItemProps {
  addOns: Array<AddOnDto>;
  currencyType: string;
}

export const PaymentDetailsItem = ({ addOns, currencyType }: ReceiptDetailsItemProps) => {
  const buildingLocale = useSelector(selectBuildingLocale) || 'en-US';

  return (
    !!addOns?.length && (
      <PaymentDetailsItemContainer>
        {addOns?.map(addOn => (
          <AddOnItem
            key={addOn?.name}
            title={addOn?.name}
            price={formatCurrency(addOn?.price, currencyType, buildingLocale)}
            quantity={addOn?.quantity}
          />
        ))}
      </PaymentDetailsItemContainer>
    )
  );
};
