import { DayLabel, DaysLayout } from './styles';
import React from 'react';
import { getWeekDaysLabels } from 'utils';
import { useLocale } from 'hooks/use-locale.hook';

export const DayLabels = (): JSX.Element => {
  const locale = useLocale();
  const dayLabels = getWeekDaysLabels(locale, false, true);

  return (
    <DaysLayout className="week-day-names" data-testid="week-day-names">
      {dayLabels.map(day => (
        <DayLabel key={day} className={`week-day-label-${day}`} data-cy={`${day}-label`} data-testid={`${day}-label`}>
          {day}
        </DayLabel>
      ))}
    </DaysLayout>
  );
};
