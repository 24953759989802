import React from 'react';
import { useCalendar } from './use-calendar.hook';
import { CalendarViewProps } from '../interface';
import { GhostCalendarView } from 'pages/date-select-page/components/ghost-calendar-view';
import { StyledCalendarView } from 'pages/date-select-page/components/styles';

export const CalendarView = ({ indexOfMonth, isLoading, setLoadingState }: CalendarViewProps): JSX.Element => {
  const { onDateClick, title, days } = useCalendar(indexOfMonth, setLoadingState);

  return isLoading ? (
    <GhostCalendarView />
  ) : (
    <StyledCalendarView
      showDayLabels={false}
      title={title}
      data={days}
      onDateClick={onDateClick}
      showRightArrow={false}
      isFlexStyle
    />
  );
};
