import styled from 'styled-components';

export const Root = styled.div`
  border-radius: 8px;
  padding: 32px;
  background-color: ${({ theme }) => theme.colors.palette.sharedWhite};
`;

export const Title = styled.h2`
  font: 600 24px ${({ theme }) => theme.fonts.join()};
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.colors.palette.systemBlack};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
`;
