import { useSelector } from 'react-redux';
import { resourcesState, selectGetResourcesLoadingStatus } from 'store/resources/selectors';
import { bookingsState } from 'store/bookings/selectors';
import { useGetCurrentLocationResourcesTypesQuery } from 'store/resources/hooks';

export const useResourceLoadingHandler = (isFilteringEnabled: boolean): boolean => {
  const isResourcesLoading = useSelector(selectGetResourcesLoadingStatus);
  const { data: resourceTypes, isLoading: isResourceTypesLoading } = useGetCurrentLocationResourcesTypesQuery();

  const upcomingBookings = useSelector(bookingsState);
  const { loading: isUpcomingBookingLoading } = useSelector(bookingsState);
  const { resources } = useSelector(resourcesState);

  const isContentExist = isFilteringEnabled
    ? !upcomingBookings || !resourceTypes || !resources
    : !upcomingBookings || !resources;

  const isLoadingState = isFilteringEnabled
    ? isResourcesLoading || isResourceTypesLoading || isUpcomingBookingLoading
    : isResourcesLoading || isUpcomingBookingLoading;

  return isLoadingState || isContentExist;
};
