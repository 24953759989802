import { LocaleEnum } from 'store/cart/types';
import { formatLocale } from 'utils/formatLocale';

export const handleNonISOLocales = (inputLocale: string, buildingLocale: string): string => {
  const formattedLocale = formatLocale(inputLocale) as LocaleEnum;

  if (!Object.values(LocaleEnum).includes(formattedLocale)) {
    return buildingLocale || 'en-us';
  }

  return inputLocale;
};
