import NotificationUi from 'components/notifications/components/notification/notification';
import useNotifications from 'store/notifications/hooks/use-notifications';
import React from 'react';
import { Root } from './styles';

export default function NotificationsContainer() {
  const { notification, hide } = useNotifications();

  return (
    <Root data-testid="notifications-container">
      {notification && <NotificationUi {...notification} onClose={hide} />}
    </Root>
  );
}
