import { useCallback } from 'react';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks';
import { useLocale } from 'hooks/use-locale.hook';
import { useDispatch } from 'react-redux';
import { searchParams, timeFormatOptions } from 'components/time-selector/time-selector.interface';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { replace } from 'store/router/actions';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { track } from '@hqo/web-tracking';
import { TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';

interface UseSetTimeQueryParam {
  time: Date;
  queryParamName: string;
}

const useSetTimeQueryParam = ({ time, queryParamName }: UseSetTimeQueryParam) => {
  const locale = useLocale();
  const dispatch = useDispatch();
  const encodedQueryParams = useSearchParams<searchParams>();

  return useCallback(
    (timeValue?: Date) => {
      const selectedTime = timeValue || time;
      if (!selectedTime) {
        return;
      }
      const isEndTimeMidnight =
        queryParamName === 'endTime' && selectedTime?.getHours() === 0 && selectedTime?.getMinutes() === 0;
      const hourFormat = isEndTimeMidnight && {
        hour12: false,
      };

      const formattedTimeValue = selectedTime.toLocaleString(locale, {
        ...timeFormatOptions,
        ...hourFormat,
      });
      const queryParams = { ...encodedQueryParams, [queryParamName]: formattedTimeValue };
      const queryString = qs.stringify(queryParams);
      dispatch(replace(`${location.pathname}?${queryString}`));
    },
    [time, locale, encodedQueryParams, queryParamName, dispatch],
  );
};

interface useTimePickerModalsProps {
  toggleOverlay: VoidFunction;
}

const useTimePickerModal = ({ toggleOverlay }: useTimePickerModalsProps) => {
  const {
    value: isTimePickerActive,
    toggle: toggleTimePickerActive,
    setFalse: closeTimePicker,
  } = useBooleanState(false);

  const onTimePickerOutsideClick = useCallback(() => {
    if (isTimePickerActive) {
      closeTimePicker();
    }
    toggleOverlay();
  }, [isTimePickerActive, toggleOverlay, closeTimePicker]);

  const toggleTimePicker = useCallback(
    (setTimeToQueryString: () => void, updateQueryParams: boolean) => () => {
      if (isTimePickerActive && updateQueryParams) {
        setTimeToQueryString();
      }
      toggleTimePickerActive();
    },
    [isTimePickerActive, toggleTimePickerActive],
  );

  return { onTimePickerOutsideClick, isTimePickerActive, closeTimePicker, toggleTimePicker };
};

interface UseTimePickerModals {
  toggleOverlay: VoidFunction;
}

const useTimePickerModals = ({ toggleOverlay }: UseTimePickerModals) => {
  const {
    isTimePickerActive: isStartTimePickerActive,
    onTimePickerOutsideClick: onStartTimePickerOutsideClick,
    toggleTimePicker: toggleStartTimePicker,
    closeTimePicker: closeStartTimePicker,
  } = useTimePickerModal({ toggleOverlay });

  const {
    isTimePickerActive: isEndTimePickerActive,
    onTimePickerOutsideClick: onEndTimePickerOutsideClick,
    toggleTimePicker: toggleEndTimePicker,
    closeTimePicker: closeEndTimePicker,
  } = useTimePickerModal({ toggleOverlay });

  const handleStartTimePickerOutsideClick = useCallback(() => {
    onStartTimePickerOutsideClick();
  }, [onStartTimePickerOutsideClick]);

  const handleEndTimePickerOutsideClick = useCallback(() => {
    onEndTimePickerOutsideClick();
  }, [onEndTimePickerOutsideClick]);

  return {
    isStartTimePickerActive,
    onStartTimePickerOutsideClick: handleStartTimePickerOutsideClick,
    toggleStartTimePicker,
    isEndTimePickerActive,
    onEndTimePickerOutsideClick: handleEndTimePickerOutsideClick,
    toggleEndTimePicker,
    closeStartTimePicker,
    closeEndTimePicker,
  };
};

interface UseTimePickerActions {
  toggleOverlay: VoidFunction;
  startTime: Date;
  endTime: Date;
  shouldUpdateQueryParams: boolean;
}

const useTimePickerModalsActions = ({
  startTime,
  endTime,
  toggleOverlay,
  shouldUpdateQueryParams,
}: UseTimePickerActions) => {
  const setStartTimeQueryParam = useSetTimeQueryParam({ time: startTime, queryParamName: 'startTime' });
  const setEndTimeQueryParam = useSetTimeQueryParam({ time: endTime, queryParamName: 'endTime' });
  const { pathname } = useLocation();
  const isResourceDetailsPage = pathname.includes('/date-schedule-select') && !pathname.includes('/resources');

  const {
    toggleStartTimePicker,
    toggleEndTimePicker,
    closeStartTimePicker,
    closeEndTimePicker,
    ...restTimePickerModalsValues
  } = useTimePickerModals({ toggleOverlay });

  const onStartItemClick = useCallback(() => {
    toggleOverlay();
    closeStartTimePicker();
  }, [closeStartTimePicker, toggleOverlay]);

  const onEndItemClick = useCallback(() => {
    toggleOverlay();
    closeEndTimePicker();
  }, [closeEndTimePicker, toggleOverlay]);

  const onStartTimePickerClick = useCallback(() => {
    toggleStartTimePicker(setStartTimeQueryParam, shouldUpdateQueryParams)();
    toggleOverlay();

    if (isResourceDetailsPage) {
      track(
        TRACK_EVENT_NAMES.RESOURCE_DETAIL_PAGE_START_TIME_CLICK,
        {
          type: TRACK_EVENT_TYPES.ACTION,
        },
        { sendToHqoTracking: true },
      );
    }
  }, [toggleStartTimePicker, setStartTimeQueryParam, shouldUpdateQueryParams, toggleOverlay, isResourceDetailsPage]);

  const onEndTimePickerClick = useCallback(() => {
    toggleEndTimePicker(setEndTimeQueryParam, shouldUpdateQueryParams)();
    toggleOverlay();

    if (isResourceDetailsPage) {
      track(
        TRACK_EVENT_NAMES.RESOURCE_DETAIL_PAGE_END_TIME_CLICK,
        {
          type: TRACK_EVENT_TYPES.ACTION,
        },
        { sendToHqoTracking: true },
      );
    }
  }, [toggleEndTimePicker, setEndTimeQueryParam, shouldUpdateQueryParams, toggleOverlay, isResourceDetailsPage]);

  return {
    ...restTimePickerModalsValues,
    setStartTimeQueryParam,
    onStartTimePickerClick,
    onEndTimePickerClick,
    onStartItemClick,
    onEndItemClick,
  };
};

interface useMobileTimePickerDropdownsProps {
  startTime: Date;
  endTime: Date;
  toggleOverlay: VoidFunction;
  shouldUpdateQueryParams?: boolean;
}

export const useMobileTimePickerDropdowns = ({
  startTime,
  endTime,
  toggleOverlay,
  shouldUpdateQueryParams = true,
}: useMobileTimePickerDropdownsProps) => {
  const restTimePickerModalValues = useTimePickerModals({
    toggleOverlay,
  });
  const timePickerModalActions = useTimePickerModalsActions({
    startTime,
    endTime,
    toggleOverlay,
    shouldUpdateQueryParams,
  });

  return {
    ...restTimePickerModalValues,
    ...timePickerModalActions,
  };
};
