import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Subtitle = styled.div`
  font: 400 16px ${({ theme }) => theme.fonts.join()};
  font-style: italic;
  color: ${({ theme }) => theme.colors.fontColor};
`;
