import * as Sentry from '@sentry/react';
import { App } from 'components/app';
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import { IntlProvider } from '../intl-provider';
import { LaunchDarklyInitializer } from 'components/launch-darkly';
import { MiniappSdkProvider } from 'components/miniapp-sdk-provider';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { RootStyles } from './root.styles';
import { history } from 'store/router/history';
import store from 'store';
import { ErrorPageWithNoDependencies } from 'pages/error/components/error-with-no-dependencies';
import 'init/initIcons';
import { ErrorPage } from 'pages/error';

import 'init/sentry';

export const Root: React.FC = Sentry.withProfiler(() => (
  <Sentry.ErrorBoundary fallback={<ErrorPageWithNoDependencies />}>
    <ReduxProvider store={store}>
      <MiniappSdkProvider>
        <LaunchDarklyInitializer>
          <Router history={history}>
            <IntlProvider>
              <RootStyles />
              <Sentry.ErrorBoundary fallback={<ErrorPage />}>
                <App />
              </Sentry.ErrorBoundary>
            </IntlProvider>
          </Router>
        </LaunchDarklyInitializer>
      </MiniappSdkProvider>
    </ReduxProvider>
  </Sentry.ErrorBoundary>
));
