import { MobileContentContainer, Text } from './styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectResource } from 'store/resource/selectors';

export const MobileModalContent = (): JSX.Element => {
  const resource = useSelector(selectResource);

  return (
    <MobileContentContainer>
      <Text>{resource.terms_and_conditions || ''}</Text>
    </MobileContentContainer>
  );
};
