import { DEFAULT_HEIGHT, ONE_HOUR, DEFAULT_TIME_INTERVAL } from '../components/schedule-select/const';

import { TimeRange } from 'store/resource-time-ranges/types';
import { TimeRangeByDuration } from '../components/schedule-select/interface';
import { bookingTimeHandler } from './bookingTimeHandler';

interface Duration {
  max?: number;
  min: number;
  currentDuration: number;
}

interface getTimeRangeByDurationProps {
  duration: Duration;
  resourceTimeRanges: TimeRange[];
  filteredTimeRanges: TimeRange[];
  hours: number[];
  timeInterval: number;
  isTimeIntervalFromTimeRanges: boolean;
}

interface getTimeRangeByStartTimeProps {
  startTime: string;
  minDuration: number;
  resourceTimeRanges: TimeRange[];
  filteredTimeRanges: TimeRange[];
}

const getIsFullOrHalfWindow = (duration: number, hours: number[]): boolean => {
  return duration === hours?.length * ONE_HOUR || duration === (hours?.length / 2) * ONE_HOUR;
};

const getIsFullWindow = (duration: number, hours: number[]): boolean => {
  return duration === hours?.length * ONE_HOUR;
};

export const getTimeRangeByDuration = ({
  duration,
  resourceTimeRanges,
  filteredTimeRanges,
  hours,
  timeInterval,
  isTimeIntervalFromTimeRanges,
}: getTimeRangeByDurationProps): TimeRangeByDuration => {
  const { max: maxDuration, min: minDuration, currentDuration } = duration || {};

  return resourceTimeRanges?.reduce(
    (timeRangeObject, timeRangeSlot, index) => {
      const isFullOrHalfWindow = getIsFullOrHalfWindow(minDuration, hours) || getIsFullOrHalfWindow(maxDuration, hours);
      const isFullWindow = getIsFullWindow(minDuration, hours) || getIsFullWindow(maxDuration, hours);
      const minimumBookingDuration = minDuration > timeInterval ? minDuration : timeInterval;
      const selectedDuration = minimumBookingDuration > currentDuration ? minimumBookingDuration : currentDuration;
      const finalDuration = maxDuration && maxDuration < selectedDuration ? maxDuration : selectedDuration;
      const timeSlotInterval = isTimeIntervalFromTimeRanges ? DEFAULT_TIME_INTERVAL : timeInterval;
      const countTimeSlots = finalDuration / timeSlotInterval;
      const result = timeRangeObject;

      const lastTimeSlot = isFullWindow
        ? filteredTimeRanges[filteredTimeRanges.length - 1]?.time
        : resourceTimeRanges[index + countTimeSlots]?.time;

      const diff = lastTimeSlot
        ? bookingTimeHandler({ startTime: timeRangeSlot.time, endTime: lastTimeSlot }).getTimeDiff()
        : 0;

      const lastAvailableTimeRangeIndex = filteredTimeRanges?.indexOf(
        resourceTimeRanges[resourceTimeRanges?.length - 1],
      );

      if (countTimeSlots === resourceTimeRanges?.length) {
        result.index = filteredTimeRanges.indexOf(resourceTimeRanges[0]);
        result.height = countTimeSlots * DEFAULT_HEIGHT;
        result.startTimeByDuration = resourceTimeRanges[0]?.time;
        result.endTimeByDuration = filteredTimeRanges[lastAvailableTimeRangeIndex + 1]?.time;
      } else if (
        ((diff && diff === finalDuration) || (diff && isFullOrHalfWindow && diff === finalDuration)) &&
        !result.index &&
        result.index !== 0
      ) {
        result.index = filteredTimeRanges.indexOf(timeRangeSlot);
        result.height = countTimeSlots * DEFAULT_HEIGHT;
        result.startTimeByDuration = timeRangeSlot.time;
        result.endTimeByDuration = isFullWindow
          ? filteredTimeRanges[filteredTimeRanges.length - 1].time
          : resourceTimeRanges[index + countTimeSlots]?.time;
      }

      return result;
    },
    { index: null, height: null, startTimeByDuration: null, endTimeByDuration: null },
  );
};

export const getTimeRangeByStartTime = ({
  startTime,
  minDuration,
  resourceTimeRanges,
  filteredTimeRanges,
}: getTimeRangeByStartTimeProps): TimeRangeByDuration => {
  return filteredTimeRanges?.reduce(
    (timeRangeObject, timeRangeSlot) => {
      const result = timeRangeObject;
      const countTimeSlots = minDuration / DEFAULT_TIME_INTERVAL;
      const startTimeRange = resourceTimeRanges.find(timeRange => timeRange.time === startTime);

      const diff =
        startTimeRange?.time &&
        bookingTimeHandler({ startTime: startTimeRange?.time, endTime: timeRangeSlot.time }).getTimeDiff();

      if (diff === minDuration && !result.index && result.index !== 0) {
        result.index = filteredTimeRanges.indexOf(startTimeRange);
        result.height = countTimeSlots * DEFAULT_HEIGHT;
        result.startTimeByDuration = startTimeRange?.time;
        result.endTimeByDuration = timeRangeSlot.time;
      }

      return result;
    },
    { index: null, height: null, startTimeByDuration: null, endTimeByDuration: null },
  );
};
