import { hqoTheme } from '@hqo/react-components-library/dist/hqo-theme-provider';

export const DEFAULT_MAPBOX_STYLES = 'mapbox://styles/mapbox/streets-v11';

export const DEFAULT_LONGITUDE = -71.057083;
export const DEFAULT_LATITUDE = 42.361145;
export const DEFAULT_ZOOM = 9;
export const MAX_ZOOM = 13;
export const SIDE_PADDING = 15;
export const TOP_PADDING = 38;
export const BOTTOM_PADDING = 5;

export const DEFAULT_MARKER_OPTIONS = {
  color: hqoTheme.colors.palette.systemPink,
  selectedColor: hqoTheme.colors.palette.systemBlue,
};
