import { BookingStatus, MAP_STATUSES } from 'store/bookings/types';
import React from 'react';
import { bookingDate } from 'utils/formatDate';
import { colors } from '@hqo/react-components-library/dist/hqo-theme-provider';
import { Carousel } from '@hqo/react-components-library/dist/molecules/carousel';
import { CHECK_IN_CLOSED_STATUSES, CHECK_IN_STATUSES } from 'shared/consts';
import { UpcomingBooking } from '@hqo/react-components-library/dist/molecules/upcomingBookingBlock';
import { UpcomingBookingsContainer } from './styles';
import { useIntl } from 'react-intl';
import { useLocale } from 'hooks/use-locale.hook';
import { useTimeZone } from 'hooks/use-timezone.hook';
import { useUpcomingBookings } from './hooks/useUpcomingBookings';
import { getResourceTimezone } from 'utils/getResourceTimezone';
import { Resource } from 'store/resource/types';

interface UpcomingBookingsProps {
  setBookingId: React.Dispatch<React.SetStateAction<number | string>>;
  openReceipt: VoidFunction;
}

export const UpcomingBookings = ({ setBookingId, openReceipt }: UpcomingBookingsProps) => {
  const locale = useLocale();
  const timezone = useTimeZone();
  const intl = useIntl();
  const {
    formatTransactions: bookings,
    renderEventDurationAndLocationString,
    handleClick,
  } = useUpcomingBookings({ setBookingId, openReceipt });
  const isBookingsExist = Boolean(bookings?.length);

  return (
    <UpcomingBookingsContainer isEmpty={!isBookingsExist}>
      {isBookingsExist && (
        <Carousel title={intl.formatMessage({ id: 'upcoming' })} orientation="horizontal" height={750} width={239}>
          {bookings.map(booking => {
            const isCheckedIn = CHECK_IN_CLOSED_STATUSES.includes(
              booking.details.check_in?.status?.toLowerCase() as CHECK_IN_STATUSES,
            );

            const resourceTimezone = getResourceTimezone(booking.details.resource as unknown as Resource, timezone);

            return (
              <UpcomingBooking
                dataTestId={booking.details.name}
                id={booking.details.id}
                key={booking.uuid}
                title={booking.details.name}
                date={bookingDate(booking.details.start_at, locale, resourceTimezone)}
                time={renderEventDurationAndLocationString(
                  booking.details.start_at,
                  booking.details.end_at,
                  booking.details.location_name,
                  resourceTimezone,
                )}
                onClick={handleClick}
                withStatus={booking.details.status !== BookingStatus.ACCEPTED}
                status={intl.formatMessage({ id: MAP_STATUSES[booking.details.status] })}
                statusColor={colors.universal.sharedOrange}
                isCheckInAllowed={!!booking.details.check_in?.status}
                checkInText={intl.formatMessage({ id: isCheckedIn ? 'is_checked_in' : 'check_in_status' })}
                isCheckedIn={isCheckedIn}
              />
            );
          })}
        </Carousel>
      )}
    </UpcomingBookingsContainer>
  );
};
