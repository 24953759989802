import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useMemo } from 'react';
import { bookingsState } from 'store/bookings/selectors';
import { Booking, BookingStatus } from 'store/bookings/types';
import { selectTransactions, selectTransactionsLoading } from 'store/transactions/selectors';
import { TransactionDetails } from 'store/transactions/types';
import { useLocale } from 'hooks/use-locale.hook';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks';
import { PAYMENT_MINIAPP_POST_MESSAGES } from 'shared/consts';
import { getTransactions } from 'store/transactions/actions';
import { getFormattedEventDuration } from 'utils/formatDate';

interface UseUpcomingBookingsProps {
  setBookingId: React.Dispatch<React.SetStateAction<number | string>>;
  openReceipt: VoidFunction;
}

interface UseUpcomingBookingsReturnValues {
  upcomingBookings: Array<Booking>;
  formatTransactions: Array<TransactionDetails>;
  isUpcomingBookingsLoading: boolean;
  isTransactionsLoading: boolean;
  renderEventDurationAndLocationString: (
    startAt: string,
    endAt: string,
    locationName: string,
    resourceTimezone: string,
  ) => string;
  handleClick: (id: string) => void;
}

export const useUpcomingBookings = ({
  setBookingId,
  openReceipt,
}: UseUpcomingBookingsProps): UseUpcomingBookingsReturnValues => {
  const isTransactionsLoading = useSelector(selectTransactionsLoading);
  const isUpcomingBookingsLoading = useSelector(bookingsState).loading;
  const dispatch = useDispatch();
  const locale = useLocale();
  const { value: showPaymentModal, toggle: togglePaymentModal } = useBooleanState(false);

  const onMessage = useCallback(
    (event: MessageEvent): void => {
      if (event.data === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_CLOSE && showPaymentModal) {
        setTimeout(() => {
          togglePaymentModal();
        }, 400);
        setTimeout(() => {
          dispatch(getTransactions.request({}));
        }, 400);
      }
    },
    [dispatch, showPaymentModal, togglePaymentModal],
  );

  useEffect(() => {
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  const upcomingBookings = useSelector(bookingsState)
    .bookings.filter(
      booking =>
        booking.status !== BookingStatus.CANCELED &&
        booking.status !== BookingStatus.DENIED_AVAILABLE &&
        booking.status !== BookingStatus.DENIED_UNAVAILABLE,
    )
    .sort((a, b) => new Date(a.start_at).getTime() - new Date(b.start_at).getTime());

  const transactions = useSelector(selectTransactions)?.filter(
    transaction =>
      transaction.details.status !== BookingStatus.CANCELED &&
      transaction.details.status !== BookingStatus.DENIED_AVAILABLE &&
      transaction.details.status !== BookingStatus.DENIED_UNAVAILABLE,
  );

  const formatTransactions: TransactionDetails[] = useMemo(
    () =>
      transactions?.reduce((acc, transaction) => {
        acc.push(transaction);

        return acc.sort((a, b) => new Date(a.details.start_at).getTime() - new Date(b.details.start_at).getTime());
      }, []),
    [transactions],
  );

  const handleClick = useCallback(
    (id: string) => {
      setBookingId(id);
      openReceipt();
      togglePaymentModal();
    },
    [openReceipt, setBookingId, togglePaymentModal],
  );

  const renderEventDurationAndLocationString = useCallback(
    (startAt: string, endAt: string, locationName: string, resourceTimezone: string): string => {
      if (locationName) {
        return `${getFormattedEventDuration(startAt, endAt, locale, resourceTimezone)} / ${locationName}`;
      }
      return getFormattedEventDuration(startAt, endAt, locale, resourceTimezone);
    },
    [locale],
  );

  return {
    upcomingBookings,
    isUpcomingBookingsLoading,
    formatTransactions,
    isTransactionsLoading,
    renderEventDurationAndLocationString,
    handleClick,
  };
};
