import { useDispatch, useSelector } from 'react-redux';
import { PatchTransaction, TransactionDetails } from 'store/transactions/types';
import { useCallback, useEffect, useMemo } from 'react';
import {
  selectPatchTransactionsStatus,
  selectTransaction,
  selectTransactions,
  selectTypeOfPatchTransaction,
} from 'store/transactions/selectors';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { checkIn, setCurrentTransaction } from '../../../store/transactions/actions';

interface UseReceiptReturnValues {
  transaction: TransactionDetails;
  onCancelBookingClick: VoidFunction;
  patchTransactionData: Pick<PatchTransaction, 'status' | 'type'>;
  isSwipeModalContent: boolean;
  onCheckInClick: VoidFunction;
  isDeepLinkAccess: boolean;
}

interface UseReceiptProps {
  transactionId?: number | string;
  setIsCancelBookingStep: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useReceipt = ({ transactionId, setIsCancelBookingStep }: UseReceiptProps): UseReceiptReturnValues => {
  const patchTransactionStatus = useSelector(selectPatchTransactionsStatus);
  const typeOfPatchTransaction = useSelector(selectTypeOfPatchTransaction);
  const patchTransactionData = {
    status: patchTransactionStatus,
    type: typeOfPatchTransaction,
  };
  const transactions = useSelector(selectTransactions);
  const { showMiniappPaymentsNavigation } = useFlags();
  const isMobileDevice = useIsSmallViewportWidth();
  const client = useMiniappSdk();
  const selectedTransaction = useSelector(selectTransaction);
  const params = useSearchParams();

  const isSwipeModalContent: boolean = showMiniappPaymentsNavigation && isMobileDevice;

  const findTargetTransaction = useCallback((): TransactionDetails | undefined => {
    let targetTransaction;
    if (transactionId) {
      targetTransaction = transactions?.find(({ details }) => details.id === transactionId);
    } else if (params?.transactionUuid) {
      targetTransaction = transactions?.find(({ uuid }) => uuid === params.transactionUuid);
    } else {
      targetTransaction = transactions?.find(({ id }) => id === selectedTransaction?.id);
    }
    return targetTransaction ?? selectedTransaction;
  }, [transactions, transactionId, selectedTransaction, params?.transactionUuid]);

  const transaction = useMemo<TransactionDetails | undefined>(() => {
    return findTargetTransaction();
  }, [findTargetTransaction]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSwipeModalContent) {
      client?.header.hideHeader();
      client?.swipe.disableBackSwipe();
    }

    return () => {
      client?.header.showHeader();
      client?.swipe.enableBackSwipe();
    };
  }, [client, isSwipeModalContent]);

  useEffect(() => {
    if (transaction) {
      dispatch(setCurrentTransaction(transaction));
    }
  }, [transaction, dispatch]);

  const onCancelBookingClick = useCallback(() => {
    setIsCancelBookingStep(true);
  }, [setIsCancelBookingStep]);

  const onCheckInClick = useCallback(() => {
    dispatch(checkIn.request(transaction?.uuid));
  }, []);

  return {
    isSwipeModalContent,
    onCancelBookingClick,
    patchTransactionData,
    transaction,
    onCheckInClick,
    isDeepLinkAccess: !!params?.transactionUuid,
  };
};
