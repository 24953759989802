import styled from 'styled-components';

export const Root = styled.div`
  position: fixed;
  padding: 4px;
  width: 320px;
  box-sizing: border-box;
  top: 1em;
  right: 1em;
  z-index: 10;

  @media only screen and (max-width: 480px) {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
    top: 0;
    transform: translateX(0);
  }
`;
