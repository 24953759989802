import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { User as SentryUser } from '@sentry/react';
import { User } from 'store/user/types';
import { Building } from 'store/building/types';
import { selectBuilding } from 'store/building/selectors';
import { configSelector } from 'store/config/selectors';
import { DEFAULT_APP_BRAND } from 'shared/consts';
import { currentUser } from 'store/user/selectors';

const getAccountAnalytics = (building: Building) => {
  return {
    id: building.uuid,
    current_building_uuid: building.uuid,
    current_building_name: building.name,
    current_portfolio_id: building.portfolio_uuid,
  };
};

const getVisitorAnalytics = (accountId: string, building: Building, user: User, appBrand: string) => {
  return {
    id: user.uuid,
    account: accountId,
    test_user: !!user.test,
    current_building_name: building.name,
    current_portfolio_id: building.portfolio_uuid,
    tenant_company_id: user.company_uuid,
    app_brand: appBrand ?? DEFAULT_APP_BRAND,
    current_user_roles: user?.roles?.map(({ name }) => name),
  };
};

export const useSentryUserAnalytics = (): SentryUser | null => {
  const building = useSelector(selectBuilding);
  const user = useSelector(currentUser);
  const { appBrand } = useSelector(configSelector);

  return useMemo(() => {
    if (!building || !user) {
      return null;
    }

    const account = getAccountAnalytics(building);
    const visitor = getVisitorAnalytics(account.id, building, user, appBrand);

    return {
      visitor,
      account,
      id: user.uuid,
      email: user.email,
    };
  }, [building, user]);
};
