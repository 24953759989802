import { AttendeeType } from 'store/transactions/types';
import { FormattedAttendees } from 'store/cart/types';
import { Visitors } from '../adding-visitors-block';
import { VisitorsError } from 'components/adding-visitors-block/visitors-error.enum';
import { currentUser } from 'store/user/selectors';
import { formatAttendees } from 'components/payment/quick-checkout/components/utils/formatAttendees';
import { selectAllVisitorsEmails } from 'store/visitors-emails/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import visitorEmailsValidator from 'components/adding-visitors-block/validators/visitorEmailsValidator';

interface UseAddingVisitorsBlockReturnValues {
  visitors: Visitors;
  formattedAttendees: Array<FormattedAttendees>;
  error?: VisitorsError;
}

export const useAddingVisitorsBlock = (): UseAddingVisitorsBlockReturnValues => {
  const visitors: Visitors = useSelector(selectAllVisitorsEmails);
  const user = useSelector(currentUser);

  const formattedVisitors = useMemo(
    () => (visitors ? Object.fromEntries(Object.entries(visitors).filter(([, value]) => Boolean(value))) : {}),
    [visitors],
  );
  const formattedAttendees = useMemo<Array<FormattedAttendees>>(
    () => formatAttendees(formattedVisitors, AttendeeType.VISITOR),
    [formattedVisitors],
  );

  const error = useMemo<VisitorsError>(
    () => visitorEmailsValidator(Object.values(formattedVisitors), user.email),
    [formattedVisitors, user],
  );

  return { visitors, formattedAttendees, error };
};
