import { NotificationsState } from './types';

import * as actions from './actions';
import { ActionType, createReducer } from 'typesafe-actions';

export const getInitialState = (): NotificationsState => ({ currentNotification: undefined });

export const initialState: NotificationsState = getInitialState();

const showNotificationHandler = (
  state: NotificationsState,
  { payload: newNotification }: ActionType<typeof actions.showNotification>,
): NotificationsState => {
  return { ...state, currentNotification: newNotification };
};

const hideNotificationHandler = (state: NotificationsState): NotificationsState => {
  return { ...state, currentNotification: undefined };
};

const kioskReducer = createReducer(initialState)
  .handleAction(actions.showNotification, showNotificationHandler)
  .handleAction(actions.hideNotification, hideNotificationHandler);

export default kioskReducer;
