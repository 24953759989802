import styled, { css } from 'styled-components';
import { Image } from 'components/image';

export const StyledInput = styled.input`
  top: 0;
  left: 0;
  width: 100%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
`;

interface SVGProps {
  checked: string;
}

export const disabledHoverStyles: Record<NonNullable<SVGProps['checked']>, ReturnType<typeof css>> = {
  true: css`
    color: ${({ theme }) => theme.colors.palette.systemBlue};
  `,
  false: css`
    color: ${({ theme }) => theme.colors.palette.systemGreyDark};
  `,
};

export const hoverStyles: Record<NonNullable<SVGProps['checked']>, ReturnType<typeof css>> = {
  true: css`
    color: ${({ theme }) => theme.colors.palette.systemBlueHover};
  `,
  false: css`
    color: ${({ theme }) => theme.colors.palette.systemBlack};
  `,
};

export const StyledCheckbox = styled.div<{
  checked: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-shrink: 0;
  cursor: pointer;
  position: relative;
  transition: 0.3s;
  height: 60px;
  gap: 10px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.palette.systemBlack};
  font: 500 14px/16px ${({ theme }) => theme.fonts.join()};
`;

export const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
  font: 400 14px/16px ${({ theme }) => theme.fonts.join()};
`;

export const Container = styled.div<{ checked: boolean }>`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.palette.systemGreyLight};
  padding: 0 12px;
  border: ${({ checked, theme }) =>
    checked ? `2px solid ${theme.colors.palette.systemGreyMedium}` : '2px solid transparent'};
  -webkit-tap-highlight-color: transparent;
`;

export const StyledImage = styled(Image)`
  width: 36px;
  height: 36px;
  border-radius: 4px;
`;

export const IconContainer = styled.div`
  margin-left: auto;
`;
