import styled from 'styled-components';

export const StyledLink = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.fontColor};
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const LinkText = styled.span`
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
  margin-left: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;
