import {
  GetDefaultProps,
  TimeRangeByDuration,
  TimeRangeIndices,
  UseScheduleSelectReturnValues,
  MinDurationAndHours,
  DurationObject,
} from './interface';
import { getResourceTimeRanges, resetResourceTimeRanges } from 'store/resource-time-ranges/actions';
import { selectResourceTimeRanges } from 'store/resource-time-ranges/selectors';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_HEIGHT } from './const';
import { OwnerTypesEnum } from 'store/app-instance-configs/enums';
import { bookingTimeHandler } from 'utils/bookingTimeHandler';
import { getResourceTimeIndices } from 'utils/getResourceTimeIndices';
import { getTimeRangeByDuration, getTimeRangeByStartTime } from 'utils/getTimeRangeByDuration';
import moment from 'moment';
import { replace } from 'store/router/actions';
import { resourceState } from 'store/resource/selectors';
import { searchParams } from 'pages/date-select-page/interface';
import { useLocale } from 'hooks/use-locale.hook';
import { useOwnerParams } from 'hooks/use-owner-params.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { TimeRange } from 'store/resource-time-ranges/types';
import { useLocation } from 'react-router-dom';
import { resolveGetResourceTimeRangesParams } from 'utils';
import { useTimeInterval } from 'hooks/use-time-interval.hook';
import { transformToSortedDatesList } from 'utils/formatDate';
import { removeQueryParams } from 'utils/removeQueryParams';

const getDefaultIndex = ({
  startTime,
  endTime,
  timeRangeIndices,
  timeRangeByDuration,
  duration,
  isExact,
  availableTimeRangeByMinDuration,
}: GetDefaultProps): number => {
  if (startTime && endTime && duration && (!isExact || isExact === 'false')) {
    return timeRangeByDuration?.index;
  }
  if (startTime || endTime) {
    return timeRangeIndices?.startTimeIndex;
  }

  return availableTimeRangeByMinDuration?.index;
};

const getDefaultHeight = ({
  startTime,
  endTime,
  timeRangeIndices,
  timeRangeByDuration,
  duration,
  isExact,
  availableTimeRangeByMinDuration,
}: GetDefaultProps): number => {
  if (startTime && endTime && duration && (!isExact || isExact === 'false')) {
    return timeRangeByDuration?.height;
  }
  if (startTime && !endTime) {
    return availableTimeRangeByMinDuration?.height;
  }
  if (startTime || endTime) {
    return timeRangeIndices?.endTimeIndex * DEFAULT_HEIGHT - timeRangeIndices?.startTimeIndex * DEFAULT_HEIGHT;
  }

  return availableTimeRangeByMinDuration?.height;
};

export const useScheduleSelect = (): UseScheduleSelectReturnValues => {
  const dispatch = useDispatch();
  const { startDate, startTime, endTime, duration, isExact, startDates } = useSearchParams<searchParams>();
  const { ownerType, ownerUuid } = useOwnerParams();
  const locale = useLocale();
  const resourceTimeRanges = useSelector(selectResourceTimeRanges);
  const { resource } = useSelector(resourceState);
  const { isTimeIntervalFromTimeRanges, timeInterval } = useTimeInterval();

  const sortedDates = !!startDates && transformToSortedDatesList(startDates);
  const date = startDate || sortedDates?.[0];
  const isDatesExist = Boolean(date);
  const areTimeRangesForSelectedDate = resourceTimeRanges?.length && date === resourceTimeRanges[0]?.date;
  const isLoadingState = !areTimeRangesForSelectedDate;
  const shouldDispatchGetResourceTimeRanges: boolean = isDatesExist && resource?.id && isLoadingState;

  const filteredTimeRanges = useMemo(() => {
    const { startResourceTimeIndex, endResourceTimeIndex, timeRangesWithLastTimeSlot } =
      getResourceTimeIndices({
        resource,
        startDate: date,
        resourceTimeRanges,
        locale,
      }) || {};

    return timeRangesWithLastTimeSlot?.slice(startResourceTimeIndex, endResourceTimeIndex + 1);
  }, [resource, resourceTimeRanges, locale, date]);

  const minDurationAndHours = useMemo<MinDurationAndHours>(() => {
    const { minimumDuration, hours, maximumDuration } =
      getResourceTimeIndices({
        resource,
        startDate: date,
        resourceTimeRanges,
        locale,
      }) || {};

    return { minimumDuration, hours, maximumDuration };
  }, [resource, resourceTimeRanges, locale, date]);

  const durations: DurationObject = {
    max: minDurationAndHours?.maximumDuration,
    min: minDurationAndHours?.minimumDuration,
  };

  const timeRange = { startTime: startTime as string, endTime: endTime as string, locale };

  const availableTimeRanges = filteredTimeRanges?.filter(timeRangeItem => timeRangeItem.available);

  const availableTimeRangeByMinDuration = useMemo<TimeRangeByDuration>(() => {
    return getTimeRangeByDuration({
      duration: {
        min: minDurationAndHours?.minimumDuration,
        currentDuration: minDurationAndHours?.minimumDuration,
      },
      resourceTimeRanges: availableTimeRanges,
      filteredTimeRanges,
      hours: minDurationAndHours?.hours,
      timeInterval,
      isTimeIntervalFromTimeRanges,
    });
  }, [minDurationAndHours, availableTimeRanges, filteredTimeRanges, timeInterval, isTimeIntervalFromTimeRanges]);

  const availableTimeRangeByStartTime = useMemo<TimeRangeByDuration>(() => {
    if (isDatesExist && startTime && !endTime) {
      return getTimeRangeByStartTime({
        startTime,
        minDuration: minDurationAndHours?.minimumDuration,
        resourceTimeRanges: availableTimeRanges,
        filteredTimeRanges,
      });
    }

    return null;
  }, [isDatesExist, startTime, endTime, minDurationAndHours?.minimumDuration, availableTimeRanges, filteredTimeRanges]);

  const indexOfStartTime = useMemo<number>(
    () => filteredTimeRanges?.indexOf(filteredTimeRanges.find(el => el.time === startTime)),
    [filteredTimeRanges, startTime],
  );

  const indexOfEndTime = useMemo<number>(
    () => filteredTimeRanges?.indexOf(filteredTimeRanges.find(el => el.time === endTime)),
    [endTime, filteredTimeRanges],
  );

  const selectedTimeRange = useMemo<TimeRange[]>(() => {
    return filteredTimeRanges
      ?.slice(indexOfStartTime, indexOfEndTime + 1)
      ?.filter(filteredTimeRange => filteredTimeRange?.available);
  }, [filteredTimeRanges, indexOfEndTime, indexOfStartTime]);

  const timeRangeByDuration = useMemo<TimeRangeByDuration>(
    () =>
      getTimeRangeByDuration({
        duration: {
          max: minDurationAndHours?.maximumDuration,
          min: minDurationAndHours?.minimumDuration,
          currentDuration:
            +duration || (startTime && endTime && bookingTimeHandler({ startTime, endTime }).getTimeDiff()),
        },
        resourceTimeRanges: selectedTimeRange,
        filteredTimeRanges,
        hours: minDurationAndHours?.hours,
        timeInterval,
        isTimeIntervalFromTimeRanges,
      }),
    [
      minDurationAndHours,
      duration,
      startTime,
      endTime,
      selectedTimeRange,
      filteredTimeRanges,
      timeInterval,
      isTimeIntervalFromTimeRanges,
    ],
  );

  const timeRangeIndices = useMemo<TimeRangeIndices>(
    () =>
      filteredTimeRanges?.reduce(
        (accum, item, index) => {
          const result = accum;
          result.indexOfAvailableTimeRange = filteredTimeRanges?.indexOf(availableTimeRanges[0]);
          if (item.time === (startTime as string)) result.startTimeIndex = index;
          if (item.time === (endTime as string)) result.endTimeIndex = index;

          return result;
        },
        { indexOfAvailableTimeRange: null, startTimeIndex: null, endTimeIndex: null },
      ),
    [filteredTimeRanges, availableTimeRanges, startTime, endTime],
  );

  const index = getDefaultIndex({
    startTime,
    endTime,
    timeRangeIndices,
    timeRangeByDuration,
    duration,
    isExact,
    availableTimeRangeByMinDuration,
  });

  const top = index * DEFAULT_HEIGHT;

  const height = getDefaultHeight({
    startTime,
    endTime,
    timeRangeIndices,
    timeRangeByDuration,
    duration,
    isExact,
    availableTimeRangeByMinDuration,
  });

  const formatTime = useCallback(
    (time: string): string => {
      return bookingTimeHandler(timeRange).getFormatTime(time, locale, { removeOClockMinute: true }).toUpperCase();
    },
    [locale],
  );

  useEffect(() => {
    if (shouldDispatchGetResourceTimeRanges) {
      const getResourceTimeRangesParams = resolveGetResourceTimeRangesParams({
        ownerType: ownerType as OwnerTypesEnum,
        resourceId: resource.id,
        start: date,
        end: moment(date).add(1, 'days').format('YYYY-MM-DD'),
        ...(startDates && { startDates }),
      });

      dispatch(getResourceTimeRanges.request(getResourceTimeRangesParams));
    }
  }, [startDate, ownerType, ownerUuid, resource, dispatch, startDates, shouldDispatchGetResourceTimeRanges]);

  return {
    filteredTimeRanges,
    isLoadingState,
    formatTime,
    top,
    height,
    timeRangeByDuration,
    availableTimeRangeByMinDuration,
    availableTimeRangeByStartTime,
    durations,
    timeInterval,
    isTimeIntervalFromTimeRanges,
  };
};

export const useOnClose = (onClose: VoidFunction) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { initialQueryString } = useSelector(resourceState);

  return useCallback(() => {
    const updatedQueryString = removeQueryParams(initialQueryString, ['step']);

    onClose();

    if (!pathname.includes('additional-information')) {
      dispatch(replace(`${location.pathname}${updatedQueryString}`));
    }

    dispatch(resetResourceTimeRanges());
  }, [dispatch, onClose, pathname, initialQueryString]);
};
