import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledText = styled.div`
  color: ${({ theme }) => theme.colors.fontColor};
  font: 400 14px ${({ theme }) => theme.fonts.join()};
`;
