import { useDispatch } from 'react-redux';
import { Location, useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { replace } from 'store/router/actions';
import { useSearchParams } from 'hooks/use-search-params.hook';
import qs from 'qs';
import { pick } from 'utils/pickObjectProperty';
import { QUERY_PARAMS } from 'shared/consts';

export const useOnPopState = (): void => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { ...queryParams } = useSearchParams();
  const [memoryLocation, setMemoryLocation] = useState<Location>(null);
  const onPopState = useMemo(() => window.onpopstate, []);
  const queryString = useMemo<string>(
    () =>
      qs.stringify(
        pick(
          queryParams,
          QUERY_PARAMS.filter(
            param =>
              param !== 'startTime' &&
              param !== 'startDate' &&
              param !== 'endTime' &&
              param !== 'isExact' &&
              param !== 'duration' &&
              param !== 'filterByType' &&
              param !== 'capacity' &&
              param !== 'presetWindows' &&
              param !== 'filterByFloor',
          ),
        ),
      ),
    [queryParams],
  );

  useEffect(() => {
    setMemoryLocation(location);
  }, [location.pathname]);

  useEffect(() => {
    Object.defineProperty(window, 'onpopstate', () => {
      if (memoryLocation?.pathname === '/resource-booking/resources') {
        dispatch(replace(`/?${queryString}`));
      }
    });

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Object.defineProperty(window, 'onpopstate', { onPopState });
    };
  }, [dispatch, location.pathname, memoryLocation, onPopState, queryParams]);
};
