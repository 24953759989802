import { QuickCheckoutWrapper, Icon, Title, Subtitle, StyledButton } from '../styles';
import React, { useCallback } from 'react';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons/faTriangleExclamation';
import { useIntl } from 'react-intl';
import qs from 'qs';
import { pick } from 'utils/pickObjectProperty';
import { QUERY_PARAMS } from 'shared/consts';
import { replace } from 'store/router/actions';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'pages/date-select-page/interface';
import { useDispatch } from 'react-redux';
import { resetPatchTransactionStatus, resetRedirectTransaction, resetTransaction } from 'store/transactions/actions';
import { resetCart, resetSubmitCartStatus } from 'store/cart/actions';

export const ModalContent = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { ...searchParam } = useSearchParams<searchParams>();

  const redirectToTheLanding = useCallback(() => {
    const queryString = qs.stringify(
      pick(
        searchParam,
        QUERY_PARAMS.filter(
          param =>
            param !== 'startTime' &&
            param !== 'startDate' &&
            param !== 'endTime' &&
            param !== 'isExact' &&
            param !== 'duration',
        ),
      ),
    );
    dispatch(replace(`/?${queryString}`));
    dispatch(resetPatchTransactionStatus());
    dispatch(resetTransaction());
    dispatch(resetRedirectTransaction());
    dispatch(resetSubmitCartStatus());
    dispatch(resetCart());
  }, [dispatch, searchParam]);

  return (
    <QuickCheckoutWrapper data-testid="quick-checkout-swipe-modal">
      <Icon icon={faTriangleExclamation} size="2x" />
      <Title>{intl.formatMessage({ id: 'transaction_failed' })}</Title>
      <Subtitle>{intl.formatMessage({ id: 'transaction_not_completed' })}</Subtitle>
      <Subtitle>{intl.formatMessage({ id: 'review_your_card' })}</Subtitle>
      <StyledButton
        key="search"
        data-testid="back-button"
        onClick={redirectToTheLanding}
        text={intl.formatMessage({ id: 'back_to_resource_booking' })}
        multiline={false}
      />
    </QuickCheckoutWrapper>
  );
};
