import {
  AgreeButton,
  ButtonContainer,
  DeclineButton,
  LinkText,
  LocationNameText,
  ModalContainer,
  ModalTitle,
  Text,
  TextContainer,
} from './styles';
import React from 'react';
import { useCurrentConfig } from 'hooks/use-current-config.hook';
import { useIntl } from 'react-intl';
import { useModalContent } from './hooks/use-modal-content.hook';
import { ModalContentProps } from 'components/termsAndConditions/types';

export const ModalContent = ({ handleChangeStep }: ModalContentProps) => {
  const intl = useIntl();
  const { location_name } = useCurrentConfig() || {};
  const { onAgreeClick, onDeclineClick, isAgreeButtonLoading, isDeclineButtonLoading, isDeclineButtonDisabled } =
    useModalContent();

  return (
    <ModalContainer>
      <TextContainer>
        <ModalTitle>{intl.formatMessage({ id: 'termsAndConditionsTitle' })}</ModalTitle>
        <Text>
          {/* @ts-ignore */}
          {intl.formatMessage(
            { id: 'byClickingAgree' },
            {
              link: <LinkText onClick={handleChangeStep}>{intl.formatMessage({ id: 'termsAndConditions' })}</LinkText>,
              locationName: <LocationNameText>{location_name}</LocationNameText>,
            },
          )}
        </Text>
        <Text>
          {/* @ts-ignore */}
          {intl.formatMessage(
            { id: 'byClickingDecline' },
            { locationName: <LocationNameText>{location_name}</LocationNameText> },
          )}
        </Text>
      </TextContainer>
      <ButtonContainer>
        <DeclineButton
          text={intl.formatMessage({ id: 'declineButton' })}
          onClick={onDeclineClick}
          variant="cancelButton"
          disabled={isDeclineButtonDisabled}
          loading={isDeclineButtonLoading}
        />
        <AgreeButton
          text={intl.formatMessage({ id: 'agreeButton' })}
          onClick={onAgreeClick}
          variant="cta"
          loading={isAgreeButtonLoading}
        />
      </ButtonContainer>
    </ModalContainer>
  );
};
