import React from 'react';
import { DateSelectPageFooter } from 'pages/date-select-page/components/date-select-page-footer';
import { TimeSelectPageFooter } from 'pages/time-select-page/components/time-select-page-footer';
import { DateTimeSelectPageFooterProps } from '../interface';

export const DateTimeSelectPageFooter = ({
  isDateStep,
  onSearchClick,
  onNextClick,
  onSkipClick,
}: DateTimeSelectPageFooterProps): JSX.Element => {
  return isDateStep ? (
    <DateSelectPageFooter onNextClick={onNextClick} />
  ) : (
    <TimeSelectPageFooter onSearchClick={onSearchClick} onSkipClick={onSkipClick} />
  );
};
