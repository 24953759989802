import styled from 'styled-components';
import { StyledModalWrapper } from 'components/time-selector/time-picker/styles';

export const BuildingGroupSelectStyledModalWrapper = styled(StyledModalWrapper)`
  z-index: 15;
  flex-direction: column;
  padding: 0px;
  padding-bottom: 16px;
  border-radius: 4px 4px 0 0;
  box-shadow: ${({ theme }) => `0px 0px 2px ${theme.colors.greys.adminBlack}`};
`;

export const SaveButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  padding: 10px;
  padding-right: 16px;
  box-shadow: ${({ theme }) => `0px 0px 2px ${theme.colors.greys.adminBlack}`};
`;

export const SaveButton = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.7px;
  color: ${({ theme }) => theme.colors.primary};
  font: 500 16px/19px ${({ theme }) => theme.fonts.join()};
`;
