import React from 'react';
import { CalendarView } from './calendar-view';
import { CalendarViewContainer } from './styles';

export const DateSelectPageContent = (): JSX.Element => {
  const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  return (
    <CalendarViewContainer data-testid="calendar-view-container">
      {months.map(indexOfMonth => (
        <CalendarView key={indexOfMonth} indexOfMonth={indexOfMonth} />
      ))}
    </CalendarViewContainer>
  );
};
