import { FloorPlanAppInstanceConfigResponse, GetFloorPlanAppInstanceConfigParams } from './types';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { FailureActionPayload } from 'store/errors/types';

export const getFloorPlanAppInstanceConfig = createAsyncAction(
  'GET_FLOORPLAN_APP_INSTANCE_CONFIG_REQUEST',
  'GET_FLOORPLAN_APP_INSTANCE_CONFIG_SUCCESS',
  'GET_FLOORPLAN_APP_INSTANCE_CONFIG_FAILURE',
)<GetFloorPlanAppInstanceConfigParams, FloorPlanAppInstanceConfigResponse, FailureActionPayload>();

export const emptyFloorPlanAppInstanceConfig = createAction('IS_FLOORPLAN_APP_INSTANCE_CONFIG_EMPTY')<{
  error: Error;
}>();
