import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';
import { ACTION_STATUSES } from 'shared/consts';

import { ResourcesState } from './types';

export const initialState: ResourcesState = {
  resources: null,
  infiniteResources: {},
  getInfiniteResources: {
    status: null,
    error: null,
  },
  total: null,
  loading: false,
  error: null,
};

const getResourcesRequestHandler = (state: ResourcesState) => ({
  ...state,
  loading: true,
});

const getResourcesSuccessHandler = (
  state: ResourcesState,
  { payload }: ActionType<typeof actions.getResources.success>,
): ResourcesState => ({
  ...state,
  resources: payload.resources,
  total: payload.total,
  loading: false,
  error: null,
});

const getResourcesFailureHandler = (
  state: ResourcesState,
  { payload: error }: ActionType<typeof actions.getResources.failure>,
): ResourcesState => ({
  ...state,
  loading: false,
  error: error.error,
});

const resetResourceHandler = (state: ResourcesState): ResourcesState => ({
  ...state,
  loading: false,
  resources: null,
});

const getInfiniteResourcesRequestHandler = (
  state: ResourcesState,
  { payload }: ActionType<typeof actions.getInfiniteResources.request>,
): ResourcesState => ({
  ...state,
  infiniteResources: { ...state.infiniteResources, [payload.indexOfSet]: null },
  getInfiniteResources: {
    status: ACTION_STATUSES.PENDING,
    error: null,
  },
});

const getInfiniteResourcesSuccessHandler = (
  state: ResourcesState,
  { payload }: ActionType<typeof actions.getInfiniteResources.success>,
): ResourcesState => ({
  ...state,
  infiniteResources: { ...state.infiniteResources, [payload.indexOfSet]: payload.resources },
  total: payload.total,
  getInfiniteResources: {
    status: ACTION_STATUSES.FULFILLED,
    error: null,
  },
});

const getInfiniteResourcesFailureHandler = (
  state: ResourcesState,
  { payload: error }: ActionType<typeof actions.getInfiniteResources.failure>,
): ResourcesState => ({
  ...state,
  getInfiniteResources: {
    status: ACTION_STATUSES.REJECTED,
    error: error.error,
  },
});

const resetInfiniteResourcesStateHandler = (state: ResourcesState): ResourcesState => ({
  ...state,
  infiniteResources: {},
  getInfiniteResources: {
    status: null,
    error: null,
  },
  total: null,
});

const resourcesReducer = createReducer(initialState)
  .handleAction(actions.getResources.request, getResourcesRequestHandler)
  .handleAction(actions.getResources.success, getResourcesSuccessHandler)
  .handleAction(actions.getResources.failure, getResourcesFailureHandler)
  .handleAction(actions.resetResourcesState, resetResourceHandler)
  .handleAction(actions.getInfiniteResources.request, getInfiniteResourcesRequestHandler)
  .handleAction(actions.getInfiniteResources.success, getInfiniteResourcesSuccessHandler)
  .handleAction(actions.getInfiniteResources.failure, getInfiniteResourcesFailureHandler)
  .handleAction(actions.resetInfiniteResourcesState, resetInfiniteResourcesStateHandler);

export default resourcesReducer;
