export enum PAYMENT_MINIAPP_POST_MESSAGES {
  PAYMENT_MINIAPP_CLOSE = 'payment-miniapp-close',
  PAYMENT_MINIAPP_GO_BACK = 'payment-miniapp-goback-button',
  PAYMENT_MINIAPP_CHECKOUT_DESKTOP = 'payment-miniapp-checkout-desktop',
  PAYMENT_MINIAPP_RECEIPT = 'payment-miniapp-receipt',
  PAYMENT_MINIAPP_QUICK_CHECKOUT = 'payment-miniapp-quick-checkout',
  PAYMENT_MINIAPP_CHECKOUT_MOBILE = 'payment-miniapp-checkout-mobile',
  PAYMENT_MINIAPP_EDIT_BUTTON_CLICK = 'payment-miniapp-edit-button-click',
  PAYMENT_MINIAPP_DONE_BUTTON_CLICK = 'payment-miniapp-done-button-click',
  PAYMENT_MINIAPP_CANCEL_BOOKING_PAGE = 'payment-miniapp-cancel-booking-page',
  PAYMENT_MINIAPP_CLOSE_CANCEL_BOOKING_PAGE = 'payment-miniapp-close-cancel-booking-page',
  PAYMENT_MINIAPP_INIT_RESPONSE = 'initResponse',
  PAYMENT_MINIAPP_INIT = 'init',
  PAYMENT_MINIAPP_3DS_REDIRECT_ERROR = 'payment-miniapp-3ds-redirect-error',
  PAYMENT_MINIAPP_3DS_REDIRECT_SUCCEEDED = 'payment-miniapp-3ds-redirect-succeeded',
  PAYMENT_MINIAPP_3DS_STATUS_UPDATE = 'payment-miniapp-3ds-status-update',
}
