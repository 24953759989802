import { Order } from 'store/cart/types';
import { TransactionDetails } from 'store/transactions/types';

export const formatCartItemsIds = (cart: Order): Array<string> => {
  return cart ? cart.items?.map(item => item.id) : [];
};

export const formatTransactionItemsIds = (transaction: TransactionDetails): Array<string> => {
  if (!transaction) return [];

  return [transaction.details?.resource_id];
};
