import * as userActions from './actions';

import { catchError, concatMap, filter, map, mapTo, switchMap, withLatestFrom } from 'rxjs/operators';

import { AjaxError } from 'rxjs/ajax';
import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { NotFound } from 'store/user/errors';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';
import { transformErrorIntoSerializableError } from 'store/utils';
import qs from 'qs';
import { replace } from 'store/router/actions';
import { getBuilding } from 'store/building/actions';
import { getBuildingTheme } from 'store/theme/actions';

const UNAUTHORIZED_ERROR_CODE = 401;
const NOT_FOUND_ERROR_CODE = 404;
const BAD_REQUEST_ERROR_CODE = 400;
const INTERNAL_SERVER_ERROR_CODE = 500;
const errors = [UNAUTHORIZED_ERROR_CODE, NOT_FOUND_ERROR_CODE, BAD_REQUEST_ERROR_CODE, INTERNAL_SERVER_ERROR_CODE];

export const externalLoginRedirect: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(userActions.getCurrentUser.success)),
    map(() => {
      const search: string = window.location?.search ?? '';
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { authToken, apiUrl, ...restSearch } = qs.parse(search.replace(/^\?/, ''));
      const newSearchString = qs.stringify(restSearch);
      const path = window.location?.pathname ?? '/';

      return replace(newSearchString ? `${path}?${newSearchString}` : path);
    }),
  );

export const externalLoginFailureRedirect: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(filter(isActionOf(userActions.getCurrentUser.failure)), mapTo(replace('/')));

export const getCurrentUser: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(userActions.getCurrentUser.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) =>
      apiClient(state)
        .getCurrentUser(payload)
        .pipe(
          map(xhrPayload => {
            return userActions.getCurrentUser.success({ user: xhrPayload.response });
          }),
          catchError((error: Error) => {
            if (error instanceof AjaxError && error instanceof AjaxError && errors.includes(error.status)) {
              return of(
                userActions.getCurrentUser.failure({
                  error: transformErrorIntoSerializableError(new NotFound()),
                }),
              );
            }

            return of(userActions.getCurrentUser.failure({ error: transformErrorIntoSerializableError(error) }));
          }),
        ),
    ),
  );

export const refetchAfterRefreshToken: Epic<RootAction, RootAction, RootState, RootDependencies> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(userActions.refreshToken)),
    withLatestFrom(state$),
    concatMap(([, state]) =>
      of(
        getBuilding.request(state.config.buildingUuid),
        getBuildingTheme.request(state.config.buildingUuid),
        userActions.getCurrentUser.request({
          authToken: state.config.authToken,
          appBrand: state.config.appBrand,
          buildingUuid: state.config.buildingUuid,
        }),
      ),
    ),
  );
