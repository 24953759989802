import { TimeHandlerInterface } from './types';
import { BookingTimeHandler } from './bookingTimeHandler';

export const bookingTimeHandler = (timeRange: {
  startTime?: string;
  endTime?: string;
  locale?: string;
}): TimeHandlerInterface => {
  return new BookingTimeHandler(timeRange);
};
