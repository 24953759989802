import { useDispatch, useSelector } from 'react-redux';
import { selectTransactions, selectTransactionsStatus } from 'store/transactions/selectors';
import { useEffect } from 'react';
import { getTransactions } from 'store/transactions/actions';
import qs from 'qs';
import { pick } from 'utils/pickObjectProperty';
import { QUERY_PARAMS } from 'shared/consts';
import { replace } from 'store/router/actions';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useLocation } from 'react-router';

export const useRedirectUserToLanding = (): void => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const queryParams = useSearchParams();
  const isResourcePage = pathname.includes('resource-booking/resource/');
  const isFirstPage = window.history.state.idx === 0;
  const shouldRedirectIfTransactionsExist = isResourcePage && isFirstPage;
  const getTransactionsStatus = useSelector(selectTransactionsStatus);
  const transactions = useSelector(selectTransactions);

  useEffect(() => {
    if (shouldRedirectIfTransactionsExist && !getTransactionsStatus) {
      dispatch(getTransactions.request({}));
    }
  }, [shouldRedirectIfTransactionsExist, getTransactionsStatus, dispatch]);

  useEffect(() => {
    if (shouldRedirectIfTransactionsExist && transactions?.length) {
      const queryString = qs.stringify(
        pick(
          queryParams,
          QUERY_PARAMS.filter(
            param =>
              param !== 'startTime' &&
              param !== 'startDate' &&
              param !== 'endTime' &&
              param !== 'isExact' &&
              param !== 'duration',
          ),
        ),
      );
      dispatch(replace(`/?${queryString}`));
    }
  }, [shouldRedirectIfTransactionsExist, transactions, dispatch, queryParams]);
};
