import { DEFAULT_TYPE_COMMA_DELIMITER, QUERY_PARAMS, TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';
import React, { useCallback, useMemo } from 'react';

import { ResourceType } from 'store/resources/types';
import { StyledLabelBlock } from './styles';
import { getResourceTypeString } from 'utils/formatResourceType';
import { pick } from 'utils/pickObjectProperty';
import { push } from 'store/router/actions';
import qs from 'qs';
import { resetResourcesState } from 'store/resources/actions';
import { track } from '@hqo/web-tracking';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useIsAppInstanceConfigNexudus } from 'hooks/use-is-app-instance-config-nexudus.hook';
import { useLocation } from 'react-router';
import { useFilteringEnabled } from 'hooks/use-filtering-enabled.hook';

interface TypesContentProps {
  resourceTypes: Array<ResourceType>;
}

interface TypesWrapperProps {
  type: ResourceType | string;
  onClick: (value: string) => void;
  isCurrentConfigNexudus?: boolean;
}

const TypesWrapper = ({ type, onClick, isCurrentConfigNexudus }: TypesWrapperProps) => {
  const intl = useIntl();

  const onLabelClick = useCallback(() => {
    onClick(type);
  }, [onClick, type]);

  return (
    <StyledLabelBlock
      label={getResourceTypeString(isCurrentConfigNexudus, type, intl)}
      key={type}
      className="resources-types"
      onClick={onLabelClick}
    />
  );
};

export const TypesContent = ({ resourceTypes }: TypesContentProps): JSX.Element => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const isFilteringEnabled = useFilteringEnabled();

  const queryParams = useMemo(() => {
    return qs.parse(search, { ignoreQueryPrefix: true });
  }, [search]);

  const isCurrentConfigNexudus = useIsAppInstanceConfigNexudus();

  const onLabelClick = useCallback(
    (value: any) => {
      track(
        TRACK_EVENT_NAMES.RESOURCE_TYPE_LANDING_CLICK,
        { type: TRACK_EVENT_TYPES.ACTION, resource_type: value },
        { sendToHqoTracking: true },
      );
      const queryString = qs.stringify({
        ...queryParams,
        filterByType: value.replace(',', DEFAULT_TYPE_COMMA_DELIMITER),
      });

      dispatch(push(`resource-booking/resources?${queryString}`));
      dispatch(resetResourcesState());
    },
    [dispatch, queryParams],
  );

  const onAllTypesLabelClick = useCallback(() => {
    track(TRACK_EVENT_NAMES.RESOURCE_TYPE_LANDING_CLICK, { type: TRACK_EVENT_TYPES.ACTION, resource_type: 'ALL' });
    const allTypesParams = qs.stringify(
      pick(
        queryParams,
        QUERY_PARAMS.filter(param => param !== 'filterByType'),
      ),
    );

    dispatch(push(`resource-booking/resources?${allTypesParams}`));
    dispatch(resetResourcesState());
  }, [dispatch, queryParams]);

  return (
    <>
      <TypesWrapper type="ALL_TYPES" onClick={onAllTypesLabelClick} />
      {isFilteringEnabled &&
        resourceTypes?.map(type => (
          <TypesWrapper type={type} onClick={onLabelClick} key={type} isCurrentConfigNexudus={isCurrentConfigNexudus} />
        ))}
    </>
  );
};
