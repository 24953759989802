import { TimeRange } from 'store/resource-time-ranges/types';
import { DEFAULT_TIME_INTERVAL, ONE_HOUR } from 'components/schedule-select/const';
import { formatTime, parseTime } from 'shared/utils/time';

interface ClosestHourAvailability {
  startTime: string;
  endTime: string;
}

function getStartAndEndTimeOfTimeRange(timeRange: TimeRange) {
  const timeRangeInterval = timeRange.time_interval ?? DEFAULT_TIME_INTERVAL;
  return {
    startTime: timeRange.time,
    endTime: formatTime(parseTime(timeRange.time) + timeRangeInterval, 'fr'),
    interval: timeRangeInterval,
  };
}

function getTimeRangeTillMaxDuration(availableTimeSlots: Array<TimeRange>, maxDuration: number) {
  let currentDuration = 0;
  const timeSlots = [];

  for (let index = 0; index < availableTimeSlots.length; index++) {
    const currentTimeSlot = availableTimeSlots[index];
    const { interval } = getStartAndEndTimeOfTimeRange(currentTimeSlot);
    currentDuration += interval;
    timeSlots.push(currentTimeSlot);

    if (currentDuration >= maxDuration) {
      break;
    }
  }

  return { duration: currentDuration, timeSlots };
}

export const findClosestHourAvailability = (availableTimeSlots: Array<TimeRange>): ClosestHourAvailability | null => {
  if (!availableTimeSlots?.length) return null;
  const firstTimeSlot = availableTimeSlots[0];
  const minDuration = firstTimeSlot.minimum_duration;
  const maxDuration =
    firstTimeSlot.maximum_duration && firstTimeSlot.maximum_duration < ONE_HOUR
      ? firstTimeSlot.maximum_duration
      : ONE_HOUR;

  const { duration, timeSlots: filteredTimeSlots } = getTimeRangeTillMaxDuration(availableTimeSlots, maxDuration);

  if (duration < minDuration || !filteredTimeSlots.length) return null;

  const { startTime } = getStartAndEndTimeOfTimeRange(filteredTimeSlots[0]);
  const lastTimeSlot = filteredTimeSlots[filteredTimeSlots.length - 1];
  const { endTime } = getStartAndEndTimeOfTimeRange(lastTimeSlot);

  return { startTime, endTime };
};
