import { GetJWTHandlerPayload } from '@hqo/hqo-miniapp-client-sdk';
import { saveExternalConfig } from 'store/config/actions';
import { useDispatch } from 'react-redux';
import { useRefreshToken } from 'hooks/use-refresh-token.hook';
import { useMiniappSdkEventHandler } from 'components/miniapp-sdk-provider/miniapp-sdk.hook';

export const useSyncAccessTokenWithHostApp = (): void => {
  const refreshTokenEventName = 'refreshTokenResponse';
  const dispatch = useDispatch();

  useRefreshToken();

  useMiniappSdkEventHandler(
    refreshTokenEventName,
    ({ token }: GetJWTHandlerPayload) => {
      dispatch(saveExternalConfig({ authToken: `Bearer ${token}` }));
    },
    [dispatch],
  );
};
