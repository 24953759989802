import React from 'react';
import { FlexContainer, CounterBar, Container } from 'components/skeleton/styles';
import { Resource } from 'components/skeleton/components/resource';

export const ResourcesListSkeleton = (): JSX.Element => {
  return (
    <Container data-testid="resources-list-skeleton" isSwipeModalContent>
      <FlexContainer gap={25} isColumnDirection isWidth className="mobile">
        <CounterBar />
        <Resource />
        <Resource />
        <Resource />
      </FlexContainer>
    </Container>
  );
};
