import React from 'react';

import { searchParams } from 'components/footer';
import { MultidayWarningMessage } from 'components/multiday-warning-message/multiday-warning-message';
import { SelectedDateTimeFilter } from 'components/selected-date-time-filter/selected-date-time-filter';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { StyledButton } from 'pages/date-select-page/components/styles';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectResource } from 'store/resource/selectors';

import { ButtonsWrapper } from './styles';

interface DateSelectPageFooterProps {
  onNextClick: VoidFunction;
  onDateLinkClick: VoidFunction;
  onTimeLinkClick: VoidFunction;
  onSaveClick?: VoidFunction;
}

export const DateSelectPageFooter = ({
  onNextClick,
  onDateLinkClick,
  onTimeLinkClick,
  onSaveClick,
}: DateSelectPageFooterProps): JSX.Element => {
  const intl = useIntl();
  const { showResourceDetailsDateTimeInputs } = useFlags();
  const { startDate, startDates } = useSearchParams<searchParams>();
  const { pathname } = useLocation();
  const isButtonDisabled = !(startDate || startDates);
  const { isMultiDayBookingEnabled } = useAppInstanceConfigFeature();
  const { supports_multi_day_bookings: supportsMultiDayBookings } = useSelector(selectResource);
  const isResourcesPage = pathname.includes('resources');
  const shouldHideDateTimeInputs = !showResourceDetailsDateTimeInputs || isResourcesPage;

  return (
    <ButtonsWrapper data-testid="date-select-buttons-wrapper">
      <SelectedDateTimeFilter
        onDateClick={onDateLinkClick}
        onTimeClick={startDate || startDates ? onTimeLinkClick : null}
        showDateTimeLinks={shouldHideDateTimeInputs}
      />
      {isMultiDayBookingEnabled && supportsMultiDayBookings && <MultidayWarningMessage />}
      <StyledButton
        key="save"
        data-testid="save-button"
        onClick={shouldHideDateTimeInputs ? onNextClick : onSaveClick}
        text={intl.formatMessage({ id: 'save' })}
        disabled={isButtonDisabled}
        multiline={false}
      />
    </ButtonsWrapper>
  );
};
