import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { TransactionsState } from './types';
import { ACTION_STATUSES } from 'shared/consts';

const initialState: TransactionsState = {
  transactions: null,
  transaction: null,
  getTransaction: {
    status: null,
    error: null,
  },
  getTransactions: {
    status: null,
    error: null,
  },
  patchTransaction: {
    status: null,
    error: null,
    type: null,
  },
  loading: false,
  processed: false,
  refreshing: false,
  error: null,
  getRedirectTransaction: {
    status: null,
    error: null,
  },
  redirectTransaction: null,
  checkIn: {
    status: null,
    error: null,
  },
  currentTransaction: null,
};

const getTransactionRequestHandler = (state: TransactionsState): TransactionsState => ({
  ...state,
  getTransaction: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getTransactionSuccessHandler = (
  state: TransactionsState,
  { payload }: ActionType<typeof actions.getTransaction.success>,
): TransactionsState => ({
  ...state,
  transaction: payload,
  getTransaction: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getTransactionFailureHandler = (
  state: TransactionsState,
  { payload: error }: ActionType<typeof actions.getTransaction.failure>,
): TransactionsState => ({
  ...state,
  getTransaction: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const resetTransactionHandler = (state: TransactionsState): TransactionsState => ({
  ...state,
  transaction: null,
  getTransaction: {
    error: null,
    status: null,
  },
});

const getTransactionsRequestHandler = (state: TransactionsState): TransactionsState => ({
  ...state,
  loading: true,
  processed: false,
  refreshing: false,
  getTransactions: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getTransactionsSuccessHandler = (
  state: TransactionsState,
  { payload }: ActionType<typeof actions.getTransactions.success>,
): TransactionsState => ({
  ...state,
  transactions: payload?.transactions,
  getTransactions: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
  loading: false,
  processed: false,
  refreshing: false,
  error: null,
});

const getTransactionsFailureHandler = (
  state: TransactionsState,
  { payload: error }: ActionType<typeof actions.getTransactions.failure>,
): TransactionsState => ({
  ...state,
  getTransactions: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
  loading: false,
  processed: false,
  refreshing: false,
  error: error.error,
});

const patchTransactionRequestHandler = (
  state: TransactionsState,
  { payload }: ActionType<typeof actions.patchTransaction.request>,
): TransactionsState => ({
  ...state,
  patchTransaction: {
    error: null,
    status: ACTION_STATUSES.PENDING,
    type: payload.status ?? payload.status_3ds,
  },
});

const patchTransactionSuccessHandler = (state: TransactionsState): TransactionsState => ({
  ...state,
  patchTransaction: {
    ...state.patchTransaction,
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const resetPatchTransaction = (state: TransactionsState): TransactionsState => ({
  ...state,
  patchTransaction: {
    type: null,
    error: null,
    status: null,
  },
});

const patchTransactionFailureHandler = (
  state: TransactionsState,
  { payload: error }: ActionType<typeof actions.patchTransaction.failure>,
): TransactionsState => ({
  ...state,
  patchTransaction: {
    ...state.patchTransaction,
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const getRedirectTransactionRequestHandler = (state: TransactionsState): TransactionsState => ({
  ...state,
  getRedirectTransaction: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getRedirectTransactionSuccessHandler = (
  state: TransactionsState,
  { payload }: ActionType<typeof actions.getRedirectTransaction.success>,
): TransactionsState => ({
  ...state,
  redirectTransaction: payload,
  getRedirectTransaction: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getRedirectTransactionFailureHandler = (
  state: TransactionsState,
  { payload: error }: ActionType<typeof actions.getRedirectTransaction.failure>,
): TransactionsState => ({
  ...state,
  getRedirectTransaction: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const resetRedirectTransactionHandler = (state: TransactionsState): TransactionsState => ({
  ...state,
  redirectTransaction: null,
  getRedirectTransaction: {
    error: null,
    status: null,
  },
});

const checkInRequestHandler = (state: TransactionsState): TransactionsState => ({
  ...state,
  checkIn: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const checkInSuccessHandler = (
  state: TransactionsState,
  { payload }: ActionType<typeof actions.checkIn.success>,
): TransactionsState => ({
  ...state,
  currentTransaction: {
    ...state.currentTransaction,
    details: { ...state.currentTransaction.details, check_in: { ...payload } },
  },
  checkIn: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const checkInFailureHandler = (
  state: TransactionsState,
  { payload: error }: ActionType<typeof actions.checkIn.failure>,
): TransactionsState => ({
  ...state,
  checkIn: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const setCurrentTransactionHandler = (
  state: TransactionsState,
  { payload }: ActionType<typeof actions.setCurrentTransaction>,
): TransactionsState => ({
  ...state,
  currentTransaction: { ...payload },
});

const resetCurrentTransactionHandler = (state: TransactionsState): TransactionsState => ({
  ...state,
  currentTransaction: null,
});

const transactionsReducer = createReducer(initialState)
  .handleAction(actions.getTransaction.request, getTransactionRequestHandler)
  .handleAction(actions.getTransaction.success, getTransactionSuccessHandler)
  .handleAction(actions.getTransaction.failure, getTransactionFailureHandler)
  .handleAction(actions.resetTransaction, resetTransactionHandler)
  .handleAction(actions.getTransactions.request, getTransactionsRequestHandler)
  .handleAction(actions.getTransactions.success, getTransactionsSuccessHandler)
  .handleAction(actions.getTransactions.failure, getTransactionsFailureHandler)
  .handleAction(actions.getRedirectTransaction.request, getRedirectTransactionRequestHandler)
  .handleAction(actions.getRedirectTransaction.success, getRedirectTransactionSuccessHandler)
  .handleAction(actions.getRedirectTransaction.failure, getRedirectTransactionFailureHandler)
  .handleAction(actions.resetRedirectTransaction, resetRedirectTransactionHandler)
  .handleAction(actions.patchTransaction.request, patchTransactionRequestHandler)
  .handleAction(actions.patchTransaction.success, patchTransactionSuccessHandler)
  .handleAction(actions.patchTransaction.failure, patchTransactionFailureHandler)
  .handleAction(actions.resetPatchTransactionStatus, resetPatchTransaction)
  .handleAction(actions.checkIn.request, checkInRequestHandler)
  .handleAction(actions.checkIn.success, checkInSuccessHandler)
  .handleAction(actions.checkIn.failure, checkInFailureHandler)
  .handleAction(actions.setCurrentTransaction, setCurrentTransactionHandler)
  .handleAction(actions.resetCurrentTransaction, resetCurrentTransactionHandler);

export default transactionsReducer;
