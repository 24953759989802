import { ACTION_STATUSES, TRANSACTION_TYPES } from 'shared/consts';
import { ReceiptContainer, ButtonContainer, CancellationExpiredText, DoublePane, LeftPane, RightPane } from '../styles';
import React from 'react';
import {
  AttendeeRO,
  PatchTransaction,
  PaymentMethod,
  ResourceFeature,
  TransactionDetails,
} from 'store/transactions/types';
import { useIntl } from 'react-intl';
import { ReceiptOverview } from './receipt-overview';
import { ReceiptSummary } from './receipt-summary';
import { ReceiptCheckIn } from './receipt-check-in';
import { AddOnDto } from 'store/add-ons/types';
import { Item, OrderType } from 'store/cart/types';
import { useBookingObject } from 'components/payment/receipt/components/hooks/useBookingObject.hook';
import { DoneButtonBlock } from 'components/payment/receipt/components/done-button';

interface SwipeReceiptContentProps {
  transaction: TransactionDetails;
  onCancelBookingClick: VoidFunction;
  patchTransactionData: Pick<PatchTransaction, 'status' | 'type'>;
  isSwipeModalContent: boolean;
  onCheckInClick: VoidFunction;
  onCloseClick: VoidFunction;
}

export interface MappedBookingObject {
  details: MappedBookingDetailsObject;
  type: TRANSACTION_TYPES | OrderType;
  payment_method?: PaymentMethod;
  currency_type: string;
  total_price: number;
  total_tax: number;
  total_charge: number;
  isMultiday: boolean;
  total_discount?: number;
  items: Array<Item>;
  vendor?: {
    name: string;
  };
}

interface MappedBookingDetailsObject {
  start_at?: string;
  end_at?: string;
  discount_applied: number;
  add_ons?: Array<AddOnDto>;
  attendees?: Array<AttendeeRO>;
  user_info?: Array<FormResponse>;
  cancellation_description?: string;
  name: string;
  resource: {
    id: string;
    name: string;
    features?: Array<ResourceFeature>;
    amenities_description?: string;
    timezone?: string;
  };
}

export const SwipeReceiptContent = ({
  isSwipeModalContent,
  onCancelBookingClick,
  patchTransactionData,
  transaction,
  onCheckInClick,
  onCloseClick,
}: SwipeReceiptContentProps) => {
  const intl = useIntl();
  const isCancellationExpiredTextVisible =
    transaction?.details?.cancellation_window_end_minutes >= 0 &&
    transaction?.details?.cancellation_window_end_minutes !== null;
  const isCheckInAllowed =
    !!transaction?.details?.check_in?.status && patchTransactionData.status !== ACTION_STATUSES.FULFILLED;

  const mappedBookingObject = useBookingObject(transaction);

  return (
    <ReceiptContainer data-testid="receipt-swipe-modal">
      {transaction && (
        <>
          <DoublePane isCancellationExpiredTextVisible={isCancellationExpiredTextVisible}>
            <LeftPane className="left-pane">
              <ReceiptOverview isSwipeModalContent={isSwipeModalContent} transaction={transaction} />
              {isCheckInAllowed && <ReceiptCheckIn onCheckIn={onCheckInClick} />}
            </LeftPane>
            <RightPane className="right-pane">
              <ReceiptSummary
                onCancelBooking={onCancelBookingClick}
                isSwipeModalContent={isSwipeModalContent}
                transaction={mappedBookingObject}
              />
            </RightPane>
          </DoublePane>
          <ButtonContainer isButtonLabelVisible className="button-container" data-testid="double-pane-button">
            {isCancellationExpiredTextVisible && (
              <CancellationExpiredText>
                {intl.formatMessage(
                  { id: 'notice_required' },
                  { hours: transaction.details?.cancellation_window_end_minutes / 60 },
                )}
              </CancellationExpiredText>
            )}
            {(transaction?.type === TRANSACTION_TYPES.RESOURCES_BOOKING ||
              mappedBookingObject?.type === OrderType.RESOURCE_BOOKING) &&
              !mappedBookingObject.isMultiday && <DoneButtonBlock onClick={onCloseClick} text="Done" />}
          </ButtonContainer>
        </>
      )}
    </ReceiptContainer>
  );
};
