import { DateTimeFilterContainer, DateTimeFilterWrapper } from './styles';
import React, { useEffect } from 'react';

import { PriceComponent } from './components/price-component';
import { resetPrice } from 'store/price/actions';
import { useDispatch } from 'react-redux';
import { useSelectedDateTimeFilter } from './hooks/use-selected-date-time-filter.hook';
import { DateTimeLinks } from './components/date-time-links';
import { MultipleBookings } from './components/multiple-bookings';

interface SelectedDateTimeFilterProps {
  onDateClick?: VoidFunction;
  onTimeClick?: VoidFunction;
  isSelectedDateTimeFilterTextOnly?: boolean;
  resourceContainerRef?: React.MutableRefObject<HTMLDivElement>;
  showDateTimeLinks?: boolean;
  kioskMode?: boolean;
}

export const SelectedDateTimeFilter = ({
  onDateClick,
  onTimeClick,
  isSelectedDateTimeFilterTextOnly,
  resourceContainerRef,
  showDateTimeLinks = true,
  kioskMode = false,
}: SelectedDateTimeFilterProps): JSX.Element => {
  const dispatch = useDispatch();
  const {
    price,
    minimumPrice,
    startTime,
    endTime,
    priceStatus,
    locale,
    showMinimumPrice,
    showFreeLabel,
    containerHeight,
    toggleMultipleBookingsVisibility,
    isMultipleBookingsVisible,
    reverseAnimation,
  } = useSelectedDateTimeFilter(resourceContainerRef);

  useEffect(() => {
    if (!startTime && !endTime) {
      dispatch(resetPrice());
    }
  }, [startTime, endTime, dispatch]);

  return (
    <DateTimeFilterWrapper isKioskMode={kioskMode}>
      <DateTimeFilterContainer areDateTimeLinksVisible={showDateTimeLinks}>
        {showDateTimeLinks && (
          <DateTimeLinks
            toggleMultipleBookingsVisibility={toggleMultipleBookingsVisibility}
            isMultipleBookingsVisible={isMultipleBookingsVisible}
            isSelectedDateTimeFilterTextOnly={isSelectedDateTimeFilterTextOnly}
            onDateClick={onDateClick}
            onTimeClick={onTimeClick}
          />
        )}
        {!kioskMode && (
          <PriceComponent
            price={price}
            minimumPrice={minimumPrice}
            priceStatus={priceStatus}
            locale={locale}
            showMinimumPrice={showMinimumPrice}
            showFreeLabel={showFreeLabel}
            hasContentBelow={showDateTimeLinks}
          />
        )}
      </DateTimeFilterContainer>
      <MultipleBookings
        containerHeight={containerHeight}
        onDateClick={onDateClick}
        onTimeClick={onTimeClick}
        reverseAnimation={reverseAnimation}
      />
    </DateTimeFilterWrapper>
  );
};
