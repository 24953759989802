import React from 'react';
import { Dropdown } from 'components/dropdown';
import { useIntl } from 'react-intl';
import { Wrapper, StyledLabel, DropdownContainer } from './styles';

import { useDesktopTimeSelector } from './use-desktop-time-selector.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useDisableTimePicker } from 'hooks/use-disable-time-picker.hook';

export const DesktopTimeSelector = (): JSX.Element => {
  const intl = useIntl();
  const {
    selectedStartTime,
    defaultValueIndex,
    endTimeValues,
    selectedEndTime,
    startTimeValues,
    onSelectEndTime,
    onSelectStartTime,
    startTimeClickTrackEvent,
    endTimeClickTrackEvent,
  } = useDesktopTimeSelector();
  const { startTime, startDate, presetWindows, startDates } = useSearchParams();

  const isPickerDisabled = useDisableTimePicker();
  const isDropdownDisabled = !(startDate || startDates) || !!presetWindows;

  return (
    <Wrapper>
      <DropdownContainer>
        <StyledLabel data-testid="hqo-time-picker-label" className="time-picker-label">
          {intl.formatMessage({ id: 'start_time' })}
        </StyledLabel>
        <Dropdown
          options={startTimeValues}
          onSelect={onSelectStartTime}
          value={selectedStartTime}
          placeholder="HH:MM"
          defaultValueIndex={defaultValueIndex}
          disabled={isDropdownDisabled}
          trackEvent={startTimeClickTrackEvent}
        />
      </DropdownContainer>
      <DropdownContainer>
        <StyledLabel data-testid="hqo-time-picker-label" className="time-picker-label">
          {intl.formatMessage({ id: 'end_time' })}
        </StyledLabel>
        <Dropdown
          options={endTimeValues}
          onSelect={onSelectEndTime}
          value={selectedEndTime}
          placeholder="HH:MM"
          disabled={!startTime || isPickerDisabled}
          trackEvent={endTimeClickTrackEvent}
        />
      </DropdownContainer>
    </Wrapper>
  );
};
