import * as actions from './actions';

import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { AjaxResponse } from 'rxjs/ajax';
import { Epic } from 'redux-observable';
import { FloorPlanAppInstanceConfigResponse } from './types';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';

export const getFloorPlanAppInstanceConfigEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getFloorPlanAppInstanceConfig.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      return apiClient(state)
        .getFloorPlanAppInstanceConfig(payload)
        .pipe(
          map(({ response }: AjaxResponse<FloorPlanAppInstanceConfigResponse>) => {
            return actions.getFloorPlanAppInstanceConfig.success({
              data: response.data,
            });
          }),
          catchError(() =>
            of(
              actions.emptyFloorPlanAppInstanceConfig({
                error: { name: 'emptyAppInstanceConfigs', message: 'Empty App Instance Configs' },
              }),
            ),
          ),
        );
    }),
  );
