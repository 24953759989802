import { StyledChipComponent } from 'pages/resources/filter-bar/styles';
import React from 'react';
import { useIntl } from 'react-intl';

import { useDurationPill } from './use-duration-pill.hook';

export const DurationPill = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const { durationEncoded, selectedDuration, onCancelClick, isExactDurationEncoded } = useDurationPill();

  if (!durationEncoded || isExactDurationEncoded) return null;

  const pillLabel = `${formatMessage({ id: selectedDuration })}`;

  return (
    <StyledChipComponent
      label={pillLabel}
      value={durationEncoded.toString()}
      isActive
      cancelEnabled
      onCancel={onCancelClick}
    />
  );
};
