import { useCallback, useEffect, useMemo } from 'react';
import mapboxgl, { Marker } from 'mapbox-gl';
import { BOTTOM_PADDING, DEFAULT_MARKER_OPTIONS, MAX_ZOOM, SIDE_PADDING, TOP_PADDING } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectGetResourcesLoadingStatus, selectResourcesLocations } from 'store/resources/selectors';
import { INITIAL_POSITION_SWIPE_MODAL_FOR_MAP_VIEW } from 'pages/resources/components/resources-swipe-modal';
import {
  selectAppInstanceConfigs,
  selectCurrentBuildingLocationCoordinates,
  selectCurrentLocationId,
  selectSelectedAppInstanceConfigsLocations,
} from 'store/app-instance-configs/selectors';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import {
  setSelectedAppInstanceConfigsLocations,
  updateCurrentAppInstanceConfigUuid,
  updateCurrentLocationId,
} from 'store/app-instance-configs/actions';

interface UseMapView {
  mapRef: React.MutableRefObject<mapboxgl.Map | null>;
}

export const useMapView = ({ mapRef }: UseMapView) => {
  const isMobileDevice = useIsSmallViewportWidth();
  const dispatch = useDispatch();
  const appInstanceConfigs = useSelector(selectAppInstanceConfigs);
  const selectedLocations = useSelector(selectResourcesLocations);
  const currentLocationId = useSelector(selectCurrentLocationId);
  const currentBuildingLocationCoordinates = useSelector(selectCurrentBuildingLocationCoordinates);
  const isResourcesLoading = useSelector(selectGetResourcesLoadingStatus);
  const selectedConfigsLocations = useSelector(selectSelectedAppInstanceConfigsLocations);
  const { longitude: defaultLongitude, latitude: defaultLatitude } = currentBuildingLocationCoordinates;
  const mapBottomPadding = useMemo<number>(
    () => (isMobileDevice ? INITIAL_POSITION_SWIPE_MODAL_FOR_MAP_VIEW : BOTTOM_PADDING),
    [isMobileDevice],
  );

  const geojson = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: selectedLocations.map(selectedLocation => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [
            selectedLocation?.longitude ?? defaultLongitude,
            selectedLocation?.latitude ?? defaultLatitude,
          ] as [number, number],
          locationId: selectedLocation?.id,
        },
      })),
    };
  }, [defaultLatitude, defaultLongitude, selectedLocations]);

  useEffect(() => {
    if (!isResourcesLoading) {
      const bounds = new mapboxgl.LngLatBounds();
      for (const location of selectedLocations) {
        bounds.extend([location?.longitude ?? defaultLongitude, location?.latitude ?? defaultLatitude]);
      }

      mapRef.current.fitBounds(bounds, {
        padding: { left: SIDE_PADDING, right: SIDE_PADDING, bottom: mapBottomPadding, top: TOP_PADDING },
        maxZoom: MAX_ZOOM,
      });
    }
  }, [defaultLatitude, defaultLongitude, geojson, isResourcesLoading, mapBottomPadding, mapRef, selectedLocations]);

  const orderSelectedLocations = useCallback(
    (locationId: string) => {
      const selectedConfigLocations = selectedConfigsLocations?.find(configLocation =>
        configLocation.locationIds.includes(locationId),
      );

      let orderedLocationIds = selectedConfigLocations?.locationIds ?? [];

      if (orderedLocationIds.length > 1) {
        orderedLocationIds = [
          locationId,
          ...(selectedConfigLocations?.locationIds?.filter(id => id !== locationId) ?? []),
        ];
      }

      dispatch(
        setSelectedAppInstanceConfigsLocations([
          { uuid: selectedConfigLocations?.uuid, locationIds: orderedLocationIds },
          ...(selectedConfigsLocations?.filter(config => config.uuid !== selectedConfigLocations?.uuid) ?? []),
        ]),
      );
    },
    [dispatch, selectedConfigsLocations],
  );

  useEffect(() => {
    const markers: Array<Marker> = [];

    geojson.features.forEach(feature => {
      const marker = new mapboxgl.Marker({
        color:
          feature.geometry.locationId === currentLocationId
            ? DEFAULT_MARKER_OPTIONS.selectedColor
            : DEFAULT_MARKER_OPTIONS.color,
      })
        .setLngLat(feature.geometry.coordinates)
        .addTo(mapRef.current);

      markers.push(marker as unknown as Marker);

      marker.getElement().addEventListener('click', () => {
        orderSelectedLocations(feature.geometry.locationId);

        dispatch(
          updateCurrentAppInstanceConfigUuid({
            currentAppInstanceConfigUuid: appInstanceConfigs.find(
              config => config.location_id === feature.geometry.locationId,
            )?.uuid,
          }),
        );
        dispatch(updateCurrentLocationId({ currentLocationId: feature.geometry.locationId }));
      });
    });

    return () => {
      markers.forEach(marker => marker.remove());
    };
  }, [appInstanceConfigs, dispatch, geojson, mapRef]);
};
