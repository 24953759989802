import moment, { Moment } from 'moment';
import { resolveStartDatesParam } from './resolveStartDatesParam';
import qs from 'qs';
import { pick } from 'utils/pickObjectProperty';
import { QUERY_PARAMS } from 'shared/consts';

export const getQueryString = (
  selectedDate: Moment,
  restQueryParams: {
    [key: string]: string;
  },
  datesParams?: { startDate: string; startDates: string },
): string => {
  const formattedDateValue = moment(selectedDate).format('YYYY-MM-DD');
  const isMultiDayBookingEnabled = !!datesParams;
  let queryParams;

  if (isMultiDayBookingEnabled) {
    const { startDate, startDates } = datesParams;
    queryParams = selectedDate
      ? { ...restQueryParams, ...resolveStartDatesParam(formattedDateValue, startDate, startDates) }
      : restQueryParams;
  } else {
    queryParams = selectedDate ? { ...restQueryParams, startDate: formattedDateValue } : restQueryParams;
  }
  const queryString = qs.stringify(
    pick(
      queryParams,
      QUERY_PARAMS.filter(param => param !== 'duration'),
    ),
  );

  return queryString;
};
