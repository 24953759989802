import { useEffect } from 'react';

interface UseScrollToProps {
  isScrollToSelectedValue: boolean;
  ref: React.MutableRefObject<HTMLDivElement>;
  top: number;
}

export const useScrollTo = ({ isScrollToSelectedValue, ref, top }: UseScrollToProps): void => {
  useEffect(() => {
    if (isScrollToSelectedValue) {
      ref.current.scrollTo({ top, behavior: 'smooth' });
    }
  }, [isScrollToSelectedValue, top, ref]);
};
