import React from 'react';
import { HqoTimePicker } from '@hqo/react-components-library/dist/molecules/wheel-time-picker';

import { TimePicker } from '../time-picker/time-picker';
import { useResourceDetailsTimeSelector } from 'components/time-selector/hooks/use-resource-details-time-selector.hook';
import { useIntl } from 'react-intl';
import { Container } from './styles';
import { useDisableTimePicker } from 'hooks/use-disable-time-picker.hook';
import { useMobileFilterTimeSelector } from 'components/time-selector/mobile/use-mobile-filter-time-selector.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocation } from 'react-router-dom';

export interface MobileTimeSelectorProps {
  toggleOverlay: VoidFunction;
}

export const MobileTimeSelector = ({ toggleOverlay }: MobileTimeSelectorProps): JSX.Element => {
  const intl = useIntl();
  const { showResourceDetailsDateTimeInputs } = useFlags();
  const { pathname } = useLocation();
  const isResourceDetailsPage = pathname.includes('/date-schedule-select') && !pathname.includes('/resources');

  const resourceDetailsTimeSelectorValues = useResourceDetailsTimeSelector({ toggleOverlay });
  const mobileFilterTimeSelectorValues = useMobileFilterTimeSelector(toggleOverlay);
  const timeSelectorValues =
    showResourceDetailsDateTimeInputs && isResourceDetailsPage
      ? resourceDetailsTimeSelectorValues
      : mobileFilterTimeSelectorValues;

  const {
    startTimeValues,
    endTimeValues,
    onSelectStartTime,
    onSelectEndTime,
    startTime,
    endTime,
    defaultValues,
    onStartTimePickerClick,
    onEndTimePickerClick,
    isStartTimePickerActive,
    isEndTimePickerActive,
    onStartItemClick,
    onStartTimePickerOutsideClick,
    onEndItemClick,
    onEndTimePickerOutsideClick,
  } = timeSelectorValues;

  const isPickerDisabled = useDisableTimePicker();

  return (
    <Container data-testid="mobile-time-selector">
      <HqoTimePicker
        isActive={isStartTimePickerActive}
        onClick={onStartTimePickerClick}
        placeholder="HH:MM"
        value={startTimeValues?.find(({ value }) => value?.toString() === startTime?.toString())?.label}
        label={intl.formatMessage({ id: 'start_time' })}
        disabled={isPickerDisabled}
      />
      <HqoTimePicker
        isActive={isEndTimePickerActive}
        onClick={onEndTimePickerClick}
        placeholder="HH:MM"
        value={endTimeValues?.find(({ value }) => value?.toString() === endTime?.toString())?.label}
        label={intl.formatMessage({ id: 'end_time' })}
        disabled={!startTime || isPickerDisabled}
      />
      {isStartTimePickerActive && (
        <TimePicker
          items={startTimeValues.filter(startTimeValue => !startTimeValue?.shouldBeSkipped)}
          onChange={onSelectStartTime}
          defaultValue={defaultValues.defaultStartValue}
          onItemClick={onStartItemClick}
          onClickOutside={onStartTimePickerOutsideClick}
        />
      )}
      {isEndTimePickerActive && (
        <TimePicker
          items={endTimeValues.filter(endTimeValue => !endTimeValue?.shouldBeSkipped)}
          onChange={onSelectEndTime}
          defaultValue={defaultValues.defaultEndValue}
          onItemClick={onEndItemClick}
          onClickOutside={onEndTimePickerOutsideClick}
        />
      )}
    </Container>
  );
};
