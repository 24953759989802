import React from 'react';
import { StyledButton } from './styles';
import { useIntl } from 'react-intl';

interface SelectedTimeFilterProps {
  timeRange: string;
  timeNotSelected?: boolean;
  onTimeClick?: VoidFunction;
  isSelectedDateTimeFilterTextOnly?: boolean;
}

export const SelectedTimeFilter = ({
  timeRange,
  timeNotSelected,
  onTimeClick,
  isSelectedDateTimeFilterTextOnly,
}: SelectedTimeFilterProps): JSX.Element => {
  const intl = useIntl();
  return (
    <StyledButton
      isSelectedDateTimeFilterTextOnly={isSelectedDateTimeFilterTextOnly}
      variant="link"
      onClick={onTimeClick}
      text={timeNotSelected ? intl.formatMessage({ id: 'addTime' }) : timeRange}
      multiline={false}
    />
  );
};
