import { WheelPicker } from 'components/wheel-picker-view';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FloorSelectStyledModalWrapper, SaveButton, SaveButtonWrapper } from './styles';
import { ModalPortal } from 'components/modals/modal-portal/modal-portal';
import { FloorItem } from 'store/spaceManager/types';

interface FloorPickerContentProps {
  onWheelChange: React.Dispatch<React.SetStateAction<string>>;
  pickedFloorUuid: string;
  options: FloorItem[];
  toggleFloorPickerWheel: VoidFunction;
}

export const FloorPickerWheel = ({
  onWheelChange,
  pickedFloorUuid,
  options,
  toggleFloorPickerWheel,
}: FloorPickerContentProps): JSX.Element => {
  const intl = useIntl();

  const wheelItems = useMemo(() => {
    return options.map((floorItem: FloorItem) => ({ label: floorItem.name, value: floorItem.uuid }));
  }, [options]);

  const onChange = useCallback(
    (value: any) => {
      onWheelChange(value);
    },
    [onWheelChange],
  );

  const onClick = useCallback(toggleFloorPickerWheel, [toggleFloorPickerWheel]);

  return (
    <ModalPortal>
      <FloorSelectStyledModalWrapper>
        <SaveButtonWrapper>
          <SaveButton onClick={onClick}>{intl.formatMessage({ id: 'save' })}</SaveButton>
        </SaveButtonWrapper>
        <WheelPicker items={wheelItems} value={pickedFloorUuid} onChange={onChange} />
      </FloorSelectStyledModalWrapper>
    </ModalPortal>
  );
};
