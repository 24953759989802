import { useCallback } from 'react';

interface getIconValuesReturnValue {
  iconType: string;
  iconName: string;
}
export const useIconValues = () => {
  const getIconValues = useCallback((icon: string): getIconValuesReturnValue => {
    const iconValues = icon?.split(' ');
    let iconType = 'far';
    let iconName = icon;

    if (iconValues?.length === 2) {
      iconType = iconValues[0];
      iconName = iconValues[1].startsWith('fa-') ? iconValues[1].slice(3) : iconValues[1];
    }

    return {
      iconType,
      iconName,
    };
  }, []);
  return { getIconValues };
};
