export const formatSelectedDates = (startDates: string, startDate: string, locale: string): string => {
  const formattedStartDates = startDates?.split(',') || [];
  const sortedDats = formattedStartDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  const formattedDate = new Date(startDate?.replace(/-/g, '/')).toLocaleDateString(locale, {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  if (startDates && sortedDats.length) {
    const formattedDates = sortedDats.map(dateString => {
      const date = new Date(dateString.replace(/-/g, '/'));
      return date.toLocaleDateString(locale, {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
      });
    });

    return formattedDates.join(' · ');
  }

  return formattedDate;
};
