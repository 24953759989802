import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { ACTION_STATUSES } from 'shared/consts';
import { FloorPlanResourcesState } from './types';

export const initialState: FloorPlanResourcesState = {
  availableResources: {},
  allResources: {},
  getAvailableResources: {},
  getAllResources: {},
};

const constructKey = (appInstanceConfigUuid: string, offset: number) => {
  return `${appInstanceConfigUuid}${offset ?? 0}`;
};

const getAvailableResourcesRequestHandler = (
  state: FloorPlanResourcesState,
  { payload }: ActionType<typeof actions.getAvailableResources.request>,
): FloorPlanResourcesState => ({
  ...state,
  availableResources: {
    ...state.availableResources,
    [constructKey(payload.filterParams.appInstanceConfigUuid, payload.filterParams.offset)]: null,
  },
  getAvailableResources: {
    ...state.getAvailableResources,
    [constructKey(payload.filterParams.appInstanceConfigUuid, payload.filterParams.offset)]: {
      status: ACTION_STATUSES.PENDING,
      error: null,
    },
  },
});

const getAvailableResourcesSuccessHandler = (
  state: FloorPlanResourcesState,
  { payload }: ActionType<typeof actions.getAvailableResources.success>,
): FloorPlanResourcesState => ({
  ...state,
  availableResources: {
    ...state.availableResources,
    [constructKey(payload.appInstanceConfigUuid, payload.offset)]: payload.resources,
  },
  getAvailableResources: {
    ...state.getAvailableResources,
    [constructKey(payload.appInstanceConfigUuid, payload.offset)]: {
      status: ACTION_STATUSES.FULFILLED,
      error: null,
    },
  },
});

const getAvailableResourcesFailureHandler = (
  state: FloorPlanResourcesState,
  { payload }: ActionType<typeof actions.getAvailableResources.failure>,
): FloorPlanResourcesState => ({
  ...state,
  getAvailableResources: {
    ...state.getAvailableResources,
    [constructKey(payload.appInstanceConfigUuid, payload.offset)]: {
      status: ACTION_STATUSES.REJECTED,
      error: null,
    },
  },
});

const getAllResourcesRequestHandler = (
  state: FloorPlanResourcesState,
  { payload }: ActionType<typeof actions.getAllResources.request>,
): FloorPlanResourcesState => ({
  ...state,
  allResources: {
    ...state.allResources,
    [constructKey(payload.filterParams.appInstanceConfigUuid, payload.filterParams.offset)]: null,
  },
  getAllResources: {
    ...state.getAllResources,
    [constructKey(payload.filterParams.appInstanceConfigUuid, payload.filterParams.offset)]: {
      status: ACTION_STATUSES.PENDING,
      error: null,
    },
  },
});

const getAllResourcesSuccessHandler = (
  state: FloorPlanResourcesState,
  { payload }: ActionType<typeof actions.getAllResources.success>,
): FloorPlanResourcesState => ({
  ...state,
  allResources: {
    ...state.allResources,
    [constructKey(payload.appInstanceConfigUuid, payload.offset)]: payload.resources,
  },
  getAllResources: {
    ...state.getAllResources,
    [constructKey(payload.appInstanceConfigUuid, payload.offset)]: {
      status: ACTION_STATUSES.FULFILLED,
      error: null,
    },
  },
});

const getAllResourcesFailureHandler = (
  state: FloorPlanResourcesState,
  { payload }: ActionType<typeof actions.getAllResources.failure>,
): FloorPlanResourcesState => ({
  ...state,
  getAllResources: {
    ...state.getAllResources,
    [constructKey(payload.appInstanceConfigUuid, payload.offset)]: {
      status: ACTION_STATUSES.REJECTED,
      error: null,
    },
  },
});

const resetResourceHandler = (): FloorPlanResourcesState => ({
  ...initialState,
});

const resetResourceAvailabilityHandler = (state: FloorPlanResourcesState): FloorPlanResourcesState => ({
  ...state,
  availableResources: {},
  getAvailableResources: {},
});

const resourcesReducer = createReducer(initialState)
  .handleAction(actions.getAvailableResources.request, getAvailableResourcesRequestHandler)
  .handleAction(actions.getAvailableResources.success, getAvailableResourcesSuccessHandler)
  .handleAction(actions.getAvailableResources.failure, getAvailableResourcesFailureHandler)
  .handleAction(actions.getAllResources.request, getAllResourcesRequestHandler)
  .handleAction(actions.getAllResources.success, getAllResourcesSuccessHandler)
  .handleAction(actions.getAllResources.failure, getAllResourcesFailureHandler)
  .handleAction(actions.resetFloorPlanResources, resetResourceHandler)
  .handleAction(actions.resetFloorPlanAvailabilityResources, resetResourceAvailabilityHandler);

export default resourcesReducer;
