import { AjaxError } from 'rxjs/ajax';

export function transformErrorIntoSerializableError(error: Error | AjaxError): SerializableError {
  if (error instanceof AjaxError) {
    let response: SerializableErrorResponse = {};
    let { message } = error;
    let key: string | undefined;
    if (typeof error.response === 'string' && error.responseType === 'json') {
      response = JSON.parse(error.response);
    } else if (error.response && typeof error.response === 'object' && error.response.message) {
      response = error.response;
      message = error.response.message;
    }

    if (response.message && typeof response.message === 'string') {
      message = response.message;
    } else if (response.message && typeof response.message === 'object') {
      if (response.message.message) {
        message = response.message.message;
      }
      if (response.message.key) {
        key = response.message.key;
      }
    }

    return {
      code: error.status,
      message,
      response,
      name: error.name,
      key,
      status: error.status,
    };
  }
  return {
    name: error.name,
    message: error.message,
    ...error,
  };
}
