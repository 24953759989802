import { StyledCompactContentTile } from 'pages/resources/content/infinite-content/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectGetInfiniteResourcesStatus, selectInfiniteResources } from 'store/resources/selectors';
import { TileButton } from 'pages/resources/content/tile-button';
import { CapacityBlock } from 'components/capacity-block';
import { useResourceTile } from 'hooks/use-resource-tile.hook';
import { ACTION_STATUSES } from 'shared/consts';
import { Resource } from 'components/compact-skeleton/styles';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';

interface ResourcesSetProps {
  indexOfSet: number;
  limitOfElements: number;
  toggleReverseAnimation: VoidFunction;
  toggleModalSlideAnimation: VoidFunction;
}

export const ResourcesSet = ({
  indexOfSet,
  limitOfElements,
  toggleReverseAnimation,
  toggleModalSlideAnimation,
}: ResourcesSetProps): JSX.Element => {
  const isMobileDevice = useIsSmallViewportWidth();
  const infiniteResources = useSelector(selectInfiniteResources);
  const infiniteResourcesSet = infiniteResources?.[indexOfSet];
  const { buttonType, onButtonClick } = useResourceTile({ toggleReverseAnimation, toggleModalSlideAnimation });

  const isPending =
    useSelector(selectGetInfiniteResourcesStatus).status === ACTION_STATUSES.PENDING &&
    !infiniteResourcesSet?.[indexOfSet];

  return isPending ? (
    <>
      {Array.from({ length: limitOfElements }, (_, index) => (
        <Resource isDesktop={!isMobileDevice} data-testid="compact-ghost-resource" key={index} />
      ))}
    </>
  ) : (
    <>
      {infiniteResourcesSet.map(resource => {
        return (
          <StyledCompactContentTile
            key={resource.uuid}
            title={resource.name}
            statusLabels={[<CapacityBlock capacity={+resource.capacity} title={resource.name} key="3" />]}
            button={<TileButton buttonType={buttonType} onButtonClick={onButtonClick} resourceUuid={resource.uuid} />}
          />
        );
      })}
    </>
  );
};
