import styled from 'styled-components';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';

export const DateFilterContainer = styled.div`
  margin-bottom: 34px;
`;

export const DateFilterHeader = styled.div`
  margin-bottom: 16px;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  width: 370px;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    width: 100%;
  }
`;

export const DateFilterTitle = styled.div`
  font: normal 16px/19px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};
`;

export const DateFilterValue = styled.div`
  font: 14px/21px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.darkGrey1};
`;
