import { ErrorsState } from './types';
import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';

export const errorsSelect = (state: RootState): RootState['errors'] => state.errors;

export const selectErrorNotifications = createSelector(
  errorsSelect,
  (state: ErrorsState): boolean => state?.errors?.length > 0,
);

export const selectIsReAuthenticateRequired = createSelector(
  errorsSelect,
  (state: ErrorsState): boolean => state.isReauthRequired,
);
