import { EmptyPriceBlock, GhostPriceBlock, PriceBlock } from './styles';
import React from 'react';
import { formatCurrency } from 'utils/formatCurrency';
import { ACTION_STATUSES } from 'shared/consts';
import { Price } from 'store/price/types';
import { useIntl } from 'react-intl';

interface PriceBlockProps {
  showMinimumPrice: boolean;
  price: Price;
  minimumPrice: Price;
  priceStatus: ACTION_STATUSES;
  locale: string;
  showFreeLabel: boolean;
  hasContentBelow?: boolean;
}

export const PriceComponent = ({
  price,
  minimumPrice,
  priceStatus,
  locale,
  showMinimumPrice,
  showFreeLabel,
  hasContentBelow = true,
}: PriceBlockProps): JSX.Element => {
  const intl = useIntl();

  if (priceStatus === ACTION_STATUSES.PENDING) {
    return <GhostPriceBlock hasContentBelow={hasContentBelow} data-testid="ghost-price-block" />;
  }

  if (showMinimumPrice) {
    return (
      <PriceBlock data-testid="price-block" hasContentBelow={hasContentBelow}>
        {formatCurrency(minimumPrice?.price, minimumPrice?.currency, locale, false)}+
      </PriceBlock>
    );
  }

  if (price?.price) {
    return (
      <PriceBlock hasContentBelow={hasContentBelow} data-testid="price-block">
        {formatCurrency(price.price, price.currency, locale)}
      </PriceBlock>
    );
  }

  if (showFreeLabel) {
    return (
      <PriceBlock hasContentBelow={hasContentBelow} data-testid="price-block">
        {intl.formatMessage({ id: 'free' })}
      </PriceBlock>
    );
  }

  return <EmptyPriceBlock data-testid="empty-price-block" />;
};
