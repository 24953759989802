import React from 'react';
import { Container, Square, Bar, FlexContainer, CounterBar, DesktopBar, TopCounterBar, Resource } from './styles';

export const CompactSkeleton = (): JSX.Element => {
  const desktopResources: number[] = Array.from(Array(20).keys());
  const mobileResources: number[] = Array.from(Array(8).keys());

  return (
    <Container data-testid="compact-skeleton">
      <FlexContainer gap={48} isWidth isColumnDirection className="desktop">
        <TopCounterBar />
        <FlexContainer gap={28}>
          <Square /> <DesktopBar />
        </FlexContainer>
        <CounterBar />
        <FlexContainer gap={24} isWrap>
          {desktopResources.map(resource => (
            <Resource key={resource} isDesktop />
          ))}
        </FlexContainer>
      </FlexContainer>
      <FlexContainer gap={16} isColumnDirection isWidth className="mobile">
        <TopCounterBar />
        <FlexContainer gap={16} isWidth>
          <Square />
          <FlexContainer isColumnDirection gap={11} isWidth>
            <Bar />
          </FlexContainer>
        </FlexContainer>
        <CounterBar />
        {mobileResources.map(resource => (
          <Resource key={resource} />
        ))}
      </FlexContainer>
    </Container>
  );
};
