import styled from 'styled-components';
import { Chip } from '@hqo/react-components-library/dist/atoms/chip';

export const StyledChip = styled(Chip)<{ isDisabled: boolean }>`
  ${({ isDisabled, theme }) =>
    isDisabled &&
    `border: 1px solid ${theme.colors.greys.sharedMediumGrey};
    cursor: not-allowed;
  .chip-text {
    color: ${theme.colors.greys.sharedMediumGrey};
  }
  `}
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px 16px;

  .duration-chip {
    justify-content: center;
  }
`;
