import React from 'react';
import { CapacityBlock } from 'components/capacity-block';
import FreeLabelPill from 'components/available-now-block/components/available-now-labels/components/free-label-pill/free-label-pill';
import PaidLabelPill from 'components/available-now-block/components/available-now-labels/components/paid-label-pill/paid-label-pill';
import {
  LocationLabel,
  Container,
  LocationIcon,
} from 'components/available-now-block/components/available-now-labels/styles';

import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';

export interface AvailableNowLabelsProps {
  capacity: number;
  title: string;
  isPaid?: boolean;
  isFree?: boolean;
  locationLabel?: string;
  compactUi?: boolean;
}

const AvailableNowLabels = ({
  capacity,
  title,
  isPaid = false,
  isFree = false,
  locationLabel,
  compactUi = false,
}: AvailableNowLabelsProps) => {
  return (
    <Container compactUi={compactUi}>
      {isPaid && <PaidLabelPill />}
      {isFree && <FreeLabelPill />}
      {capacity > 0 && <CapacityBlock capacity={capacity} title={title} />}
      {locationLabel && (
        <LocationLabel>
          <LocationIcon icon={faBuilding} />
          {locationLabel}
        </LocationLabel>
      )}
    </Container>
  );
};

export default AvailableNowLabels;
