import { FailureActionPayload } from 'store/errors/types';
import {
  GetTransactionsResponse,
  TransactionDetails,
  PatchTransactionDto,
  RedirectTransactionDetails,
  UpdateCheckInResponse,
} from 'store/transactions/types';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const getTransactions = createAsyncAction(
  'GET_TRANSACTIONS_REQUEST',
  'GET_TRANSACTIONS_SUCCESS',
  'GET_TRANSACTIONS_FAILURE',
)<Record<string, unknown>, GetTransactionsResponse, FailureActionPayload>();

export const getTransaction = createAsyncAction(
  'GET_TRANSACTION_REQUEST',
  'GET_TRANSACTION_SUCCESS',
  'GET_TRANSACTION_FAILURE',
)<string, TransactionDetails, FailureActionPayload>();

export const resetTransaction = createAction('RESET_TRANSACTION')<void>();

export const patchTransaction = createAsyncAction(
  'PATCH_TRANSACTION_REQUEST',
  'PATCH_TRANSACTION_SUCCESS',
  'PATCH_TRANSACTION_FAILURE',
)<PatchTransactionDto, void, FailureActionPayload>();

export const getRedirectTransaction = createAsyncAction(
  'GET_REDIRECT_TRANSACTION_REQUEST',
  'GET_REDIRECT_TRANSACTION_SUCCESS',
  'GET_REDIRECT_TRANSACTION_FAILURE',
)<string, RedirectTransactionDetails, FailureActionPayload>();

export const resetRedirectTransaction = createAction('RESET_REDIRECT_TRANSACTION')<void>();

export const resetPatchTransactionStatus = createAction('RESET_PATCH_TRANSACTION_STATUS')<void>();

export const checkIn = createAsyncAction('CHECK_IN_REQUEST', 'CHECK_IN_SUCCESS', 'CHECK_IN_FAILURE')<
  string,
  UpdateCheckInResponse,
  FailureActionPayload
>();

export const setCurrentTransaction = createAction('SET_CURRENT_TRANSACTION')<TransactionDetails>();

export const resetCurrentTransaction = createAction('RESET_CURRENT_TRANSACTION')<void>();
