import { TimeRange } from 'store/resource-time-ranges/types';

export const isSelectedTimeRangeAvailable = (
  startTime: string,
  endTime: string,
  timeRanges: Array<TimeRange>,
): boolean => {
  const availableTimeRanges = timeRanges.filter(timeRange => timeRange.available);
  const selectedEndTimeIndex = timeRanges.findIndex(({ time }) => time === endTime);
  const availableSelectedEndTime = timeRanges[selectedEndTimeIndex - 1]?.time;
  const isPreviousTimeSlotWithEndTimeAvailable =
    availableTimeRanges.some(({ time }) => time === availableSelectedEndTime) && startTime !== availableSelectedEndTime;

  const isSelectedStartTimeAvailable = availableTimeRanges.some(({ time }) => time === startTime);
  const isSelectedEndTimeAvailable =
    availableTimeRanges.some(({ time }) => time === endTime) || isPreviousTimeSlotWithEndTimeAvailable;

  return isSelectedStartTimeAvailable && isSelectedEndTimeAvailable;
};
