import { ACTION_STATUSES } from 'shared/consts';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { selectPatchTransactionsStatus } from 'store/transactions/selectors';
import { useLoadingHandler } from './loading-handler.hook';
import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hook';
import { HEADER_BUTTON_MODE } from '@hqo/hqo-miniapp-client-sdk';
import { selectIsHeaderSet } from 'store/header-state/selectors';
import { setHeaderAction } from 'store/header-state/actions';

export const useNativeHeader = (): void => {
  const dispatch = useDispatch();
  const patchTransactionsStatus = useSelector(selectPatchTransactionsStatus);
  const client = useMiniappSdk();
  const isHeaderSet = useSelector(selectIsHeaderSet);
  const isDataLoading = useLoadingHandler();

  useEffect(() => {
    if (!isHeaderSet && patchTransactionsStatus !== ACTION_STATUSES.PENDING && !isDataLoading) {
      dispatch(setHeaderAction());
      client?.header.setHeader('', HEADER_BUTTON_MODE.CLOSE);
      client?.header.showHeader();
      client?.swipe.disableBackSwipe();
    }
  }, [isHeaderSet, patchTransactionsStatus, isDataLoading, client, dispatch]);
};
