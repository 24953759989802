import React, { useRef } from 'react';
import { ModalPortal } from 'components/modals/modal-portal/modal-portal';
import { WheelPicker } from '../../wheel-picker-view';
import { StyledModalWrapper } from './styles';

interface Item {
  label: React.ReactNode;
  value: Date;
  shouldBeSkipped?: boolean;
}

interface TimePickerProps {
  items: Item[];
  onChange: (value: React.ReactNode) => void;
  defaultValue?: Date;
  onItemClick?: (event: React.MouseEvent) => void;
  onClickOutside?: () => void;
}

export const TimePicker = ({ items, defaultValue, onChange, onItemClick, onClickOutside }: TimePickerProps) => {
  const wheelPickerRef = useRef<HTMLDivElement>(null);

  return (
    <ModalPortal>
      <StyledModalWrapper ref={wheelPickerRef}>
        <WheelPicker
          items={items}
          // @ts-ignore
          onChange={onChange}
          value={defaultValue}
          onItemClick={onItemClick}
          onClickOutside={onClickOutside}
        />
      </StyledModalWrapper>
    </ModalPortal>
  );
};
