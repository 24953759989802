import styled from 'styled-components';
import { Button } from '@hqo/react-components-library/dist/atoms/button';

export const TypesFilterContainer = styled.div`
  margin-bottom: 24px;
`;

export const TypesFilterTitle = styled.div`
  margin-bottom: 16px;
  font: normal 16px/19px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};
`;

interface ChipContainerProps {
  isExpanded?: boolean;
}

export const ChipsContainer = styled.div<ChipContainerProps>`
  padding: 0 2px;
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  ${({ isExpanded }) =>
    !isExpanded &&
    `
    .type-chip:nth-child(n + 11) {
      display: none;
    }
  `}
`;

export const ViewMoreButton = styled(Button)`
  text-decoration: underline;
`;
