import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAppInstanceConfigs,
  selectCurrentAppInstanceConfigUuid,
  selectCurrentLocationId,
} from 'store/app-instance-configs/selectors';
import { updateCurrentLocationId } from 'store/app-instance-configs/actions';
import { useLocation } from 'react-router-dom';
import { replace } from 'store/router/actions';
import { useIntl } from 'react-intl';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const useCurrentLocation = (): string => {
  const dispatch = useDispatch();
  const location = useLocation();
  const intl = useIntl();
  const { resourceBookingPortfolioBased } = useFlags();

  const appInstanceConfigs = useSelector(selectAppInstanceConfigs);
  const locationId = useSelector(selectCurrentLocationId);

  const currentAppInstanceConfigUuid = useSelector(selectCurrentAppInstanceConfigUuid);
  const currentLocationName = useMemo<string>(() => {
    if (locationId) {
      if (resourceBookingPortfolioBased) {
        const selectedLocationIds = locationId.split(',');
        // If all locations are selected, return "All locations"
        if (selectedLocationIds.length === appInstanceConfigs.length) {
          return intl.formatMessage({ id: 'all_locations' });
        }
      }
      return locationId
        ?.split(',')
        .map(locId => {
          const appInstanceConfig = appInstanceConfigs?.find(config => config.location_id === locId);

          return appInstanceConfig?.location_name;
        })
        .filter(locId => {
          return locId;
        })
        .join(', ');
    }
    const currentAppInstanceConfig = appInstanceConfigs?.find(
      config => config.uuid === currentAppInstanceConfigUuid && config.location_default,
    );

    return currentAppInstanceConfig?.location_name;
  }, [appInstanceConfigs, currentAppInstanceConfigUuid, locationId]);

  const currentLocationId = useMemo<string>(() => {
    const appInstanceConfig = appInstanceConfigs?.find(config => config.location_name === currentLocationName);

    return appInstanceConfig?.location_id;
  }, [appInstanceConfigs, currentLocationName]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location?.search);
    queryParams.set('locationId', locationId);
    if (!location) {
      queryParams.delete('locationId');
    }
    const newUrl = `${location?.pathname}?${queryParams.toString()}`;

    dispatch(replace(newUrl));
    if (!locationId) {
      dispatch(updateCurrentLocationId({ currentLocationId }));
    }
  }, [dispatch, locationId, currentLocationId]);

  return currentLocationName;
};
