import qs, { ParsedQs } from 'qs';
import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

export const useSearchParams = <T extends ParsedQs>(): T => {
  const { search } = useLocation();

  return useMemo(() => {
    return qs.parse(search?.replace(/^\?/, '')) as T;
  }, [search]);
};
