import styled from 'styled-components';

export const CapacityNumberContainer = styled.div`
  margin-left: 8px;
`;

export const CapacityContainer = styled.div`
  display: flex;
  align-items: center;
  font: 400 14px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;
