import React, { useCallback } from 'react';

import { DropdownOption } from '../types';
import { Container, Text } from './styles';

interface Props {
  active: boolean;
  item: DropdownOption;
  lastItem: boolean;
  onSelect: (item: DropdownOption) => void;
}

export const DropdownItem: React.FC<Props> = ({ active, item, lastItem, onSelect }: Props) => {
  const handleClick = useCallback(() => {
    onSelect(item);
  }, [item, onSelect]);

  const className = item.props?.className;
  return (
    <Container
      active={active}
      lastItem={lastItem}
      onClick={handleClick}
      data-testid="resource-booking-dropdown-item"
      data-cy="hqo-dropdown-item"
      className={className}
    >
      <Text>{item.label}</Text>
    </Container>
  );
};
