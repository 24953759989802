import { StyledChip, Wrapper } from './styles';

import React from 'react';

interface CapacityComponentProps {
  capacities: Array<number>;
  selectedCapacity: number;
  onClickHandler: (arg: number) => void;
}

export const CapacityComponent = ({
  selectedCapacity,
  onClickHandler,
  capacities,
}: CapacityComponentProps): JSX.Element => {
  return (
    <Wrapper>
      {capacities?.map(capacity => (
        <StyledChip
          isDisabled={false}
          key={capacity}
          label={String(capacity)}
          value={capacity}
          isActive={capacity === selectedCapacity}
          className="capacity-chip"
          data-test={`capacity-filter-chip-${capacity}`}
          onChipClick={onClickHandler}
        />
      ))}
    </Wrapper>
  );
};
