import { Checkbox, CheckboxChecked, IconContentProps } from '@hqo/react-components-library/dist/icons';
import React, { ChangeEvent, ElementType } from 'react';

import { useSelector } from 'react-redux';
import { selectBrandTheme } from 'store/theme/selectors';
import {
  Container,
  IconContainer,
  StyledCheckbox,
  StyledImage,
  StyledInput,
  Subtitle,
  Title,
  TitleWrapper,
} from './styles';

export interface LocationOptionProps {
  checked: boolean;
  checkboxName: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  value: string;
  subtitle?: string;
  imageUrl?: string;
}

export const LocationOption = ({
  checked,
  checkboxName,
  onChange,
  label,
  value,
  subtitle,
  imageUrl,
}: LocationOptionProps): JSX.Element => {
  const Icon: ElementType<IconContentProps> = checked ? CheckboxChecked : Checkbox;
  const theme = useSelector(selectBrandTheme);

  return (
    <Container checked={checked}>
      <StyledCheckbox checked={checked}>
        <StyledImage src={imageUrl} />
        <StyledInput id={checkboxName} type="checkbox" checked={checked} onChange={onChange} value={value} />
        <TitleWrapper>
          <Title>{label}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </TitleWrapper>
        <IconContainer>
          <Icon size="lg" data-testid={`checkbox-${checkboxName}-${checked}`} style={{ color: theme.primary_color }} />
        </IconContainer>
      </StyledCheckbox>
    </Container>
  );
};
