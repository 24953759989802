import { Order, OrderType } from 'store/cart/types';

import { formatCurrency } from './formatCurrency';
import { formatSummaryValues } from './formatSummaryValues';

export const getPrice = (isFreeCart: boolean, cart: Order, freeText: string, locale: string): string => {
  const { currencyType, total } = formatSummaryValues(cart?.total_summary);

  if (!isFreeCart && currencyType) {
    return formatCurrency(total, currencyType, locale);
  }

  if (cart.type === OrderType.RESOURCE_BOOKING) {
    return '';
  }

  return freeText;
};
