import { BaseQueryApi } from '@reduxjs/toolkit/query';
import { RootState } from 'store';

export default function prepareHeaders(
  headers: Headers,
  { getState }: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>,
) {
  const { config } = getState() as RootState;

  if (!config.authToken) {
    throw new Error('Cannot sign the request without an authToken');
  }

  headers.set('Content-Type', 'application/json');
  headers.set('Authorization', config.authToken);
  headers.set('Hqo-Building-UUID', config.buildingUuid);
  headers.set('Hqo-App-Brand', config.appBrand);

  return headers;
}
