import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/device';
import { IconBlock } from '@hqo/react-components-library/dist/molecules/iconBlock';
import {
  IconBlockContainer,
  PaymentMethodContainer,
  PaymentMethodTitle,
  ServiceDetails,
  StyledPaymentDetails,
  StyledSummarySubtitle,
  SummaryContainer,
} from './styles';
import { formatDate } from 'utils/formatDate';
import { selectBuildingLocale, selectBuildingTimeZone } from 'store/building/selectors';
import { Amenities } from 'components/amenities';
import { CancellationInformation } from 'components/cancellation-information';
import React, { useMemo } from 'react';
import { TRANSACTION_TYPES } from 'shared/consts';
import { formatCurrency } from 'utils/formatCurrency';
import { formatSavedPaymentMethodLabelFromTransaction } from 'utils/formatSavedPaymentMethodLabelFromTransaction';
import { getReceiptInfoFromTransaction } from 'utils/getReceiptInfoFromTransaction';
import { useIntl } from 'react-intl';
import { useLocale } from 'hooks/use-locale.hook';
import { useSelector } from 'react-redux';
import { ReceiptAdditionalInformation } from 'components/payment/receipt/receipt-additional-information';
import { ReceiptPaymentMethod } from '../receipt-payment-method';
import { PaymentDetailsItem } from './payment-details-item';
import { AddOnDto } from 'store/add-ons/types';
import { getPaymentDetailsLabels } from 'utils/getPaymentDetailsLabels';
import { getPaymentDetailsValues } from 'utils/getPaymentDetailsValues';
import { MappedBookingObject } from 'components/payment/receipt/components/swipe-receipt-content';
import { MultiDayIconsBlock } from 'components/payment/receipt/components/multiDayIconsBlock/multiDayIconsBlock';
import { OrderType } from 'store/cart/types';
import { VisitorsCount } from './visitors-count';
import { AttendeeType } from 'store/transactions/types';
import { getResourceTimezone } from 'utils/getResourceTimezone';
import { Resource } from 'store/resource/types';

interface ReceiptSummaryProps {
  transaction: MappedBookingObject;
  isSwipeModalContent?: boolean;
  onCancelBooking?: VoidFunction;
}

export const ReceiptSummary = ({ transaction, onCancelBooking, isSwipeModalContent = false }: ReceiptSummaryProps) => {
  const intl = useIntl();
  const locale = useLocale();
  const buildingLocale = useSelector(selectBuildingLocale) || 'en-US';
  const buildingTimeZone = useSelector(selectBuildingTimeZone);
  const resourceTimezone = getResourceTimezone(transaction.details.resource as unknown as Resource, buildingTimeZone);
  const { eventWeekDay, eventDateMonthLong, eventDateDay, eventDateYear } = formatDate(
    transaction?.details.start_at,
    locale as string,
    resourceTimezone,
  );
  const { capitalizedBrand, formattedExpMonth, formattedExpYear } = formatSavedPaymentMethodLabelFromTransaction(
    transaction?.payment_method,
  );
  const currencyType = transaction.currency_type;
  const subtotal = transaction.total_price;
  const taxSummary = transaction.total_tax;
  const total = transaction.total_charge;
  const discountApplied = transaction.details.discount_applied;
  const addOns = transaction?.details?.add_ons;
  const attendees = transaction?.details?.attendees;
  const isMobileDevice = useIsSmallViewportWidth();

  const visitorsCount = useMemo(() => {
    const visitors = attendees?.length ? attendees?.filter(attendee => attendee.type === AttendeeType.VISITOR) : [];

    return visitors.length;
  }, [attendees]);

  const sortedAddOns = useMemo<AddOnDto[]>(
    () => addOns?.sort((a, b) => (a?.quantity < b?.quantity ? 1 : -1)),
    [addOns],
  );
  const addOnsTotalCost = sortedAddOns?.reduce((sum, addOn) => sum + addOn.price * addOn.quantity, 0);
  const resourcePrice = subtotal - addOnsTotalCost;

  return (
    <SummaryContainer data-testid="historical-receipt-summary">
      {transaction?.type !== TRANSACTION_TYPES.MEMBERSHIP && (
        <ServiceDetails isSwipeModalContent={isSwipeModalContent}>
          <StyledSummarySubtitle>
            {intl.formatMessage({
              id: getReceiptInfoFromTransaction(transaction, locale as string, resourceTimezone).title,
            })}
          </StyledSummarySubtitle>
          <IconBlockContainer>
            {transaction?.isMultiday ? (
              <MultiDayIconsBlock transaction={transaction} />
            ) : (
              <IconBlock
                icon="calendar-alt"
                iconType="fal"
                action
                actionText={intl.formatMessage({ id: 'cancel_booking' })}
                onActionClick={onCancelBooking}
                title={`${eventWeekDay}, ${eventDateMonthLong} ${eventDateDay}, ${eventDateYear}`}
                subtitle={
                  getReceiptInfoFromTransaction(transaction, locale as string, resourceTimezone).eventBlockSubtitle
                }
              />
            )}
            {Boolean(visitorsCount) && <VisitorsCount visitorsCount={visitorsCount} />}
            <IconBlock
              icon="location-dot"
              iconType="fal"
              title={transaction.vendor.name}
              subtitle={transaction.details.resource.name}
            />
          </IconBlockContainer>
          {(transaction?.type === TRANSACTION_TYPES.RESOURCES_BOOKING ||
            transaction?.type === OrderType.RESOURCE_BOOKING) &&
          transaction?.details?.user_info?.length ? (
            <ReceiptAdditionalInformation formResponses={transaction?.details.user_info} />
          ) : null}
          {transaction?.details?.cancellation_description && (
            <CancellationInformation
              cancellationRule={transaction?.details?.cancellation_description.replace(/\n/g, '<br/>')}
            />
          )}
          {!!transaction?.details?.resource?.features?.length && (
            <Amenities
              amenitiesDescription={transaction?.details?.resource?.amenities_description}
              amenities={transaction?.details?.resource?.features}
              isMobileDevice={isMobileDevice}
            />
          )}
        </ServiceDetails>
      )}
      {!!transaction?.total_price && (
        <StyledPaymentDetails
          paymentItem={<PaymentDetailsItem addOns={sortedAddOns} currencyType={currencyType} />}
          title={intl.formatMessage({ id: 'payment_details' })}
          paymentDetailsLabels={getPaymentDetailsLabels(transaction.details.name, intl)}
          paymentDetailsValues={getPaymentDetailsValues(
            resourcePrice || 0,
            total,
            subtotal,
            taxSummary,
            discountApplied,
            currencyType,
            buildingLocale,
            intl,
          )}
        />
      )}
      {!!transaction?.total_price && (
        <PaymentMethodContainer>
          <PaymentMethodTitle>{intl.formatMessage({ id: 'payment' })}</PaymentMethodTitle>
          <ReceiptPaymentMethod
            title={intl.formatMessage(
              {
                id: 'saved_payment_method_option',
              },
              {
                cardBrand: capitalizedBrand,
                cardLastFour: transaction?.payment_method?.last_four,
                cardExpMonth: formattedExpMonth,
                cardExpYear: formattedExpYear,
              },
            )}
            paymentMethod={transaction?.payment_method.type?.toUpperCase()}
            creditsAmount={formatCurrency(
              transaction?.payment_method?.remaining_points || 0,
              transaction?.currency_type,
              buildingLocale,
            )}
            total={transaction?.total_price}
            amounts={{ totalCharge: transaction?.total_charge, totalDiscount: transaction?.total_discount }}
          />
        </PaymentMethodContainer>
      )}
    </SummaryContainer>
  );
};
