import { LinkText, StyledLink } from './styles';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ArrowLeftChevron } from '@hqo/react-components-library/dist/icons';

interface ArrowBackLinkProps {
  className?: string;
  onClick: () => void;
}

export const ArrowBackLink = ({ className, onClick }: ArrowBackLinkProps): JSX.Element => {
  return (
    <StyledLink className={className} data-cy="back-link" data-testid="back-link" onClick={onClick}>
      <ArrowLeftChevron variant="adminGreyDark" size="sm" />
      <LinkText>
        <FormattedMessage id="back" />
      </LinkText>
    </StyledLink>
  );
};
