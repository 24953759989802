import { replace } from 'store/router/actions';
import { useSearchParams } from 'hooks/use-search-params.hook';
import qs, { ParsedQs } from 'qs';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { QUERY_PARAMS } from 'shared/consts';
import { pick } from 'utils/pickObjectProperty';

interface UseCapacityPillProps {
  capacityEncoded: string | ParsedQs | string[] | ParsedQs[] | null;
  onCancelClick: VoidFunction;
}

export const useCapacityPill = (): UseCapacityPillProps => {
  const { capacity: capacityEncoded } = useSearchParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const onCancelClick = useCallback(() => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const queryString = qs.stringify(
      pick(
        queryParams,
        QUERY_PARAMS.filter(param => param !== 'capacity'),
      ),
    );

    dispatch(replace(`${location.pathname}?${queryString}`));
  }, [dispatch, location.pathname, location.search]);

  return { capacityEncoded, onCancelClick };
};
