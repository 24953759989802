import { RootState } from 'store/reducer';
import { FloorPlanAppInstanceConfigState } from 'store/floorplan-app-instance-config/types';
import { createSelector } from 'reselect';

export const floorplanAppInstanceConfigSelector = (state: RootState): FloorPlanAppInstanceConfigState =>
  state.floorplanAppInstanceConfig;

export const selectFloorPlanAppInstanceConfig = createSelector(
  [floorplanAppInstanceConfigSelector],
  config => config.data ?? null,
);

export const selectFloorPlanAppInstanceConfigStatus = createSelector(
  [floorplanAppInstanceConfigSelector],
  configs => configs.getAppInstanceConfig.status,
);

export const selectFloorPlanAppInstanceConfigError = createSelector(
  [floorplanAppInstanceConfigSelector],
  configs => configs.getAppInstanceConfig.error,
);
