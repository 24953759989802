import React, { useEffect } from 'react';
import {
  selectGetRedirectTransactionStatus,
  selectTransactionStatus,
  selectRedirectTransaction,
  selectTransaction,
  selectPatchTransactionsStatus,
} from 'store/transactions/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_STATUSES, PATCH_STATUSES_3DS, QUERY_PARAMS } from 'shared/consts';
import { getRedirectTransaction, getTransaction, patchTransaction } from 'store/transactions/actions';
import { replace } from 'store/router/actions';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useCart } from 'hooks/use-cart.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import qs from 'qs';
import { pick } from 'utils/pickObjectProperty';
import { Skeleton } from 'components/skeleton';

export const RedirectPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const { cartId, transaction_token, ...restQueryParams } = useSearchParams();
  const { isCartFulfilled, cart } = useCart(cartId as string);
  const getRedirectTransactionStatus = useSelector(selectGetRedirectTransactionStatus);
  const redirectTransaction = useSelector(selectRedirectTransaction);
  const { showMiniappPaymentsNavigation } = useFlags();
  const transactionStatus = useSelector(selectTransactionStatus);
  const transaction = useSelector(selectTransaction);
  const patchTransactionsStatus = useSelector(selectPatchTransactionsStatus);

  useEffect(() => {
    if (transaction_token) {
      dispatch(getRedirectTransaction.request(transaction_token as string));
    }
  }, [dispatch, transaction_token]);

  useEffect(() => {
    if (showMiniappPaymentsNavigation && cart?.transaction_uuid) {
      dispatch(getTransaction.request(cart.transaction_uuid));
    }
  }, [cart?.transaction_uuid, dispatch, showMiniappPaymentsNavigation]);

  useEffect(() => {
    if (patchTransactionsStatus === ACTION_STATUSES.FULFILLED) {
      const queryString = qs.stringify(
        pick(
          restQueryParams,
          QUERY_PARAMS.filter(
            param =>
              param !== 'startTime' &&
              param !== 'startDate' &&
              param !== 'endTime' &&
              param !== 'isExact' &&
              param !== 'duration',
          ),
        ),
      );

      dispatch(replace(`/transaction-failed?${queryString}`));
    }
  }, [restQueryParams, patchTransactionsStatus]);

  useEffect(() => {
    if (
      getRedirectTransactionStatus === ACTION_STATUSES.FULFILLED &&
      isCartFulfilled &&
      cart?.transaction_uuid &&
      transactionStatus === ACTION_STATUSES.FULFILLED
    ) {
      if (redirectTransaction?.succeeded) {
        if (showMiniappPaymentsNavigation && transactionStatus === ACTION_STATUSES.FULFILLED) {
          dispatch(
            patchTransaction.request({
              transactionId: transaction?.id.toString(),
              status_3ds: PATCH_STATUSES_3DS.SUCCEEDED,
              cartId: cartId as string,
            }),
          );

          const queryString = qs.stringify(restQueryParams);
          dispatch(replace(`${pathname.replace('redirect', 'receipt')}?${queryString}`));
        }
      } else {
        dispatch(
          patchTransaction.request({
            transactionId: transaction?.id.toString(),
            status_3ds: PATCH_STATUSES_3DS.FAILED,
            cartId: cartId as string,
          }),
        );
      }
    }
  }, [
    transactionStatus,
    transaction,
    cart?.transaction_uuid,
    cart?.transaction_id,
    dispatch,
    getRedirectTransactionStatus,
    isCartFulfilled,
    location.search,
    redirectTransaction,
    showMiniappPaymentsNavigation,
  ]);

  return <Skeleton />;
};
