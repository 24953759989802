export const HQO_PARAMETER_NAME_TO_OPEN_OS_BROWSER_ON_EXTERNAL_LINK = 'hqoExternalLink';

const transformExternalUrl = (inputStringUrl: string, openInOsBrowserExternalLink = true): string | undefined => {
  try {
    const url = new URL(inputStringUrl);

    if (openInOsBrowserExternalLink && url.host) {
      url.searchParams.set(HQO_PARAMETER_NAME_TO_OPEN_OS_BROWSER_ON_EXTERNAL_LINK, 'true');
    }

    return url.toString();
  } catch (e) {
    return undefined;
  }
};

export default transformExternalUrl;
