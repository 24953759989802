import React from 'react';
import { DateSelectDesktopPage } from 'pages/date-select-page/desktop';
import { TimeSelectDesktopPage } from 'pages/time-select-page/desktop';
import { DateTimeSelectPageDesktopProps } from '../interface';

export const DateTimeSelectPageDesktop = ({
  isDateStep,
  onSearchClick,
  onNextClick,
  onClose,
  onSkipClick,
  onPressBack,
  backToDateStep,
}: DateTimeSelectPageDesktopProps): JSX.Element => {
  return isDateStep ? (
    <DateSelectDesktopPage onClose={onClose} onNextClick={onNextClick} />
  ) : (
    <TimeSelectDesktopPage
      onClose={onClose}
      onSearchClick={onSearchClick}
      onSkipClick={onSkipClick}
      onPressBack={onPressBack}
      backToDateStep={backToDateStep}
    />
  );
};
