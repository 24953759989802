import { createElement } from 'react';
import useMarkdownText from 'shared/hooks/useMarkdownText/useMarkdownText.hook';

export interface MarkdownTextProps {
  children: string;
  element?: string;
}

const MarkdownText = ({ children, element = 'div' }: MarkdownTextProps) => {
  const { html } = useMarkdownText(children);
  return createElement(element, {
    dangerouslySetInnerHTML: {
      __html: html,
    },
  });
};

export default MarkdownText;
