import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { ACTION_STATUSES } from 'shared/consts';
import { FloorPlanAppInstanceConfigState } from './types';

const initialState: FloorPlanAppInstanceConfigState = {
  data: null,
  getAppInstanceConfig: {
    status: null,
    error: null,
  },
};

const getFloorPlanAppInstanceConfigRequestHandler = (
  state: FloorPlanAppInstanceConfigState,
): FloorPlanAppInstanceConfigState => ({
  ...state,
  getAppInstanceConfig: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getFloorPlanAppInstanceConfigSuccessHandler = (
  state: FloorPlanAppInstanceConfigState,
  { payload }: ActionType<typeof actions.getFloorPlanAppInstanceConfig.success>,
): FloorPlanAppInstanceConfigState => ({
  ...state,
  data: payload.data,
  getAppInstanceConfig: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getFloorPlanAppInstanceConfigFailureHandler = (
  state: FloorPlanAppInstanceConfigState,
  { payload: error }: ActionType<typeof actions.getFloorPlanAppInstanceConfig.failure>,
): FloorPlanAppInstanceConfigState => ({
  ...state,
  getAppInstanceConfig: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const appFloorPlanInstanceConfigReducer = createReducer(initialState)
  .handleAction(actions.getFloorPlanAppInstanceConfig.request, getFloorPlanAppInstanceConfigRequestHandler)
  .handleAction(actions.getFloorPlanAppInstanceConfig.success, getFloorPlanAppInstanceConfigSuccessHandler)
  .handleAction(actions.getFloorPlanAppInstanceConfig.failure, getFloorPlanAppInstanceConfigFailureHandler);

export default appFloorPlanInstanceConfigReducer;
