import { ArrowBackLink } from 'components/arrow-back-link/arrow-back-link';
import React from 'react';
import { useSelector } from 'react-redux';
import { AjaxError } from 'rxjs/ajax';
import { errorsSelect } from 'store/errors/selectors';
import { ErrorDetailsWrapper, ErrorContainer } from './styles';

export interface ErrorDetailsProps {
  toggleDevInfo: VoidFunction;
}

interface GenericAjaxError {
  uuid: string;
  action: string;
  error: AjaxError;
}

export const ErrorDetails = ({ toggleDevInfo }: ErrorDetailsProps) => {
  const errors = useSelector(errorsSelect);

  return (
    <ErrorDetailsWrapper>
      <ArrowBackLink onClick={toggleDevInfo} />
      {errors?.genericErrors?.map((genericError: GenericAjaxError) => (
        <ErrorContainer key={genericError.uuid}>
          <div>
            <b>Action: </b>
            {genericError.action}
          </div>
          <div>
            <b>Url: </b>
            {genericError.error?.request?.url}
          </div>
          <div>
            <b>Error: </b>
            {genericError.error?.response?.message?.error}
          </div>
          <div>
            <b>Error message: </b>
            {genericError.error?.response?.message?.message}
          </div>
        </ErrorContainer>
      ))}
    </ErrorDetailsWrapper>
  );
};
