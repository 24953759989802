import { ShowNativePaymentHandlerPayload } from '@hqo/hqo-miniapp-client-sdk';
import { useMiniappSdkEventHandler } from 'components/miniapp-sdk-provider/miniapp-sdk.hook';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { submitCart } from 'store/cart/actions';
import { selectCurrentPaymentMethodId } from 'store/payment/selectors';
import { Order } from 'store/cart/types';

export interface UseNativePaymentMethodProps {
  cart: Order;
  locationId: string;
}

export const useNativePaymentMethod = ({ cart, locationId }: UseNativePaymentMethodProps) => {
  const dispatch = useDispatch();

  const [nativePaymentResponse, setNativePaymentResponse] = useState<ShowNativePaymentHandlerPayload>(null);
  const [pendingNativePayment, setPendingNativePayment] = useState<boolean>(false);
  const [isNativePaymentError, setIsNativePaymentError] = useState<boolean>(null);
  const paymentMethodId = useSelector(selectCurrentPaymentMethodId);
  const isApplePayMethodSelected = paymentMethodId === '-1';
  useMiniappSdkEventHandler(
    'showNativePaymentResponse',
    (data: ShowNativePaymentHandlerPayload) => {
      setNativePaymentResponse(data);
    },
    [dispatch],
  );

  useEffect(() => {
    if (nativePaymentResponse?.id) {
      const paymentInfo = {
        cart_id: cart.id as string,
        payment_method_id: `${nativePaymentResponse.id}`,
        locationId,
      };

      dispatch(submitCart.request(paymentInfo));
    } else if (nativePaymentResponse?.id === 0) {
      setIsNativePaymentError(true);
      setPendingNativePayment(false);
    }
  }, [nativePaymentResponse]);

  useEffect(() => {
    if (!isApplePayMethodSelected && isNativePaymentError) {
      setIsNativePaymentError(false);
    }
  }, [isApplePayMethodSelected, isNativePaymentError]);

  return {
    isApplePayMethodSelected,
    setPendingNativePayment,
    pendingNativePayment,
    isNativePaymentError,
  };
};
