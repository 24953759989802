export interface BookingFlowState {
  selectedSlotByUserAt?: number;
}

export interface ChangeSelectedSlotOptions {
  initiator: 'user' | 'auto';
}

export enum BookingCreationError {
  CART_SUBMISSION = 'cart_submission_error',
  PAYMENT_PROCESSING = 'payment_processing_error',
  CARD_DECLINED = 'card_declined_error',
  BOOKING_UNAVAILABLE = 'booking_unavailable_error',
  BOOKING_PROBLEM_APOINTMENT = 'problem_booking_appointment',
}
