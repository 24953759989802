import {
  AppInstanceConfigsResponse,
  GetAppInstanceConfigsParams,
  UpdateCurrentAppInstanceConfigUuidParams,
  UpdateCurrentLocationIdParams,
  IsCurrentLocationIdUpdatingParams,
  OnboardUseParams,
  AppInstanceConfigWithLocations,
} from './types';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { FailureActionPayload } from 'store/errors/types';

export const getAppInstanceConfigs = createAsyncAction(
  'GET_APP_INSTANCE_CONFIGS_REQUEST',
  'GET_APP_INSTANCE_CONFIGS_SUCCESS',
  'GET_APP_INSTANCE_CONFIGS_FAILURE',
)<GetAppInstanceConfigsParams, AppInstanceConfigsResponse, FailureActionPayload>();

export const onboardUser = createAsyncAction('ONBOARD_USER_REQUEST', 'ONBOARD_USER_SUCCESS', 'ONBOARD_USER_FAILURE')<
  OnboardUseParams,
  void,
  FailureActionPayload
>();

export const updateCurrentAppInstanceConfigUuid = createAction(
  'UPDATE_APP_INSTANCE_CONFIG_UUID',
)<UpdateCurrentAppInstanceConfigUuidParams>();

export const updateCurrentLocationId = createAction('UPDATE_LOCATION_ID')<UpdateCurrentLocationIdParams>();

export const setSelectedAppInstanceConfigsLocations = createAction('SET_SELECTED_APP_INSTANCE_CONFIGS_LOCATIONS')<
  Array<AppInstanceConfigWithLocations>
>();

export const isCurrentLocationIdUpdating = createAction('IS_LOCATION_ID_UPDATING')<IsCurrentLocationIdUpdatingParams>();

export const resetCurrentLocationIdUpdating = createAction('RESET_LOCATION_ID_UPDATING')<undefined>();

export const resetAppInstanceConfig = createAction('RESET_APP_INSTANCE_CONFIG')<undefined>();

export const resetOnboardUserStatus = createAction('RESET_ONBOARD_USER_STATUS')<undefined>();

export const emptyAppInstanceConfigs = createAction('IS_APP_INSTANCE_CONFIGS_EMPTY')<{ error: Error }>();
