import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  selectAppInstanceConfigs,
  selectCurrentAppInstanceConfigUuid,
  selectCurrentLocationId,
} from 'store/app-instance-configs/selectors';
import { AppInstanceConfig } from 'store/app-instance-configs/types';

export default function useCurrentAppInstanceConfigs(): AppInstanceConfig[] {
  const appInstanceConfigs = useSelector(selectAppInstanceConfigs);
  const currentAppInstanceConfigUuid = useSelector(selectCurrentAppInstanceConfigUuid);
  const locationId = useSelector(selectCurrentLocationId);
  const locationUuids = useMemo<Map<string, string>>(
    () =>
      new Map(
        locationId
          ? locationId
              .split(',')
              .filter(s => s.length > 0)
              .map(e => [e, e])
          : [],
      ),
    [locationId],
  );

  return useMemo<AppInstanceConfig[]>(() => {
    if (!appInstanceConfigs || appInstanceConfigs.length === 0) {
      return [];
    }

    if (locationUuids.size > 0) {
      return appInstanceConfigs.filter(config => config.location_id && locationUuids.has(config.location_id));
    }

    if (currentAppInstanceConfigUuid) {
      return appInstanceConfigs.filter(
        config => config.uuid === currentAppInstanceConfigUuid && config.location_default,
      );
    }
    return [];
  }, [appInstanceConfigs, currentAppInstanceConfigUuid, locationUuids]);
}
