import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { useFilteringEnabled } from 'hooks/use-filtering-enabled.hook';
import { useResourceLoadingHandler } from 'pages/resources/hooks';
import { useCallback, useMemo } from 'react';
import { goBack } from 'store/router/actions';
import { resetResourcesState } from 'store/resources/actions';
import { useDispatch } from 'react-redux';
import { useBackButtonVisibility } from 'hooks/use-back-button-visibility.hook';

interface UseArrowBackButtonReturnValues {
  arrowBackButtonIsVisible: boolean;
  onPressBack: VoidFunction;
}

export const useArrowBackButton = (): UseArrowBackButtonReturnValues => {
  const dispatch = useDispatch();
  const isMobileDevice = useIsSmallViewportWidth();
  const isFilteringEnabled = useFilteringEnabled();
  const isLoadingState = useResourceLoadingHandler(isFilteringEnabled);
  const isBackButtonVisible = useBackButtonVisibility();
  const arrowBackButtonIsVisible = useMemo<boolean>(
    () => isMobileDevice && !isLoadingState && isBackButtonVisible,
    [isLoadingState, isMobileDevice, isBackButtonVisible],
  );

  const onPressBack = useCallback(() => {
    dispatch(goBack());
    dispatch(resetResourcesState());
  }, [dispatch]);

  return {
    arrowBackButtonIsVisible,
    onPressBack,
  };
};
