import { DateTimeSelectModalContent } from './components/date-time-select-modal-content';
import { DateTimeSelectModalDesktop } from './components/date-time-select-modal-desktop';
import { DateTimeSelectModalFooter } from './components/date-time-select-modal-footer';
import { DateTimeSelectModalProps } from './interface';
import { DayLabels } from 'pages/date-select-page/components/day-labels';
import React, { useEffect, useRef } from 'react';
import { SwipeModal } from '@hqo/react-components-library/dist/molecules/modals/swipe-modal';
import { resourceState } from 'store/resource/selectors';
import { useIntl } from 'react-intl';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport/';
import { useOnClose } from 'components/schedule-select/use-schedule-select.hook';
import { useSelector, useDispatch } from 'react-redux';
import { useToggleNativeHeader } from 'hooks/use-toggle-native-header/use-toggle-native-header.hook';
import { useToggleSwipeGestures } from 'hooks/use-toggle-swipe-gestures/use-toggle-swipe-gestures.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { setInitialQueryString } from 'store/resource/actions';
import { useLocation } from 'react-router-dom';

export const DateTimeSelectModal = ({
  onClose,
  isDateStep,
  onNextClick,
  onDateLinkClick,
  onTimeLinkClick,
  onPressBack,
  backToDateStep,
  onPressSave,
  isModalWithoutSlideAnimation,
  reverseHorizontalAnimation,
  showNativeMobileHeader,
  onDateTimeSaveClick,
}: DateTimeSelectModalProps): JSX.Element => {
  const intl = useIntl();
  const isSmallViewportWidth = useIsSmallViewportWidth();
  const { showResourceDetailsDateTimeInputs } = useFlags();
  const {
    resource: { name: resourceName },
  } = useSelector(resourceState);
  const dateTimeTitle = isDateStep ? intl.formatMessage({ id: 'whenBooking' }) : resourceName;
  const handleOnClose = useOnClose(onClose);
  const scrollContainerRef = useRef(null);
  const contentBodyRef = useRef(null);
  useToggleNativeHeader(showNativeMobileHeader);
  useToggleSwipeGestures();
  const dispatch = useDispatch();
  const { search } = useLocation();

  useEffect(() => {
    dispatch(setInitialQueryString(search));
  }, []);

  return isSmallViewportWidth ? (
    <SwipeModal
      scrollContainerRef={scrollContainerRef}
      contentBodyRef={contentBodyRef}
      isModalWithoutSlideAnimation={showResourceDetailsDateTimeInputs || isModalWithoutSlideAnimation}
      reverseHorizontalAnimation={reverseHorizontalAnimation}
      reverseAnimation={false}
      title={dateTimeTitle}
      subtitle={isDateStep && <DayLabels />}
      className="date-time-select-modal"
      content={
        <DateTimeSelectModalContent
          isDateStep={isDateStep}
          scrollContainerRef={scrollContainerRef}
          contentBodyRef={contentBodyRef}
        />
      }
      footerContent={
        <DateTimeSelectModalFooter
          onPressSave={onPressSave}
          isDateStep={isDateStep}
          onNextClick={onNextClick}
          onDateLinkClick={onDateLinkClick}
          onTimeLinkClick={onTimeLinkClick}
          onDateTimeSaveClick={onDateTimeSaveClick}
        />
      }
      onClose={handleOnClose}
      onPressBack={onPressBack}
      withLeftArrowButton={backToDateStep}
      withLeftExitButton={!backToDateStep}
    />
  ) : (
    <DateTimeSelectModalDesktop
      onPressSave={onPressSave}
      handleOnClose={handleOnClose}
      onClose={onClose}
      onNextClick={onNextClick}
      isDateStep={isDateStep}
      onDateLinkClick={onDateLinkClick}
      onTimeLinkClick={onTimeLinkClick}
      onPressBack={onPressBack}
      backToDateStep={backToDateStep}
      onDateTimeSaveClick={onDateTimeSaveClick}
    />
  );
};
