import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { replace } from 'store/router/actions';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';
import { ACTION_STATUSES, LANDING_PATH } from 'shared/consts';
import { useCurrentConfig } from 'hooks/use-current-config.hook';
import { selectAppInstanceConfigsStatus } from '../store/app-instance-configs/selectors';

export const useTermsAndConditionsVisibility = (): void => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const { termsAndConditionsValues } = useAppInstanceConfigFeature();
  const appInstanceConfig = useCurrentConfig();
  const iAppInstanceConfigsStatusFulfilled = useSelector(selectAppInstanceConfigsStatus) === ACTION_STATUSES.FULFILLED;
  const isTermsAndConditionsVisible =
    termsAndConditionsValues.isTermsAndConditionsEnabled &&
    !pathname.includes('terms-and-conditions') &&
    appInstanceConfig?.show_terms_and_conditions &&
    iAppInstanceConfigsStatusFulfilled;
  const termsAndConditionsPath =
    pathname === LANDING_PATH
      ? `${pathname}terms-and-conditions${search}`
      : `${pathname}/terms-and-conditions${search}`;

  useEffect(() => {
    if (isTermsAndConditionsVisible) {
      dispatch(replace(termsAndConditionsPath));
    }
  }, [pathname, search, isTermsAndConditionsVisible, dispatch, termsAndConditionsPath]);
};
