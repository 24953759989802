import styled from 'styled-components';

export const IconContainer = styled.div`
  position: relative;
  width: 24px;
  cursor: pointer;
`;

export const ActiveDot = styled.div`
  position: absolute;
  top: -4px;
  right: 0;
  width: 8px;
  height: 8px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.$white};
  box-sizing: content-box;
`;
