import { GetPriceParams, PriceResponse } from './types';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { FailureActionPayload } from 'store/errors/types';

export const getPrice = createAsyncAction('GET_PRICE_REQUEST', 'GET_PRICE_SUCCESS', 'GET_PRICE_FAILURE')<
  GetPriceParams,
  PriceResponse,
  FailureActionPayload
>();

export const resetPrice = createAction('RESET_GET_PRICE')<undefined>();

export const changePrice = createAction('CHANGE_PRICE')<number>();
