import { Order } from 'store/cart/types';
import React, { useMemo } from 'react';
import { formatSummaryValues } from 'utils/formatSummaryValues';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectBuildingLocale } from 'store/building/selectors';
import { selectTransactionDisplay } from 'store/transactions/selectors';
import { PaymentDetailsItem } from 'components/payment/receipt/components/payment-details-item';
import { StyledPaymentDetails } from './styles';
import { AddOnDto } from 'store/add-ons/types';
import { getPaymentDetailsLabels } from 'utils/getPaymentDetailsLabels';
import { getPaymentDetailsValues } from 'utils/getPaymentDetailsValues';

interface CheckoutPaymentDetailsProps {
  cart: Order;
}

export const CheckoutPaymentDetails = ({ cart }: CheckoutPaymentDetailsProps): JSX.Element => {
  const intl = useIntl();
  const buildingLocale = useSelector(selectBuildingLocale) || 'en-US';
  const transactionDisplay = useSelector(selectTransactionDisplay);
  const addOns = cart?.items[0]?.resource_booking?.add_ons;

  const { currencyType, subtotal, discountApplied, total, taxSummary } = formatSummaryValues(
    cart?.total_summary || transactionDisplay?.total_summary,
  );

  const sortedAddOns = useMemo<AddOnDto[]>(() => addOns?.sort((a, b) => (a.quantity < b.quantity ? 1 : -1)), [addOns]);

  return (
    <StyledPaymentDetails
      paymentItem={<PaymentDetailsItem addOns={sortedAddOns} currencyType={currencyType} />}
      paymentDetailsLabels={getPaymentDetailsLabels(cart?.items[0]?.display_info?.title, intl)}
      paymentDetailsValues={getPaymentDetailsValues(
        cart?.items[0]?.price,
        total,
        subtotal,
        taxSummary,
        discountApplied,
        currencyType,
        buildingLocale,
        intl,
      )}
    />
  );
};
