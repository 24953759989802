import { CHECKOUT_PATH, HISTORICAL_RECEIPT_PATH, ITEM_TYPE, QUICK_CHECKOUT_PATH } from 'shared/consts';

import { configSelector } from 'store/config/selectors';
import { getMicroFrontendParams } from 'utils';
import { useSelector } from 'react-redux';
import { userState } from 'store/user/selectors';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { selectBuilding } from 'store/building/selectors';
import { handleNonISOLocales } from 'utils/handleNonISOLocales';

export const useIframeParams = (
  cartId: string,
  isQuickCheckout: boolean,
  bookingId?: number | string,
  locationId?: string,
  miniappPaymentPath?: string,
  showMiniappPaymentsNavigation?: boolean,
  isQuickCheckoutIframe?: boolean,
  error?: string,
  paymentId?: string,
): string => {
  const stateUser = useSelector(userState);
  const config = useSelector(configSelector);
  const building = useSelector(selectBuilding);
  const forPayment: boolean = !isQuickCheckoutIframe && showMiniappPaymentsNavigation;
  const isMobileDevice = useIsSmallViewportWidth();
  const lang = handleNonISOLocales(config.locale, building.locale);

  const params = getMicroFrontendParams(
    stateUser.user,
    config.apiUrl,
    config.authToken,
    config.buildingUuid,
    cartId,
    locationId,
    lang,
    forPayment,
    isMobileDevice,
    error,
    paymentId,
  );

  if (bookingId) {
    return `${process.env.REACT_APP_PAYMENTS_MINIAPP_URL}${HISTORICAL_RECEIPT_PATH}?${params}&itemId=${bookingId}&itemType=${ITEM_TYPE.RESOURCES_BOOKING}`;
  }
  const checkoutPath = isQuickCheckout ? QUICK_CHECKOUT_PATH : CHECKOUT_PATH;
  const path = showMiniappPaymentsNavigation ? miniappPaymentPath : `${checkoutPath}/${cartId}`;

  return `${process.env.REACT_APP_PAYMENTS_MINIAPP_URL}${path}?${params}`;
};
