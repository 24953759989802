const EXTRA_CONTENT_HEIGHT = 150;
const HORIZONTAL_PADDINGS = 68;
const GAP = 24;
const BLOCK_HEIGHT = 120;
const BLOCK_WIDTH = 220;

export const getDesktopOffsetElements = (): number => {
  const containerWidth = window.innerWidth - HORIZONTAL_PADDINGS;
  const containerHeight = window.innerHeight - EXTRA_CONTENT_HEIGHT;

  const itemsPerRow = Math.floor((containerWidth + GAP) / (BLOCK_WIDTH + GAP));
  const itemsPerColumn = Math.floor((containerHeight + GAP) / (BLOCK_HEIGHT + GAP));

  return itemsPerRow * itemsPerColumn;
};
