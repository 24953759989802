import styled from 'styled-components';

export const ErrorDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  height: 100vh;
  width: 100vw;
  gap: 16px;
`;

export const ErrorContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  border-bottom: 1px solid black;
`;
