import styled from 'styled-components';
import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { SwipeModal } from '@hqo/react-components-library/dist/molecules/modals/swipe-modal';

export const StyledClearButton = styled(Button)<{ isActive?: boolean }>`
  font: 500 16px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme, isActive }) =>
    isActive ? `${theme.colors.greys.adminBlack}` : `${theme.colors.greys.adminGreyMediumAlt}`};
  opacity: 1;
`;

export const StyledSwipeModal = styled(SwipeModal)`
  .right-icon {
    align-self: flex-end;
    width: auto;
    height: auto;
    top: 25px;
    z-index: 1;
  }
`;
