import { Button } from '@hqo/react-components-library/dist/atoms/button';
import styled from 'styled-components';

export const StyledCheckoutButton = styled(Button)<{ isNativePayment?: boolean }>`
  border-color: ${({ theme }) => theme.colors.greys.darkGrey6};
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.greys.sharedDarkGrey : theme.colors.$primary)};
  -webkit-tap-highlight-color: transparent;
  align-self: flex-end;
  width: 100%;
  height: 100%;
  margin-top: 0;
  padding: 4px 23px;
  justify-content: center;

  ${({ isNativePayment }) =>
    isNativePayment &&
    `background-color: black;

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
      opacity: 0.9;
      background-color: black;
      border-color: black;
  }
`}
`;
