import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ACTION_STATUSES } from 'shared/consts';
import { resetGetCartStatus, resetSubmitCartStatus } from 'store/cart/actions';
import { selectValuesForQuickCheckoutContent } from 'store/cart/selectors';
import { replace } from 'store/router/actions';
import { getTransaction } from 'store/transactions/actions';

export const useMultidayQuickCheckoutContent = (): void => {
  const { submitCartStatus, transactionStatus, transactionUuid } = useSelector(selectValuesForQuickCheckoutContent);

  const { pathname, search } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (submitCartStatus === ACTION_STATUSES.FULFILLED) {
      dispatch(getTransaction.request(transactionUuid));
    }
  }, [transactionUuid, dispatch, submitCartStatus]);

  useEffect(() => {
    if (transactionStatus === ACTION_STATUSES.FULFILLED) {
      dispatch(replace(`${pathname.replace('quick-checkout', 'receipt')}${search}`));
      dispatch(resetSubmitCartStatus());
      dispatch(resetGetCartStatus());
    }
  }, [dispatch, transactionStatus]);
};
