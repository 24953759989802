import { CartState } from './types';
import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';
import { DEFAULT_3DSECURE_VERSION } from 'shared/consts';
import { selectTransactionStatus } from 'store/transactions/selectors';

export const cartSelector = (state: RootState): CartState => state.cart;

export const selectCartId = createSelector([cartSelector], cart => cart.cart_id);

export const selectCreateCartStatus = createSelector([cartSelector], cart => cart.createCart.status);

export const selectCreateCartError = createSelector([cartSelector], cart => cart.createCart.error);

export const selectCart = createSelector([cartSelector], cart => cart.cart);

export const selectTriggerTransaction = createSelector([cartSelector], cart => cart.transaction);

export const selectGetCartStatus = createSelector([cartSelector], cart => cart.getCart.status);

export const selectGetCartError = createSelector([cartSelector], cart => cart.getCart.error);

export const selectSubmitCartStatus = createSelector([cartSelector], cart => cart.submitCart.status);

export const selectSubmitCartError = createSelector([cartSelector], cart => cart.submitCart.error);

export const selectComplete3DSCartStatus = createSelector([cartSelector], cart => cart.complete3DSCart.status);

export const selectComplete3DSCartError = createSelector([cartSelector], cart => cart.complete3DSCart.error);

export const select3DSecure = createSelector([selectCart], cart => {
  return {
    is3DSecureSupported: !!cart?.config?.payment_gateway?.supports_3dsecure,
    version: cart?.config?.payment_gateway?.version_3dsecure ?? DEFAULT_3DSECURE_VERSION,
  };
});

export const selectConfigPaymentGatewayId = createSelector([selectCart], cart => {
  return cart?.config?.payment_gateway_id;
});

export const shouldSubmit3DSecure = createSelector(
  [selectCart],
  cart => !!cart?.config?.payment_gateway?.supports_3dsecure && cart?.total_summary?.total > 0,
);

export const select3DSTransactionState = createSelector([selectCart], cart => cart?.transaction?.state);

export const select3DSTransactionToken = createSelector([selectCart], cart => cart?.transaction?.token);

export const selectCartDiscountSummary = createSelector(
  [cartSelector],
  cart => cart?.cart?.total_summary?.discount_summary,
);

export const selectValuesForQuickCheckoutContent = createSelector(
  [selectCart, selectSubmitCartStatus, selectTransactionStatus],
  (cart, submitCartStatus, transactionStatus) => ({
    submitCartStatus,
    transactionStatus,
    transactionUuid: cart?.transaction_uuid,
  }),
);
