import { DurationContainer, Text, StyledDot } from './styles';
import React from 'react';
import { useScheduleSelectPage } from 'pages/schedule-select-page/use-schedule-select-page.hook';

interface DurationBlockProps {
  isSwitchBlockVisible: boolean;
}

export const DurationBlock = ({ isSwitchBlockVisible }: DurationBlockProps): JSX.Element => {
  const { minDurationText, maxDurationText } = useScheduleSelectPage();

  return (
    <DurationContainer isSwitchBlockVisible={isSwitchBlockVisible}>
      <Text>{minDurationText}</Text>
      {maxDurationText && (
        <>
          <StyledDot />
          <Text>{maxDurationText}</Text>
        </>
      )}
    </DurationContainer>
  );
};
