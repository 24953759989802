const ONE_ITEM_LENGTH = 1;
const TWO_ITEMS_LENGTH = 2;

export const joinArrayItemsWithOxfordComma = (itemsArray: Array<string>, separator = 'and') => {
  const selectedItemsLength = itemsArray?.length;

  switch (selectedItemsLength) {
    case ONE_ITEM_LENGTH:
      return itemsArray.join();
    case TWO_ITEMS_LENGTH:
      return itemsArray.join(` ${separator} `);
    default:
      const lastItem = itemsArray?.pop();

      return `${itemsArray?.join(', ')}, ${separator} ${lastItem}`;
  }
};
