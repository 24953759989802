import { useSelector } from 'react-redux';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'pages/date-select-page/interface';
import { DEFAULT_TIME_INTERVAL } from 'components/schedule-select/const';
import { selectResourceTimeRanges } from 'store/resource-time-ranges/selectors';
import { resourceState } from 'store/bookings/selectors';

interface UseTimeIntervalResponseObject {
  isTimeIntervalFromTimeRanges: boolean;
  timeInterval: number;
}

export const useTimeInterval = (): UseTimeIntervalResponseObject => {
  const { startTime } = useSearchParams<searchParams>();
  const resourceTimeRanges = useSelector(selectResourceTimeRanges);
  const { resource } = useSelector(resourceState);

  const timeIntervalForTimeRange = startTime
    ? resourceTimeRanges?.find(range => range.time === startTime)?.time_interval
    : null;

  const isTimeIntervalFromTimeRanges = !!resourceTimeRanges?.[0]?.time_interval;

  const timeInterval =
    timeIntervalForTimeRange || resource?.booking_windows?.[0]?.time_interval || DEFAULT_TIME_INTERVAL;

  return { isTimeIntervalFromTimeRanges, timeInterval };
};
