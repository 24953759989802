import moment from 'moment-timezone';
import { CreateBookingDto } from 'shared/types';
import { AddOnDto } from 'store/add-ons/types';
import { ItemDto, ItemType } from 'store/cart/types';
import { Price } from 'store/price/types';
import { Resource, RoomLayout } from 'store/resource/types';

export interface ItemsData {
  resource: Resource;
  getBookingRequest: CreateBookingDto;
  price: Price;
  itemSubtitleText: string;
  timeRangeParam: TimeRangeParam;
  availableNowParams: AvailableNowParameters;
  currentRoomLayout: RoomLayout;
  selectedAddOns: Array<AddOnDto>;
}

export interface TimeRangeParam {
  startTime: string;
  endTime: string;
}

export interface AvailableNowParameters {
  availableNowStartTime?: string;
  availableNowEndTime?: string;
  availableNowStartDate?: string;
}

const MINIMUM_QUANTITY_PER_ITEM = 1;

const CENTS_IN_CURRENCY_UNIT = 100;

export const getFormattedTime = <T>(date: string, time: string, timezone: string): T =>
  moment(`${date} ${time}`).tz(timezone, true)?.utc().format() as unknown as T;

export const getGeneralInformation = (resourceId: number, getBookingRequest: CreateBookingDto, price: Price) => ({
  id: `${resourceId}`,
  type: ItemType.RESOURCE_BOOKING,
  quantity: MINIMUM_QUANTITY_PER_ITEM,
  price: getBookingRequest.price ? getBookingRequest.price * CENTS_IN_CURRENCY_UNIT : price?.price,
  discount: price?.used_credit,
});

export const resolveCartItems = (
  dates: Array<string>,
  {
    resource,
    getBookingRequest,
    price,
    itemSubtitleText,
    timeRangeParam,
    availableNowParams,
    currentRoomLayout,
    selectedAddOns,
  }: ItemsData,
  unitCode?: string,
): Array<ItemDto> => {
  const { timezone } = resource;
  const generalInformation = getGeneralInformation(resource.id, getBookingRequest, price);
  const currentUnitCode = unitCode ? { unit_code: unitCode } : {};

  if (availableNowParams.availableNowStartTime) {
    const { availableNowStartTime, availableNowEndTime, availableNowStartDate } = availableNowParams;

    return [
      {
        ...generalInformation,
        display_info: {
          title: resource.name,
          subtitle: itemSubtitleText,
          description1: resource.description,
          description2: getFormattedTime<string>(availableNowStartDate, availableNowStartTime, timezone),
          cancellation_description: getBookingRequest.cancellation_description,
        },
        resource_booking: {
          name: getBookingRequest.name || resource.name,
          start_at: getFormattedTime<Date>(availableNowStartDate, availableNowStartTime, timezone),
          end_at: getFormattedTime<Date>(availableNowStartDate, availableNowEndTime, timezone),
          form_responses: getBookingRequest.form_responses,
          ...(currentRoomLayout && { room_layout: currentRoomLayout }),
          ...(selectedAddOns && { add_ons: selectedAddOns }),
          ...currentUnitCode,
        },
      },
    ];
  }

  const { startTime, endTime } = timeRangeParam;

  return dates
    .filter(date => date !== undefined)
    .map(date => ({
      ...generalInformation,
      display_info: {
        title: resource.name,
        subtitle: itemSubtitleText,
        description1: resource.description,
        description2: getFormattedTime<string>(date, startTime, timezone),
        cancellation_description: getBookingRequest.cancellation_description,
      },
      resource_booking: {
        name: getBookingRequest.name || resource.name,
        start_at: getFormattedTime<Date>(date, startTime, timezone),
        end_at: getFormattedTime<Date>(date, endTime, timezone),
        form_responses: getBookingRequest.form_responses,
        ...(currentRoomLayout && { room_layout: currentRoomLayout }),
        ...(selectedAddOns && { add_ons: selectedAddOns }),
        ...currentUnitCode,
      },
    }));
};
