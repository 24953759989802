import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { ResourceState } from './types';
import { ACTION_STATUSES } from 'shared/consts';

export const initialState: ResourceState = {
  resource: {
    timezone: null,
    id: null,
    uuid: null,
    address: null,
    booking_windows: [],
    created_at: null,
    updated_at: null,
    name: '',
    capacity: null,
    manager_email: '',
    minimum_price: null,
    preset_booking_windows: '',
  },
  getResource: {
    status: null,
    error: null,
  },
  imageIsLoaded: false,
  initialQueryString: null,
};

const getResourceRequestHandler = (state: ResourceState): ResourceState => ({
  ...state,
  getResource: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getResourceSuccessHandler = (
  state: ResourceState,
  { payload }: ActionType<typeof actions.getResource.success>,
): ResourceState => ({
  ...state,
  resource: payload,
  getResource: {
    status: ACTION_STATUSES.FULFILLED,
    error: null,
  },
});

const getResourceFailureHandler = (
  state: ResourceState,
  { payload: error }: ActionType<typeof actions.getResource.failure>,
): ResourceState => ({
  ...state,
  getResource: {
    status: ACTION_STATUSES.REJECTED,
    error: error.error,
  },
});

const resetResourceHandler = (): ResourceState => ({
  ...initialState,
});

const imageIsLoaded = (state: ResourceState, { payload }: ActionType<typeof actions.imageIsLoaded>): ResourceState => ({
  ...state,
  imageIsLoaded: payload,
});

const setInitialQueryStringHandler = (
  state: ResourceState,
  { payload }: ActionType<typeof actions.setInitialQueryString>,
): ResourceState => ({
  ...state,
  initialQueryString: payload,
});

const resourceReducer = createReducer(initialState)
  .handleAction(actions.getResource.request, getResourceRequestHandler)
  .handleAction(actions.getResource.success, getResourceSuccessHandler)
  .handleAction(actions.getResource.failure, getResourceFailureHandler)
  .handleAction(actions.resetResource, resetResourceHandler)
  .handleAction(actions.imageIsLoaded, imageIsLoaded)
  .handleAction(actions.setInitialQueryString, setInitialQueryStringHandler);

export default resourceReducer;
