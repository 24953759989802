import styled from 'styled-components';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';
import IframeModalWrapper from 'components/modals/iframeWrapper';
import { reverseSlide, slide } from 'hocs/shared-styles';

export const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 265px;
  border-radius: 8px 8px 0 0;

  @media only screen and (max-width: ${DIMENSIONS.LARGE}) {
    align-items: flex-start;
  }

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    height: 350px;
  }
`;

export const StyledModalWrapper = styled(IframeModalWrapper)<{
  reverseAnimation: boolean;
}>`
  width: 490px;
  transition: width 1s;
  height: 265px;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    height: 350px;
    width: 100%;

    .iframe-wrapper {
      height: 350px;
      border-radius: 8px 8px 0 0;
      top: auto;
      animation: ${props => (props.reverseAnimation ? reverseSlide : slide)} 0.5s;
    }

    .iframe-modal-content {
      border-radius: 8px 8px 0 0;
      height: auto;
    }

    .iframe-modal-content > div {
      border-radius: 8px 8px 0 0;
      height: 100%;
      inset: 0;
    }
  }
`;
