import React from 'react';
import { useFilteringEnabled } from 'hooks/use-filtering-enabled.hook';
import { LocationSelector } from 'pages/landing/components/location-selector';
import { useResourceLoadingHandler } from 'pages/resources/hooks';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';

export const LocationPicker = (): JSX.Element => {
  const isFilteringEnabled = useFilteringEnabled();
  const isLoadingState = useResourceLoadingHandler(isFilteringEnabled);
  const isMobileDevice = useIsSmallViewportWidth();

  if (!isFilteringEnabled || (!isMobileDevice && isLoadingState)) return null;

  return <LocationSelector isResourcesPage />;
};
