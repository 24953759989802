import { useCallback } from 'react';
import { useSearchParams } from '../use-search-params.hook';
import { searchParams } from 'pages/date-select-page/interface';
import { useLocation } from 'react-router';
import { goBack, replace } from 'store/router/actions';
import { useDispatch } from 'react-redux';
import qs from 'qs';
import { resetCart, resetGetCartStatus, resetSubmitCartStatus } from 'store/cart/actions';
import { resetSpreedlyStatus } from 'store/add-card/actions';

interface UseCheckoutScreenReturnValues {
  onCloseCheckout: VoidFunction;
  openCheckout: VoidFunction;
}

export const useCheckoutScreen = (): UseCheckoutScreenReturnValues => {
  const dispatch = useDispatch();
  const { ...queryParams } = useSearchParams<searchParams>();
  const { pathname } = useLocation();

  const onCloseCheckout = useCallback(() => {
    dispatch(goBack());
    dispatch(resetGetCartStatus());
    dispatch(resetSubmitCartStatus());
    dispatch(resetCart());
    dispatch(resetSpreedlyStatus());
  }, [dispatch]);

  const openCheckout = useCallback(() => {
    const queryString = qs.stringify(queryParams);
    dispatch(replace(`${pathname.replace('quick-checkout', 'checkout')}?${queryString}`));
  }, [dispatch, pathname]);

  return {
    onCloseCheckout,
    openCheckout,
  };
};
