import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectAppInstanceConfigs } from 'store/app-instance-configs/selectors';

interface UseBuildingLocationLabelOptions {
  withCity?: boolean;
}

export default function useResourceBuildingLocationLabel(
  buildingUuid: string,
  { withCity = true }: UseBuildingLocationLabelOptions = {},
) {
  const appInstanceConfigs = useSelector(selectAppInstanceConfigs);
  return useMemo(() => {
    const appInstanceConfig = appInstanceConfigs.find(appInstance => appInstance.location_id === buildingUuid);
    if (appInstanceConfig) {
      return appInstanceConfig.location_city && withCity
        ? `${appInstanceConfig.location_city} (${appInstanceConfig.location_name})`
        : appInstanceConfig.location_name;
    }
    return undefined;
  }, [buildingUuid, appInstanceConfigs, withCity]);
}
