import React from 'react';
import { Wrapper } from './styles';
import { CapacityTitle } from './capacity-title';
import { CapacityContent } from './capacity-content';

export const Capacity = (): JSX.Element => {
  return (
    <Wrapper data-testid="capacity-wrapper">
      <CapacityTitle />
      <CapacityContent />
    </Wrapper>
  );
};
