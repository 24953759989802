export const getWeekDaysLabels = (locale = 'en', weekStartsOnMonday?: boolean, isCalendarView = false): string[] => {
  const dayStart = weekStartsOnMonday ? 5 : 4;
  if (isCalendarView) {
    return [...Array(7).keys()].map(v =>
      new Date(1970, 0, dayStart + v).toLocaleDateString(locale, { weekday: 'short' }),
    );
  }

  return [...Array(7).keys()].map(v =>
    new Date(1970, 0, dayStart + v).toLocaleDateString(locale, { weekday: 'long' }).substr(0, 2),
  );
};
