import { push } from 'store/router/actions';
import { useSearchParams } from 'hooks/use-search-params.hook';
import qs, { ParsedQs } from 'qs';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { QUERY_PARAMS } from 'shared/consts';
import { durations } from 'shared/consts/duration';
import { pick } from 'utils/pickObjectProperty';

interface UseDurationPillProps {
  durationEncoded: string | ParsedQs | string[] | ParsedQs[] | null;
  selectedDuration: string;
  onCancelClick: VoidFunction;
  isExactDurationEncoded: string | ParsedQs | string[] | ParsedQs[] | null;
}

export const useDurationPill = (): UseDurationPillProps => {
  const { duration: durationEncoded, isExact: isExactDurationEncoded } = useSearchParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const selectedDuration = useMemo<string>(
    () => durations.find(duration => duration.value === +durationEncoded)?.label,
    [durationEncoded],
  );

  const onCancelClick = useCallback(() => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    const queryString = qs.stringify(
      pick(
        queryParams,
        QUERY_PARAMS.filter(param => param !== 'duration'),
      ),
    );

    dispatch(push(`${location.pathname}?${queryString}`));
  }, [location]);

  return { durationEncoded, selectedDuration, onCancelClick, isExactDurationEncoded };
};
