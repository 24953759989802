import styled, { keyframes } from 'styled-components';

import { BottomModal } from '@hqo/react-components-library/dist/molecules/modals/bottomModal';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';

interface BottomModalProps {
  reverseAnimation: boolean;
}

const slide = (reverseAnimation: boolean) => keyframes`
  from {
    transform: translateY(${reverseAnimation ? 0 : '100%'});
  }
  to {
    transform: translateY(${reverseAnimation ? '100%' : 0});
  }
`;

const fade = (reverseAnimation: boolean) => keyframes`
  from {
    opacity: ${reverseAnimation ? 1 : 0};
  }
  to {
    opacity: ${reverseAnimation ? 0 : 1};
  }
`;

export const CheckoutContainer = styled.div`
  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    width: 100%;
    height: 100%;
  }
`;

export const StyledBottomMobileModal = styled(BottomModal)<BottomModalProps>`
    .container-content {
      @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
        animation: ${({ reverseAnimation }) => slide(reverseAnimation)} 0.4s;
      }
    }
    .background-container {
      @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
        animation: ${({ reverseAnimation }) => fade(reverseAnimation)} 0.4s;
      }
      z-index: 14;
      background-color: ${({ theme }) => `${theme.colorsRgba.black_5}`};
      backdrop-filter: blur(0);
    }
  }
`;
