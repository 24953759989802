import styled from 'styled-components';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';
import { EXTRA_LARGE_DIMENSION, PLUS_LARGE_DIMENSION } from 'shared/consts';

const DEFAULT_HEADER_HEIGHT = 135;
const DEFAULT_DESKTOP_HEADER_HEIGHT = 356;

export const Wrapper = styled.div`
  height: calc(100vh - ${DEFAULT_DESKTOP_HEADER_HEIGHT}px);
  width: 35%;
  margin-top: 80px;

  @media only screen and (max-width: ${EXTRA_LARGE_DIMENSION}) {
    width: 50%;
  }

  @media only screen and (max-width: ${PLUS_LARGE_DIMENSION}) {
    width: 65%;
  }

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    height: calc(100vh - ${DEFAULT_HEADER_HEIGHT}px);
    width: 100%;
    margin-bottom: 0;
    margin-top: 0;
  }
`;

export const MapViewContainer = styled.div`
  height: 100%;
  width: 100%;

  .mapboxgl-ctrl-bottom-right,
  .mapboxgl-ctrl-bottom-left {
    visibility: hidden;
  }

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    margin-top: 0;
  }
`;
