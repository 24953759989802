import { searchParams, timeFormatOptions, Item } from '../time-selector.interface';
import { replace } from 'store/router/actions';
import { useLocale } from 'hooks/use-locale.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import qs from 'qs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MIDNIGHT_HOURS_24H } from 'components/schedule-select/const';
import {
  getAvailableEndTimesFromStartTime,
  getAvailableStartTimes,
} from 'pages/resource/utils/getAvailableStartEndTimes';
import { selectResourceTimeRanges } from 'store/resource-time-ranges/selectors';
import { useMobileTimePickerDropdowns } from 'components/time-selector/hooks/use-mobile-time-picker-dropdowns.hook';
import { DropdownOption } from 'components/dropdown/types';
import {
  MAPPER_ITEMS_MODE,
  mapFormattedTimeRangesByMode,
} from 'components/time-selector/hooks/utils/mapFormattedTimeRangesByMode';
import { resolveSafariMidnight } from 'components/time-selector/hooks/utils/resolveSafariMidnight';
import { COMMA } from 'shared/consts';

const formatTimeLabel = (date: Date, locale: string, isEndTime = false) => {
  const midnightOptions = date.getHours() === 0 && date.getMinutes() === 0 && isEndTime ? { hour12: false } : {};

  return date.toLocaleTimeString(locale, {
    hour: 'numeric',
    minute: '2-digit',
    ...midnightOptions,
  });
};

export const formatStartEndTimeItemsArray = (
  timeArray: Array<string>,
  dateString: string,
  locale: string,
  isEndTime = false,
  options?: {
    shouldBeSkipped: boolean;
  },
): Array<Item> => {
  return timeArray.map(timeLabel => {
    const [hour, minute] = timeLabel.split(':');
    const dateValue = new Date(dateString);
    dateValue.setHours(+hour);
    dateValue.setMinutes(+minute);

    const formattedTimeLabel = formatTimeLabel(dateValue, locale, isEndTime);

    return { label: formattedTimeLabel, value: dateValue, shouldBeSkipped: options?.shouldBeSkipped };
  });
};

interface useResourceDetailTimeSelectorActionsProps {
  selectedEndTimeItem: Item;
  getEndTimeItems: (updatedStartTime?: string) => Array<Item>;
  setSelectedStartTimeItem: React.Dispatch<React.SetStateAction<Item>>;
  setSelectedEndTimeItem: React.Dispatch<React.SetStateAction<Item>>;
  setEndTimeItems: React.Dispatch<React.SetStateAction<Array<Item>>>;
  updateQueryParams: VoidFunction;
}

export const useResourceDetailTimeSelectorActions = ({
  selectedEndTimeItem,
  getEndTimeItems,
  setSelectedStartTimeItem,
  setSelectedEndTimeItem,
  setEndTimeItems,
  updateQueryParams,
}: useResourceDetailTimeSelectorActionsProps) => {
  const locale = useLocale();

  // the mobile dropdowns use Date instead of DropdownOption as its dropdown options
  const onSelectStartTime = useCallback(
    (option: Date | DropdownOption) => {
      const isMobile = option instanceof Date;
      const value = isMobile ? option : option.value;
      const formattedStartTimeString = value.toLocaleString(locale, timeFormatOptions);

      const endTimeItems = getEndTimeItems(formattedStartTimeString);
      setEndTimeItems(endTimeItems);

      const isValidEndTime =
        endTimeItems?.some(item => item.label === selectedEndTimeItem?.label) && value < selectedEndTimeItem?.value;

      if (!isValidEndTime) {
        const updatedEndTime = endTimeItems?.find(endTimeItem => endTimeItem.value > value);

        setSelectedEndTimeItem(updatedEndTime);
      }

      setSelectedStartTimeItem({ label: formatTimeLabel(value, locale), value });

      if (!isMobile) {
        updateQueryParams();
      }
    },
    [
      locale,
      getEndTimeItems,
      setEndTimeItems,
      selectedEndTimeItem?.value,
      selectedEndTimeItem?.label,
      setSelectedStartTimeItem,
      setSelectedEndTimeItem,
      updateQueryParams,
    ],
  );

  // the mobile dropdowns use Date instead of Item as its dropdown options
  const onSelectEndTime = useCallback(
    (option: Date | DropdownOption) => {
      const isMobile = option instanceof Date;
      const value = isMobile ? option : option.value;
      const updatedEndTimeItem = { label: formatTimeLabel(value, locale, true), value };

      setSelectedEndTimeItem(updatedEndTimeItem);

      if (!isMobile) {
        updateQueryParams();
      }
    },
    [locale, setSelectedEndTimeItem, updateQueryParams],
  );

  return { onSelectStartTime, onSelectEndTime };
};

interface useResourceDetailsTimeSelectorProps {
  toggleOverlay?: VoidFunction;
}

export const useResourceDetailsTimeSelector = ({ toggleOverlay }: useResourceDetailsTimeSelectorProps) => {
  const dispatch = useDispatch();
  const locale = useLocale();
  const { startDate, startDates, startTime, endTime, ...restQueryParams } = useSearchParams<searchParams>();
  const selectedDate = startDate || startDates?.split(COMMA)?.[0];

  const timeRanges = useSelector(selectResourceTimeRanges);
  const startTimeItems = useMemo(() => {
    if (!timeRanges) {
      return [];
    }
    const startTimeLabels = getAvailableStartTimes(timeRanges);

    return mapFormattedTimeRangesByMode(
      { startTime, endTime, selectedDate },
      timeRanges,
      startTimeLabels,
      locale,
      MAPPER_ITEMS_MODE.START_TIME,
    );
  }, [locale, selectedDate, timeRanges]);

  const getEndTimeItems = useCallback(
    (updatedStartTime?: string) => {
      if (!timeRanges) {
        return [];
      }
      const endTimeLabels = getAvailableEndTimesFromStartTime(timeRanges, updatedStartTime || startTime);

      return mapFormattedTimeRangesByMode(
        { startTime, endTime, selectedDate },
        timeRanges,
        endTimeLabels,
        locale,
        MAPPER_ITEMS_MODE.END_TIME,
      );
    },
    [locale, selectedDate, startTime, timeRanges],
  );

  const [endTimeItems, setEndTimeItems] = useState<Array<Item>>(getEndTimeItems());
  const { defaultSelectedStartTimeItem, defaultSelectedEndTimeItem } = useMemo(() => {
    const hourFormat = endTime?.includes(MIDNIGHT_HOURS_24H) && {
      hour12: false,
    };

    const startTimeItem = startTimeItems?.find(
      ({ value }) => (value.toLocaleString(locale, timeFormatOptions) as string) === startTime,
    );

    const endTimeItem = endTimeItems?.find(({ value }) => {
      const resolvedEndTime = resolveSafariMidnight(value, {
        locale,
        timeFormatOptions,
        hourFormat,
      });

      return resolvedEndTime === endTime;
    });

    return { defaultSelectedStartTimeItem: startTimeItem, defaultSelectedEndTimeItem: endTimeItem };
  }, [endTime, endTimeItems, locale, startTime, startTimeItems]);

  const [selectedStartTimeItem, setSelectedStartTimeItem] = useState<Item>(defaultSelectedStartTimeItem);
  const [selectedEndTimeItem, setSelectedEndTimeItem] = useState<Item>(defaultSelectedEndTimeItem);
  const [defaultStartValue, setDefaultStartValue] = useState<Date>(defaultSelectedStartTimeItem?.value);
  const [defaultEndValue, setDefaultEndValue] = useState<Date>(defaultSelectedEndTimeItem?.value);

  useEffect(() => {
    if (endTime) {
      setDefaultEndValue(selectedEndTimeItem?.value);
    }
  }, [locale, endTime]);

  useEffect(() => {
    if (startTime) {
      setDefaultStartValue(selectedStartTimeItem?.value);
      setEndTimeItems(getEndTimeItems(startTime));
    }
  }, [startTime]);

  const updateQueryParams = useCallback(() => {
    const datesParam = startDate ? { startDate } : { startDates };

    const midnightOptions =
      selectedEndTimeItem?.value.getHours() === 0 && selectedEndTimeItem?.value.getMinutes() === 0
        ? { hour12: false }
        : {};

    const resolvedEndTime = resolveSafariMidnight(selectedEndTimeItem?.value, {
      locale,
      timeFormatOptions,
      hourFormat: midnightOptions,
    });

    const formattedStartTimeParam = selectedStartTimeItem?.value.toLocaleString(locale, timeFormatOptions);
    const queryParams = {
      ...restQueryParams,
      ...datesParam,
      endTime: resolvedEndTime,
      startTime: formattedStartTimeParam,
    };

    const queryString = qs.stringify(queryParams);

    dispatch(replace(`${location.pathname}?${queryString}`));
  }, [dispatch, locale, restQueryParams, selectedEndTimeItem, selectedStartTimeItem, startDate, startDates]);

  const { onSelectStartTime, onSelectEndTime } = useResourceDetailTimeSelectorActions({
    selectedEndTimeItem,
    getEndTimeItems,
    setSelectedStartTimeItem,
    setSelectedEndTimeItem,
    setEndTimeItems,
    updateQueryParams,
  });

  const {
    onStartTimePickerOutsideClick,
    onEndTimePickerOutsideClick,
    onStartItemClick,
    onEndItemClick,
    ...mobileTimePickerDropdownValues
  } = useMobileTimePickerDropdowns({
    startTime: selectedStartTimeItem?.value,
    endTime: selectedEndTimeItem?.value,
    toggleOverlay,
    shouldUpdateQueryParams: false,
  });

  useEffect(() => {
    updateQueryParams();
  }, [onStartTimePickerOutsideClick, onEndTimePickerOutsideClick, onStartItemClick, onEndItemClick]);

  return {
    onSelectStartTime,
    onSelectEndTime,
    defaultValueIndex: startTimeItems.indexOf(selectedStartTimeItem),
    selectedStartTimeLabel: selectedStartTimeItem?.label,
    selectedEndTimeLabel: selectedEndTimeItem?.label,
    startTime: selectedStartTimeItem?.value,
    endTime: selectedEndTimeItem?.value,
    endTimeValues: endTimeItems,
    startTimeValues: startTimeItems,
    defaultValues: { defaultStartValue, defaultEndValue },
    onStartTimePickerOutsideClick,
    onEndTimePickerOutsideClick,
    onStartItemClick,
    onEndItemClick,
    ...mobileTimePickerDropdownValues,
  };
};
