import React from 'react';
import { StyledCalendarView } from './styles';
import { useCalendar } from '../use-calendar.hook';
import { CalendarViewProps } from '../interface';

export const CalendarView = ({ indexOfMonth }: CalendarViewProps): JSX.Element => {
  const { onDateClick, datesMonth, title } = useCalendar(indexOfMonth);

  return (
    <StyledCalendarView
      showDayLabels={false}
      title={title}
      data={datesMonth}
      onDateClick={onDateClick}
      showRightArrow={false}
      isFlexStyle
    />
  );
};
