export const getDateObjectFromTimeString = (time: string): Date | null => {
  if (!time) {
    return null;
  }

  const [hours, minutes] = time.split(':').map(Number);
  const dateWithTime = new Date();
  dateWithTime.setUTCHours(hours, minutes, 0, 0);

  return dateWithTime;
};
