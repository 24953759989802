import { CreateCartDto, CreateCartResponse, CompleteCartDto, Order, SubmitOrderDto, TransactionObject } from './types';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { FailureActionPayload } from 'store/errors/types';

export const createCart = createAsyncAction('CREATE_CART_REQUEST', 'CREATE_CART_SUCCESS', 'CREATE_CART_FAILURE')<
  CreateCartDto,
  CreateCartResponse,
  FailureActionPayload
>();

export const resetCreateCartStatus = createAction('RESET_CREATE_CART_STATUS')<undefined>();

export const getCart = createAsyncAction('GET_CART_REQUEST', 'GET_CART_SUCCESS', 'GET_CART_FAILURE')<
  string,
  Order,
  FailureActionPayload
>();

export const submitCart = createAsyncAction('SUBMIT_CART_REQUEST', 'SUBMIT_CART_SUCCESS', 'SUBMIT_CART_FAILURE')<
  SubmitOrderDto,
  Order,
  SerializableError
>();

export const complete3DSCart = createAsyncAction(
  'COMPLETE_3DS_CART_REQUEST',
  'COMPLETE_3DS_CART_SUCCESS',
  'COMPLETE_3DS_CART_FAILURE',
)<CompleteCartDto, TransactionObject, FailureActionPayload>();

export const resetComplete3DSCart = createAction('COMPLETE_3DS_CART_RESET')<void>();

export const resetSubmitCartStatus = createAction('RESET_SUBMIT_CART_STATUS')<void>();

export const resetGetCartStatus = createAction('RESET_GET_CART_STATUS')<void>();

export const resetCart = createAction('RESET_CART')<void>();
