import React, { useCallback } from 'react';
import { DateFilterContainer, DateFilterHeader, DateFilterTitle, DateFilterValue } from './styles';
import { useDatePicker } from './use-date-filter.hook';
import { useIntl } from 'react-intl';
import { DatePicker } from 'components/date-picker';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks';

export interface DateFilterProps {
  toggleOverlay: VoidFunction;
  dateRef?: React.MutableRefObject<HTMLDivElement>;
}

export const DateFilter = ({ toggleOverlay, dateRef }: DateFilterProps): JSX.Element => {
  const intl = useIntl();
  const { value: isOpened, setTrue: setIsOpenedTrue, setFalse: setIsOpenedFalse } = useBooleanState(false);

  const { value, formattedDate, minDate, buttons, locale, onChangeHandler, startDatesValue } = useDatePicker({
    setIsOpenedFalse,
  });

  const openCalendar = useCallback(() => {
    setIsOpenedTrue();
    dateRef.current.scrollIntoView();
    toggleOverlay();
  }, [toggleOverlay, setIsOpenedTrue, dateRef]);

  const closeCalendar = useCallback(() => {
    setIsOpenedFalse();
    toggleOverlay();
  }, [toggleOverlay, setIsOpenedFalse]);

  return (
    <DateFilterContainer>
      <DateFilterHeader>
        <DateFilterTitle>{intl.formatMessage({ id: 'date' })}</DateFilterTitle>
        <DateFilterValue>{formattedDate || intl.formatMessage({ id: 'selectDate' })}</DateFilterValue>
      </DateFilterHeader>
      <DatePicker
        value={value}
        minDate={minDate}
        locale={locale}
        buttons={buttons}
        onChange={onChangeHandler}
        isOpened={isOpened}
        setIsOpenedTrue={openCalendar}
        setIsOpenedFalse={closeCalendar}
        dateRef={dateRef}
        activeDays={startDatesValue}
        formattedDate={formattedDate}
      />
    </DateFilterContainer>
  );
};
