import React from 'react';

import { SelectedDateFilter } from 'components/selectedDateFilter/selected-date-filter';
import { SelectedTimeFilter } from 'components/selectedTimeFilter/selected-time-filter';
import { StyledDot } from 'pages/resource/styles';
import { bookingTimeHandler } from 'utils/bookingTimeHandler';
import { useLocale } from 'hooks/use-locale.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { ArrowIcon, Container, DatesPill, StyledText } from './styles';
import { searchParams } from 'pages/date-select-page/interface';
import { useIntl } from 'react-intl';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocation } from 'react-router-dom';
import useKioskEnabled from 'store/kiosk/hooks/use-kiosk-enabled';

interface DateTimeLinksProps {
  onDateClick?: VoidFunction;
  onTimeClick?: VoidFunction;
  toggleMultipleBookingsVisibility: VoidFunction;
  isMultipleBookingsVisible: boolean;
  isSelectedDateTimeFilterTextOnly?: boolean;
}

export const DateTimeLinks = ({
  onDateClick,
  onTimeClick,
  isSelectedDateTimeFilterTextOnly,
  toggleMultipleBookingsVisibility,
  isMultipleBookingsVisible,
}: DateTimeLinksProps): JSX.Element => {
  const intl = useIntl();
  const locale = useLocale();
  const { showResourceDetailsDateTimeInputs } = useFlags();

  const { startDate, startTime, endTime, startDates } = useSearchParams<searchParams>();
  const { pathname } = useLocation();
  const isKiosk = useKioskEnabled();
  const formattedStartDates = startDates ? startDates.split(',') : [];
  const isResourcesPage = pathname.includes('/resources');

  const timeRange = {
    startTime: startTime || null,
    endTime: endTime || null,
    locale,
  };

  const SelectedDateComponent =
    showResourceDetailsDateTimeInputs && !isResourcesPage ? (
      <StyledText data-testid="selected-date-text">
        {bookingTimeHandler(timeRange).getDateRangeString(startDate)}
      </StyledText>
    ) : (
      <SelectedDateFilter
        isSelectedDateTimeFilterTextOnly={isSelectedDateTimeFilterTextOnly}
        date={bookingTimeHandler(timeRange).getDateRangeString(startDate)}
        dateNotSelected={!startDate}
        onDateClick={onDateClick}
      />
    );

  const SelectedTimeComponent =
    showResourceDetailsDateTimeInputs && !isResourcesPage ? (
      <StyledText isKiosk={isKiosk} data-testid="selected-time-text">
        {bookingTimeHandler(timeRange).getTimeRangeString()}{' '}
      </StyledText>
    ) : (
      <SelectedTimeFilter
        isSelectedDateTimeFilterTextOnly={isSelectedDateTimeFilterTextOnly}
        onTimeClick={onTimeClick}
        timeNotSelected={!(startTime || endTime)}
        timeRange={bookingTimeHandler(timeRange).getTimeRangeString()}
      />
    );

  return (
    <Container>
      {!isKiosk && (
        <>
          {formattedStartDates.length ? (
            <DatesPill>{intl.formatMessage({ id: 'days_pill' }, { count: formattedStartDates.length })}</DatesPill>
          ) : (
            SelectedDateComponent
          )}
          <StyledDot showResourceDetailsDateTimeInputs={showResourceDetailsDateTimeInputs} />
        </>
      )}
      {SelectedTimeComponent}
      {!!formattedStartDates.length && (
        <ArrowIcon isMultipleBookingsVisible={isMultipleBookingsVisible} onClick={toggleMultipleBookingsVisibility} />
      )}
    </Container>
  );
};
