import { StyledChipComponent } from 'pages/resources/filter-bar/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import { useCapacityPill } from './use-capacity-pill.hook';

export const CapacityPill = (): JSX.Element => {
  const intl = useIntl();
  const { capacityEncoded, onCancelClick } = useCapacityPill();
  if (!capacityEncoded) return null;

  const pillLabel = `${capacityEncoded.toString()} ${intl.formatMessage({ id: 'capacity' })}`;

  return (
    <StyledChipComponent
      label={pillLabel}
      value={capacityEncoded.toString()}
      isActive
      cancelEnabled
      onCancel={onCancelClick}
    />
  );
};
