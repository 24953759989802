import React, { useCallback } from 'react';
import { useMiniappSdkClientWithoutInit } from 'components/miniapp-sdk-provider/miniapp-sdk.hook';
import { SDK_APP_UUID } from 'shared/consts';

import { Page, Content, Button, Title, Description, Icon } from './styles';

const ErrorPageWithNoDependencies = () => {
  const sdkClient = useMiniappSdkClientWithoutInit(SDK_APP_UUID);
  const handleClick = useCallback(() => {
    sdkClient.close();
  }, [sdkClient]);

  return (
    <Page>
      <Content>
        <Icon icon={['far', 'exclamation-triangle']} />
        <Title>Something went wrong</Title>
        <Description>An unexpected error occurred. Please try again later.</Description>
        <Button onClick={handleClick}>Go back</Button>
      </Content>
    </Page>
  );
};

export default ErrorPageWithNoDependencies;
