import React from 'react';
import { Container, Question, Answer } from './styles';

interface ReceiptUserResponseProps {
  response: FormResponse;
}

export const ReceiptUserResponse = ({ response }: ReceiptUserResponseProps): JSX.Element => {
  return (
    <Container>
      <Question>{response.question}</Question>
      <Answer>{response.answer}</Answer>
    </Container>
  );
};
