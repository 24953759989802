import { ArrowLeftChevron } from '@hqo/react-components-library/dist/icons';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';
import styled, { css } from 'styled-components';

interface ContainerProps {
  wrapping?: 'internalModal' | 'externalModal';
}

const containerStyles: Record<NonNullable<ContainerProps['wrapping']>, ReturnType<typeof css>> = {
  internalModal: css`
    padding: 16px;
    height: 100%;
  `,
  externalModal: css`
    padding: 0;
    height: calc(100vh - 92px);
  `,
};

export const Title = styled.div`
  font: 700 24px/24px ${({ theme }) => theme.fonts.join()};
  margin-bottom: 20px;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    margin-top: 25px;
  }
`;

export const Description = styled.div`
  font: 400 16px/22px ${({ theme }) => theme.fonts.join()};
`;

export const BoldText = styled.strong`
  font-weight: 500;
`;

export const Content = styled.div``;

export const Container = styled.div<ContainerProps>`
  ${({ wrapping }) => containerStyles[wrapping]}
  box-sizing: border-box;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const BackArrowIcon = styled(ArrowLeftChevron)`
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
  margin-bottom: 14px;
  cursor: pointer;
`;
