import { useCallback } from 'react';
import moment, { Moment } from 'moment-timezone';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useDispatch } from 'react-redux';
import qs from 'qs';
import { replace } from 'store/router/actions';
import { CalendarDateInfo, searchParams, UseCalendarReturnValues } from './interface';
import { bookingTimeHandler } from 'utils/bookingTimeHandler';
import { useLocale } from 'hooks/use-locale.hook';
import { isAvailableMonth } from 'utils/isAvailableMonth';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';
import { toggleStringElement } from 'utils/toggleStringElement';
import { BOOKING_DAYS_LIMIT, CalendarDateStatuses } from 'shared/consts/const';

export const getDates = (startDate: string, stopDate: string): string[] => {
  const dateArray = [];
  const currentDate = moment(new Date(startDate.replace(/-/g, '/')));
  const stopDateMoment = moment(new Date(stopDate.replace(/-/g, '/')));
  while (currentDate.isSameOrBefore(stopDateMoment)) {
    dateArray.push(currentDate.format('YYYY-MM-DD'));
    currentDate.add(1, 'days');
  }

  return dateArray;
};

export const useCalendar = (indexOfMonth: number): UseCalendarReturnValues => {
  const { startDate, startDates, duration, ...restQueryParams } = useSearchParams<searchParams>();
  const formattedStartDates = startDates ? startDates.split(',') : [];
  const currentDate = new Date();
  const { isMultiDayBookingEnabled } = useAppInstanceConfigFeature();

  const locale = useLocale();
  const timezone = moment.tz.guess();
  const month = moment().tz(timezone).clone().add(indexOfMonth, 'M');
  const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + indexOfMonth, 1);
  const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1 + indexOfMonth, 0);
  const firstDayTime = firstDay.toLocaleTimeString(locale, { hour: 'numeric', minute: 'numeric' });
  const lastDayTime = lastDay.toLocaleTimeString(locale, { hour: 'numeric', minute: 'numeric' });
  const timeRange = { startTime: firstDayTime, endTime: lastDayTime, locale };
  const formattedFirstDay = bookingTimeHandler(timeRange).getFormatDate(firstDay, 'YYYY-MM-DD').replace(/\./g, '');
  const formattedLastDay = bookingTimeHandler(timeRange).getFormatDate(lastDay, 'YYYY-MM-DD').replace(/\./g, '');
  const title = bookingTimeHandler(timeRange).getFormatDate(firstDay, 'MMMM YYYY');
  const dispatch = useDispatch();
  const isCartOverloaded = formattedStartDates.length >= BOOKING_DAYS_LIMIT;

  const setDateStatus = useCallback(
    (day: Moment, status: CalendarDateStatuses): CalendarDateStatuses => {
      const formattedDay = day.format('YYYY-MM-DD');

      if (formattedStartDates.length) {
        return formattedStartDates.some(date => date === formattedDay) ? CalendarDateStatuses.ACTIVE : status;
      }

      return startDate === formattedDay ? CalendarDateStatuses.ACTIVE : status;
    },
    [startDate, formattedStartDates],
  );

  const getAvailableDates = useCallback(
    (dates: string[]): CalendarDateInfo[] => {
      const firstDayOfMonth = month.clone().startOf('month').startOf('week');
      const lastDayOfMonth = month.clone().endOf('month').endOf('week');
      const data: CalendarDateInfo[] = [];
      const day = firstDayOfMonth.clone();

      while (day.isSameOrBefore(lastDayOfMonth)) {
        let status: CalendarDateStatuses = CalendarDateStatuses.AVAILABLE;
        if (isCartOverloaded) {
          status = CalendarDateStatuses.UNAVAILABLE;
        } else if (
          !dates.some(
            date => moment(date).isSame(day, 'day') && isAvailableMonth(moment(date).month(), month?.month()),
          ) ||
          moment(day).isBefore(moment())
        ) {
          status = CalendarDateStatuses.UNAVAILABLE;
        }
        if (moment(day).isSame(moment(), 'day')) {
          status = CalendarDateStatuses.CURRENT;
        }
        data.push({
          date: +day.format('DD'),
          month: +day.format('MM'),
          year: +day.format('YYYY'),
          status: setDateStatus(day, status),
        });
        day.add(1, 'day');
      }

      return data.map(date => (date.month !== firstDay.getMonth() + 1 ? ({} as CalendarDateInfo) : date));
    },
    [startDate, setDateStatus, isCartOverloaded],
  );

  const getQueryString = useCallback(
    (selectedDate: string) => {
      if (formattedStartDates.length && !startDate) {
        const selectedDates = toggleStringElement(formattedStartDates, selectedDate);
        const queryParams =
          selectedDates.length === 1
            ? { ...restQueryParams, startDate: selectedDates[0] }
            : { ...restQueryParams, startDates: selectedDates.join(',') };

        return qs.stringify(queryParams);
      }

      if (startDate && !formattedStartDates.length && isMultiDayBookingEnabled && selectedDate !== startDate) {
        const selectedDates = [startDate, selectedDate];

        return qs.stringify({ ...restQueryParams, startDates: selectedDates.join(',') });
      }

      return qs.stringify({ ...restQueryParams, startDate: selectedDate });
    },
    [startDate, formattedStartDates, isMultiDayBookingEnabled, restQueryParams],
  );

  const onDateClick = useCallback(
    (_: any, data: any) => {
      const date = `${data.year}/${data.month}/${data.date}`;
      const formattedDateValue = bookingTimeHandler(timeRange)
        .getFormatDate(new Date(date), 'YYYY-MM-DD')
        .replace(/\./g, '');
      const queryString = getQueryString(formattedDateValue);

      if (isCartOverloaded && !formattedStartDates.includes(formattedDateValue)) return;

      dispatch(replace(`${location.pathname}?${queryString}`));
    },
    [restQueryParams, dispatch, timeRange, getQueryString, isCartOverloaded, formattedStartDates],
  );

  const datesMonth = getAvailableDates(getDates(formattedFirstDay, formattedLastDay));

  return {
    onDateClick,
    datesMonth,
    title,
  };
};
