import { useSearchParams } from 'hooks/use-search-params.hook';
import { TILE_BUTTON_TYPE } from 'pages/resources/content/tile-button/tile-button.enum';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { push } from 'store/router/actions';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import { useOwnerParams } from './use-owner-params.hook';
import { getResource } from 'store/resource/actions';
import { useLocation } from 'react-router';
import { resourceState, selectGetResourceStatus } from 'store/resource/selectors';
import { ACTION_STATUSES, RESOURCE_PATH, TRACK_EVENT_SOURCES } from 'shared/consts';
import { getPrice } from 'store/price/actions';
import moment from 'moment-timezone';
import { GetPriceParams } from 'store/price/types';
import { selectPriceStatus } from 'store/price/selectors';
import { useOnSubmitBookingRequest } from 'pages/schedule-select-page/components/use-on-submit-booking-request.hook';
import { selectCurrentRoomLayout } from '../store/room-layout/selectors';
import { selectAllAddOns } from '../store/add-ons/selectors';
import { resetResourceAvailableDates } from 'store/resource-available-dates/actions';

interface UseResourceTileProps {
  toggleReverseAnimation: VoidFunction;
  toggleModalSlideAnimation: VoidFunction;
  isAvailable?: boolean;
}

export const useResourceTile = ({
  toggleReverseAnimation,
  toggleModalSlideAnimation,
  isAvailable,
}: UseResourceTileProps) => {
  const trackingSource = TRACK_EVENT_SOURCES.FLOOR_PLAN;
  const { ownerType, ownerUuid } = useOwnerParams();
  const { startTime, endTime, startDate, step, ...restQueryParams } = useSearchParams();
  const [isBookButtonClicked, setIsBookButtonClick] = useState<boolean>(false);
  const onSubmitBookingRequest = useOnSubmitBookingRequest();
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const { resource } = useSelector(resourceState);
  const hasAdditionalInfo = !!resource?.user_prompts?.length;
  const getResourceStatus = useSelector(selectGetResourceStatus);
  const getPriceStatus = useSelector(selectPriceStatus);
  const isPaidResource = useMemo<boolean>(
    () => !!(resource?.booking_windows?.some(({ pricing_rules }) => pricing_rules?.length) || resource?.minimum_price),
    [resource?.booking_windows, resource?.minimum_price],
  );
  const currentRoomLayout = useSelector(selectCurrentRoomLayout);
  const selectedAddOns = useSelector(selectAllAddOns);
  const currentStep = useMemo((): number => {
    if (startDate && (!startTime || !endTime)) {
      return 1;
    }
    if (!startDate) {
      return 0;
    }

    return 0;
  }, [endTime, startDate, startTime]);

  useEffect(() => {
    if (isPaidResource && startTime && endTime && startDate) {
      dispatch(
        getPrice.request({
          ownerType,
          ownerUuid,
          resourceId: resource?.id,
          from: moment(`${startDate} ${startTime}`).tz(resource?.timezone, true)?.utc().format(),
          to: moment(`${startDate} ${endTime}`).tz(resource?.timezone, true)?.utc().format(),
        } as unknown as GetPriceParams),
      );
    }
  }, [dispatch, endTime, isPaidResource, ownerType, ownerUuid, resource?.id, resource?.timezone, startDate, startTime]);

  useEffect(() => {
    if (getResourceStatus === ACTION_STATUSES.FULFILLED && isBookButtonClicked) {
      if (hasAdditionalInfo) {
        setIsBookButtonClick(false);
        dispatch(push(`${RESOURCE_PATH}/${resource.uuid}/additional-information${search}&source=${trackingSource}`));
      } else if ((isPaidResource && getPriceStatus === ACTION_STATUSES.FULFILLED) || !isPaidResource) {
        setIsBookButtonClick(false);
        onSubmitBookingRequest({ currentRoomLayout, selectedAddOns });
      }
    }
  }, [
    dispatch,
    getPriceStatus,
    getResourceStatus,
    hasAdditionalInfo,
    isBookButtonClicked,
    isPaidResource,
    onSubmitBookingRequest,
    pathname,
    resource?.uuid,
    search,
    currentRoomLayout,
    selectedAddOns,
  ]);

  const buttonType = isAvailable ?? (startTime && endTime) ? TILE_BUTTON_TYPE.BOOK : TILE_BUTTON_TYPE.VIEW_AVAILABILITY;

  const onButtonClick = useCallback(
    (resourceUuid: string, ignoreDateTimeParams = false) => {
      const queryString = qs.stringify({
        ...(!ignoreDateTimeParams && { startTime, endTime, startDate }),
        step: currentStep,
        ...restQueryParams,
      });
      dispatch(getResource.request({ ownerUuid, ownerType, resourceUuid }));
      dispatch(resetResourceAvailableDates());
      if (buttonType === TILE_BUTTON_TYPE.VIEW_AVAILABILITY) {
        toggleReverseAnimation();
        toggleModalSlideAnimation();
        dispatch(push(`${pathname}/date-schedule-select?${queryString}`));
      } else {
        setIsBookButtonClick(true);
      }
    },
    [
      step,
      startTime,
      endTime,
      startDate,
      currentStep,
      restQueryParams,
      dispatch,
      ownerUuid,
      ownerType,
      buttonType,
      toggleReverseAnimation,
      toggleModalSlideAnimation,
      pathname,
    ],
  );

  return { buttonType, onButtonClick };
};
