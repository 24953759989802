import { GetKioskEnabledPayload } from '@hqo/hqo-miniapp-client-sdk';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useMiniappSdk, useMiniappSdkEventHandler } from 'components/miniapp-sdk-provider/miniapp-sdk.hook';
import { disableKiosk, enableKiosk } from 'store/kiosk/actions';

export default function useSyncKioskWithHostApp() {
  const client = useMiniappSdk();
  const dispatch = useDispatch();

  const onKioskChange = useCallback(
    (enabled: boolean) => {
      if (enabled) {
        dispatch(enableKiosk());
      } else {
        dispatch(disableKiosk());
      }
    },
    [dispatch],
  );

  useMiniappSdkEventHandler(
    'getKioskEnabledResponse',
    (data: GetKioskEnabledPayload) => {
      onKioskChange(data.enabled);
    },
    [],
  );

  useEffect(() => {
    client.kiosk.getKioskEnabled();
  }, [client]);
}
