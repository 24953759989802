import { ButtonContainer, DoneButton } from './styles';

import React from 'react';

interface DoneButtonBlockProps {
  onClick: VoidFunction;
  text: string;
}

export const DoneButtonBlock = ({ onClick, text }: DoneButtonBlockProps) => {
  return (
    <ButtonContainer>
      <DoneButton data-testid="done-button" onClick={onClick} text={text} />
    </ButtonContainer>
  );
};
