import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const HeaderTitle = styled.div`
  color: ${({ theme }) => theme.colors.palette.systemBlack};
  font: 500 28px/32px ${({ theme }) => theme.fonts.join()};
`;

export const StyledBackLink = styled.div`
  padding-left: 52px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 55px 52px 35px 52px;
  width: 100%;
  gap: 15px;
  align-items: flex-start;
  border-bottom: 2px solid ${({ theme }) => theme.colors.greys.adminGreyMedium};
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const StyledCalendarIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.palette.systemBlack};
  width: 21px;
  height: 25px;
`;

export const RightPane = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  user-select: none;
`;

export const LeftPane = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.palette.systemBlack};
  font: 500 16px / normal ${({ theme }) => theme.fonts.join()};
`;
