export interface TokenCard {
  card_brand: string;
  card_cvc_check?: string;
  card_exp_month: string;
  card_exp_year: string;
  card_last_four: string;
  payment_method_address_city: string;
  payment_method_address_country: string;
  payment_method_address_line1: string;
  payment_method_address_line2?: string;
  payment_method_address_state: string;
  payment_method_address_zip: string;
  payment_method_country: string;
  payment_method_name: string;
  payment_method_tokenization_method?: string;
  payment_method_type: string;
}

export interface SavedPaymentMethod extends TokenCard {
  card_id: string;
  created_at: string;
  deleted_at?: string;
  id: number;
  default?: boolean;
  payment_method_stripe_source_id?: string;
  updated_at: string;
  payment_token?: string;
  user_id: number;
  points_balance?: number;
}

export const CREDIT_POINTS_METHOD_ID = '-3';

export enum PaymentMethodTypes {
  INVOICE = 'INVOICE',
  CREDIT_CARD = 'credit_card',
  APPLE_PAY = 'APPLE_PAY',
  CREDIT_POINTS = 'CREDIT_POINTS',
}

export interface PaymentState {
  paymentMethods: Array<SavedPaymentMethod>;
  currentPaymentMethodId: string;
  getPaymentsMethods: AsyncActionTypes;
  deletePaymentMethod: AsyncActionTypes;
  savePaymentMethod: AsyncActionTypes;
  updatePaymentMethod: AsyncActionTypes;
}

export interface SavePaymentMethodRequest {
  id?: number;
  user_id?: number;
  payment_method_type?: string;
  payment_method_stripe_source_id?: string;
  payment_method_tokenization_method?: string;
  payment_method_name?: string;
  payment_method_address_city?: string;
  payment_method_address_country?: string;
  payment_method_address_line1?: string;
  payment_method_address_line2?: string;
  payment_method_address_state?: string;
  payment_method_address_zip?: string;
  payment_method_country?: string;
  card_brand?: string;
  card_cvc_check?: string;
  card_last_four?: string;
  card_exp_month?: string;
  card_exp_year?: string;
  card_id?: string;
  payment_gateway_id?: number;
  payment_token?: string;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}

export interface DeletePaymentMethodRequest {
  paymentMethodId: number;
  paymentMethods: Array<SavedPaymentMethod>;
}

export interface UpdatePaymentMethodRequest extends SavePaymentMethodRequest {
  default?: boolean;
  paymentMethodId: number;
}

export interface SavePaymentMethodResponse extends SavePaymentMethodRequest {
  id?: number;
  status?: string;
  message?: string;
}
