import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTransactions,
  resetCurrentTransaction,
  resetPatchTransactionStatus,
  resetRedirectTransaction,
  resetTransaction,
} from 'store/transactions/actions';
import { useLocation } from 'react-router';
import { LANDING_PATH, PATCH_STATUSES, QUERY_PARAMS } from 'shared/consts';
import qs from 'qs';
import { pick } from 'utils/pickObjectProperty';
import { goBack, push, replace } from 'store/router/actions';
import { useSearchParams } from '../use-search-params.hook';
import { searchParams } from 'pages/date-select-page/interface';
import { resetCart, resetSubmitCartStatus } from 'store/cart/actions';
import { selectTypeOfPatchTransaction } from 'store/transactions/selectors';
import { updateInitialRoute } from 'store/routes/actions';

interface UseReceiptScreenReturnValues {
  isCancelBookingStep: boolean;
  setIsCancelBookingStep: React.Dispatch<React.SetStateAction<boolean>>;
  onCloseReceipt: VoidFunction;
  onPressBack: VoidFunction;
  openReceipt: VoidFunction;
}

export const useReceiptScreen = (): UseReceiptScreenReturnValues => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [isCancelBookingStep, setIsCancelBookingStep] = useState<boolean>(false);
  const { ...restQueryParams } = useSearchParams<searchParams>();
  const typeOfPatchTransaction = useSelector(selectTypeOfPatchTransaction);

  const onCloseReceipt = useCallback(() => {
    if (pathname === LANDING_PATH || pathname.includes('bookings')) {
      dispatch(goBack());
    } else {
      if (typeOfPatchTransaction === PATCH_STATUSES.CANCELLED) {
        dispatch(getTransactions.request({}));
      }
      const queryString = qs.stringify(
        pick(
          restQueryParams,
          QUERY_PARAMS.filter(
            param =>
              param !== 'startTime' &&
              param !== 'startDate' &&
              param !== 'endTime' &&
              param !== 'isExact' &&
              param !== 'duration' &&
              param !== 'presetWindows' &&
              param !== 'startDates' &&
              param !== 'capacity',
          ),
        ),
      );

      dispatch(updateInitialRoute(LANDING_PATH));
      dispatch(replace(`/?${queryString}`));
    }
    dispatch(resetPatchTransactionStatus());
    dispatch(resetTransaction());
    dispatch(resetRedirectTransaction());
    dispatch(resetSubmitCartStatus());
    dispatch(resetCurrentTransaction());
    dispatch(resetCart());
    dispatch(getTransactions.request({}));
  }, [dispatch, pathname, restQueryParams, typeOfPatchTransaction]);

  const openReceipt = useCallback(() => {
    const queryString = qs.stringify(restQueryParams);
    if (pathname === LANDING_PATH) {
      dispatch(push(`receipt?${queryString}`));
    } else if (pathname.includes('bookings')) {
      dispatch(push(`${pathname}/receipt?${queryString}`));
    } else {
      dispatch(replace(`${pathname.replace('checkout', 'receipt')}?${queryString}`));
    }
  }, [dispatch, pathname, restQueryParams]);

  const onPressBack = useCallback(() => {
    setIsCancelBookingStep(false);
  }, []);

  return {
    isCancelBookingStep,
    setIsCancelBookingStep,
    onCloseReceipt,
    onPressBack,
    openReceipt,
  };
};
