import { Item } from 'store/cart/types';

export const isBookingsForOneResource = (items: Array<Item>): boolean => {
  if (items?.length <= 1 || !items) {
    return false;
  }

  const firstBookingName = items[0].resource_booking.name;

  return items.every(booking => booking.resource_booking.name === firstBookingName);
};
