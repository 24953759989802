export const formatCurrency = (
  currencyValue: number,
  currencyType: string,
  locale: string,
  showCents = true,
  isDiscount = false,
): string => {
  const value = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyType,
    minimumFractionDigits: !showCents && Number.isInteger(currencyValue / 100) ? 0 : 2,
  }).format(currencyValue / 100);

  return isDiscount ? `-${value}` : value;
};
