import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getResourceTimeRanges, resetResourceTimeRanges } from 'store/resource-time-ranges/actions';
import { goBack, push, replace } from 'store/router/actions';
import { searchParams } from 'pages/date-select-page/interface';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { DateTimeSteps } from 'hooks/use-touch-search-bar.hook';
import qs from 'qs';
import { resolveGetResourceTimeRangesParams } from '../utils';
import { OwnerTypesEnum } from 'store/app-instance-configs/enums';
import moment from 'moment/moment';
import { transformToSortedDatesList } from '../utils/formatDate';
import { resourceState } from 'store/bookings/selectors';
import { useOwnerParams } from './use-owner-params.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { removeQueryParams } from 'utils/removeQueryParams';

interface UseDateScheduleModalNavigationReturnValues {
  reverseAnimation: boolean;
  toggleModal: VoidFunction;
  onNextClick: VoidFunction;
  onTimeLinkClick: VoidFunction;
  onDateLinkClick: VoidFunction;
  isDateStep: boolean;
  onPressSave: VoidFunction;
  onPressBack: VoidFunction;
  backToDateStep: boolean;
  isModalWithoutSlideAnimation: boolean;
  reverseHorizontalAnimation: boolean;
  toggleReverseAnimation: VoidFunction;
  toggleModalSlideAnimation: VoidFunction;
  onDateClick: VoidFunction;
  onDateTimeSaveClick: VoidFunction;
}

export const useDateScheduleModalNavigation = (): UseDateScheduleModalNavigationReturnValues => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { resource, initialQueryString } = useSelector(resourceState);
  const { ownerType } = useOwnerParams();
  const { showResourceDetailsDateTimeInputs } = useFlags();
  const { step, isReverseAnimation, startDates, ...restQueryParams } = useSearchParams();
  const { startDate } = useSearchParams<searchParams>();
  const sortedDates = !!startDates && transformToSortedDatesList(startDates as string);
  const date = startDate || sortedDates?.[0];
  const { value: reverseAnimation, toggle: toggleReverseAnimation } = useBooleanState(!isReverseAnimation);
  const initialReverseHorizontalAnimation = useMemo(
    () => !(isReverseAnimation && step === '1'),
    [isReverseAnimation, step],
  );
  const { value: reverseHorizontalAnimation, toggle: toggleReverseHorizontalAnimation } = useBooleanState(
    initialReverseHorizontalAnimation,
  );
  const { value: isModalWithoutSlideAnimation, toggle: toggleModalSlideAnimation } = useBooleanState(
    !!isReverseAnimation,
  );
  const { value: backToDateStep, toggle: toggleBackToDateStep } = useBooleanState(false);
  const [currentStep, setCurrentStep] = useState<DateTimeSteps>(DateTimeSteps.DateSelectPage);
  const isDateStep = currentStep === DateTimeSteps.DateSelectPage;
  const isResourceDetailsDateTimeInputsVisible = showResourceDetailsDateTimeInputs && !pathname.includes('resources');

  const onDateClick = useCallback(() => {
    toggleReverseAnimation();
    if (!isModalWithoutSlideAnimation) {
      toggleModalSlideAnimation();
    }
    const params = qs.stringify({ ...restQueryParams, ...(startDates && { startDates }), step: 0 });
    dispatch(push(`${pathname}/date-schedule-select?${params}`));
  }, [
    startDates,
    toggleReverseAnimation,
    isModalWithoutSlideAnimation,
    toggleModalSlideAnimation,
    restQueryParams,
    dispatch,
    pathname,
  ]);

  useEffect(() => {
    if (step === '0') {
      setCurrentStep(DateTimeSteps.DateSelectPage);
    } else if (step === '1') {
      setCurrentStep(DateTimeSteps.TimeSelectPage);
    } else {
      setCurrentStep(DateTimeSteps.DateSelectPage);
    }
  }, [step]);

  const onPressSave = useCallback(() => {
    if (showResourceDetailsDateTimeInputs && !pathname.includes('resources')) {
      const params = qs.stringify({ ...restQueryParams, startDate, ...(startDates && { startDates }) });
      dispatch(replace(`${pathname.replace('/date-schedule-select', '')}?${params}`));
    } else if (!showResourceDetailsDateTimeInputs && !pathname.includes('resources')) {
      toggleBackToDateStep();
      toggleReverseHorizontalAnimation();
    } else {
      toggleBackToDateStep();
      toggleReverseAnimation();
      toggleReverseHorizontalAnimation();
      toggleModalSlideAnimation();
    }
  }, [
    startDate,
    startDates,
    toggleBackToDateStep,
    toggleModalSlideAnimation,
    toggleReverseAnimation,
    toggleReverseHorizontalAnimation,
    pathname,
    restQueryParams,
    showResourceDetailsDateTimeInputs,
  ]);

  const onPressBack = useCallback(() => {
    toggleBackToDateStep();
    toggleReverseHorizontalAnimation();
    if (isDateStep) {
      dispatch(goBack());
    }
    const queryString = qs.stringify({ ...restQueryParams, ...(startDates && { startDates }), step: 0 });
    dispatch(replace(`${location.pathname}?${queryString}`));
  }, [dispatch, isDateStep, restQueryParams, startDates, toggleBackToDateStep, toggleReverseHorizontalAnimation]);

  const toggleModal = useCallback(() => {
    const updatedQueryString = removeQueryParams(initialQueryString, ['step']);
    toggleReverseAnimation();
    if (isModalWithoutSlideAnimation) {
      toggleModalSlideAnimation();
    }
    if (isDateStep) {
      dispatch(replace(`${pathname.replace('/date-schedule-select', '')}${updatedQueryString}`));
    } else {
      if (!reverseHorizontalAnimation) {
        toggleReverseHorizontalAnimation();
      }
      if (backToDateStep) {
        toggleBackToDateStep();
      }
      dispatch(replace(`${pathname.replace('/date-schedule-select', '')}${updatedQueryString}`));
      dispatch(resetResourceTimeRanges());
    }
  }, [
    initialQueryString,
    toggleReverseAnimation,
    isModalWithoutSlideAnimation,
    isDateStep,
    toggleModalSlideAnimation,
    dispatch,
    reverseHorizontalAnimation,
    backToDateStep,
    restQueryParams,
    toggleReverseHorizontalAnimation,
    toggleBackToDateStep,
  ]);

  const onNextClick = useCallback(() => {
    if (isModalWithoutSlideAnimation) {
      toggleModalSlideAnimation();
    }
    toggleReverseHorizontalAnimation();
    dispatch(resetResourceTimeRanges());
    toggleBackToDateStep();
    const queryString = qs.stringify({ ...restQueryParams, ...(startDates && { startDates }), step: 1 });
    dispatch(replace(`${location.pathname}?${queryString}`));
  }, [
    startDates,
    dispatch,
    isModalWithoutSlideAnimation,
    restQueryParams,
    toggleBackToDateStep,
    toggleModalSlideAnimation,
    toggleReverseHorizontalAnimation,
  ]);

  const onDateTimeSaveClick = useCallback(() => {
    const newPathname = pathname.replace('/date-schedule-select', '');
    const queryString = qs.stringify({ ...restQueryParams, ...(startDates && { startDates }) });
    const getResourceTimeRangesParams = resolveGetResourceTimeRangesParams({
      ownerType: ownerType as OwnerTypesEnum,
      resourceId: resource.id,
      start: date,
      end: moment(date).add(1, 'days').format('YYYY-MM-DD'),
      ...(startDates && { startDates }),
    });

    if (isDateStep && isResourceDetailsDateTimeInputsVisible) {
      dispatch(getResourceTimeRanges.request(getResourceTimeRangesParams));
    }

    dispatch(replace(`${newPathname}?${queryString}`));
  }, [
    dispatch,
    pathname,
    restQueryParams,
    startDates,
    date,
    ownerType,
    resource,
    isDateStep,
    isResourceDetailsDateTimeInputsVisible,
  ]);

  const onTimeLinkClick = useCallback(() => {
    if (currentStep === DateTimeSteps.TimeSelectPage && !pathname.includes('additional-information')) return;
    toggleReverseHorizontalAnimation();
    if (reverseAnimation) {
      toggleReverseAnimation();
    }
    if (isModalWithoutSlideAnimation) {
      toggleModalSlideAnimation();
    }
    if (pathname.includes('date-schedule-select')) {
      toggleBackToDateStep();
      const queryString = qs.stringify({ ...restQueryParams, ...(startDates && { startDates }), step: 1 });
      dispatch(replace(`${location.pathname}?${queryString}`));
    } else if (pathname.includes('additional-information')) {
      toggleModalSlideAnimation();
      const queryString = qs.stringify({
        ...restQueryParams,
        ...(startDates && { startDates }),
        step: 1,
        isReverseAnimation: true,
      });
      dispatch(push(`${pathname}/date-schedule-select?${queryString}`));
    } else if (!(startDate || startDates)) {
      toggleModalSlideAnimation();
      const queryString = qs.stringify({ ...restQueryParams, step: 0 });
      dispatch(push(`${pathname}/date-schedule-select?${queryString}`));
    } else {
      toggleModalSlideAnimation();
      const queryString = qs.stringify({ ...restQueryParams, ...(startDates && { startDates }), step: 1 });
      dispatch(push(`${pathname}/date-schedule-select?${queryString}`));
    }
  }, [
    currentStep,
    toggleReverseHorizontalAnimation,
    reverseAnimation,
    isModalWithoutSlideAnimation,
    pathname,
    startDate,
    startDates,
    toggleReverseAnimation,
    toggleModalSlideAnimation,
    toggleBackToDateStep,
    restQueryParams,
    dispatch,
  ]);

  const onDateLinkClick = useCallback(() => {
    if (currentStep === DateTimeSteps.DateSelectPage) return;
    dispatch(resetResourceTimeRanges());
    toggleReverseHorizontalAnimation();
    if (backToDateStep) {
      toggleBackToDateStep();
    }
    if (isModalWithoutSlideAnimation) {
      toggleModalSlideAnimation();
    }
    if (pathname.includes('additional-information') && !pathname.includes('date-schedule-select')) {
      const queryString = qs.stringify({ ...restQueryParams, step: 0, isReverseAnimation: true });
      dispatch(replace(`${pathname}/date-schedule-select?${queryString}`));
    } else {
      const queryString = qs.stringify({ ...restQueryParams, ...(startDates && { startDates }), step: 0 });
      dispatch(replace(`${location.pathname}?${queryString}`));
    }
  }, [
    currentStep,
    dispatch,
    toggleReverseHorizontalAnimation,
    backToDateStep,
    isModalWithoutSlideAnimation,
    pathname,
    toggleBackToDateStep,
    toggleModalSlideAnimation,
    restQueryParams,
    startDates,
  ]);

  return {
    reverseHorizontalAnimation,
    isModalWithoutSlideAnimation,
    onPressSave,
    reverseAnimation,
    toggleModal,
    onNextClick,
    onDateTimeSaveClick,
    onTimeLinkClick,
    onDateLinkClick,
    isDateStep,
    backToDateStep,
    onPressBack,
    toggleReverseAnimation,
    toggleModalSlideAnimation,
    onDateClick,
  };
};
