import styled from 'styled-components';

interface Props {
  disabled: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 24px 0 0 5px;
`;

export const SwitchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 14px;
`;

export const Title = styled.div<Props>`
  font: 500 14px/21px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.greys.sharedMediumGrey : theme.colors.greys.sharedDarkGrey};
`;

export const Subtitle = styled.div<Props>`
  font: 400 14px/21px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.greys.sharedMediumGrey : theme.colors.greys.sharedDarkGrey};
`;
