import { ActionType, createReducer } from 'typesafe-actions';
import { clearVisitors, setVisitors } from 'store/visitors-emails/actions';

import { VisitorsEmailsState } from './types';

export const initialState: VisitorsEmailsState = {};

const setVisitorsHandler = (
  _state: VisitorsEmailsState,
  { payload }: ActionType<typeof setVisitors>,
): VisitorsEmailsState => {
  return payload;
};

const clearVisitorsHandler = (): VisitorsEmailsState => initialState;

const visitorsEmailsReducer = createReducer(initialState)
  .handleAction(setVisitors, setVisitorsHandler)
  .handleAction(clearVisitors, clearVisitorsHandler);

export default visitorsEmailsReducer;
