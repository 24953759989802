import React, { useEffect } from 'react';
import { StyledModal, Container, Header, Title, FooterContainer } from './styles';
import { ModalPortal } from 'components/modals/modal-portal/modal-portal';

interface DateDesktopModalProps {
  children: React.ReactNode;
  onClose: VoidFunction;
  className: string;
  title: string;
  headerContent: React.ReactNode;
  footerContent: React.ReactNode;
}

export const DateDesktopModal = ({
  onClose,
  className,
  children,
  title,
  headerContent,
  footerContent,
}: DateDesktopModalProps): JSX.Element => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, []);

  return (
    <ModalPortal>
      <StyledModal opened onClose={onClose} dataTestId="modal-window" data-cy="modal-window">
        <Container className={className} data-testid={className}>
          <Title>{title}</Title>
          <Header>{headerContent}</Header>
          {children}
          <FooterContainer>{footerContent}</FooterContainer>
        </Container>
      </StyledModal>
    </ModalPortal>
  );
};
