import React, { useCallback } from 'react';

import { ErrorNotification } from '@hqo/react-components-library/dist/molecules/errorNotification';
import { ErrorWrapper, StyledButton } from './styles';
import { hideGenericErrorNotifications } from '../../store/errors/actions';
import { goBack } from 'store/router/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigationType } from 'react-router';
import { useIntl } from 'react-intl';
import { currentUser } from 'store/user/selectors';
import { ErrorDetails } from 'pages/error/components/errorDetails';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks/use-boolean-state';
import { selectIsReAuthenticateRequired } from 'store/errors/selectors';

interface ErrorPageProps {
  onClick?: VoidFunction;
}

export const ErrorPage = ({ onClick }: ErrorPageProps): JSX.Element => {
  const action = useNavigationType();
  const dispatch = useDispatch();
  const intl = useIntl();
  const user = useSelector(currentUser);
  const isReAuthenticateRequired = useSelector(selectIsReAuthenticateRequired);

  const isUserDeveloper = user?.roles?.some(role => role.name === 'hqo_dev');
  const { value: isDevInfoVisible, toggle: toggleDevInfo } = useBooleanState(false);
  const handleClick = useCallback(() => {
    if (action !== 'POP') {
      dispatch(goBack());
      dispatch(hideGenericErrorNotifications());
    }
  }, [action, dispatch]);

  const onDevInfoButtonClick = useCallback(() => {
    toggleDevInfo();
  }, [toggleDevInfo]);

  return (
    <ErrorWrapper>
      {isDevInfoVisible ? (
        <ErrorDetails toggleDevInfo={toggleDevInfo} />
      ) : (
        <>
          <ErrorNotification
            title={intl.formatMessage({ id: 'something_went_wrong' })}
            description={intl.formatMessage({
              id: isReAuthenticateRequired ? 'reSignInRequired' : 'unexpected_error_occurred',
            })}
            buttonText={intl.formatMessage({ id: 'go_back' })}
            onClick={onClick || handleClick}
            showButton={!isReAuthenticateRequired}
          />
          {isUserDeveloper && <StyledButton onClick={onDevInfoButtonClick} text="Show dev info" variant="danger" />}
        </>
      )}
    </ErrorWrapper>
  );
};
