import { Navigate, RouteProps } from 'react-router-dom';

import React from 'react';
import { hasToken } from 'store/config/selectors';
import { useCurrentUser } from 'hooks/use-current-user.hook';
import { useSelector } from 'react-redux';
import { Skeleton } from 'components/skeleton';

interface PrivateProps extends RouteProps {
  redirectTo?: string;
  children: React.ReactNode;
}
export const PrivateRoute: React.FC<PrivateProps> = ({
  redirectTo = '/',
  ...routeProps
}: PrivateProps): JSX.Element | null => {
  const [currentUser, loading] = useCurrentUser();
  const isTokenStored = useSelector(hasToken);

  if (currentUser) {
    return <>{routeProps.children}</>;
  }

  if (!isTokenStored && !loading) {
    return <Navigate to={redirectTo} />;
  }

  return <Skeleton />;
};
