import { useOwnerParams } from 'hooks/use-owner-params.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_STATUSES } from 'shared/consts';
import { getInfiniteResources } from 'store/resources/actions';
import {
  selectGetInfiniteResourcesStatus,
  selectInfiniteResources,
  selectInfiniteResourcesTotal,
  selectResources,
} from 'store/resources/selectors';
import { FilterParams } from 'store/resources/types';
import { getFilterParams } from 'utils';
import { RESOURCE_TILE_HEIGHT } from './infinite-content.enums';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { getDesktopOffsetElements } from 'pages/resources/utils/getDesktopOffsetElements';

export const useInfiniteContent = (
  scrollContainerRef: React.MutableRefObject<HTMLDivElement>,
): { limitOfElements: number } => {
  const isMobileDevice = useIsSmallViewportWidth();
  const dispatch = useDispatch();
  const infiniteResources = useSelector(selectInfiniteResources);
  const infiniteResourcesTotal = useSelector(selectInfiniteResourcesTotal);
  const getInfiniteResourcesStatus = useSelector(selectGetInfiniteResourcesStatus);
  const resources = useSelector(selectResources);
  const { ...queryParams } = useSearchParams();
  const { filterByType, startDate, startTime, endTime, duration, capacity, filterByFloor } = queryParams;
  const { ownerType, ownerUuid } = useOwnerParams();
  const isInfiniteResourcesLoading = getInfiniteResourcesStatus.status === ACTION_STATUSES.PENDING;
  const swipeModalScrollContainer = document.getElementById('scroll-container');

  const limitOfElements = useMemo<number>(() => {
    const scrollElement = scrollContainerRef?.current || swipeModalScrollContainer;
    if (!scrollElement?.clientHeight) {
      return 0;
    }

    if (isMobileDevice) {
      const limit = Math.round(scrollElement.clientHeight / RESOURCE_TILE_HEIGHT);

      return limit < resources?.length ? resources?.length : limit;
    }

    return getDesktopOffsetElements();
  }, [
    isMobileDevice,
    scrollContainerRef?.current?.clientHeight,
    swipeModalScrollContainer?.clientHeight,
    resources?.length,
  ]);

  const numberDownloadedResources = useMemo(
    () => Object.values(infiniteResources).flat().length + limitOfElements,
    [limitOfElements, infiniteResources],
  );

  const onScroll = useCallback((): void => {
    const scrollElement = scrollContainerRef?.current || swipeModalScrollContainer;
    if (!scrollElement) return;

    const { scrollTop, scrollHeight, clientHeight } = scrollElement;
    const limit = limitOfElements || Math.round(clientHeight / RESOURCE_TILE_HEIGHT);
    const resourcesLimit = limit < resources?.length ? resources?.length : limit;

    if (
      clientHeight + scrollTop > scrollHeight - 10 &&
      !isInfiniteResourcesLoading &&
      infiniteResourcesTotal > numberDownloadedResources
    ) {
      const searchParams = {
        types: filterByType,
        startDate,
        startTime,
        endTime,
        duration,
        capacity: +capacity,
        floor: filterByFloor,
      } as FilterParams;
      const filterParams = getFilterParams({ ...searchParams });

      const offset =
        numberDownloadedResources < infiniteResourcesTotal
          ? resourcesLimit * ((Object.keys(infiniteResources)?.length ?? 0) + 1)
          : infiniteResourcesTotal;

      dispatch(
        getInfiniteResources.request({
          ownerType,
          ownerUuid,
          filterParams: {
            ...filterParams,
            limit: resourcesLimit,
            offset,
          },
          indexOfSet: (Object.keys(infiniteResources)?.length ?? 0) + 1,
        }),
      );
    }
  }, [
    resources?.length,
    swipeModalScrollContainer,
    capacity,
    filterByFloor,
    numberDownloadedResources,
    limitOfElements,
    infiniteResourcesTotal,
    scrollContainerRef,
    isInfiniteResourcesLoading,
    filterByType,
    startDate,
    startTime,
    endTime,
    duration,
    dispatch,
    ownerType,
    ownerUuid,
    infiniteResources,
  ]);

  useEffect(() => {
    const scrollElement = scrollContainerRef?.current || swipeModalScrollContainer;

    if (limitOfElements < infiniteResourcesTotal && scrollElement) {
      scrollElement.addEventListener('scroll', onScroll);
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', onScroll);
      }
    };
  }, [onScroll, scrollContainerRef, limitOfElements, infiniteResourcesTotal, swipeModalScrollContainer]);

  return { limitOfElements };
};
