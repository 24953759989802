import { MappedBookingObject } from 'components/payment/receipt/components/swipe-receipt-content';
import { getFormattedEventDuration } from './formatDate';

interface ReceiptInfo {
  title: string;
  eventBlockSubtitle?: string;
}

export const getReceiptInfoFromTransaction = (
  transaction: MappedBookingObject,
  locale: string,
  timezone?: string,
): ReceiptInfo => {
  return {
    title: 'details',
    eventBlockSubtitle: getFormattedEventDuration(
      transaction.details.start_at as string,
      transaction.details.end_at as string,
      locale as string,
      timezone,
    ),
  };
};
