import {
  BoldText,
  BookingContainer,
  Title,
  ContentContainer,
  CloseButtonContainer,
  QuickCheckoutContent,
  StyledTitleBlock,
  StyledButton,
} from './styles';
import React, { useMemo } from 'react';
import { selectSubmitCartStatus } from 'store/cart/selectors';
import { useSelector } from 'react-redux';
import { CloseButton } from 'components/close-button';
import { searchParams } from 'pages/date-select-page/interface';
import { useIntl } from 'react-intl';
import { useLocale } from 'hooks/use-locale.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { selectResource } from 'store/resource/selectors';
import { Booking } from './booking/booking';
import { joinArrayItemsWithOxfordComma } from './utils/joinItemsWithOxfordComma';
import { useMultidayQuickCheckoutContent } from './hooks/useMultidayQuickCheckoutContent.hook';
import { formatStartDatesQueryParams } from './utils/formatStartDatesQueryParam';

interface MultidayQuickCheckoutDesktopContentProps {
  closeQuickCheckoutHandler: VoidFunction;
  onCTAClick: VoidFunction;
}

export const MultidayQuickCheckoutDesktopContent = ({
  closeQuickCheckoutHandler,
  onCTAClick,
}: MultidayQuickCheckoutDesktopContentProps) => {
  const intl = useIntl();
  const resource = useSelector(selectResource);
  const locale = useLocale();
  useMultidayQuickCheckoutContent();

  const { startDates, startTime, endTime } = useSearchParams<searchParams>();
  const formattedDatesString = formatStartDatesQueryParams(startDates, locale);
  const andSeparator = intl.formatMessage({ id: 'and' });
  const submitCartStatus = useSelector(selectSubmitCartStatus);
  const subtitle: string = intl.formatMessage(
    { id: 'you_are_about_book' },
    {
      serviceName: <BoldText>{resource.name}</BoldText>,
      serviceTime: <BoldText>{joinArrayItemsWithOxfordComma(formattedDatesString, andSeparator)}</BoldText>,
    },
  ) as string;
  const sortedDates = useMemo<Array<string>>(() => {
    const formattedStartDates = startDates?.split(',') || [];

    return formattedStartDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  }, [startDates]);

  return (
    <ContentContainer>
      <CloseButtonContainer>
        <CloseButton onClose={closeQuickCheckoutHandler} />
      </CloseButtonContainer>
      <QuickCheckoutContent className="quick-checkout-desktop-content">
        <StyledTitleBlock spacing={20} title={intl.formatMessage({ id: 'checkout' })} subtitle={subtitle} />
        <BookingContainer isDesktop data-testid="multiday-booking-container">
          <Title isDesktop>{resource.name}</Title>
          {sortedDates.map(date => (
            <Booking date={date} timeRange={{ startTime, endTime }} />
          ))}
        </BookingContainer>
        <StyledButton isFreeCart submitCartStatus={submitCartStatus} onCTAClick={onCTAClick} />
      </QuickCheckoutContent>
    </ContentContainer>
  );
};
