import styled from 'styled-components';

export const Container = styled.div`
  margin-top: -18px;
  &:last-child {
    margin-bottom: 12px;
  }
`;

export const Question = styled.div`
  font: 500 14px/16px ${({ theme }) => theme.fonts.join()};
`;

export const Answer = styled.div`
  font: 400 14px/16px ${({ theme }) => theme.fonts.join()};
  margin-top: 8px;
  margin-bottom: 12px;
`;
