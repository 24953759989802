import React from 'react';

import { SelectedDateFilter } from 'components/selectedDateFilter/selected-date-filter';
import { SelectedTimeFilter } from 'components/selectedTimeFilter/selected-time-filter';
import { StyledDot } from 'pages/resource/styles';
import { bookingTimeHandler } from 'utils/bookingTimeHandler';
import { useLocale } from 'hooks/use-locale.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { Container, StyledText } from './styles';
import { searchParams } from 'pages/date-select-page/interface';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface DateTimeBlockProps {
  onDateClick?: VoidFunction;
  onTimeClick?: VoidFunction;
  startDate: string;
}

export const DateTimeBlock = ({ onDateClick, onTimeClick, startDate }: DateTimeBlockProps): JSX.Element => {
  const locale = useLocale();
  const { showResourceDetailsDateTimeInputs } = useFlags();
  const { startTime, endTime } = useSearchParams<searchParams>();
  const timeNotSelected = !(startTime || endTime);

  const timeRange = {
    startTime: startTime || null,
    endTime: endTime || null,
    locale,
  };

  const SelectedDateComponent = showResourceDetailsDateTimeInputs ? (
    <StyledText data-testid="selected-date-text">
      {bookingTimeHandler(timeRange).getDateRangeString(startDate)}
    </StyledText>
  ) : (
    <SelectedDateFilter date={bookingTimeHandler(timeRange).getDateRangeString(startDate)} onDateClick={onDateClick} />
  );

  const SelectedTimeComponent = showResourceDetailsDateTimeInputs ? (
    <StyledText data-testid="selected-time-text">{bookingTimeHandler(timeRange).getTimeRangeString()}</StyledText>
  ) : (
    <SelectedTimeFilter
      onTimeClick={onTimeClick}
      timeRange={bookingTimeHandler(timeRange).getTimeRangeString()}
      timeNotSelected={timeNotSelected}
    />
  );

  return (
    <Container>
      {SelectedDateComponent}
      <StyledDot showResourceDetailsDateTimeInputs={showResourceDetailsDateTimeInputs} />
      {SelectedTimeComponent}
    </Container>
  );
};
