import { Button } from '@hqo/react-components-library/dist/atoms/button';
import styled from 'styled-components';
import { PAYMENT_CONTENT_WIDTH } from 'shared/consts';

export const CancelButton = styled(Button)<{ isSwipeModalContent: boolean }>`
  align-self: flex-end;
  width: 100%;
  height: 48px;
  margin-top: 0;
  padding: 4px 23px;
  ${({ isSwipeModalContent }) => !isSwipeModalContent && `border: none;`};
`;

export const ButtonContainer = styled.div`
  width: 215px;

  @media only screen and (max-width: ${PAYMENT_CONTENT_WIDTH}) {
    width: 100%;
  }
`;
