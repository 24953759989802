import React, { useRef } from 'react';
import {
  ScheduleActiveBlock,
  CircleContainer,
  TopCircle,
  BottomCircle,
  ScheduleActiveTime,
  BottomCircleContainer,
  TopCircleContainer,
} from './styles';
import { ActiveBlockProps } from '../interface';
import { useDragArea } from './use-drag-area.hook';
import { useScrollTo } from '../use-scroll-to.hook';

export const ActiveBlock = ({
  top,
  listRefs,
  height,
  setIsScrollToSelectedValue,
  timeRangeByDuration,
  availableTimeRangeByMinDuration,
  availableTimeRangeByStartTime,
  currentRef,
  isScrollToSelectedValue,
  durations,
  timeInterval,
  isTimeIntervalFromTimeRanges,
}: ActiveBlockProps): JSX.Element => {
  useScrollTo({ isScrollToSelectedValue, ref: currentRef, top });
  const resizableBottomRef = useRef(null);
  const resizableTopRef = useRef(null);
  const { handleBottomClick, handleTopClick, handleBottomTouch, handleTopTouch, isTransition } = useDragArea({
    resizableBottomRef,
    resizableTopRef,
    listRefs,
    top,
    setIsScrollToSelectedValue,
    timeRangeByDuration,
    availableTimeRangeByMinDuration,
    availableTimeRangeByStartTime,
    durations,
    timeInterval,
    isTimeIntervalFromTimeRanges,
  });

  return (
    <ScheduleActiveBlock
      ref={resizableTopRef}
      id="resizable-top"
      className="schedule-active-block"
      data-testid="schedule-active-block"
      top={top}
      isTransition={isTransition}
    >
      <ScheduleActiveTime />
      <CircleContainer
        ref={resizableBottomRef}
        className="active-block"
        id="resizable-bottom"
        isTransition={isTransition}
        height={height}
      >
        <TopCircleContainer data-testid="top-circle" onMouseDown={handleTopClick} onTouchStart={handleTopTouch}>
          <TopCircle />
        </TopCircleContainer>
        <BottomCircleContainer
          data-testid="bottom-circle"
          onMouseDown={handleBottomClick}
          onTouchStart={handleBottomTouch}
        >
          <BottomCircle />
        </BottomCircleContainer>
      </CircleContainer>
    </ScheduleActiveBlock>
  );
};
