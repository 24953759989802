import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { ACTION_STATUSES } from 'shared/consts';
import { SpreedlyState } from './types';

export const INITIAL_STATE: SpreedlyState = {
  initializeSpreedly: {
    status: null,
    consoleError: null,
  },
  reloadSpreedly: {
    status: null,
    consoleError: null,
  },
  tokenizeCreditCardSpreedly: {
    status: null,
    error: null,
  },
  creditCardConfigSpreedly: {
    first_name: '',
    last_name: '',
    month: '',
    year: '',
    country: 'United States',
    zip: '',
    email: '',
  },
  paymentMethodData: {
    token: '',
    paymentMethod: null,
  },
  validationSpreedly: {
    cardType: '',
    validNumber: false,
    validCvv: false,
    numberLength: 0,
    cvvLength: 0,
  },
  readySpreedly: false,
  errorsSpreedly: [],
};

const configureSpreedlyRequestHandler = (state: SpreedlyState): SpreedlyState => ({
  ...state,
  readySpreedly: false,
  initializeSpreedly: {
    ...state.initializeSpreedly,
    status: ACTION_STATUSES.PENDING,
  },
});

const resetSpreedlyStatusHandler = (state: SpreedlyState): SpreedlyState => ({
  ...state,
  readySpreedly: false,
  reloadSpreedly: {
    ...state.reloadSpreedly,
    status: null,
  },
  initializeSpreedly: {
    ...state.initializeSpreedly,
    status: null,
  },
});

const reloadSpreedlyRequestHandler = (state: SpreedlyState): SpreedlyState => ({
  ...state,
  readySpreedly: false,
  reloadSpreedly: {
    ...state.reloadSpreedly,
    status: ACTION_STATUSES.PENDING,
  },
});

const setReadySpreedlyHandler = (state: SpreedlyState): SpreedlyState => ({
  ...state,
  initializeSpreedly: {
    status: ACTION_STATUSES.FULFILLED,
    consoleError: null,
  },
  reloadSpreedly: {
    status: ACTION_STATUSES.FULFILLED,
    consoleError: null,
  },
  readySpreedly: true,
});

const setSpreedlyCreditCardConfigHandler = (
  state: SpreedlyState,
  { payload }: ActionType<typeof actions.setCreditCardConfigSpreedly>,
): SpreedlyState => ({
  ...state,
  creditCardConfigSpreedly: payload,
});

// Not to be confused with the async validation handler.
// This handler is for "manually" setting the validation state in store
// from the Spreedly input event
const setValidationHandler = (
  state: SpreedlyState,
  { payload }: ActionType<typeof actions.setValidationSpreedly>,
): SpreedlyState => ({
  ...state,
  validationSpreedly: payload,
});

const tokenizeCreditCardSpreedlyRequestHandler = (state: SpreedlyState): SpreedlyState => ({
  ...state,
  tokenizeCreditCardSpreedly: {
    ...state.tokenizeCreditCardSpreedly,
    status: ACTION_STATUSES.PENDING,
  },
  paymentMethodData: INITIAL_STATE.paymentMethodData,
  errorsSpreedly: INITIAL_STATE.errorsSpreedly,
});

const tokenizeCreditCardSpreedlySuccessHandler = (
  state: SpreedlyState,
  { payload }: ActionType<typeof actions.tokenizeCreditCardSpreedly.success>,
): SpreedlyState => ({
  ...state,
  tokenizeCreditCardSpreedly: {
    status: ACTION_STATUSES.FULFILLED,
    error: null,
  },
  paymentMethodData: payload,
  errorsSpreedly: INITIAL_STATE.errorsSpreedly,
});

const tokenizeCreditCardSpreedlyFailureHandler = (
  state: SpreedlyState,
  { payload }: ActionType<typeof actions.tokenizeCreditCardSpreedly.failure>,
): SpreedlyState => ({
  ...state,
  tokenizeCreditCardSpreedly: {
    status: ACTION_STATUSES.REJECTED,
    error: null,
  },
  paymentMethodData: INITIAL_STATE.paymentMethodData,
  errorsSpreedly: payload,
});

const configureSpreedlyFailureHandler = (
  state: SpreedlyState,
  { payload }: ActionType<typeof actions.configureSpreedly.failure>,
): SpreedlyState => ({
  ...state,
  paymentMethodData: INITIAL_STATE.paymentMethodData,
  errorsSpreedly: [payload, ...state.errorsSpreedly],
});

const reloadSpreedlyFailureHandler = (
  state: SpreedlyState,
  { payload }: ActionType<typeof actions.reloadSpreedly.failure>,
): SpreedlyState => ({
  ...state,
  paymentMethodData: INITIAL_STATE.paymentMethodData,
  errorsSpreedly: [payload, ...state.errorsSpreedly],
});

const spreedlyReducer = createReducer(INITIAL_STATE)
  .handleAction(actions.resetSpreedlyStatus, resetSpreedlyStatusHandler)
  .handleAction(actions.configureSpreedly.request, configureSpreedlyRequestHandler)
  .handleAction(actions.reloadSpreedly.request, reloadSpreedlyRequestHandler)
  .handleAction(actions.setReadySpreedly, setReadySpreedlyHandler)
  .handleAction(actions.setCreditCardConfigSpreedly, setSpreedlyCreditCardConfigHandler)
  .handleAction(actions.setValidationSpreedly, setValidationHandler)
  .handleAction(actions.tokenizeCreditCardSpreedly.request, tokenizeCreditCardSpreedlyRequestHandler)
  .handleAction(actions.tokenizeCreditCardSpreedly.success, tokenizeCreditCardSpreedlySuccessHandler)
  .handleAction(actions.tokenizeCreditCardSpreedly.failure, tokenizeCreditCardSpreedlyFailureHandler)
  .handleAction(actions.configureSpreedly.failure, configureSpreedlyFailureHandler)
  .handleAction(actions.reloadSpreedly.failure, reloadSpreedlyFailureHandler);
export default spreedlyReducer;
