import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';
import { BookingStatus, BookingsState } from './types';

export const initialState: BookingsState = {
  bookings: [],
  startDate: null,
  endDate: null,
  createdBooking: null,
  loading: false, // true during GET request
  processed: false, // State when non-GET request finished
  cancelProcessed: false,
  refreshing: false, // true during non-GET request
  error: null,
  createBookingRequest: {
    startAt: null,
    endAt: null,
    name: '',
    description: '',
    contacted: false,
    unit_code: '',
    form_responses: [],
    price: null,
    cancellation_description: '',
  },
};

const getBookingsRequestHandler = (state: BookingsState) => ({
  ...state,
  loading: true,
  processed: false,
  refreshing: false,
});

const getBookingsSuccessHandler = (
  state: BookingsState,
  { payload }: ActionType<typeof actions.getBookings.success>,
): BookingsState => ({
  ...state,
  bookings: payload.bookings,
  startDate: payload.startDate,
  endDate: payload.endDate,
  loading: false,
  processed: false,
  refreshing: false,
  error: null,
});

const getBookingsFailureHandler = (
  state: BookingsState,
  { payload: error }: ActionType<typeof actions.getBookings.failure>,
): BookingsState => ({
  ...state,
  loading: false,
  processed: false,
  refreshing: false,
  error: error.error,
});

const createBookingRequestHandler = (state: BookingsState): BookingsState => ({
  ...state,
  processed: false,
  refreshing: true,
});

const createBookingSuccessHandler = (
  state: BookingsState,
  { payload }: ActionType<typeof actions.createBooking.success>,
): BookingsState => ({
  ...state,
  processed: true,
  refreshing: false,
  createdBooking: payload,
  createBookingRequest: initialState.createBookingRequest,
});

const createBookingFailureHandler = (
  state: BookingsState,
  { payload: error }: ActionType<typeof actions.createBooking.failure>,
): BookingsState => ({
  ...state,
  processed: false,
  refreshing: false,
  error: error.error,
});

const cancelBookingRequestHandler = (state: BookingsState): BookingsState => ({
  ...state,
  cancelProcessed: false,
  refreshing: true,
});

const cancelBookingSuccessHandler = (
  state: BookingsState,
  { payload }: ActionType<typeof actions.cancelBooking.success>,
): BookingsState => {
  const canceledBooking = state.bookings.find(booking => booking.uuid === payload.bookingUuid);

  return {
    ...state,
    cancelProcessed: true,
    refreshing: false,
    bookings: [
      ...state.bookings.filter(booking => booking.uuid !== payload.bookingUuid),
      { ...canceledBooking, status: BookingStatus.CANCELED },
    ],
  };
};

const cancelBookingFailureHandler = (
  state: BookingsState,
  { payload: error }: ActionType<typeof actions.cancelBooking.failure>,
): BookingsState => ({
  ...state,
  cancelProcessed: false,
  refreshing: false,
  error: error.error,
});

const setBookingRequestHandler = (
  state: BookingsState,
  { payload }: ActionType<typeof actions.setBookingRequest>,
): BookingsState => ({
  ...state,
  createBookingRequest: payload,
});

const resetBookingRequestHandler = (state: BookingsState): BookingsState => ({
  ...state,
  createBookingRequest: initialState.createBookingRequest,
});

const setBookingErrorNull = (state: BookingsState): BookingsState => ({
  ...state,
  error: null,
});

const resourceReducer = createReducer(initialState)
  .handleAction(actions.getBookings.request, getBookingsRequestHandler)
  .handleAction(actions.getBookings.success, getBookingsSuccessHandler)
  .handleAction(actions.getBookings.failure, getBookingsFailureHandler)
  .handleAction(actions.createBooking.request, createBookingRequestHandler)
  .handleAction(actions.createBooking.success, createBookingSuccessHandler)
  .handleAction(actions.createBooking.failure, createBookingFailureHandler)
  .handleAction(actions.cancelBooking.request, cancelBookingRequestHandler)
  .handleAction(actions.cancelBooking.success, cancelBookingSuccessHandler)
  .handleAction(actions.cancelBooking.failure, cancelBookingFailureHandler)
  .handleAction(actions.setBookingRequest, setBookingRequestHandler)
  .handleAction(actions.resetBookingRequest, resetBookingRequestHandler)
  .handleAction(actions.setBookingErrorNull, setBookingErrorNull);

export default resourceReducer;
