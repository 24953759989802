import { IconTextBlock } from '@hqo/react-components-library/dist/molecules/iconTextBlock';
import React from 'react';
import { ResourceFeature } from 'store/transactions/types';
import { faCircleCheck } from '@fortawesome/pro-light-svg-icons';
import { formatLink } from 'utils/FormatLink';
import { useIconValues } from 'hooks/use-icon-values';
import { useIntl } from 'react-intl';
import { library } from '@fortawesome/fontawesome-svg-core';

Promise.all([import('@fortawesome/pro-solid-svg-icons'), import('@fortawesome/free-brands-svg-icons')]).then(
  ([{ fas }, { fab }]) => {
    library.add(fas, fab);
  },
);

interface AmenityProps {
  amenity: ResourceFeature;
}

export const Amenity = ({ amenity }: AmenityProps) => {
  const { id, name, icon, cta_value, cta_type } = amenity;

  const { getIconValues } = useIconValues();
  const { iconName, iconType = 'far' } = icon ? getIconValues(icon) : { iconName: faCircleCheck.iconName };

  const intl = useIntl();

  return (
    <IconTextBlock
      data-testid="icon-text-block"
      key={id}
      icon={iconName}
      iconType={iconType}
      text={name ? intl.formatMessage({ id: name }) : ''}
      linkRef={formatLink(cta_type, cta_value)}
      wrapText
      shouldOpenInNewTab={!/mobi/i.test(navigator.userAgent)}
    />
  );
};
