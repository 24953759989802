import React from 'react';
import { IconBlock } from '@hqo/react-components-library/dist/molecules/iconBlock';
import { useLocale } from 'hooks/use-locale.hook';
import { useSelector } from 'react-redux';
import { selectBuildingTimeZone } from 'store/building/selectors';
import { formatDate, getFormattedEventDuration } from 'utils/formatDate';
import { MappedBookingObject } from 'components/payment/receipt/components/swipe-receipt-content';

export interface MultiDayIconsBlockProps {
  transaction: MappedBookingObject;
}

export const MultiDayIconsBlock = ({ transaction }: MultiDayIconsBlockProps) => {
  const locale = useLocale();
  const buildingTimeZone = useSelector(selectBuildingTimeZone);
  const duplicateTransactionItems = transaction.items.slice();
  const sortedTransactionItems = duplicateTransactionItems.sort(
    (firstTransactionItem, secondTransactionItem) =>
      new Date(firstTransactionItem.resource_booking.start_at).getTime() -
      new Date(secondTransactionItem.resource_booking.start_at).getTime(),
  );

  return (
    <>
      {sortedTransactionItems.map(item => {
        const { eventWeekDay, eventDateMonthLong, eventDateDay, eventDateYear } = formatDate(
          item.resource_booking.start_at,
          locale as string,
          buildingTimeZone,
        );

        return (
          <IconBlock
            key={item.id}
            icon="calendar-alt"
            iconType="fal"
            title={`${eventWeekDay}, ${eventDateMonthLong} ${eventDateDay}, ${eventDateYear}`}
            subtitle={getFormattedEventDuration(
              item.resource_booking.start_at,
              item.resource_booking.end_at,
              locale as string,
              buildingTimeZone,
            )}
          />
        );
      })}
    </>
  );
};
