import { SMALL_DEVICE_MAX_WIDTH } from 'shared/consts';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface WrapperProps {
  isResourcesPage?: boolean;
}

export const RESOURCES_PAGE_DESKTOP_WIDTH = 381;
export const FILTER_ICON_WIDTH = 24;

export const Wrapper = styled.div<WrapperProps>`
  margin-bottom: 21px;
  width: ${({ isResourcesPage }) => (isResourcesPage ? `calc(100% - ${FILTER_ICON_WIDTH}px)` : '100%')};

  .touch-search-bar-wrapper {
    display: flex;
    align-items: center;
    width: ${({ isResourcesPage }) => (isResourcesPage ? `${RESOURCES_PAGE_DESKTOP_WIDTH}px` : '100%')};
    max-width: ${({ isResourcesPage }) => (isResourcesPage ? `${RESOURCES_PAGE_DESKTOP_WIDTH}px` : '423px')};
  }

  .left-label-wrapper {
    display: flex;
    flex-shrink: 1;
    white-space: nowrap;
    overflow: hidden;
  }

  .left-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .right-label-wrapper {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    flex-shrink: 0;
  }

  .labels-dot {
    display: flex;
    flex-shrink: 0;
  }

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    .touch-search-bar-ghost {
      width: 100%;
    }

    .touch-search-bar-container {
      width: 100%;
    }

    .touch-search-bar-wrapper {
      width: 100%;
    }
  }
`;

export const StyledCalendarIcon = styled(FontAwesomeIcon)`
  margin-left: 12px;
`;
