import { createAction, createAsyncAction } from 'typesafe-actions';

import { GetResourcesParams } from 'store/resources/types';
import {
  FloorPlanResourceResponse,
  FloorPlanResourceAllFailureActionPayload,
  FloorPlanResourceAvailableFailureActionPayload,
} from 'store/floorplan-resources/types';

export const getAvailableResources = createAsyncAction(
  'GET_AVAILABLE_RESOURCES_REQUEST',
  'GET_AVAILABLE_RESOURCES_SUCCESS',
  'GET_AVAILABLE_RESOURCES_FAILURE',
)<GetResourcesParams, FloorPlanResourceResponse, FloorPlanResourceAvailableFailureActionPayload>();

export const getAllResources = createAsyncAction(
  'GET_ALL_RESOURCES_REQUEST',
  'GET_ALL_RESOURCES_SUCCESS',
  'GET_ALL_RESOURCES_FAILURE',
)<GetResourcesParams, FloorPlanResourceResponse, FloorPlanResourceAllFailureActionPayload>();

export const resetFloorPlanResources = createAction('RESET_FLOOR_PLAN_RESOURCES')<void>();
export const resetFloorPlanAvailabilityResources = createAction('RESET_FLOOR_PLAN_AVAILABILITY_RESOURCES')<void>();
