import { GetResourceTimeRangesParams, ResourceTimeRangesResponse, TimeRange } from './types';
import { createAsyncAction, createAction } from 'typesafe-actions';

import { FailureActionPayload } from 'store/errors/types';

export const getResourceTimeRanges = createAsyncAction(
  'GET_RESOURCE_TIME_RANGES_REQUEST',
  'GET_RESOURCE_TIME_RANGES_SUCCESS',
  'GET_RESOURCE_TIME_RANGES_FAILURE',
)<GetResourceTimeRangesParams, ResourceTimeRangesResponse, FailureActionPayload>();

export const resetResourceTimeRanges = createAction('RESET_RESOURCE_TIME_RANGES')();

export const setResourceTimeRanges = createAction('SET_RESOURCE_TIME_RANGES')<Array<TimeRange>>();
