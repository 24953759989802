import styled from 'styled-components';

export const TermsLinkTitle = styled.div`
  margin-left: 5px;
  font: 300 16px/19px ${({ theme }) => theme.fonts.join()};
  color: rgb(3, 124, 194);
  cursor: pointer;
`;

export const TermsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
