import styled from 'styled-components';

export const SelectedBuildingsText = styled.div`
  font: 500 16px/19px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-wrap: wrap;
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
