import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { AddOnsState } from './types';

const initialState: AddOnsState = {
  addOns: [],
};

const addAddOnHandler = (state: AddOnsState, { payload }: ActionType<typeof actions.addAddOn>): AddOnsState => {
  const indexOfAddon = state.addOns?.findIndex(addOn => addOn.id === payload.id);

  if (indexOfAddon >= 0) {
    const addOnsCopy = [...state.addOns];
    addOnsCopy[indexOfAddon] = payload;

    return { addOns: addOnsCopy };
  }

  return { addOns: [...state.addOns, payload] };
};

const removeAddOnHandler = (state: AddOnsState, { payload }: ActionType<typeof actions.removeAddOn>): AddOnsState => {
  const clearedAddonsState = state.addOns?.filter(addOn => addOn.id !== payload);

  return { addOns: clearedAddonsState };
};

const resetAddOnsStateHandler = (): AddOnsState => ({
  addOns: [],
});

const addOnsReducer = createReducer(initialState)
  .handleAction(actions.addAddOn, addAddOnHandler)
  .handleAction(actions.removeAddOn, removeAddOnHandler)
  .handleAction(actions.resetAddOnsState, resetAddOnsStateHandler);

export default addOnsReducer;
