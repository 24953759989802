import { CheckboxHqo } from '@hqo/react-components-library/dist/molecules/checkbox-hqo';
import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';
import styled from 'styled-components';
import { SwitchV2 } from '@hqo/react-components-library/dist/molecules/switch-v2';

export const StyledButton = styled(Button)`
  width: 110px;
  height: 36px;
  padding: 16px;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    width: 100%;
    height: 48px;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    justify-content: center;
  }
`;

export const LocationsListContainer = styled.div`
  .locations-list > div {
    border-bottom: 1px solid ${({ theme }) => theme.colors.greys.adminGreyMedium};
    padding: 16px 16px 16px 10px;
    font-size: 16px;
  }

  .radio-button-title {
    font-weight: 500;
  }
  .radio-button-subtitle {
    font-style: normal;
    color: ${({ theme }) => theme.colors.greys.adminBlack};
  }

  .checkbox-label {
    font-weight: 500;
    margin-top: -6px;
    color: ${({ theme }) => theme.colors.fontColor};
  }

  .checkbox-subtitle {
    font-style: normal;
    color: ${({ theme }) => theme.colors.greys.adminBlack};
  }
`;

export const CheckboxFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
`;

export const StyledCheckboxHqo = styled(CheckboxHqo)<{ fill: string }>`
  .checkbox-label {
    margin-top: 0 !important;
  }

  svg {
    color: ${({ fill }) => fill};
  }

  span:hover svg {
    color: ${({ fill }) => fill};
  }
`;

export const AllLocationToggle = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const AllLocationTitle = styled.div`
  font: 500 14px/16px ${({ theme }) => theme.fonts.join()};
`;

export const StyledSwitchV2 = styled(SwitchV2)<{ color: string }>`
  input:checked {
    background-color: ${({ color }) => color};
  }
`;
