import React from 'react';
import { useIntl } from 'react-intl';

import { AlertRoot, AlertLabel } from './styles';

export interface AlertProps {
  label: string;
  testId: string;
}

export const Alert = ({ label, testId }: AlertProps) => {
  const intl = useIntl();
  const labelId = `alert-label-${testId}`;
  return (
    <AlertRoot role="alert" aria-labelledby={labelId} data-testid={testId}>
      <AlertLabel id={labelId}>{intl.formatMessage({ id: label })}</AlertLabel>
    </AlertRoot>
  );
};
