import React from 'react';
import { StyledPaper, StyledPopover } from './styles';

interface CalendarPopoverProps {
  isOpened: boolean;
  toggleIsOpened: () => void;
  children: React.ReactNode;
}

export const CalendarPopover = ({ isOpened, toggleIsOpened, children }: CalendarPopoverProps): JSX.Element => (
  <StyledPopover
    wrapperClassName="popover-wrapper"
    open={isOpened}
    onClose={toggleIsOpened}
    buttonComponent={null}
    position="bottom"
    alignment="center"
  >
    <StyledPaper depth={2} borderRadius="md" className="date-picker-paper" data-testid="calendar-popover">
      {children}
    </StyledPaper>
  </StyledPopover>
);
