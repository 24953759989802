import { flatten } from 'utils/flatten/flatten.util';
import messagesInDutch from 'lang/nl-NL.json';
import messagesInEnglish from 'lang/en.json';
import messagesInFrenchCanada from 'lang/fr-CA.json';
import messagesInFrenchStandard from 'lang/fr-FR.json';
import messagesInGerman from 'lang/de-DE.json';
import messagesInSpanish from 'lang/es-ES.json';
import messagesInItalian from 'lang/it-IT.json';
import messagesInNorwegian from 'lang/nb-NO.json';
import messagesInPolish from 'lang/pl.json';
import messagesInUnitedKingdomEnglish from 'lang/en-GB.json';
import { useMemo } from 'react';

export const DEFAULT_LOCALE = 'en';

const messagesMap = new Map<string, Record<string, unknown>>([
  ['de-de', messagesInGerman.resourceBooking],
  ['en', messagesInEnglish.resourceBooking],
  ['es-es', messagesInSpanish.resourceBooking],
  ['fr-fr', messagesInFrenchStandard.resourceBooking],
  ['fr-ca', messagesInFrenchCanada.resourceBooking],
  ['nl-nl', messagesInDutch.resourceBooking],
  ['it-it', messagesInItalian.resourceBooking],
  ['nb-no', messagesInNorwegian.resourceBooking],
  ['pl', messagesInPolish.resourceBooking],
  ['en-gb', messagesInUnitedKingdomEnglish.resourceBooking],
]);

const getAvailableLocale = (userLocale: string): [string, string] => {
  if (messagesMap.has(userLocale)) {
    return [userLocale, userLocale];
  }

  const [lang] = userLocale.split('-');

  if (messagesMap.has(lang)) {
    return [lang, userLocale];
  }

  return [DEFAULT_LOCALE, DEFAULT_LOCALE];
};

interface LocaleData {
  messages: Record<string, string>;
  locale: string;
}

export const useLocaleData = (userLocale: string): LocaleData => {
  return useMemo(() => {
    const [messagesKey, locale] = getAvailableLocale(userLocale);

    return {
      locale,
      messages: flatten(messagesMap.get(messagesKey)) as Record<string, string>,
    };
  }, [userLocale]);
};
