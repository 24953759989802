import { ONE_HOUR } from 'components/schedule-select/const';
import { getResourceTimeIndices } from 'utils/getResourceTimeIndices';
import { resourceState } from 'store/resource/selectors';
import { searchParams } from '../date-select-page/interface';
import { selectResourceTimeRanges } from 'store/resource-time-ranges/selectors';
import { useIntl } from 'react-intl';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport/';
import { useMemo } from 'react';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useSelector } from 'react-redux';
import { useLocale } from 'hooks/use-locale.hook';

interface UseScheduleSelectPageReturnValues {
  resourceName: string;
  isSmallViewportWidth: boolean;
  minDurationText: string;
  maxDurationText: string;
}

interface Durations {
  minimumDuration: number;
  maximumDuration: number;
}

export const useScheduleSelectPage = (): UseScheduleSelectPageReturnValues => {
  const intl = useIntl();
  const locale = useLocale();
  const isSmallViewportWidth = useIsSmallViewportWidth();
  const { resource } = useSelector(resourceState);
  const resourceTimeRanges = useSelector(selectResourceTimeRanges);
  const { startDate, startDates } = useSearchParams<searchParams>();
  const {
    resource: { name: resourceName },
  } = useSelector(resourceState);
  const formattedStartDates = useMemo<Array<string>>(() => startDates?.split(',') || [], [startDates]);

  const durations = useMemo<Durations>(() => {
    let greatestMinimumDuration = 0;
    let smallestMaximumDuration = 0;

    formattedStartDates.forEach(dateString => {
      const { minimumDuration, maximumDuration } =
        getResourceTimeIndices({
          resource,
          startDate: dateString,
          resourceTimeRanges,
          locale,
        }) || {};

      if (minimumDuration > greatestMinimumDuration) {
        greatestMinimumDuration = minimumDuration;
      }

      if (maximumDuration > smallestMaximumDuration) {
        smallestMaximumDuration = maximumDuration;
      }
    });

    const { minimumDuration, maximumDuration } =
      getResourceTimeIndices({
        resource,
        startDate,
        resourceTimeRanges,
        locale,
      }) || {};

    if (formattedStartDates.length) {
      return { minimumDuration: greatestMinimumDuration, maximumDuration: smallestMaximumDuration };
    }

    return { minimumDuration, maximumDuration };
  }, [resource, startDate, resourceTimeRanges, locale, formattedStartDates]);

  const minDurationText = useMemo<string>(() => {
    const hours = Math.floor(durations.minimumDuration / ONE_HOUR);
    if (hours >= 1) {
      return intl.formatMessage(
        { id: 'minDuration' },
        { durationType: 'hr', hours, minutes: durations.minimumDuration },
      );
    }

    return intl.formatMessage(
      { id: 'minDuration' },
      { durationType: 'min', minutes: durations.minimumDuration, hours },
    );
  }, [durations.minimumDuration, intl]);

  const maxDurationText = useMemo<string | null>(() => {
    const hours = Math.floor(durations.maximumDuration / ONE_HOUR);
    if (!durations.maximumDuration) {
      return null;
    }
    if (hours >= 1) {
      return intl.formatMessage({ id: 'maxDuration' }, { type: 'hr', hours, minutes: durations.maximumDuration });
    }

    return intl.formatMessage({ id: 'maxDuration' }, { type: 'min', minutes: durations.maximumDuration, hours });
  }, [durations.maximumDuration, intl]);

  return { resourceName, isSmallViewportWidth, minDurationText, maxDurationText };
};
