import { useCallback, useState, useEffect, useMemo } from 'react';
import { replace } from 'store/router/actions';
import { useDispatch } from 'react-redux';
import { durations, Duration } from 'shared/consts/duration';
import { pick } from 'utils/pickObjectProperty';
import qs from 'qs';
import { useLocation } from 'react-router';

import { QUERY_PARAMS } from 'shared/consts';
import { bookingTimeHandler } from 'utils/bookingTimeHandler';

const LAST_TIME_SLOT = '24:00';

interface UseTypesFilterReturnValues {
  selectedDuration: Duration;
  onDurationChipClickHandler: (value: number) => void;
  disabledDurations: Array<Duration>;
}

const getQueryString = (
  value: number,
  queryParams: qs.ParsedQs,
  setSelectedDuration: React.Dispatch<React.SetStateAction<Duration>>,
): string => {
  let queryString: string;
  if (value === null || value === +queryParams.duration) {
    queryString = qs.stringify({
      ...pick(
        queryParams,
        QUERY_PARAMS.filter(param => param !== 'duration'),
      ),
      isExact: false,
    });
  } else {
    const clearedQueryParams = pick(
      queryParams,
      QUERY_PARAMS.filter(param => param !== 'isExact'),
    );
    queryString = qs.stringify({ ...clearedQueryParams, duration: value });
    setSelectedDuration(durations.find(dur => dur.value === value));
  }
  return queryString;
};

const getDisabledDurations = (
  setDisabledDurations: React.Dispatch<React.SetStateAction<Duration[]>>,
  startTime: string,
  endTime?: string,
): void => {
  const lastEndTime = endTime || LAST_TIME_SLOT;
  const timeRange = { startTime: startTime as string, endTime: lastEndTime };
  const closestDurationTotimeDiff = bookingTimeHandler(timeRange).getDisabledDurations(durations, !endTime);
  setDisabledDurations(closestDurationTotimeDiff);
};

export const useDurationFilter = (): UseTypesFilterReturnValues => {
  const [selectedDuration, setSelectedDuration] = useState<Duration>(null);
  const [disabledDurations, setDisabledDurations] = useState<Array<Duration>>([]);
  const { search, pathname } = useLocation();
  const dispatch = useDispatch();
  const queryParams = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]);
  const { startTime, endTime, duration, startDate, presetWindows, isExact, startDates } = queryParams;

  useEffect(() => {
    if (startTime && !endTime) {
      getDisabledDurations(setDisabledDurations, startTime as string);
    } else if (startTime && endTime) {
      getDisabledDurations(setDisabledDurations, startTime as string, endTime as string);
    } else {
      setDisabledDurations([]);
    }
    if (!(startDate || startDates) || presetWindows) setDisabledDurations(durations);
  }, [endTime, queryParams, startTime, startDate, presetWindows, startDates]);

  useEffect(() => {
    if (isExact === 'true' || !duration) {
      setSelectedDuration(null);
    } else {
      setSelectedDuration(durations.find(durationParam => durationParam.value === +duration));
    }
  }, [duration]);

  useEffect(() => {
    if (startTime && endTime && !duration && isExact !== 'true') {
      const diff = bookingTimeHandler({ startTime: startTime.toString(), endTime: endTime.toString() }).getTimeDiff();
      const queryString = qs.stringify(
        diff > 0
          ? { ...queryParams, duration: diff, isExact: true }
          : pick(
              queryParams,
              QUERY_PARAMS.filter(param => param !== 'endTime'),
            ),
      );
      setSelectedDuration(null);
      dispatch(replace(`${pathname}?${queryString}`));
    }
  }, [startTime, endTime, duration, queryParams, dispatch, isExact, setSelectedDuration]);

  const onDurationChipClickHandler = useCallback(
    (value: number) => dispatch(replace(`${pathname}?${getQueryString(value, queryParams, setSelectedDuration)}`)),
    [dispatch, pathname, queryParams],
  );

  return { selectedDuration, onDurationChipClickHandler, disabledDurations };
};
