import React from 'react';
import { StyledButton } from './styles';
import { useIntl } from 'react-intl';

interface SelectedDateFilterProps {
  date: string;
  dateNotSelected?: boolean;
  onDateClick: VoidFunction;
  isSelectedDateTimeFilterTextOnly?: boolean;
}

export const SelectedDateFilter = ({
  date,
  dateNotSelected = false,
  onDateClick,
  isSelectedDateTimeFilterTextOnly,
}: SelectedDateFilterProps): JSX.Element => {
  const intl = useIntl();

  return (
    <StyledButton
      isSelectedDateTimeFilterTextOnly={isSelectedDateTimeFilterTextOnly}
      variant="link"
      onClick={onDateClick}
      text={dateNotSelected ? intl.formatMessage({ id: 'addDate' }) : date}
      multiline={false}
    />
  );
};
