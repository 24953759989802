import React, { useMemo } from 'react';
import { MultipleBookingsContainer } from './styles';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'pages/date-select-page/interface';
import { DateTimeBlock } from './components/date-time-block';

interface MultipleBookingsProps {
  containerHeight: number;
  reverseAnimation: boolean;
  onDateClick?: VoidFunction;
  onTimeClick?: VoidFunction;
}

export const MultipleBookings = ({
  containerHeight,
  onDateClick,
  onTimeClick,
  reverseAnimation,
}: MultipleBookingsProps): JSX.Element => {
  const { startDates } = useSearchParams<searchParams>();
  const sortedDates = useMemo<Array<string>>(() => {
    const formattedStartDates = startDates?.split(',') || [];

    return formattedStartDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  }, [startDates]);

  return (
    <MultipleBookingsContainer containerHeight={containerHeight} reverseAnimation={reverseAnimation}>
      {!!sortedDates.length &&
        sortedDates.map(date => (
          <DateTimeBlock key={date} startDate={date} onDateClick={onDateClick} onTimeClick={onTimeClick} />
        ))}
    </MultipleBookingsContainer>
  );
};
