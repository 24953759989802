import { CapacityFilterContainer, CapacityFilterTitle } from './styles';

import { CapacityComponent } from './capacity-component';
import React from 'react';
import { capacities } from 'shared/consts/capacities';
import { useCapacityFilter } from './use-capacity-filter.hook';
import { useIntl } from 'react-intl';

export const CapacityFilter = () => {
  const { formatMessage } = useIntl();
  const { selectedCapacity, onCapacityChipClickHandler } = useCapacityFilter();

  return (
    <CapacityFilterContainer>
      <CapacityFilterTitle>{formatMessage({ id: 'capacity' })}</CapacityFilterTitle>
      <CapacityComponent
        capacities={capacities}
        selectedCapacity={selectedCapacity}
        onClickHandler={onCapacityChipClickHandler}
      />
    </CapacityFilterContainer>
  );
};
