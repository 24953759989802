import * as actions from './actions';

import { createReducer, ActionType } from 'typesafe-actions';
import { RoomLayoutState } from '../resource/types';

export const initialState: RoomLayoutState = {
  room_layout: null,
};

const setRoomLayoutHandler = (
  state: RoomLayoutState,
  { payload: roomLayout }: ActionType<typeof actions.setRoomLayout>,
): RoomLayoutState => ({
  ...state,
  room_layout: roomLayout,
});

const resetRoomLayoutHandler = (): RoomLayoutState => ({
  ...initialState,
});

const roomLayoutReducer = createReducer(initialState)
  .handleAction(actions.setRoomLayout, setRoomLayoutHandler)
  .handleAction(actions.resetRoomLayout, resetRoomLayoutHandler);

export default roomLayoutReducer;
