import { Booking, GetBookingsResponse } from 'store/bookings/types';
import { BookingsParams, CancelBookingParams, CreateBookingDto, CreateBookingParams } from 'shared/types';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { FailureActionPayload } from 'store/errors/types';

export const getBookings = createAsyncAction('GET_BOOKINGS_REQUEST', 'GET_BOOKINGS_SUCCESS', 'GET_BOOKINGS_FAILURE')<
  BookingsParams,
  GetBookingsResponse,
  FailureActionPayload
>();

export const createBooking = createAsyncAction(
  'CREATE_BOOKING_REQUEST',
  'CREATE_BOOKING_SUCCESS',
  'CREATE_BOOKING_FAILURE',
)<CreateBookingParams, Booking, FailureActionPayload>();

export const cancelBooking = createAsyncAction(
  'CANCEL_BOOKING_REQUEST',
  'CANCEL_BOOKING_SUCCESS',
  'CANCEL_BOOKING_FAILURE',
)<CancelBookingParams, CancelBookingParams, FailureActionPayload>();

export const setBookingRequest = createAction('SET_BOOKING_REQUEST')<CreateBookingDto>();

export const resetBookingRequest = createAction('RESET_BOOKING_REQUEST')<undefined>();

export const setBookingErrorNull = createAction('SET_BOOKING_ERROR_NULL')<undefined>();
