import { useCallback, useEffect, useMemo, useState } from 'react';

import qs from 'qs';
import { replace } from 'store/router/actions';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

interface UseCapacityFilterReturnValues {
  selectedCapacity: number;
  onCapacityChipClickHandler: (value: number) => void;
}

const getQueryString = (
  value: number,
  queryParams: qs.ParsedQs,
  setSelectedCapacity: React.Dispatch<React.SetStateAction<number>>,
): string => {
  const queryString = qs.stringify({
    ...queryParams,
    capacity: value,
  });
  setSelectedCapacity(value);

  return queryString;
};

export const useCapacityFilter = (): UseCapacityFilterReturnValues => {
  const [selectedCapacity, setSelectedCapacity] = useState<number>(null);

  const { search, pathname } = useLocation();
  const dispatch = useDispatch();
  const queryParams = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]);
  const { capacity } = queryParams;

  useEffect(() => {
    setSelectedCapacity(+capacity);
  }, [capacity, setSelectedCapacity]);

  const onCapacityChipClickHandler = useCallback(
    (value: number) => dispatch(replace(`${pathname}?${getQueryString(value, queryParams, setSelectedCapacity)}`)),
    [dispatch, pathname, queryParams],
  );

  return { selectedCapacity, onCapacityChipClickHandler };
};
