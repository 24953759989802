import { ButtonsWrapper, StyledButton } from './styles';
import React, { useMemo } from 'react';
import { TimeSelectPageFooterProps } from '../interface';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'pages/resource/cta-button';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocation } from 'react-router-dom';

export const TimeSelectPageFooter = ({
  onSearchClick,
  onSkipClick,
  onPressSave,
}: TimeSelectPageFooterProps): JSX.Element => {
  const intl = useIntl();
  const { showResourceDetailsDateTimeInputs } = useFlags();
  const { pathname } = useLocation();
  const { startTime, presetWindows } = useSearchParams<searchParams>();

  const primaryButtonProps = useMemo(() => {
    if (showResourceDetailsDateTimeInputs && !pathname.includes('resources') && onPressSave) {
      return {
        key: 'save',
        dataTestId: 'save-button',
        onClick: onPressSave,
        text: intl.formatMessage({ id: 'save' }),
      };
    }

    return {
      key: 'search',
      dataTestId: 'search-button',
      onClick: onSearchClick,
      text: intl.formatMessage({ id: 'search' }),
    };
  }, [showResourceDetailsDateTimeInputs, pathname, onPressSave, onSearchClick]);

  const { key, onClick, dataTestId, text } = primaryButtonProps;

  return (
    <ButtonsWrapper data-testid="time-select-buttons-wrapper">
      {!(startTime || presetWindows) ? (
        <StyledButton
          key="skip"
          data-testid="skip-button"
          onClick={onSkipClick}
          text={intl.formatMessage({ id: 'skip' })}
          variant="skip"
          multiline={false}
        />
      ) : (
        <StyledButton key={key} data-testid={dataTestId} onClick={onClick} text={text} multiline={false} />
      )}
    </ButtonsWrapper>
  );
};
