import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport/';
import { useMemo } from 'react';

export const useBackButtonVisibility = (): boolean => {
  const isMobileDevice = useIsSmallViewportWidth();
  const { showResourceBookingNavigation: isResourceBookingNavigationVisible } = useFlags();

  return useMemo<boolean>(() => {
    return isResourceBookingNavigationVisible || !isMobileDevice;
  }, [isMobileDevice, isResourceBookingNavigationVisible]);
};
