import { EMPTY_STRING } from 'components/schedule-select/const';
import { HOUR_MIDNIGHT, HOUR_MIDNIGHT_ALTERNATE } from 'shared/consts/time';
import { DEFAULT_INTL_LOCALE } from 'utils/constants';

export const getEndTimeByDuration = (date: Date, startTime: string, duration: number): string | null => {
  if (!date || !startTime || !duration) {
    return null;
  }
  const selectedDate = new Date(date);
  const [hours, minutes] = (startTime || EMPTY_STRING).split(':').map(Number);
  selectedDate.setHours(hours, minutes + duration);

  const formattedTime = selectedDate.toLocaleTimeString(DEFAULT_INTL_LOCALE, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  const [formattedHours, formattedMinutes] = formattedTime.split(':');
  const correctedHours = formattedHours === HOUR_MIDNIGHT_ALTERNATE ? HOUR_MIDNIGHT : formattedHours;

  return `${correctedHours}:${formattedMinutes}`;
};
