import styled from 'styled-components';

export const AlertLabel = styled.span`
  font: italic 14px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.$adminPink};
`;

export const AlertRoot = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0 20px 0;
`;
