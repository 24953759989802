import { selectBuildingCurrencyType } from 'store/building/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useCurrencyType = (): string => {
  const buildingCurrencyType = useSelector(selectBuildingCurrencyType);

  return useMemo(() => {
    return buildingCurrencyType;
  }, [buildingCurrencyType]);
};
