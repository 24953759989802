import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const Title = styled.div`
  font: 500 16px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const ChangeButton = styled.div`
  font: 500 16px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Icon = styled(FontAwesomeIcon)`
  flex: 0 1 auto;
  color: ${({ theme }) => theme.colors.fontColor};
  margin: 0 6px 0 8px;
`;

export const ApplePayIcon = styled(Icon)`
  border: 1px solid ${({ theme }) => theme.colors.fontColor};
  border-radius: 4px;
  padding: 0 5px 0 5px;
`;

export const PaymentMethodSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
