import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { hqoTheme } from '@hqo/react-components-library/dist/hqo-theme-provider';
import { selectBrandTheme } from 'store/theme/selectors';
import { lighten, darken } from 'polished';

export const useGetTheme = () => {
  const brandTheme = useSelector(selectBrandTheme);

  return useMemo(() => {
    if (brandTheme) {
      const primaryColor = brandTheme.primary1 ? brandTheme.primary1 : brandTheme.primary_color;
      const secondaryColor = brandTheme.primary2 ? brandTheme.primary2 : brandTheme.secondary_color;

      return {
        ...hqoTheme,
        colors: {
          ...hqoTheme.colors,
          $primary: primaryColor || hqoTheme.colors.$primary,
          $secondary: secondaryColor || hqoTheme.colors.$secondary,
          primary: primaryColor || hqoTheme.colors.primary,
          secondary: secondaryColor || hqoTheme.colors.secondary,
          hqo: {
            ...hqoTheme.colors.hqo,
            primary: primaryColor || hqoTheme.colors.hqo.primary,
            secondary: secondaryColor || hqoTheme.colors.hqo.secondary,
            primaryHover: primaryColor ? lighten(0.1, primaryColor) : hqoTheme.colors.hqo.primaryHover,
            primaryActive: primaryColor ? darken(0.1, primaryColor) : hqoTheme.colors.hqo.primaryActive,
            secondaryHover: secondaryColor ? lighten(0.1, secondaryColor) : hqoTheme.colors.hqo.secondaryHover,
            secondaryActive: secondaryColor ? darken(0.1, secondaryColor) : hqoTheme.colors.hqo.secondaryActive,
          },
          fontColor: brandTheme.primary_font_color ? brandTheme.primary_font_color : hqoTheme.colors.fontColor,
        },
        fonts: brandTheme.font?.body?.font_family
          ? [`${brandTheme.font?.body?.font_family}`, ...hqoTheme.fonts]
          : hqoTheme.fonts,
      };
    }
    return hqoTheme;
  }, [brandTheme]);
};
