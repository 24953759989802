import { AppInstanceConfig } from 'store/app-instance-configs/types';
import { Resource } from 'store/resource/types';

export const getResourceBuilding = (
  resource: Resource,
  appInstanceConfigs: AppInstanceConfig[],
  isResourceBookingPortfolioBased: boolean,
) => {
  const buildingUuid = resource.building_uuid || '';

  const appInstanceConfig = appInstanceConfigs.find(appInstance => appInstance.location_id === buildingUuid);
  if (appInstanceConfig && isResourceBookingPortfolioBased) {
    const city = appInstanceConfig.location_city || '';
    return `${city} (${appInstanceConfig.location_name})`;
  }
  return '';
};
