import { FormattedDates } from 'hooks/use-preselected-day-loading/use-preselected-day-loading.interface';
import { DATE_FORMAT } from 'shared/consts';
import { bookingTimeHandler } from 'utils/bookingTimeHandler';

const NUMBER_OF_PRELOADED_MONTHS = 2;

export const getFormattedDates = (numberOfMonths: number, locale: string): FormattedDates => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();

  const firstDay = new Date(currentYear, currentMonth + NUMBER_OF_PRELOADED_MONTHS + numberOfMonths, 1);
  const lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0);

  const firstDayTime = firstDay.toLocaleTimeString(locale, { hour: 'numeric', minute: 'numeric' });
  const lastDayTime = lastDay.toLocaleTimeString(locale, { hour: 'numeric', minute: 'numeric' });
  const timeRange = { startTime: firstDayTime, endTime: lastDayTime, locale };
  const timeHandler = bookingTimeHandler(timeRange);
  const formattedFirstDay = timeHandler.getFormatDate(firstDay, DATE_FORMAT);
  const formattedLastDay = timeHandler.getFormatDate(lastDay, DATE_FORMAT);

  return { formattedFirstDay, formattedLastDay };
};
