import { StatusPill } from '@hqo/react-components-library/dist/atoms/statusPill';
import styled from 'styled-components';

export const StyledPill = styled(StatusPill)`
  background-color: ${({ theme }) => theme.colors.greys.sharedGreyBackground};
  border-radius: 4px;
  white-space: nowrap;

  .status-pill {
    font: 500 16px/18px ${({ theme }) => theme.fonts.join()};
    color: ${({ theme }) => theme.colors.fontColor};
  }
`;
