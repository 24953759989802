import { ACTION_STATUSES } from 'shared/consts';
import React from 'react';
import { CheckoutMobile } from './checkout-mobile';
import { Order } from 'store/cart/types';
import { SavedPaymentMethod } from 'store/payment/types';

interface CheckoutMobileContentProps {
  cart: Order;
  onRemovePaymentMethod: VoidFunction;
  defaultPaymentMethod: string;
  paymentError: boolean;
  paymentMethods: SavedPaymentMethod[];
  onAddPaymentMethodClick: VoidFunction;
  onPayClick: VoidFunction;
  submitCartStatus: ACTION_STATUSES;
  isCartFulfilled: boolean;
  showAddCardForm: boolean;
  cartId: string;
  submittedPaymentMethodId: number;
  onCancelAddCardClick: VoidFunction;
  isAddCardButtonDisabled: boolean;
  setIsAddCardButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  pendingNativePayment: boolean;
  isNativePaymentError: boolean;
}

export const CheckoutMobileContent = ({
  cart,
  cartId,
  defaultPaymentMethod,
  paymentError,
  isCartFulfilled,
  onAddPaymentMethodClick,
  onPayClick,
  onRemovePaymentMethod,
  paymentMethods,
  showAddCardForm,
  submitCartStatus,
  submittedPaymentMethodId,
  onCancelAddCardClick,
  isAddCardButtonDisabled,
  setIsAddCardButtonDisabled,
  pendingNativePayment,
  isNativePaymentError,
}: CheckoutMobileContentProps): JSX.Element => {
  return (
    <CheckoutMobile
      cart={cart}
      onRemovePaymentMethod={onRemovePaymentMethod}
      currentPaymentMethodId={defaultPaymentMethod}
      submittedPaymentMethodId={submittedPaymentMethodId}
      currentPaymentError={paymentError}
      paymentMethods={paymentMethods}
      onAddPaymentMethodClick={onAddPaymentMethodClick}
      onPayClick={onPayClick}
      submitCartStatus={submitCartStatus}
      isCartFulfilled={isCartFulfilled}
      showAddCardForm={showAddCardForm}
      cartId={cartId}
      onCancelAddCardClick={onCancelAddCardClick}
      isAddCardButtonDisabled={isAddCardButtonDisabled}
      setIsAddCardButtonDisabled={setIsAddCardButtonDisabled}
      pendingNativePayment={pendingNativePayment}
      isNativePaymentError={isNativePaymentError}
    />
  );
};
