import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { ACTION_STATUSES } from 'shared/consts';
import { ResourceTimeRangesState } from './types';

const initialState: ResourceTimeRangesState = {
  time_ranges: null,
  preset_bookable_windows: null,
  getResourceTimeRanges: {
    status: null,
    error: null,
  },
};

const getResourceTimeRangesRequestHandler = (state: ResourceTimeRangesState): ResourceTimeRangesState => ({
  ...state,
  getResourceTimeRanges: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getResourceTimeRangesSuccessHandler = (
  state: ResourceTimeRangesState,
  { payload }: ActionType<typeof actions.getResourceTimeRanges.success>,
): ResourceTimeRangesState => ({
  ...state,
  time_ranges: payload.time_ranges,
  preset_bookable_windows: payload?.preset_bookable_windows,
  getResourceTimeRanges: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getResourceTimeRangesFailureHandler = (
  state: ResourceTimeRangesState,
  { payload: error }: ActionType<typeof actions.getResourceTimeRanges.failure>,
): ResourceTimeRangesState => ({
  ...state,
  getResourceTimeRanges: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const resetResourceTimeRangesHandler = (): ResourceTimeRangesState => ({
  time_ranges: null,
  preset_bookable_windows: null,
  getResourceTimeRanges: {
    error: null,
    status: null,
  },
});

const setResourceTimeRangesHandler = (
  state: ResourceTimeRangesState,
  { payload }: ActionType<typeof actions.setResourceTimeRanges>,
): ResourceTimeRangesState => ({
  ...state,
  time_ranges: payload,
});

const resourceTimeRangesReducer = createReducer(initialState)
  .handleAction(actions.getResourceTimeRanges.request, getResourceTimeRangesRequestHandler)
  .handleAction(actions.getResourceTimeRanges.success, getResourceTimeRangesSuccessHandler)
  .handleAction(actions.getResourceTimeRanges.failure, getResourceTimeRangesFailureHandler)
  .handleAction(actions.resetResourceTimeRanges, resetResourceTimeRangesHandler)
  .handleAction(actions.setResourceTimeRanges, setResourceTimeRangesHandler);

export default resourceTimeRangesReducer;
