import { OpenCloseTimes } from './getOpeningHours.enum';

export const getFilteredOpeningHours = (openingHours: OpenCloseTimes[]): Record<string, string> => {
  return openingHours?.reduce((time, period) => {
    const { open, close } = period;
    const result = time;
    if (result[open]) {
      const actual = result[open];
      delete result[open];
      result[close] = actual;
    } else {
      result[close] = open;
    }

    return result;
  }, {} as Record<string, string>);
};
