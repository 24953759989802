import React from 'react';
import { useSelector } from 'react-redux';
import { selectResourceInfiniteAvailableDates } from 'store/resource-available-dates/selectors';
import { InfiniteCalendarView } from '../infinite-calendar-view';

export const InfiniteView = (): JSX.Element => {
  const resourceInfiniteAvailableDates = useSelector(selectResourceInfiniteAvailableDates);

  return (
    <>
      {Object.keys(resourceInfiniteAvailableDates)?.map(indexOfMonth => (
        <InfiniteCalendarView key={indexOfMonth} indexOfMonth={+indexOfMonth} />
      ))}
    </>
  );
};
