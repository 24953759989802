import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hook';
import { useEffect } from 'react';

export const useToggleNativeHeader = (shouldToggleNativeMobileHeader = true) => {
  const client = useMiniappSdk();

  useEffect(() => {
    if (shouldToggleNativeMobileHeader) {
      client?.header.hideHeader();

      return () => client?.header.showHeader();
    }
  }, [client, shouldToggleNativeMobileHeader]);
};
