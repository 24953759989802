import React, { useCallback } from 'react';
import { ItemWrapper, ItemLabel } from './styles';
import { WheelPickerItemProps } from './wheel-picker-item.interface';

export const WheelPickerItem = ({
  item: { value, label },
  setRef,
  itemHeight,
  onItemClick,
}: WheelPickerItemProps): JSX.Element => {
  const handleClick = useCallback(() => onItemClick?.(value), [value, onItemClick]);

  return (
    <ItemWrapper ref={setRef} itemHeight={itemHeight} onClick={handleClick} data-testid="wheel-picker-item">
      <ItemLabel data-testid="wheel-picker-item-label">{label}</ItemLabel>
    </ItemWrapper>
  );
};
