import React from 'react';
import { ResourcesTypesContainer, Wrapper } from './styles';
import { TypesTitle } from './types-title';
import { TypesContent } from './types-content';
import { useGetCurrentLocationResourcesTypesQuery } from 'store/resources/hooks';

export const ResourcesTypes = (): JSX.Element => {
  const { data: resourceTypes } = useGetCurrentLocationResourcesTypesQuery();

  return (
    <Wrapper data-testid="resources-types-wrapper">
      <TypesTitle />
      <ResourcesTypesContainer>
        <TypesContent resourceTypes={resourceTypes} />
      </ResourcesTypesContainer>
    </Wrapper>
  );
};
