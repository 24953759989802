import { GetResourcesParams, ResourcesResponse } from './types';

import { FailureActionPayload } from '../errors/types';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const getResources = createAsyncAction(
  'GET_RESOURCES_REQUEST',
  'GET_RESOURCES_SUCCESS',
  'GET_RESOURCES_FAILURE',
)<GetResourcesParams, ResourcesResponse, FailureActionPayload>();

export const resetResourcesState = createAction('RESET_RESOURCES_STATE')<undefined>();

export const resetInfiniteResourcesState = createAction('RESET_INFINITE_RESOURCES_STATE')<undefined>();

export const resetGetResourcesTypes = createAction('RESET_GET_RESOURCES_TYPES')<undefined>();

export const getInfiniteResources = createAsyncAction(
  'GET_INFINITE_RESOURCES_REQUEST',
  'GET_INFINITE_RESOURCES_SUCCESS',
  'GET_INFINITE_RESOURCES_FAILURE',
)<GetResourcesParams, ResourcesResponse, FailureActionPayload>();
