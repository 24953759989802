import React, { useCallback } from 'react';
import { StyledContentTile, ResponsiveResourceTile, StyledVerticalContentTile } from './styles';
import AvailableNowLabels from 'components/available-now-block/components/available-now-labels/available-now-labels';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';

interface ResourceItemProps {
  uuid: string;
  title: string;
  capacity: number;
  subtitle: string;
  isPaidResource: boolean;
  imageUrl: string;
  locationUuid: string;
  onClick?: (resourceUuid: string, locationUuid: string) => void;
}

export const ResourceItem: React.FC<ResourceItemProps> = ({
  uuid,
  title,
  subtitle,
  imageUrl,
  capacity,
  onClick,
  isPaidResource,
  locationUuid,
}: ResourceItemProps): JSX.Element => {
  const isMobileDevice = useIsSmallViewportWidth();
  const onTileClick = useCallback(() => {
    onClick(uuid, locationUuid);
  }, [onClick, uuid, locationUuid]);
  const contentTileProps = {
    className: 'resource-tile',
    title,
    subtitle,
    imageUrl,
    onClick: onTileClick,
    statusLabels: [<AvailableNowLabels key="labels" isPaid={isPaidResource} capacity={capacity} title={title} />],
    skeleton: <ResponsiveResourceTile />,
  };

  return isMobileDevice ? (
    <StyledContentTile {...contentTileProps} />
  ) : (
    <StyledVerticalContentTile {...contentTileProps} />
  );
};
