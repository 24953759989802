export interface SwitchDefaultFilterProps {
  children: Array<JSX.Element>;
  value: string;
}

export const SwitchDefaultFilter = ({ children, value }: SwitchDefaultFilterProps): JSX.Element => {
  if (!value) return null;

  return children.find(child => child.props['data-value'] === value) ?? null;
};
